import * as actionTypes from '../action-types'
import { ALL_TOURS } from 'Common/constants'
const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case actionTypes.INIT_TOURS:
      const initState = {}
      ALL_TOURS.forEach(e => {
        initState[e] = !!localStorage.getItem(e)
      })
      return initState
    case actionTypes.SET_TOUR:
      localStorage.setItem(payload, 'true')
      return { ...state, [payload]: true }
    case actionTypes.REMOVE_ALL_TOURS:
      const removeState = {}
      ALL_TOURS.forEach(e => {
        localStorage.removeItem(e)
        removeState[e] = false
      })
      return removeState
    default:
      return state
  }
}
export default reducer
