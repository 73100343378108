import styled from 'styled-components'
import { Layout, Icon } from 'antd'
import { colors } from 'Common/constants'

export const HeaderBar = styled.div`
  height: 16px;
  background-color: ${colors.main};
  text-align: center;
  font-weight: bold;
  color: white;
`
export const StyledHeader = styled(Layout.Header)`
  &.ant-layout-header {
      background-color: ${colors.white};
      border: 1px solid ${colors.grey2};
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
      height: 72px;
      display: grid;
      grid-gap: 50px;
      grid-template-columns: ${props =>
        props.isTourAvailable ? '1fr 5fr auto auto' : '1fr 5fr auto'};
      align-items: center;
      padding: 0 16px;
      z-index: 999;
  }
`
export const AppLogo = styled.img``
export const TutorialIcon = styled(Icon)`
  font-size: 24px;
  color: ${colors.grey3};
`
