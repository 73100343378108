import React from 'react';
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import * as _ from 'lodash';
import MainLayout from "Hoc/MainLayout";
import {StyledH1} from "Common/styled";
import {fetchDepartments, fetchFamilies} from "Actions/data-actions";
import Loader from "Components/Loader";
import SearchProductsForm from "Components/SearchProductsForm";
import SearchProductsResults from "Components/SearchProductsResults";
import api from "Common/api";
import { handleServerSuccess, handleServerError } from 'Common/utils'
import { StyledButton } from 'Common/styled'
import { Row, Col, notification } from 'antd'

/**
 * Container description
 */
class SearchDossierReferences extends React.Component {
    /**
     * Component's state
     * @type {{isLoading: boolean, references: [], redirectTo: null}}
     */
    state = {
        isLoading: true,
        redirectTo: null,
        references: null,
        totalReferences: null,
        totalAssignableReferences: null,
        dossier: null,
        search: {},
        isSearching: false,
        isChangingPage: false,
        isSaving: false,
        newRefsCount: 0
    };

    /**
     * Component on mount
     */
    componentDidMount() {
        const {fetchDepartments, fetchFamilies} = this.props;
        const {dossierId} = this.props.match.params;

        Promise.all([
            fetchDepartments(),
            fetchFamilies(),
            api.dossiers.getOne(dossierId)
        ]).then(([departmentsResp, familiesResp, dossierResp]) => {
            const dossier = _.get(dossierResp, ['data', 'elements', 0], null);
            let redirectTo = null;

            if (_.isNil(dossier)) {
                redirectTo = '/';
            }

            if (!['XS', 'PL'].includes(dossier.type)) {
                api.references.count(dossier.idFamily, dossier.department, dossier.id).then(response => {
                    this.setState({isLoading: false, dossier: dossier, redirectTo: redirectTo, newRefsCount: response.data});
                });
            } else {
                this.setState({isLoading: false, dossier: dossier, redirectTo: redirectTo});
            }
        });
    }

    /**
     * Handle on form search submit
     * @param search
     */
    handleOnFormSubmit = (search) => {
        if (!_.isNil(this.searchProductsResults)) {
            this.searchProductsResults.reset();
        }
        search.dossier = this.state.dossier.id
        search.nPage = 0;
        search.dimPage = 10;
        this.setState({isSearching: true})
        api.references.get(search).then(response => {
            if (response.data.invalidReferences.length > 0) {
                notification['warning']({
                    message: 'Referenze non valide',
                    duration: 0,
                    description:
                      'Le seguenti referenze inserite nel campo di ricerca non sono valide: ' + response.data.invalidReferences.join(', '),
                });
            }

            this.setState({
                references: _.get(response, 'data.elements', []),
                totalReferences: _.get(response, 'data.numTotElements', 0),
                totalAssignableReferences: _.get(response, 'data.numTotAssignableElements', 0),
                search: search
            });
        }).finally(() => this.setState({ isSearching: false }))
    };

    handleSave = (addBySubtraction, checkedItems, uncheckedItems) => {
        const {id} = this.state.dossier
        const {
          department,
          dimPage,
          dossier,
          family,
          nPage,
          productCodes,
          productDescription,
          productLine,
          state,
          subdepartments,
          topxs,
          flagSimcom
        } = this.state.search
        let payload

        console.log(this.state.search);

        if (addBySubtraction) {
            payload = {
                department,
                dimPage,
                dossier,
                family,
                nPage,
                productCodes,
                productDescription,
                productLine,
                state,
                subdepartments,
                topxs,
                flagSimcom,
                mode: 'unchecked',
                refs: uncheckedItems}
        } else {
            payload = {mode: 'checked', refs: checkedItems}
        }

        this.setState({isSaving: true})
        api.dossiers.addRefs(id, payload).then(() => {
            handleServerSuccess();
            if (this.state.dossier.type === 'PS') {
                this.setState({redirectTo: `/prodotti-simbolo/${this.state.dossier.idStore}`})
            } else {
                this.setState({redirectTo: `/edit-dossier/${id}`})
            }
        }, handleServerError).finally(() => this.setState({isSaving: false}))
    }

    /**
     * Handle on pagination page change
     * @param page
     * @param pageSize
     */
    handleOnPageChange = (page, pageSize) => {
        const {search} = this.state
        this.setState({isChangingPage: true})
        api.references.get({...search, nPage: page - 1, dimPage: pageSize}).then(response => this.setState({references: _.get(response, 'data.elements', []), totalReferences: _.get(response, 'data.numTotElements', 0)})).finally(() => this.setState({isChangingPage: false}));
    }

    /**
     * Render method
     * @returns {*}
     */
    render() {
        const {isLoading, redirectTo, newRefsCount, references, totalReferences, totalAssignableReferences, dossier, isSearching, isChangingPage, isSaving} = this.state;
        const {departments, families, user} = this.props;

        return redirectTo !== null ? <Redirect to={redirectTo} /> : isLoading ? <Loader/> :
        (
            <MainLayout
                tourSteps={[]}
                isTourAvailable={false}
                isTourOpen={false}
                defaultCurrentPage={'dossier'}
            >
                <div>
                    <Row>
                        <Col xs={{span: 22, offset: 1}} xl={{span: 18, offset: 3}} xxl={{span: 12, offset: 6}}
                            style={{
                                position: 'relative'
                            }}>
                            <StyledButton
                                icon="left"
                                style={{ position: 'absolute',  left: '0', top: '50%', transform: 'translateY(-50%)'}}
                                onClick={ () =>
                                    this.setState({ redirectTo: this.state.dossier.type === 'PS' ? `/prodotti-simbolo/${this.state.dossier.idStore}` : `/edit-dossier/${dossier.id}` })
                                }>
                                Indietro
                            </StyledButton>
                            <StyledH1 style={{margin: 'auto'}}>Ricerca Referenze</StyledH1>
                        </Col>
                    </Row>
                </div>
                <SearchProductsForm newRefsCount={newRefsCount} dossier={dossier} onSubmit={this.handleOnFormSubmit} departments={departments} families={families} isSearching={isSearching} user={user} />
                {references && <SearchProductsResults ref={ref => this.searchProductsResults = ref} items={references} totalItems={totalReferences} totalAssignableItems={totalAssignableReferences} departments={departments} onPageChange={this.handleOnPageChange} onSave={this.handleSave} isSearching={isSearching} isChangingPage={isChangingPage} isSaving={isSaving} dossier={dossier} />}
            </MainLayout>
        );
    }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => ({
    departments: state.Data.departments,
    families: state.Data.families,
    user: state.User
});

/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => ({
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchFamilies: () => dispatch(fetchFamilies())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchDossierReferences));
