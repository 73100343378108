import store from 'Store';
import { Button, Popover } from "antd";
import { NotePopupIcon } from "Components/LMTable/styled";
import React from "react";
import {
    ROLE_CP,
    ROLE_CPXS,
    ROLE_CS,
    ROLE_MERCH,
    ROLE_AZ_COMM,
    ROLE_GUEST,
    TITLE_STORE_LEADER,
    TITLE_STORE_PERFORMANCE_LEADER,
    ROLE_CS_WEB
} from 'Common/constants/global'
import * as moment from 'moment';
import { dossierTypes } from './constants';

/**
 * Class to manage app access for various parts
 */
export default class AppAccess {
    static ROLE_CP = ROLE_CP;
    static ROLE_MERCH = ROLE_MERCH;
    static ROLE_CS = ROLE_CS;
    static ROLE_CPXS = ROLE_CPXS;
    static ROLE_AZ_COMM = ROLE_AZ_COMM;
    static ROLE_CS_WEB = ROLE_CS_WEB;
    static ROLE_GUEST = ROLE_GUEST;

    static TITLE_STORE_LEADER = TITLE_STORE_LEADER;
    static TITLE_STORE_PERFORMANCE_LEADER = TITLE_STORE_PERFORMANCE_LEADER;

    /**
     * Check if a specific identifier is authorized
     * @param identifier 
     * @param opts
     * @returns {boolean}
     */
    static isAuthorized = (identifier, opts = {}) => {
        const user = store.getState().User;

        switch (identifier) {
            case 'anagrafica':
                return [ROLE_MERCH, ROLE_CPXS].includes(user.role);

            case 'app':
                return [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_CPXS, ROLE_AZ_COMM, ROLE_GUEST, ROLE_CS_WEB].includes(user.role);

            case 'family':
                return user.role === ROLE_MERCH;

            case 'family-store':
                return user.role === ROLE_CS || user.role === ROLE_MERCH;

            case 'dossier':
                return user.role !== AppAccess.ROLE_AZ_COMM && user.role !== AppAccess.ROLE_CS_WEB;

            case 'sng':
                return ['claudio.bota@ext.leroymerlin.it', 'angelo.antonielli@leroymerlin.it', 'irene.nocenti@leroymerlin.it', 'cristina.norello@leroymerlin.it'].includes(user.id);

            case 'opecom':
                return [ROLE_CP, ROLE_CS, ROLE_AZ_COMM, ROLE_GUEST, ROLE_CS_WEB].includes(user.role);

            case 'create_dossier':
                return [ROLE_CP, ROLE_CPXS, ROLE_CS].includes(user.role);

            case 'edit_dossier':
                if (user.role === ROLE_CP && ['CR', 'R1'].includes(opts.stato) && ['NG', 'RG', 'ST'].includes(opts.type) && opts.storeId === null) {
                    return true;
                }
                if (user.role === ROLE_MERCH && ['CR', 'R1'].includes(opts.stato) && ['NG', 'RG', 'ST'].includes(opts.type) && opts.storeId === null) {
                    return true;
                }
                if (user.role === ROLE_CPXS && ['CR', 'R1'].includes(opts.stato) && opts.type === 'XS' && opts.storeId === null) {
                    return true;
                }
                if (user.role === ROLE_CS && opts.stato === 'CC' && ['NG', 'RG', 'XS', 'ST'].includes(opts.type) && opts.storeId !== null && opts.storeId === user.store) {
                    return true;
                }
                if (user.role === ROLE_CS && ['CR', 'R2'].includes(opts.stato) && opts.type === 'PL' && moment().isSameOrBefore(moment(opts.installationTo), 'day') && opts.storeId !== null && opts.storeId === user.store) {
                    return true;
                }

                return false;

            case 'edit_dossier_header':
                if (user.role === ROLE_CP && ['CR', 'R1'].includes(opts.stato) && ['NG', 'RG', 'ST'].includes(opts.type)) {
                    return true;
                }
                if (user.role === ROLE_CPXS && ['CR', 'R1'].includes(opts.stato) && opts.type === 'XS') {
                    return true;
                }
                if (user.role === ROLE_CS && opts.stato === 'CC' && ['NG', 'RG', 'ST'].includes(opts.type)) {
                    return true;
                }

                if (user.role === ROLE_CS && ['CR', 'R2'].includes(opts.stato) && opts.type === 'PL' && (opts.creator === user.id || user.mission === TITLE_STORE_LEADER || user.mission === TITLE_STORE_PERFORMANCE_LEADER) && moment().isSameOrBefore(moment(opts.installationTo), 'day')) {
                    return true;
                }

                return false;

            case 'delete_dossier':
                if (user.role === ROLE_CP && ['CR', 'R1'].includes(opts.stato) && ['NG', 'RG', 'ST'].includes(opts.type) && opts.creator === user.id) {
                    return true;
                }

                if (user.role === ROLE_CS && ['CR', 'R2'].includes(opts.stato) && opts.type === 'PL' && (opts.creator === user.id || user.mission === TITLE_STORE_LEADER || user.mission === TITLE_STORE_PERFORMANCE_LEADER) && moment().isSameOrBefore(moment(opts.installationTo).subtract(15, 'days'), 'day')) {
                    return true;
                }

                return false;

            case 'mass_ref_dossier':
                if (user.role === ROLE_CP && ['CR', 'R1'].includes(opts.stato) && ['NG', 'RG', 'ST'].includes(opts.type)) {
                    return true;
                }
                if (user.role === ROLE_MERCH && ['CR', 'R1'].includes(opts.stato) && ['NG', 'RG', 'ST'].includes(opts.type)) {
                    return true;
                }
                if (user.role === ROLE_CPXS && ['CR', 'R1'].includes(opts.stato) && opts.type === 'XS') {
                    return true;
                }
                if (user.role === ROLE_CS && opts.stato === 'CC' && ['NG', 'RG', 'ST'].includes(opts.type)) {
                    return true;
                }

                return false;

            case 'add_ref_dossier':
            case 'del_ref_dossier':
                if (user.role === ROLE_CP && ['CR', 'R1'].includes(opts.stato) && opts.type !== 'XS') {
                    return true;
                }
                if (user.role === ROLE_CPXS && ['CR', 'R1'].includes(opts.stato) && opts.type === 'XS') {
                    return true;
                }
                if (user.role === ROLE_CS && opts.stato === 'CR' && opts.type === 'PL') {
                    return true;
                }

                return false;

            case 'duplicate-dossier':
                if (((['NG', 'RG', 'ST', 'XS'].includes(opts.type) && opts.idStore === null) || opts.type === 'PL') && dossierTypes.filter(type => type.key === opts.type)[0].canCreate.includes(user.role)) {
                    return true;
                }

                return false;

            case 'edit_opecom':
                if (user.role === ROLE_AZ_COMM && opts.store === null && ['CR', 'R1'].includes(opts.stato)) {
                    return true;
                }

                if (user.role === ROLE_CP && opts.store === null && ['CR', 'R1'].includes(opts.stato)) {
                    return true;
                }

                if (user.role === ROLE_CS && opts.store !== null && opts.stato === 'CC') {
                    return true;
                }

                if (user.role === ROLE_CS_WEB && opts.store !== null && user.store === opts.store && opts.stato === 'CC') {
                    return true;
                }

                return false;

            case 'mass_ref_opecom':
                if (user.role === ROLE_CP && opts.store === null && ['CR', 'R1'].includes(opts.stato)) {
                    return true;
                }

                return false;

            case 'prodotti_simbolo':
                return user.role !== ROLE_CS_WEB;

            case 'add_prodotto_simbolo':
            case 'change_header_prodotti_simbolo':
                return user.role === ROLE_AZ_COMM || user.role === ROLE_CPXS;

            case 'edit_cluster_garden':
                return ['ST', 'NG', 'RG'].includes(opts.dossierType) && opts.dossierStore === null && opts.dossierState === 'CR' && user.role === ROLE_CP;

            default:
                return false;
        }
    };


    /**
     * Get columns by role
     * @param column
     * @returns {boolean}
     */
    static dossierReferencesColumnsFilterByRole = (column) => {
        const user = store.getState().User;
        let filteredCols = [];

        switch (user.role) {
            case ROLE_CP:
                filteredCols = ["noteMerch", "modGestionNegozio", "topReappro", "stockxs", "dataPrevArrivoDeposito", "pcbNegozio"];
                break;

            case ROLE_MERCH:
                filteredCols = ["modGestionNegozio", "topReappro", "stockxs", "dataPrevArrivoDeposito", "pcbNegozio"];
                break;

            case ROLE_CS:
                filteredCols = ["stato", "prevVMM", "vmm", "numNegozi", "stock", "pcb"];
                break;

            default:
        }

        return filteredCols.indexOf(column.dataIndex) === -1;
    };

    /**
     * Map columns by role
     * @param column
     * @returns {*}
     */
    static dossierReferencesColumnsMapByRole = (column, dossierState) => {
        const updatedColumn = { ...column };
        const user = store.getState().User;

        if (!column.editable) {
            return column;
        }

        let editableCols = [];

        switch (user.role) {
            case ROLE_CP:
                if (dossierState === "CR") {
                    editableCols = ["prevVMM", "idRefSostituita", "gammaToBe", "precotopReapproToBe", "topxsToBe", "noteCp", "tipoSostituzione"];
                }
                break;

            case ROLE_MERCH:
                if (dossierState === "CR") {
                    editableCols = ["facingAbc", "facingAb", "facingA", "stock", "numPdd", "noteMerch"];
                }
                break;

            case ROLE_CS:
                editableCols = ["facingAbc", "facingAb", "facingA", "topReappro"];
                break;

            default:
        }

        if (editableCols.indexOf(column.dataIndex) === -1 && updatedColumn.editable) {
            updatedColumn.editable = false;

            if (updatedColumn.dataIndex === 'noteCp' || updatedColumn.dataIndex === 'noteMerch') {
                updatedColumn.render = (value, row) => (
                    <Popover
                        content={
                            <p style={{ marginBottom: 0 }}>{row[updatedColumn.dataIndex]}</p>
                        }
                        trigger="click"
                        placement="left">
                        <Button type="link" size="small" title="Visualizza la nota">
                            <NotePopupIcon type="eye" active={row['noteMerch']} />
                        </Button>
                    </Popover>
                )
            }
        }

        return updatedColumn;
    };
};
