// APP
export const SET_LOADING = 'SET_LOADING'
export const FETCH_BUILD_INFO = 'FETCH_BUILD_INFO'

// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const SET_USER = 'SET_USER';
export const SET_SPECIAL_AUTH = 'SET_SPECIAL_AUTH';

// TOUR
export const INIT_TOURS = 'INIT_TOURS'
export const SET_TOUR = 'SET_TOUR'
export const REMOVE_ALL_TOURS = 'REMOVE_ALL_TOURS'

// DATA
export const FETCH_VALIDITY_PERIODS = 'FETCH_VALIDITY_PERIODS'
export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS'
export const FETCH_SUBDEPARTMENTS = 'FETCH_SUBDEPARTMENTS'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_SUBCATEGORIES = 'FETCH_SUBCATEGORIES'
export const FETCH_FAMILIES = 'FETCH_FAMILIES'
export const FETCH_DOSSIERS = 'FETCH_DOSSIERS'
export const FETCH_STORES = 'FETCH_STORES'
export const FETCH_PRODUCT_IDS = 'FETCH_PRODUCT_IDS'

// EDIT
export const EDIT_FAMILY = 'EDIT_FAMILY'
export const SAVE_FAMILY = 'SAVE_FAMILY'
export const FETCH_FAMILY = 'FETCH_FAMILY'
export const EDIT_DOSSIER = 'EDIT_DOSSIER'
export const SAVE_DOSSIER = 'SAVE_DOSSIER'
export const FETCH_DOSSIER = 'FETCH_DOSSIER'

// SEARCH
export const SAVE_DOSSIER_FILTERS = 'SAVE_DOSSIER_FILTERS'
export const SAVE_OPECOM_FILTERS = 'SAVE_OPECOM_FILTERS'
export const SAVE_FAMILY_FILTERS = 'SAVE_FAMILY_FILTERS';

// NOTIFICATIONS
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'