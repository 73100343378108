import styled from 'styled-components'
import { Descriptions, Button } from 'antd'

export const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-title {
    margin-bottom: 16px;
  }
  margin-bottom: 16px;
`

export const StyledButton = styled(Button)`
  margin-left: 16px;
`

export const StyledDiv = styled.div`
  display: flex;
  justifyContent: space-between;
  alignItems: center;
`

export const StyledTitle = styled.div`
  flex-grow: 1;
  margin-left: 16px;
  font-size: 20px;
`
