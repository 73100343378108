import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { ROLE_MERCH } from 'Common/constants/global'
import api from 'Common/api'
import NomenclatureNotification from 'Components/NomenclatureNotification'
import { dismissNotification } from 'Actions/notifications-actions'

/**
 * Container description
 */
class Notifications extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    redirectTo: null,
    showNomenclatures: false,
    nomenclatures: [],
  }

  componentDidMount() {
    const { nomenclatures } = this.props.notifications

    switch (this.props.user.role) {
      case ROLE_MERCH:
        if (!nomenclatures.dismissed) {
          api.nomenclature.get(false).then(response => {
            if (response.data.length > 0) {
              this.setState({
                showNomenclatures: true,
                nomenclatures: response.data,
              })
            }
          })
        }
        break

      default:
        break
    }
  }

  dismiss(notificationKey) {}

  /**
   * Render method
   * @returns {*}
   */
  render() {
    const { redirectTo, showNomenclatures, nomenclatures } = this.state
    const { dismissNotification } = this.props

    return redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : (
      <NomenclatureNotification
        visible={showNomenclatures}
        items={nomenclatures}
        onOk={() => {
          this.setState({ redirectTo: '/' })
          dismissNotification('nomenclatures')
        }}
        onCancel={() => {
          this.setState({ showNomenclatures: false })
          dismissNotification('nomenclatures')
        }}
        currentPath={this.props.location.pathname}
      />
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => ({
  user: state.User,
  notifications: state.Notifications,
})

/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => ({
  dismissNotification: key => dispatch(dismissNotification(key)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Notifications))
