import React, {forwardRef, useImperativeHandle, useState}  from 'react';
import { getProductInfoTranslatedDescriptions } from '../../../common/utils'

export default forwardRef((props, ref) => {
  const [node, setNode] = useState(
    props.api.getDisplayedRowAtIndex(props.rowIndex)
  );

  useImperativeHandle(ref, () => ({
    getReactContainerClasses() {
      return ['ag-grid-custom-info-tooltip']
    }
  }));

  let errors = node.data.errors || [];

  return (
    <div
      className="ag-grid-custom-info-tooltip-inner"
    >
      {getProductInfoTranslatedDescriptions(errors).map((description, index) => <p key={index}>{description}</p>)}
    </div>
  );
});
