import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import User from 'Components/User'
import NavigationTabs from 'Components/NavigationTabs'
import { StyledHeader, HeaderBar, AppLogo, TutorialIcon } from './styled'

const Header = props => {
  const { isTourAvailable, user, resetTours, onNavigationChange, defaultCurrentPage } = props

  let style = {};
  let title = '';


  if (process.env.REACT_APP_ENV === 'test') {
    title = 'AMBIENTE DI TEST';
    style = {
      height: '30px',
      lineHeight: '30px',
      backgroundColor: '#338acc'
    };
  } else if (process.env.REACT_APP_ENV === 'preprod') {
    title = 'AMBIENTE DI PRE PRODUZIONE';
    style = {
      height: '30px',
      lineHeight: '30px',
      backgroundColor: '#cc8c33'
    };
  } else if (process.env.REACT_APP_ENV === 'dev') {
    title = 'AMBIENTE DI SVILUPPO';
    style = {
      height: '30px',
      lineHeight: '30px'
    };
  }

  return (
    <Fragment>
      <HeaderBar style={style}>{title}</HeaderBar>
      <StyledHeader isTourAvailable={isTourAvailable}>
        {/*<AppLogo src="/assets/img/LM-logo.png" />*/}
        <NavigationTabs onNavigationChange={onNavigationChange} defaultCurrentPage={defaultCurrentPage} />
        {!isEmpty(user) && <User user={user} />}
        {isTourAvailable && (
          <TutorialIcon
            theme="filled"
            type="question-circle"
            onClick={resetTours}
            title="Show tutorial"
          />
        )}
      </StyledHeader>
    </Fragment>
  )
}

Header.propTypes = {
  isTourAvailable: PropTypes.bool,
  user: PropTypes.object,
  resetTours: PropTypes.func
}
export default Header
