import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import _ from 'lodash'

import { setTour } from 'Actions/tour-actions'
import {
  fetchDepartments,
  fetchSubdepartments,
  fetchCategories,
  fetchSubcategories
} from 'Actions/data-actions'
import { editFamily, saveFamily, fetchFamily } from 'Actions/edit-actions'

import { Popconfirm, Tooltip, Input, Col, Modal, List, Button, Checkbox, Row, Select } from 'antd'
import MainLayout from 'Hoc/MainLayout'
import {
  ButtonBar,
  StyledButton,
  Title,
  StyledForm,
  StyledFormItem,
  StyledDiv,
  StyledRow
} from './styled'
import { SubdepartmentModal } from 'Components/SubdepartmentModal'
import SubdepartmentRow from 'Components/SubdepartmentRow'
import AddButton from 'Components/AddButton'
import Loader from 'Components/Loader'

import { steps } from './constants'
import { TOUR_HOME } from 'Common/constants'
import { handleServerSuccess, handleServerError, feedbackNotification } from 'Common/utils'
import api from 'Common/api'
import { AgGridReact } from 'ag-grid-react'
import { AG_GRID_LOCALE_IT } from 'Common/ag-grid.locale'
import { ROLE_MERCH } from 'Common/constants/global'

class Edit extends Component {
  static propTypes = {
    tour: PropTypes.object,
    setTour: PropTypes.func,
    fetchDepartments: PropTypes.func,
    fetchSubdepartments: PropTypes.func,
    editFamily: PropTypes.func
  }

  state = {
    originalFamily: {},
    familyName: { validateStatus: null, errorMsg: null },
    isLoading: true,
    isSubdepartmentModalVisible: false,
    canSave: false,
    saveButtonTooltip: '',
    isSaving: false,
    saveAndStay: false,
    isDeletingFamily: false,
    redirectTo: null,
    importModalVisible: false,
    familyTree: [],
    familyTreeSelections: {
      family: null,
      subdepartment: null,
      category: null
    },
    familyTreeLoading: true,
    familyTreeImport: [],
    familyTreeSelected: [],
    familyTreeImportTrace: {},
    families: [],
    subdepartments: [],
    categories: [],
    subcategories: [],
    selectedFamilies: [],
    selectedSubdepartments: [],
    selectedCategories: [],
    selectedSubcategories: []
  }

  componentDidMount() {
    const id = this.props.match.params.id
    if (id) {
      Promise.all([
        this.props
          .fetchFamily(id)
          .then(() =>
            this.props.fetchSubdepartments(this.props.family.idDepartment)
          ),
        this.props.fetchDepartments()
      ]).then(() =>
        this.setState({
          originalFamily: _.cloneDeep(this.props.family),
          isLoading: false
        })
      )
    } else {

      Promise.all([
        this.props.fetchSubdepartments(this.props.family.idDepartment),
        this.props.fetchDepartments()
      ]).then(() =>
        this.setState({
          originalFamily: _.cloneDeep(this.props.family),
          isLoading: false
        })
      )
    }
  }

  loadFamilyTreeList() {
    const { familyTreeSelections } = this.state;
    let query = {};

    if (
      familyTreeSelections.family === null
      && familyTreeSelections.subdepartment === null
      && familyTreeSelections.category === null
    ) {
      query = {
        selectedFamily: this.props.family.idFamily || 0,
        department: this.props.family.idDepartment || null,
        tuple: true,
        selections: ['FAMILY_ID', 'FAMILY_NAME'],
        distinct: true
      }
    } else if (
      familyTreeSelections.family !== null
      && familyTreeSelections.subdepartment === null
      && familyTreeSelections.category === null
    ) {
      query = {
        family: familyTreeSelections.family,
        department: this.props.family.idDepartment || null,
        tuple: true,
        selections: ['SUBDEPARTMENT_ID', 'SUBDEPARTMENT_NAME'],
        distinct: true
      }
    } else if (
      familyTreeSelections.family !== null
      && familyTreeSelections.subdepartment !== null
      && familyTreeSelections.category === null
    ) {
      query = {
        family: familyTreeSelections.family,
        subdepartment: familyTreeSelections.subdepartment,
        department: this.props.family.idDepartment || null,
        tuple: true,
        selections: ['CATEGORY_ID', 'CATEGORY_NAME'],
        distinct: true
      }
    } else if (
      familyTreeSelections.family !== null
      && familyTreeSelections.subdepartment !== null
      && familyTreeSelections.category !== null
    ) {
      query = {
        family: familyTreeSelections.family,
        subdepartment: familyTreeSelections.subdepartment,
        category: familyTreeSelections.category,
        department: this.props.family.idDepartment || null,
        tuple: true,
        selections: ['SUBCATEGORY_ID', 'SUBCATEGORY_NAME'],
        distinct: true
      }
    } else {
      return;
    }

    if (this.state.selectedFamilies.length > 0) {
      query.families = this.state.selectedFamilies;
    }

    if (this.state.selectedSubdepartments.length > 0) {
      query.subdepartments = this.state.selectedSubdepartments;
    }

    if (this.state.selectedCategories.length > 0) {
      query.categories = this.state.selectedCategories;
    }

    if (this.state.selectedSubcategories.length > 0) {
      query.subcategories = this.state.selectedSubcategories;
    }

    api.families.queryFamilyTree(query).then(response => {
      this.setState({
        familyTree: response.data,
        familyTreeLoading: false
      });
    }).catch(console.error);
  }

  componentDidMountFamily = () => {
    this.loadFamilyTreeList();

    api.families.queryFamilyTree({
      department: this.props.family.idDepartment,
      selections: ['SUBDEPARTMENT_ID', 'SUBDEPARTMENT_NAME'],
      distinct: true,
      tuple: true
    }).then(response => {
      this.setState({
        subdepartments: response.data
      });
    }).catch(console.error);

    api.families.queryFamilyTree({
      department: this.props.family.idDepartment,
      selections: ['FAMILY_ID', 'FAMILY_NAME'],
      distinct: true,
      tuple: true
    }).then(response => {
      this.setState({
        families: response.data
      });
    }).catch(console.error);
  };

  // TODO Check unused params
  componentDidUpdate(prevProps, prevState) {
    const { canSave, saveButtonTooltip } = this.checkIfSaveIsAllowed()
    if (canSave !== this.state.canSave) {
      this.setState({ canSave: canSave })
    }
    if (saveButtonTooltip !== this.state.saveButtonTooltip) {
      this.setState({ saveButtonTooltip: saveButtonTooltip })
    }

    if (prevProps.family !== this.props.family || (prevProps.family && this.props.family && prevProps.family.idFamily !== this.props.family.idFamily)) {
      this.componentDidMountFamily();
    }

    if (
      prevState.selectedFamilies !== this.state.selectedFamilies
      || prevState.selectedSubdepartments !== this.state.selectedSubdepartments
      || prevState.selectedCategories !== this.state.selectedCategories
      || prevState.selectedSubcategories !== this.state.selectedSubcategories
    ) {
      this.loadFamilyTreeList();
    }
  }

  showSubdepartmentModal = () => {
    this.setState({ isSubdepartmentModalVisible: true })
  }

  handleSubdepartmentModalCancel = () => {
    this.setState({ isSubdepartmentModalVisible: false })
  }

  handleSubdepartmentModalOk = () => {
    const { family } = this.props
    const { form } = this.subdepartmentModal.props
    form.validateFields((err, values) => {
      if (!err) {
        const updatedFamily = { ...family }
        updatedFamily.subdepartments.push({ ...values, categories: [] })
        this.props.editFamily(updatedFamily)
        form.resetFields()
        this.setState({ isSubdepartmentModalVisible: false })
      }
    })
  }

  handleDeleteSubdepartment = id => {
    const { family } = this.props
    const updatedFamily = { ...family }
    updatedFamily.subdepartments = family.subdepartments.filter(
      subdepartment => subdepartment.idSubDepartment !== id
    )
    this.props.editFamily(updatedFamily)
  }

  handleShowAddSubcategoryModal = subdepartmentId => {
    return this.props.fetchCategories(
      this.props.family.idDepartment,
      subdepartmentId
    )
  }

  handleSelectCategory = (subdepartmentId, categoryId) => {
    return this.props.fetchSubcategories(
      this.props.family.idDepartment,
      subdepartmentId,
      categoryId === 'all'
        ? this.props.categories.map(category => category.id)
        : [categoryId],
      true
    )
  }

  handleAddSubcategoryModalOk = (subdepartmentId, subcategories) => {
    const { family } = this.props
    const updatedFamily = { ...family }
    const categories = updatedFamily.subdepartments.filter(
      subdepartment => subdepartment.idSubDepartment === subdepartmentId
    )[0].categories
    for (const subcategory of subcategories) {
      // subcategory is a string 'categoryId.subcategoryId'
      const categoryId = parseInt(subcategory.split('.')[0])
      const subcategoryId = parseInt(subcategory.split('.')[1])
      const subcategoryObj = this.props.subcategories.filter(
        s => s.id === subcategoryId
      )[0]
      const filteredCategories = categories.filter(
        category => category.idCategory === categoryId
      )
      if (filteredCategories.length === 0) {
        // If category has not been added yet, add it along with subcategory
        categories.push({
          idCategory: categoryId,
          subcategories: [subcategoryObj]
        })
      } else {
        // otherwise just add subcategory
        filteredCategories[0].subcategories.push(subcategoryObj)
      }
    }
    this.props.editFamily(updatedFamily)
  }

  handleShowEditSubcategoryModal = (subdepartmentId, categoryId) => {
    return Promise.all([
      this.props.fetchCategories(
        this.props.family.idDepartment,
        subdepartmentId
      ),
      this.props.fetchSubcategories(
        this.props.family.idDepartment,
        subdepartmentId,
        [categoryId],
        false
      )
    ])
  }

  handleEditSubcategoryModalOk = (
    subdepartmentId,
    categoryId,
    subcategoryIds
  ) => {
    const { family, subcategories } = this.props
    const updatedFamily = { ...family }
    updatedFamily.subdepartments
      .filter(
        subdepartment => subdepartment.idSubDepartment === subdepartmentId
      )[0]
      .categories.filter(
        category => category.idCategory === categoryId
      )[0].subcategories = subcategories.filter(subcategory =>
      subcategoryIds.includes(subcategory.id)
    )
    this.props.editFamily(updatedFamily)
  }

  handleDeleteCategory = (subdepartmentId, categoryId) => {
    const { family } = this.props
    const updatedFamily = { ...family }
    const subdepartmentToUpdate = updatedFamily.subdepartments.filter(
      subdepartment => subdepartment.idSubDepartment === subdepartmentId
    )[0]
    subdepartmentToUpdate.categories = subdepartmentToUpdate.categories.filter(
      category => category.idCategory !== categoryId
    )
    this.props.editFamily(updatedFamily)
  }

  checkIfSaveIsAllowed = () => {
    const family = { ...this.props.family }
    if (family.name) {
      family.name = family.name.trim()
    }
    if (_.isEqual(family, this.state.originalFamily)) {
      const opts = {
        canSave: false
      }

      if (!this.state.saveAndStay) {
        opts.saveButtonTooltip = 'Nessuna modifica da salvare'
      }

      return opts
    }
    if (family.name.length === 0) {
      return {
        canSave: false,
        saveButtonTooltip: 'La famiglia deve avere un nome'
      }
    }
    // if (!family.subdepartments || family.subdepartments.length === 0) {
    //   return {
    //     canSave: false,
    //     saveButtonTooltip: 'La famiglia deve contenere almeno un sottoreparto'
    //   }
    // }
    for (const subdepartment of family.subdepartments) {
      if (!subdepartment.categories || subdepartment.categories.length === 0) {
        return {
          canSave: false,
          saveButtonTooltip:
            'Ogni sottoreparto deve contenere almeno un sottotipo'
        }
      }
    }

    return { canSave: true, saveButtonTooltip: '' }
  }

  save = () => {
    const family = { ...this.props.family }
    family.name = family.name.trim()
    this.setState({ isSaving: true }, () =>
      this.props
        .saveFamily(family)
        .then(() => {
          this.setState({
            originalFamily: _.cloneDeep(this.props.family),
            isSaving: false,
            saveAndStay: true
          })
        })
        .catch(() => {
          this.setState({
            isSaving: false,
            saveAndStay: true
          })
        })
    )
  }

  handleOnMouseOutSaveButton = () => {
    this.setState({
      saveAndStay: false
    })
  }

  handleFamilyNameChange = event => {
    const value = event.target.value
    const updatedFamily = { ...this.props.family }
    updatedFamily.name = value
    this.props.editFamily(updatedFamily)

    let validateStatus, errorMsg
    if (value.length === 0) {
      validateStatus = 'error'
      errorMsg = 'Inserisci il nome della famiglia'
    } else {
      validateStatus = 'success'
      errorMsg = null
    }
    this.setState({
      familyName: { validateStatus: validateStatus, errorMsg: errorMsg }
    })
  }

  deleteFamily = () => {
    this.setState({isDeletingFamily: true})
    api.families.delete(this.props.family.idFamily)
      .then(() => {
        handleServerSuccess()
        this.setState({redirectTo: '/'})
      })
      .catch(handleServerError)
  }

  showImportModal = () => {
    this.setState({ importModalVisible: true });
  }

  enterInsideFamily = familyId => {
    this.setState({
      familyTreeLoading: true,
      familyTreeSelections: {
        ...this.state.familyTreeSelections,
        family: familyId
      }
    });

    setTimeout(() => this.loadFamilyTreeList());
  };

  enterInsideSubdepartment = subdepartmentId => {
    this.setState({
      familyTreeLoading: true,
      familyTreeSelections: {
        ...this.state.familyTreeSelections,
        subdepartment: subdepartmentId
      }
    });

    setTimeout(() => this.loadFamilyTreeList());
  };

  enterInsideCategory = categoryId => {
    this.setState({
      familyTreeLoading: true,
      familyTreeSelections: {
        ...this.state.familyTreeSelections,
        category: categoryId
      }
    });

    setTimeout(() => this.loadFamilyTreeList());
  };

  familyTreeGoBack = () => {

    const { familyTreeSelections } = this.state;

    if (familyTreeSelections.category !== null) {
      this.setState({
        familyTreeLoading: true,
        familyTreeSelections: {
          ...familyTreeSelections,
          category: null
        }
      });
    } else if (familyTreeSelections.subdepartment !== null) {
      this.setState({
        familyTreeLoading: true,
        familyTreeSelections: {
          ...familyTreeSelections,
          subdepartment: null
        }
      });
    } else if (familyTreeSelections.family !== null) {
      this.setState({
        familyTreeLoading: true,
        familyTreeSelections: {
          ...familyTreeSelections,
          family: null
        }
      });
    }

    setTimeout(() => this.loadFamilyTreeList());

  };

  getFamilyTreeRenderItem = item => {
    const { familyTreeSelections, familyTreeSelected } = this.state;

    const generalStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    };

    if (
      familyTreeSelections.family === null
    ) {
      return <List.Item
        style={generalStyle}
        key={`famiglia-${item.idFamiglia}`}
        actions={[<Checkbox defaultChecked={familyTreeSelected.includes(`${item.idFamiglia}-0-0-0`)} style={{ transform: 'translateY(-3px)', marginRight: 10 }} onChange={target => this.handleFamilyTreeSelectionChange(target, item.idFamiglia, null, null, null)} />, <Button shape="circle" icon="eye" onClick={() => this.enterInsideFamily(item.idFamiglia)} />]}
      >{item.idFamiglia.toString().padStart(2, '0')} - {item.nomeFamiglia}</List.Item>
    } else if (familyTreeSelections.subdepartment === null) {
      return <List.Item
        style={generalStyle}
        key={`sottoreparto-${item.idSottoreparto}`}
        actions={[<Checkbox defaultChecked={this.isCompositionSelected(familyTreeSelections.family, item.idSottoreparto, null, null)} disabled={this.isCompositionSelected(familyTreeSelections.family, null, null, null)} style={{ transform: 'translateY(-3px)', marginRight: 10 }} onChange={target => this.handleFamilyTreeSelectionChange(target, familyTreeSelections.family, item.idSottoreparto, null, null)} />, <Button shape="circle" icon="eye" onClick={() => this.enterInsideSubdepartment(item.idSottoreparto)} />]}
      >{item.idSottoreparto.toString().padStart(2, '0')} - {item.nomeSottoreparto}</List.Item>
    } else if (familyTreeSelections.category === null) {
      return <List.Item
        style={generalStyle}
        key={`tipo-${item.idTipo}`}
        actions={[<Checkbox defaultChecked={this.isCompositionSelected(familyTreeSelections.family, familyTreeSelections.subdepartment, item.idTipo, null)} disabled={this.isCompositionSelected(familyTreeSelections.family, familyTreeSelections.subdepartment, null, null)} style={{ transform: 'translateY(-3px)', marginRight: 10 }} onChange={target => this.handleFamilyTreeSelectionChange(target, familyTreeSelections.family, familyTreeSelections.subdepartment, item.idTipo, null)} />, <Button shape="circle" icon="eye" onClick={() => this.enterInsideCategory(item.idTipo)} />]}
      >{item.idTipo.toString().padStart(2, '0')} - {item.nomeTipo}</List.Item>
    } else {
      return <List.Item
        style={generalStyle}
        key={`sottotipo-${item.idSottotipo}`}
        actions={[<Checkbox defaultChecked={this.isCompositionSelected(familyTreeSelections.family, familyTreeSelections.subdepartment, familyTreeSelections.category, item.idSottotipo)} disabled={this.isCompositionSelected(familyTreeSelections.family, familyTreeSelections.subdepartment, familyTreeSelections.category, null)} style={{ transform: 'translateY(-3px)' }} onChange={target => this.handleFamilyTreeSelectionChange(target, familyTreeSelections.family, familyTreeSelections.subdepartment, familyTreeSelections.category, item.idSottotipo)} />]}
      >{item.idSottotipo.toString().padStart(2, '0')} - {item.nomeSottotipo}</List.Item>
    }
  };

  compositionIsSelected = (familyId, subdepartmentId, categoryId, subcategoryId) => {
    const { familyTreeImport } = this.state;

    const findings = familyTreeImport.filter(o => {
      if (
        (
          o.familyId === familyId
          && o.subdepartmentId === subdepartmentId
          && o.categoryId === categoryId
          && o.subcategoryId === subcategoryId
        )
        || (
          o.familyId === familyId
          && o.subdepartmentId === subdepartmentId
          && o.categoryId === categoryId
          && o.subcategoryId === null
        )
        || (
          o.familyId === familyId
          && o.subdepartmentId === subdepartmentId
          && o.categoryId === null
          && o.subcategoryId === null
        )
        || (
          o.familyId === familyId
          && o.subdepartmentId === null
          && o.categoryId === null
          && o.subcategoryId === null
        )
      ) {
        return true;
      }

      return false;
    }).length;

    return findings > 0;
  }

  isCompositionSelected = (familyId, subdepartmentId, categoryId, subcategoryId) => {
    const { familyTreeSelected } = this.state;

    if (familyId && familyTreeSelected.includes(`${familyId}-0-0-0`)) {
      return true;
    } else if (subdepartmentId && familyTreeSelected.includes(`${familyId}-${subdepartmentId}-0-0`)) {
      return true;
    } else if (categoryId && familyTreeSelected.includes(`${familyId}-${subdepartmentId}-${categoryId}-0`)) {
      return true;
    } else if (subcategoryId && familyTreeSelected.includes(`${familyId}-${subdepartmentId}-${categoryId}-${subcategoryId}`)) {
      return true;
    }

    return false;
  };

  clearLowLevelSelections = (items, keys, familyId, subdepartmentId, categoryId, subcategoryId) => {
    let newItems = [...items];
    let newKeys = [...keys];

    if (subcategoryId) {
      return [newItems, newKeys];
    } else if (categoryId) {
      newItems = newItems.filter(o => !(o.familyId === familyId && o.subdepartmentId === subdepartmentId && o.categoryId === categoryId && o.subcategoryId !== null));
      newKeys = newKeys.filter(key => key.match(new RegExp(`${familyId}-${subdepartmentId}-${categoryId}-[1-9]+`)) === null);
    } else if (subdepartmentId) {
      newItems = newItems.filter(o => !(o.familyId === familyId && o.subdepartmentId === subdepartmentId && o.categoryId !== null));
      newKeys = newKeys.filter(key => key.match(new RegExp(`${familyId}-${subdepartmentId}-[1-9]+-[0-9]+`)) === null);
    } else if (familyId) {
      newItems = newItems.filter(o => !(o.familyId === familyId && o.subdepartmentId !== null));
      newKeys = newKeys.filter(key => key.match(new RegExp(`${familyId}-[1-9]+-[0-9]+-[0-9]+`)) === null);
    }

    return [newItems, newKeys];
  };

  handleFamilyTreeSelectionChange = (event, familyId, subdepartmentId, categoryId, subcategoryId) => {
    const { familyTreeImport, familyTreeSelected } = this.state;
    const checked = event.target.checked === true;

    if (checked && this.compositionIsSelected(familyId, subdepartmentId, categoryId, subcategoryId)) {
      return;
    }

    let newFamilyTreeImport = [...familyTreeImport];
    let newFamilyTreeSelected = [...familyTreeSelected];

    if (checked) {
      newFamilyTreeImport.push({
        familyId,
        subdepartmentId,
        categoryId,
        subcategoryId
      });

      newFamilyTreeSelected.push(`${familyId || 0}-${subdepartmentId || 0}-${categoryId || 0}-${subcategoryId || 0}`);
    } else {
      newFamilyTreeImport = newFamilyTreeImport.filter(o => !(o.familyId === familyId && o.subdepartmentId === subdepartmentId && o.categoryId === categoryId && o.subcategoryId === subcategoryId));
      newFamilyTreeSelected = newFamilyTreeSelected.filter(o => o !== `${familyId || 0}-${subdepartmentId || 0}-${categoryId || 0}-${subcategoryId || 0}`);
    }

    const result = this.clearLowLevelSelections(newFamilyTreeImport, newFamilyTreeSelected, familyId, subdepartmentId, categoryId, subcategoryId);
    newFamilyTreeImport = result[0];
    newFamilyTreeSelected = result[1];

    this.setState({
      familyTreeImport: newFamilyTreeImport,
      familyTreeSelected: newFamilyTreeSelected
    });

    // let newFamilyTreeImport = [...familyTreeImport];
    // const subdepartmentId = (familyTreeSelections.family && familyTreeSelections.subdepartment || selectedId;
    // const categoryId = (familyTreeSelections.subdepartment && familyTreeSelections.category) || selectedId;
    // const subcategoryId = (familyTreeSelections.family && familyTreeSelections.subdepartment && familyTreeSelections.category && selectedId) || null;
    //
    // const familySelectedIndex = familyTreeImport.findIndex(o => o.familyId === familyId && o.subdepartmentId === null);
    // const subdepartmentSelectedIndex = familyTreeImport.findIndex(o => o.familyId === familyId && o.subdepartmentId === subdepartmentId && o.categoryId === null);
    // const categorySelectedIndex = familyTreeImport.findIndex(o => o.familyId === familyId && o.subdepartmentId === subdepartmentId && o.categoryId === categoryId && o.subccategoryId === null);
    // const subcategorySelectedIndex = familyTreeImport.findIndex(o => o.familyId === familyId && o.subdepartmentId === subdepartmentId && o.categoryId === null);
    //
    // this.setState({ familyTreeImport: newFamilyTreeImport });
  };

  handleImport = () => {
    const { familyTreeImport } = this.state;

    if (familyTreeImport.length === 0) {
      return;
    }

    api.families.importFamilyTree(this.props.family.idFamily, familyTreeImport).then(response => {
      feedbackNotification('success', 'Operazione riuscita');
      this.setState({ importModalVisible: false, familyTreeImport: [], familyTreeSelected: [] });
      this.loadFamilyTreeList();
      Promise.all([
        this.props
          .fetchFamily(this.props.family.idFamily)
          .then(() =>
            this.props.fetchSubdepartments(this.props.family.idDepartment)
          ),
        this.props.fetchDepartments()
      ]).then(() =>
        this.setState({
          originalFamily: _.cloneDeep(this.props.family)
        })
      )
    }).catch(console.error);
  }


  handleFamilyFilterChange = value => {
    this.setState({ selectedFamilies: value });
  }

  handleSubdepartmentFilterChange = value => {
    this.setState({ selectedSubdepartments: value });

    api.families.queryFamilyTree({
      department: this.props.family.idDepartment,
      subdepartments: value,
      selections: ['CATEGORY_ID', 'CATEGORY_NAME'],
      distinct: true,
      tuple: true
    }).then(response => {
      this.setState({
        categories: response.data,
        subcategories: []
      });
    }).catch(console.error);
  }

  handleCategoryFilterChange = value => {
    this.setState({ selectedCategories: value });

    api.families.queryFamilyTree({
      department: this.props.family.idDepartment,
      subdepartments: this.state.selectedSubdepartments || [],
      categories: value,
      selections: ['SUBCATEGORY_ID', 'SUBCATEGORY_NAME'],
      distinct: true,
      tuple: true
    }).then(response => {
      this.setState({
        subcategories: response.data
      });
    }).catch(console.error);
  }

  handleSubcategoryFilterChange = value => {
    this.setState({ selectedSubcategories: value });
  }

  render() {
    const {
      // tour,
      setTour,
      family,
      departments,
      subdepartments,
      categories,
      subcategories
    } = this.props
    const {
      originalFamily,
      familyName,
      isLoading,
      isSubdepartmentModalVisible,
      canSave,
      saveButtonTooltip,
      isSaving,
      isDeletingFamily,
      redirectTo
    } = this.state
    // const isTourAvailable = TOUR_HOME in tour
    // const isTourOpen = isTourAvailable && !tour[TOUR_HOME]
    const department = departments.filter(
      department => department.id === family.idDepartment
    )[0]

    const saveButton = (
      <StyledButton
        type="primary"
        icon="download"
        onMouseOut={this.handleOnMouseOutSaveButton}
        onClick={this.save}
        loading={isSaving}>
        Salva
      </StyledButton>
    )

    return isLoading ? (
      <Loader />
    ) : redirectTo ? (
      <Redirect to={redirectTo} />
    ) : _.isEmpty(family) ? (
      <Redirect to="/" />
    ) : (
      <MainLayout
        tourSteps={steps}
        isTourAvailable={false}
        isTourOpen={false}
        closeTour={() => setTour(TOUR_HOME)}>
        <ButtonBar>
          <Popconfirm
            title="Le modifiche non salvate andranno perse"
            onConfirm={() => this.props.history.push('/')}
            okText="OK"
            cancelText="Annulla"
            disabled={_.isEqual(family, originalFamily)}
            placement="topLeft">
            <StyledButton
              type="primary"
              icon="left"
              onClick={() =>
                _.isEqual(family, originalFamily)
                  ? this.props.history.push('/')
                  : null
              }>
              Indietro
            </StyledButton>
          </Popconfirm>
          <span>
            {this.props.user && this.props.user.role === ROLE_MERCH && this.props.family && this.props.family.idFamily && (
              <StyledButton style={{ marginRight: '16px' }} icon='import' onClick={this.showImportModal}>
                Importazione
              </StyledButton>
            )}
            <Popconfirm
              title="Sei sicuro di voler eliminare la famiglia?"
              onConfirm={this.deleteFamily}
              okText="Sì"
              cancelText="No">
              <StyledButton style={{marginRight: '16px'}}type='danger' icon='delete' loading={isDeletingFamily}>
                Elimina
              </StyledButton>
            </Popconfirm>
            {canSave ? (
              saveButton
            ) : (
              <Tooltip title={saveButtonTooltip} placement="topRight">
                {saveButton}
              </Tooltip>
            )}
          </span>
        </ButtonBar>
        {/* <TitleBar> */}
        <StyledRow>
          <Col span={8}>
            <StyledForm layout="inline">
              <StyledFormItem
                label="Famiglia merchandising"
                validateStatus={familyName.validateStatus}
                help={familyName.errorMsg}>
                <Input
                  value={family.name}
                  onChange={this.handleFamilyNameChange}
                  allowClear
                />
              </StyledFormItem>
            </StyledForm>
          </Col>
          <Col span={8}>
            <Title>{`Reparto: ${department && department.id}-${department && department.label}`}</Title>
          </Col>
        </StyledRow>
        {/* </TitleBar> */}
        {family.subdepartments.map(familySubdepartment => (
          <SubdepartmentRow
            key={familySubdepartment.idSubDepartment}
            subdepartment={
              subdepartments.filter(
                subdepartment =>
                  subdepartment.id === familySubdepartment.idSubDepartment
              )[0]
            }
            categories={categories}
            subcategories={subcategories}
            familySubdepartment={familySubdepartment}
            onDeleteSubdepartment={this.handleDeleteSubdepartment}
            onShowAddSubcategoryModal={this.handleShowAddSubcategoryModal}
            onSelectCategory={this.handleSelectCategory}
            onAddSubcategoryModalOk={this.handleAddSubcategoryModalOk}
            onShowEditSubcategoryModal={this.handleShowEditSubcategoryModal}
            onEditSubcategoryModalOk={this.handleEditSubcategoryModalOk}
            onDeleteCategory={this.handleDeleteCategory}
          />
        ))}
        <StyledDiv>
          <AddButton
            text="Aggiungi sottoreparto"
            onClick={this.showSubdepartmentModal}
          />
        </StyledDiv>
        <SubdepartmentModal
          wrappedComponentRef={ref => {
            this.subdepartmentModal = ref
          }}
          visible={isSubdepartmentModalVisible}
          onCancel={this.handleSubdepartmentModalCancel}
          onOk={this.handleSubdepartmentModalOk}
          // Show only subdepartments that are not already in family
          subdepartments={
            family.subdepartments
              ? subdepartments.filter(
                  subdepartment =>
                    !family.subdepartments
                      .map(
                        familySubdepartment =>
                          familySubdepartment.idSubDepartment
                      )
                      .includes(subdepartment.id)
                )
              : subdepartments
          }
        />
        <Modal
          title="Importazione merchandising famiglie"
          visible={this.state.importModalVisible}
          onOk={this.handleImport}
          onCancel={() => this.setState({ importModalVisible: false })}
          width="90vw"
        >
          <Row gutter={16} style={{ marginBottom: 50 }}>
            <Col className="gutter-row" span={6}>
              <Select placeholder="Famiglia" style={{ width: '100%' }} mode="multiple" onChange={this.handleFamilyFilterChange}>
                {this.state.families.map(item => (
                  <Select.Option value={item.idFamiglia}>{item.idFamiglia} - {item.nomeFamiglia}</Select.Option>
                ))}
              </Select>
            </Col>
            <Col className="gutter-row" span={6}>
              <Select placeholder="Sottoreparto" style={{ width: '100%' }} mode="multiple" onChange={this.handleSubdepartmentFilterChange}>
                {this.state.subdepartments.map(item => (
                  <Select.Option value={item.idSottoreparto}>{item.idSottoreparto} - {item.nomeSottoreparto}</Select.Option>
                ))}
              </Select>
            </Col>
            <Col className="gutter-row" span={6}>
              <Select placeholder="Tipo" style={{ width: '100%' }} mode="multiple" onChange={this.handleCategoryFilterChange}>
                {this.state.categories.map(item => (
                  <Select.Option value={item.idTipo}>{item.idTipo} - {item.nomeTipo}</Select.Option>
                ))}
              </Select>
            </Col>
            <Col className="gutter-row" span={6}>
              <Select placeholder="Sottotipo" style={{ width: '100%' }} mode="multiple" onChange={this.handleSubategoryFilterChange}>
                {this.state.subcategories.map(item => (
                  <Select.Option value={item.idSottotipo}>{item.idSottotipo} - {item.nomeSottotipo}</Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          {this.state.familyTreeLoading && <Loader />}

          {!this.state.familyTreeLoading && (
            <>
              {this.state.familyTreeSelections.family !== null && (
                <Button
                  icon="left"
                  onClick={this.familyTreeGoBack}
                  style={{ marginBottom: 20 }}
                >
                  Indietro
                </Button>
              )}

              <List
                pagination={{
                  pageSize: 10,
                }}
                bordered
                dataSource={this.state.familyTree}
                renderItem={this.getFamilyTreeRenderItem}
              />
            </>
          )}
        </Modal>
      </MainLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    tour: state.Tour,
    family: state.Edit.family,
    departments: state.Data.departments,
    subdepartments: state.Data.subdepartments,
    categories: state.Data.categories,
    subcategories: state.Data.subcategories,
    user: state.User
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTour: value => dispatch(setTour(value)),
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchSubdepartments: departmentId =>
      dispatch(fetchSubdepartments(departmentId)),
    fetchCategories: (departmentId, subdepartmentId) =>
      dispatch(fetchCategories(departmentId, subdepartmentId)),
    fetchSubcategories: (
      departmentId,
      subdepartmentId,
      categoryIds,
      unassigned
    ) =>
      dispatch(
        fetchSubcategories(
          departmentId,
          subdepartmentId,
          categoryIds,
          unassigned
        )
      ),
    editFamily: family => dispatch(editFamily(family)),
    saveFamily: family => dispatch(saveFamily(family)),
    fetchFamily: id => dispatch(fetchFamily(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
