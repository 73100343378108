import styled from 'styled-components'
import { Button, Icon } from 'antd'

export const StyledButton = styled(Button)`
  height: 7rem !important;
  width: 11rem !important;
  margin-right: 1.5rem;
  }
`

export const StyledIcon = styled(Icon)`
  font-size: 2rem !important;
  }
`

export const StyledText = styled.div`
  font-size: 0.7rem;
`