import styled from 'styled-components';
import { Select, Radio, InputNumber } from 'antd'

export const StyledSelect = styled(Select)`
  width: 100%;
  padding-bottom: 16px !important;
`

export const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  padding: 4px 0px !important;
`

export const StyledRadio = styled(Radio)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px 12px !important;
`

export const StyledInputNumber = styled(InputNumber)`
  width: 100% !important;
`