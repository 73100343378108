import React from 'react';
import * as _ from 'lodash';
import LMTable from "Components/LMTable";
import {Button, Checkbox, Col, Menu, Row, Dropdown, Popconfirm, Badge, Radio, Tooltip, Icon} from "antd";
import {ActionBar, StyledButton} from "Components/DossierReferencesTable/styled";
import {Wrapper} from "Common/styled";
import api from "Common/api";
import PropTypes from 'prop-types';
import AppAccess from "Common/appAccess";
import {
    hasChanged,
    handleServerSuccess,
    handleServerError,
    feedbackNotification,
    getFromSession,
    deleteFromSession,
    parseDate,
    isEmpty, transformFilters
} from 'Common/utils'
import ModalConfirm from "Common/ModalConfirm";
import { REFERENCE_STATE } from 'Common/globals';
import { ROLE_CP, ROLE_CPXS, ROLE_CS, ROLE_MERCH, ROLE_GUEST } from "Common/constants/global";
import { refNotUpdatedReasons } from 'Common/constants';
import AgTable from 'Components/AgTable/AgTable'
import {
    CUSTOM_ERROR,
    GAMMA_TO_BE_A_CLUSTER_A_AND_FACING_A_ZERO,
    GAMMA_TO_BE_A_CLUSTER_AB_AND_FACING_AB_ZERO,
    GAMMA_TO_BE_A_CLUSTER_ABC_AND_FACING_ABC_ZERO, NUM_PDD_DUPLICATE, PL_PREV_VMM_NULL, PL_SPT_NULL,
    PRECOTOP_REAPPRO_TO_BE_1_AND_STOCK_NULL_OR_ZERO,
    PRECOTOP_REAPPRO_TO_BE_NOT_NULL_AND_GAMMA_TO_BE_D_R_S
} from 'Common/constants/product-errors'
import moment from 'moment'
import {
    ID_REF_SOSTITUITA_NOT_NULL_AND_TIPO_SOSTITUZIONE_NULL,
    NUM_PDD_NULL,
    PRECOTOP_REAPPRO_TO_BE_1_AND_PREV_VENDUTO_ANNUO_NULL,
    PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_A_NULL_OR_ZERO,
    PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_AB_NULL_OR_ZERO,
    PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_ABC_NULL_OR_ZERO,
    PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_B_AND_FACING_AB_NULL_OR_ZERO,
    PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_B_AND_FACING_ABC_NULL_OR_ZERO,
    PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_C_AND_FACING_ABC_NULL_OR_ZERO,
    SPT_NULL,
    PREV_VMM_NULL, FACING_A_NULL, FACING_AB_NULL, FACING_ABC_NULL, STATO_INI_OR_PRE
} from 'Common/constants/product-warnings'
import { colors } from 'Common/constants'

/**
 * Component description
 */
class DossierReferencesTable extends React.Component {
    state = {
        visibleColumns: [],
        items: [],
        totalItems: 0,
        page: 1,
        filters: {},
        sorter: {},
        selectedKeys: [],
        deselectedKeys: [],
        selectedAll: false,
        columnsMenuVisible: false,
        tableLoading: false,
        changes: [],
        refNotUpdated: [],
        loading: true,
        canSave: true,
        newRefsCount: 0,
        chosenCluster: null,
        showFacingModal: false,
        facingModalLoading: false,
        availableFilters: {},
        records: [],
        fullscreen: false,
        recordChanges: {},
        selectedNodes: [],
        allRefNotNewSelected: false
    };


    constructor(props, context) {
        super(props, context)
        this.agTable = React.createRef();
    }

    /**
     * When component mounts, fill visible columns in the state
     */
    componentDidMount() {
        const {dossier, cluster, user} = this.props;

        const visibleColumns = this.getColumns(dossier).filter(column => column.hidden !== true).map(column => column.dataIndex);
        this.setState({visibleColumns: visibleColumns});
        // this.fetchData();

        if (_.isNil(cluster) && user.role === ROLE_CS && !['XS', 'PL'].includes(dossier.type)) {
            this.setState({showFacingModal: true});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (hasChanged(['page'], prevState, this.state)) {
            this.fetchData();
        } else if (hasChanged(['filters', 'sorter'], prevState, this.state)) {
            this.setState({page: 1}, this.fetchData);
        }

        if (prevProps.cluster !== this.props.cluster && this.props.user.role === ROLE_CS) {
            if (_.isNil(this.props.cluster) && this.state.showFacingModal === false) {
                this.setState({showFacingModal: true, facingModalLoading: false});
            } else if (!_.isNil(this.props.cluster) && this.state.showFacingModal === true) {
                this.setState({showFacingModal: false, facingModalLoading: false});
            }
            const visibleColumns = this.getColumns(this.props.dossier).filter(column => column.hidden !== true).map(column => column.dataIndex);
            this.setState({visibleColumns: visibleColumns});
        }
    }

    /**
     * Get table columns
     * @returns []
     */
    getColumns = dossier => {
        const {cluster, user, departments, families, stores} = this.props
        const {availableFilters} = this.state

        let columns = [
            {
                title: () => <span className="special-table-head-title"><i>NEW</i></span> ,
                cleanTitle: 'NEW',
                dataIndex: 'newReferenza',
                key: 'newReferenza',
                filtrable: true,
                filters: [
                    {value: true, text: 'Sì'},
                    {value: false, text: 'No'}
                ],
                editable: user.role === ROLE_CP,
                filterMultiple: false,
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                contentType: 'bool',
                width: 60,
                onHeaderCell: column => ({style: {minWidth: '60px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '60px'}}),
            },
            {
                title: 'IMMAGINE',
                dataIndex: 'foto',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
                renderer: 'productImage'
            },
            {
                title: 'TIPO',
                dataIndex: 'nomeTipo',
                key: 'tipo',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                searchable: true,
                sortable: true,
                width: 200,
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
            },
            {
                title: 'SOTTOTIPO',
                dataIndex: 'nomeSottotipo',
                key: 'sottotipo',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                sortable: true,
                searchable: true,
                width: 200,
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
            },
            {
                title: 'REF LM',
                dataIndex: 'idReferenza',
                key: 'idReferenza',
                searchable: true,
                searchMultiple: true,
                sortable: true,
                align: 'center',
                width: 150,
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: 'DESCRIZIONE',
                dataIndex: 'descrizione',
                key: 'descrizione',
                searchable: true,
                sortable: true,
                align: 'center',
                width: 250,
                onHeaderCell: column => ({style: {minWidth: '250px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '250px'}}),
            },
            {
                title: 'GAMMA',
                dataIndex: 'gamma',
                key: 'gamma',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                filtrable: true,
                filters: availableFilters.gamma ? availableFilters.gamma.map(opt => ({value: opt, text: opt})) : [],
                align: 'center',
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>GAMMA</i> FUTURA</span>,
                cleanTitle: 'GAMMA FUTURA',
                dataIndex: 'gammaToBe',
                key: 'gammaToBe',
                sortable: true,
                filtrable: true,
                filters: availableFilters.gammaToBe ? availableFilters.gammaToBe.map(opt => ({value: opt, text: opt})) : [],
                validation: true,
                validationOpts: {
                    needsConfirmation: true,
                    skipConfirmation: (value, record, column) => record.gammaToBe !== 'S' || record.stato !== 'SUP',
                    confirmationText: 'Referenza soppressa, sei sicuro di voler procedere?'
                },
                editable: !dossier || (user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier)),
                editableSelect: true,
                editableSelectList: [
                    {id: 'A', label: 'A'},
                    {id: 'B', label: 'B'},
                    {id: 'C', label: 'C'},
                    {id: 'D', label: 'D'},
                    {id: 'L', label: 'L'},
                    {id: 'R', label: 'R'},
                    {id: 'S', label: 'S'}
                ],
                align: 'center',
                width: 120,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '120px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '120px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>PRECOTOP</i> REAPPRO</span>,
                cleanTitle: 'PRECOTOP REAPPRO',
                dataIndex: 'precotopReappro',
                key: 'precotopReappro',
                filtrable: true,
                filters: [
                    { value: 0, text: '0' },
                    { value: 1, text: '1' },
                    { value: 2, text: '2' }
                ],
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                contentType: 'integer',
                align: 'center',
                sortable: true,
                width: 155,
                onHeaderCell: column => ({style: {minWidth: '155px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '155px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>PRECOTOP</i> REAPPRO FUTURO</span>,
                cleanTitle: 'PRECOTOP REAPPRO FUTURO',
                dataIndex: 'precotopReapproToBe',
                key: 'precotopReapproToBe',
                filtrable: true,
                filters: [
                    { value: 1, text: '1' },
                    { value: 2, text: '2' }
                ],
                editable: (value, record) => user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier),
                editableSelect: true,
                editableSelectList: [
                    {id: 1, label: '1'},
                    {id: 2, label: '2'}
                ],
                contentType: 'integer',
                align: 'center',
                sortable: true,
                width: 155,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '155px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '155px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP REAPPRO</i> FUTURO</span>,
                cleanTitle: 'TOP REAPPRO FUTURO',
                dataIndex: 'topReappro',
                editable: (value, record) => {
                    if (
                        ['NG', 'RG', 'ST'].includes(dossier.type)
                        && user.role === ROLE_CS
                        && dossier.statoDossier === 'CC'
                        && ((record.gammaToBe === 'A' && record.topMdh === false) || record.gammaToBe !== 'A')
                        && record.gammaToBe !== 'D'
                    ) {
                        return true
                    }

                    if (
                        dossier.type === 'XS'
                        && user.role === ROLE_CS
                        && record.gamma === 'P'
                    ) {
                        return true
                    }

                    return false
                },
                editableSelect: true,
                editableSelectList: [
                    {id: 1, label: '1'},
                    {id: 2, label: '2'}
                ],
                contentType: 'integer',
                align: 'center',
                warning: (value, record) => {
                    return ((
                        value === 2
                        && record.precotopReapproToBe === 1
                    ) || (
                        value === 1
                        && record.precotopReapproToBe === 2
                    ));
                },
                warningText: (value, record) => {
                    if (
                        value === 2
                        && record.precotopReapproToBe === 1
                        && (record.gammaToBe === 'A' || record.dataAvsRefSostituita)
                        && record.topEm !== true
                    ) {
                        return 'Attenzione: referenza in A1 o con data AVS messa in A2'
                    }

                    if (
                        value === 2
                        && record.precotopReapproToBe === 1
                    ) {
                        return 'Attenzione: referenza con top suggerito = 1 messa in top = 2'
                    }

                    if (
                        value === 1
                        && record.precotopReapproToBe === 2
                    ) {
                        return 'Attenzione: referenza top reappro 2 messa in top reappro 1'
                    }
                },
                customVisibility: dossier && ['NG', 'RG', 'ST', 'XS'].includes(dossier.type) && [ROLE_CP, ROLE_CS, ROLE_GUEST].includes(user.role) && dossier.idStore,
                width: 110,
                onHeaderCell: column => ({style: {minWidth: '110px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '110px'}}),
                filtrable: true,
                filters: [
                    { value: 1, text: '1' },
                    { value: 2, text: '2' }
                ],
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP REAPPRO</i> BO</span>,
                cleanTitle: 'TOP REAPPRO BO',
                dataIndex: 'precotopReapproToBe',
                contentType: 'integer',
                align: 'center',
                customVisibility: dossier && ['NG', 'RG', 'ST', 'XS'].includes(dossier.type) && [ROLE_CP, ROLE_CS, ROLE_GUEST].includes(user.role) && dossier.idStore,
                width: 110,
                onHeaderCell: column => ({style: {minWidth: '110px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '110px'}}),
                filtrable: true,
                filters: [
                    { value: 1, text: '1' },
                    { value: 2, text: '2' }
                ],
            },
            {
                title: 'CLUSTER XSELLING',
                dataIndex: 'clusterxs',
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                editableSelect: true,
                editableSelectList: [
                    {id: 'A', label: 'S'},
                    {id: 'AB', label: 'M'},
                    {id: 'ABC', label: 'L'}
                ],
                align: 'center',
                width: 100,
                dossierTypes: ['XS'],
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
                valueFormatter: params => {
                    switch (params.value) {
                        case 'A':
                            return 'S';
                        case 'AB':
                            return 'M';
                        case 'ABC':
                            return 'L';
                        default:
                            return '';
                    }
                }
            },
            {
                title: 'FACING XSELLING',
                dataIndex: 'facingxs',
                editable: (value, record) => {
                    return (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier))
                    || (user.role === ROLE_CS && dossier.statoDossier === 'CC'/* && record.precotopReappro === 1*/)},
                contentType: 'integer',
                minValue: () => user.role === ROLE_CPXS ? 1 : 0,
                align: 'center',
                dossierTypes: ['XS'],
                onHeaderCell: column => ({style: {minWidth: '115px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '115px'}}),
                width: 115
            },
            {
                title: () => <span className="special-table-head-title"><i>STOCK PRESENTAZIONE</i> XSELLING</span>,
                cleanTitle: 'STOCK PRESENTAZIONE XSELLING',
                dataIndex: 'stockxs',
                contentType: 'decimal',
                precision: 2,
                align: 'center',
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                width: 200,
                dossierTypes: ['XS'],
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
            },
            {
                title: 'TIPO EVENTO XSELLING',
                dataIndex: 'tipoEventoxs',
                key: 'tipoEventoxs',
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                editableSelect: true,
                filtrable: true,
                filters: [
                    {value: 'Avancasse', text: 'Avancasse'},
                    {value: 'Stagionale', text: 'Stagionale'},
                    {value: 'X-Selling', text: 'X-Selling'},
                    {value: 'X-Video', text: 'X-Video'},
                    {value: 'Casse Sco', text: 'Casse Sco'},
                    {value: 'Complementari Piante', text: 'Complementari Piante'},
                    {value: 'Complementari Simbolo', text: 'Complementari Simbolo'},
                    {value: 'Piante', text: 'Piante'}
                ],
                editableSelectList: [
                    {id: 'Avancasse', label: 'Avancasse'},
                    {id: 'Stagionale', label: 'Stagionale'},
                    {id: 'X-Selling', label: 'X-Selling'},
                    {id: 'X-Video', label: 'X-Video'},
                    {id: 'Casse Sco', label: 'Casse Sco'},
                    {id: 'Complementari Piante', label: 'Complementari Piante'},
                    {id: 'Complementari Simbolo', label: 'Complementari Simbolo'},
                    {id: 'Piante', label: 'Piante'}
                ],
                align: 'center',
                width: 210,
                dossierTypes: ['XS'],
                onHeaderCell: column => ({style: {minWidth: '210px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '210px'}}),
            },
            {
                title: 'DATA INIZIO XSELLING',
                dataIndex: 'dataInizioxs',
                contentType: 'date',
                dossierTypes: ['XS'],
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                meetsRequirements: (value, record, column) => {
                    const installationFrom = parseDate(dossier.installationFrom)
                    const installationTo = parseDate(dossier.installationTo)

                    if (column.field === 'dataInizioxs') {
                      const dataInizioxs = parseDate(value)
                      const dataFinexs = parseDate(record.dataFinexs)

                      if (dataInizioxs === null) {
                        return {success: false, message: "Seleziona una data"}
                      }
                      if (!dataInizioxs.isBetween(installationFrom, installationTo, 'day', '[]')) {
                        return {success: false, message: "Seleziona una data compresa nel periodo impianto"}
                      }
                      if (dataFinexs !== null && dataInizioxs.isSameOrAfter(dataFinexs)) {
                        return {success: false, message: "Seleziona una data precedente quella di fine XS"}
                      }
                    } else if (column.field === 'dataFinexs') {
                      const dataInizioxs = parseDate(record.dataInizioxs)
                      const dataFinexs = parseDate(value)

                      if (dataInizioxs !== null) {
                          if (!dataInizioxs.isBetween(installationFrom, installationTo, 'day', '[]')) {
                            return {success: false, message: "Seleziona una data compresa nel periodo impianto"}
                          }
                          if (dataFinexs !== null && dataInizioxs.isSameOrAfter(dataFinexs)) {
                            return {success: false, message: "Seleziona una data precedente quella di fine XS"}
                        }
                      }
                    }

                    return {success: true}
                },
                align: 'center',
                width: 170,
                onHeaderCell: column => ({style: {minWidth: '170px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '170px'}}),
            },
            {
                title: 'DATA FINE XSELLING',
                dataIndex: 'dataFinexs',
                contentType: 'date',
                dossierTypes: ['XS'],
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                meetsRequirements: (value, record, column) => {
                    const installationFrom = parseDate(dossier.installationFrom)
                    const installationTo = parseDate(dossier.installationTo)

                    if (column.field === 'dataFinexs') {
                      const dataFinexs = parseDate(value)
                      const dataInizioxs = parseDate(record.dataInizioxs)

                      if (dataFinexs === null) {
                        return {success: false, message: "Seleziona una data"}
                      }
                      if (!dataFinexs.isBetween(installationFrom, installationTo, 'day', '[]')) {
                        return {success: false, message: "Seleziona una data compresa nel periodo impianto"}
                      }
                      if (dataInizioxs !== null && dataFinexs.isSameOrBefore(dataInizioxs)) {
                        return {success: false, message: "Seleziona una data successiva a quella di inizio XS"}
                      }
                    } else if (column.field === 'dataInizioxs') {
                      const dataFinexs = parseDate(record.dataFinexs)
                      const dataInizioxs = parseDate(value)

                      if (dataFinexs !== null) {
                          if (!dataFinexs.isBetween(installationFrom, installationTo, 'day', '[]')) {
                            return {success: false, message: "Seleziona una data compresa nel periodo impianto"}
                        }
                          if (dataInizioxs !== null && dataFinexs.isSameOrBefore(dataInizioxs)) {
                            return {success: false, message: "Seleziona una data successiva a quella di inizio XS"}
                        }
                      }
                    }

                    return {success: true}
                },
                align: 'center',
                width: 170,
                onHeaderCell: column => ({style: {minWidth: '170px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '170px'}}),
            },
            {
                title: 'REPARTO SUGGERITO',
                dataIndex: 'departments',
                dossierTypes: ['XS'],
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                editableSelect: true,
                editableSelectList: departments.map(department => ({id: department.id, label: `${department.id}-${department.label}`})),
                editableSelectOpts: {mode: 'multiple', allowClear: true, maxTagCount: 1, maxTagTextLength: 15},
                maxSelectedOpts: 5,
                align: 'center',
                width: 285,
                onHeaderCell: column => ({style: {minWidth: '285px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '285px'}}),
                valueFormatter: params => {
                    return this.props.departments.filter(department => {
                        return params.value && params.value.includes(department.id)})
                        .map(department => `${department.id}-${department.label}`)
                        .join(', ')
                }
            },
            {
                title: 'FAMIGLIA MERCH SUGGERITA',
                dataIndex: 'families',
                dossierTypes: ['XS'],
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                editableSelect: true,
                editableSelectList: families.map(family => ({id: family.idFamily, label: family.name})),
                editableSelectOpts: {mode: 'multiple', allowClear: true, maxTagCount: 1, maxTagTextLength: 15},
                maxSelectedOpts: 5,
                align: 'center',
                width: 285,
                onHeaderCell: column => ({style: {minWidth: '285px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '285px'}}),
                valueFormatter: params => {
                    return this.props.families.filter(family => params.value && params.value.includes(family.idFamily))
                    .map(family => family.name)
                    .join(', ')
                }
            },
            {
                title: 'NEGOZI',
                dataIndex: 'stores',
                customVisibility: dossier && ['NG', 'RG', 'ST'].includes(dossier.type) && !dossier.idStore,
                align: 'center',
                width: 235,
                // ellipsis: true,
                onHeaderCell: column => ({style: {minWidth: '235px', maxWidth: '235px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '235px', maxWidth: '235px'}}),
                editable: (value, record) => {
                    if (user.role === ROLE_CP
                        && ['CR', 'R1'].includes(dossier.statoDossier)
                        && record.nomeReparto.startsWith('01') && record.gammaToBe === 'L') {
                        return true;
                    }

                    return false;
                },
                valueFormatter: params => {
                    if (!(params.data.nomeReparto.startsWith('01') && params.data.gammaToBe === 'L')
                        && !(!params.data.nomeReparto.startsWith('01') && params.data.attribuzioneGeografica === '1')) {
                        return "Tutti";
                    }
                    if (Array.isArray(params.value)) {
                        return stores
                            .filter(store => params.value.includes(store.id))
                            .map(store => `${store.id}-${store.name}`)
                            .join(', ');
                    }
                    return '';
                },
                editableSelect: true,
                editableSelectList: stores.map(store => ({id: store.id, label: `${store.id}-${store.name}`})),
                editableSelectOpts: {mode: 'multiple', allowClear: true, maxTagCount: 1, maxTagTextLength: 15},
                renderer: 'listTooltip'
            },
            {
                title: dossier && dossier.type === 'PL' ? 'SPT' : <span className="special-table-head-title"><i>FACING</i> CLUSTER S</span>,
                cleanTitle: dossier && dossier.type === 'PL' ? 'SPT' : 'FACING CLUSTER S',
                dataIndex: 'facingA',
                editable: (value, record) => {
                    if (dossier.pxLoaded) {
                        return false;
                    }

                    if (
                        user.role === ROLE_MERCH
                        && ['CR', 'R1'].includes(dossier.statoDossier)
                        && record.precotopReapproToBe === 1
                        && record.gammaToBe !== 'S'
                    ) {
                        return true;
                    }

                    const diffBetweenTodayAndInstallationTo = moment(dossier.installationTo).diff(moment(), 'days');

                    if (
                        user.role === ROLE_CS
                        && dossier.type !== 'PL'
                        && dossier.statoDossier === 'CC'
                        && record.topReappro === 1
                        && record.gammaToBe !== 'S'
                       && diffBetweenTodayAndInstallationTo >= 15
                    ) {
                        return true;
                    }

                    if (
                        user.role === ROLE_CS
                        && dossier.type === 'PL'
                        && ['CR', 'R2'].includes(dossier.statoDossier)
                        && moment().isSameOrBefore(moment(dossier.installationTo), 'day')
                      && diffBetweenTodayAndInstallationTo >= 15
                    ) {
                        return true;
                    }

                    return false
                },
                contentType: 'integer',
                minValue: (record) => user.role === ROLE_CS && record.gammaToBe === 'A' && dossier.type !== 'PL' ? 1 : 0,
                align: 'center',
                cluster: 'A',
                dossierTypes: ['NG', 'RG', 'ST', 'PL'],
                onHeaderCell: column => ({style: {minWidth: '115px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '115px'}}),
                width: 115,
                warning: (value, record) => {
                    return value > 100;
                },
                warningText: "Attenzione: valore di facing superiore a 100",
                searchable: true,
            },
            {
                title: () => dossier && dossier.type === 'PL' ? 'SPT' : <span className="special-table-head-title"><i>FACING</i> CLUSTER M</span>,
                cleanTitle: dossier && dossier.type === 'PL' ? 'SPT' : 'FACING CLUSTER M',
                dataIndex: 'facingAb',
                editable: (value, record) => {
                    if (dossier.pxLoaded) {
                        return false;
                    }

                    if (
                        user.role === ROLE_MERCH
                        && ['CR', 'R1'].includes(dossier.statoDossier)
                        && record.precotopReapproToBe === 1
                        && record.gammaToBe !== 'S'
                    ) {
                        return true;
                    }

                    if (
                        user.role === ROLE_CS
                        && dossier.type !== 'PL'
                        && dossier.statoDossier === 'CC'
                        && record.topReappro === 1
                        && record.gammaToBe !== 'S'
                    ) {
                        return true;
                    }

                    if (
                        user.role === ROLE_CS
                        && dossier.type === 'PL'
                        && ['CR', 'R2'].includes(dossier.statoDossier)
                    ) {
                        return true;
                    }

                    return false
                },
                contentType: 'integer',
                minValue: (record) => (user.role === ROLE_CS && record.gammaToBe === 'A' && dossier.type !== 'PL' ? 1 : 0),
                align: 'center',
                cluster: 'AB',
                width: 115,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '115px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '115px'}}),
                searchable: true,
            },
            {
                title: () => dossier && dossier.type === 'PL' ? 'SPT' : <span className="special-table-head-title"><i>FACING</i> CLUSTER L</span>,
                cleanTitle: dossier && dossier.type === 'PL' ? 'SPT' : 'FACING CLUSTER L',
                dataIndex: 'facingAbc',
                editable: (value, record) => {
                    if (dossier.pxLoaded) {
                        return false;
                    }

                    if (
                        user.role === ROLE_MERCH
                        && ['CR', 'R1'].includes(dossier.statoDossier)
                        && record.precotopReapproToBe === 1
                        && record.gammaToBe !== 'S'
                    ) {
                        return true;
                    }

                    if (
                        user.role === ROLE_CS
                        && dossier.type !== 'PL'
                        && dossier.statoDossier === 'CC'
                        && record.topReappro === 1
                        && record.gammaToBe !== 'S'
                    ) {
                        return true;
                    }

                    if (
                        user.role === ROLE_CS
                        && dossier.type === 'PL'
                        && ['CR', 'R2'].includes(dossier.statoDossier)
                    ) {
                        return true;
                    }

                    return false
                },
                contentType: 'integer',
                minValue: (record) => (user.role === ROLE_CS && record.gammaToBe === 'A' && dossier.type !== 'PL' ? 1 : 0),
                align: 'center',
                cluster: 'ABC',
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '115px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '115px'}}),
                width: 115,
                searchable: true,
            },
            {
                title: 'VENDUTO ANNUO (QTA)',
                dataIndex: 'vendutoAnnuo',
                key: 'vendutoAnnuo',
                sortable: true,
                searchable: true,
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                contentType: 'decimal',
                precision: 2,
                align: 'center',
                hidden: false,
                visibleTo: [ROLE_CP, ROLE_MERCH, ROLE_CPXS, ROLE_GUEST],
                width: 130,
                onHeaderCell: column => ({style: {minWidth: '130px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '130px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>NUMERO</i> NEGOZI</span> ,
                cleanTitle: 'NUMERO NEGOZI',
                dataIndex: 'numNegozi',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                hidden: false,
                align: 'center',
                contentType: 'integer',
                width: 90,
                visibleTo: [ROLE_CP, ROLE_MERCH, ROLE_CPXS, ROLE_GUEST],
                onHeaderCell: column => ({style: {minWidth: '90px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '90px'}}),
            },
            {
                title: 'PREVISIONE VENDUTO',
                dataIndex: 'prevVMM',
                key: 'prevVMM',
                sortable: true,
                searchable: true,
                editable: () => {
                    const diffBetweenTodayAndInstallationTo = moment(dossier.installationTo).diff(moment(), 'days');
                    return !dossier || (user.role === ROLE_CS && dossier.type === 'PL' && ['CR', 'R2'].includes(dossier.statoDossier) && moment().isSameOrBefore(moment(dossier.installationTo), 'day') && diffBetweenTodayAndInstallationTo >= 15);
                },
                contentType: 'decimal',
                precision: 2,
                align: 'center',
                removeSpinner: true,
                width: 130,
                customVisibility: dossier && dossier.type === 'PL' && [ROLE_CS, ROLE_GUEST].includes(user.role),
                onHeaderCell: column => ({style: {minWidth: '130px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '130px'}}),
            },
            {
                title: 'PREV VENDUTO ANNUO (QTA)',
                dataIndex: 'prevVendutoAnnuo',
                key: 'prevVendutoAnnuo',
                sortable: true,
                searchable: true,
                editable: !dossier || (user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier)),
                contentType: 'decimal',
                precision: 2,
                align: 'center',
                removeSpinner: true,
                width: 145,
                customVisibility: dossier && ['NG', 'RG', 'ST'].includes(dossier.type) && [ROLE_CP, ROLE_MERCH, ROLE_GUEST].includes(user.role),
                onHeaderCell: column => ({style: {minWidth: '145px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '145px'}}),
            },
            {
                title: 'PREV VENDUTO ANNUO XS (QTA)',
                dataIndex: 'prevVendutoAnnuoXs',
                key: 'prevVendutoAnnuoXs',
                sortable: true,
                searchable: true,
                editable: (value, record) => user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier) && record.gamma === 'P',
                contentType: 'decimal',
                precision: 2,
                align: 'center',
                dossierTypes: ['XS'],
                visibleTo: [ROLE_CPXS, ROLE_GUEST],
                onHeaderCell: column => ({style: {minWidth: '155px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '155px'}}),
                removeSpinner: true,
                width: 155
            },
            {
                title: 'REF SOSTITUITA',
                dataIndex: 'idRefSostituita',
                key: 'idRefSostituita',
                editable: !dossier || (user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier)),
                align: 'center',
                groupWith: 'tipoSostituzione',
                width: 100,
                searchable: true,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '130px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '130px'}}),
                validation: true,
                validationOpts: {
                    onBlurAjaxCall: (value, record, column) => new Promise((resolve, reject) => {
                        if (_.isNil(value) || _.isEmpty(value)) {
                            resolve();
                        } else {
                            api.references.getById(value).then(response => {
                                resolve(response);
                            }).catch(error => {
                                feedbackNotification('error', 'Referenza inesistente', 'La referenza sostituita inserita non è valida');
                                reject();
                            });
                        }
                    }),
                    onSuccess: (response, record, handleRowChange) => handleRowChange(response ? response.data.dataAvs : null, record, { dataIndex: 'dataAvsRefSostituita' })
                }
            },
            {
                title: 'TIPO SOSTITUZIONE',
                dataIndex: 'tipoSostituzione',
                key: 'tipoSostituzione',
                editable: !dossier || (user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier)),
                hideFromVisibility: true,
                editableSelect: true,
                filtrable: true,
                filters: [
                    { value: 'sostituita', text: 'Sostituita' },
                    { value: 'similare', text: 'Similare' }
                ],
                filterMultiple: false,
                editableSelectOpts: {
                    allowClear: true
                },
                meetsRequirements: (value, record, column) => {
                    if (column.field === 'idRefSostituita') {
                        if (!_.isNil(value) && !_.isEmpty(value) && _.isNil(record.tipoSostituzione)) {
                            return {success: false, message: 'Seleziona un tipo sostituzione'};
                        }
                    } else if (column.field === 'tipoSostituzione') {
                        if (!_.isNil(record.idRefSostituita) && !_.isEmpty(record.idRefSostituita) && _.isNil(value)) {
                            return {success: false, message: 'Seleziona un tipo sostituzione'};
                        }
                    } else {
                        if (!_.isNil(record.idRefSostituita) && !_.isEmpty(record.idRefSostituita) && _.isNil(record.tipoSostituzione)) {
                            return {success: false, message: 'Seleziona un tipo sostituzione'};
                        }
                    }

                    return {success: true};
                },
                editableSelectList: [
                    {id: 'sostituita', label: 'Sostituita'},
                    {id: 'similare', label: 'Similare'}
                ],
                align: 'center',
                width: 200,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}})
            },
            {
                title: 'DATA AVS REF SOSTITUITA',
                dataIndex: 'dataAvsRefSostituita',
                align: 'center',
                contentType: 'date',
                width: 150,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: 'STOCK PRESENTAZIONE',
                dataIndex: 'stock',
                editable: !dossier || (!dossier.pxLoaded && user.role === ROLE_MERCH && ['CR', 'R1'].includes(dossier.statoDossier)),
                contentType: 'decimal',
                precision: 2,
                align: 'center',
                dossierTypes: ['NG', 'RG', 'ST'],
                width: 200,
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
                searchable: true,
                minValue: (record) => (record.precotopReapproToBe === 1 ? 1 : 0),
                meetsRequirements: (value, record, column) => {
                    if (user.role === ROLE_MERCH) {
                        if ((column.field === 'stock' && record.precotopReapproToBe === 1 && _.isNil(value))
                            || (column.field !== 'stock' && record.precotopReapproToBe === 1 && _.isNil(record.stock))) {
                            return {success: false, message: 'Inserisci un valore maggiore di 0'};
                        }
                    }

                    return {success: true};
                },
            },
            {
                title: 'NUM PDD',
                dataIndex: 'numPdd',
                key: 'numPdd',
                sortable: true,
                searchable: true,
                contentType: 'integer',
                editable: !dossier || (user.role === ROLE_MERCH && !dossier.pxLoaded && ['CR', 'R1'].includes(dossier.statoDossier)),
                align: 'center',
                width: 100,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'REPARTO',
                dataIndex: 'nomeReparto',
                key: 'nomeReparto',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                sortable: true,
                hidden: true,
                width: 200,
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
            },
            {
                title: 'SOTTOREPARTO',
                dataIndex: 'nomeSottoreparto',
                key: 'nomeSottoreparto',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                sortable: true,
                hidden: true,
                width: 200,
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
            },
            {
                title: 'EAN',
                dataIndex: 'ean',
                key: 'ean',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                searchable: true,
                width: 150,
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: 'STATO',
                dataIndex: 'stato',
                key: 'stato',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                filtrable: true,
                filters: availableFilters.stato ? availableFilters.stato.map(opt => ({value: opt, text: REFERENCE_STATE[opt].value})) : [],
                align: 'center',
                visibleTo: [ROLE_CP, ROLE_MERCH, ROLE_CPXS, ROLE_GUEST],
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '200px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '200px'}}),
                render: (value, record) => REFERENCE_STATE[value].value
            },
            // {
            //     title: () => <span className="special-table-head-title"><i>DATA PREVISTA</i> ARRIVO IN DEP</span> ,
            //     cleanTitle: 'DATA PREVISTA ARRIVO IN DEP',
            //     dataIndex: 'dataPrevArrivoDeposito',
            //     dossierTypes: ['NG', 'RG', 'XS', 'ST'],
            //     align: 'center',
            //     contentType: 'date',
            //     width: 120,
            //     visibleTo: [ROLE_MERCH, ROLE_CS, ROLE_GUEST],
            //     onHeaderCell: column => ({style: {minWidth: '120px'}}),
            //     onCell: (record, rowIndex) => ({style: {minWidth: '120px'}}),
            // },
            {
                title: () => <span className="special-table-head-title"><i>DATA ATTIVO</i> COMMERCIO</span> ,
                cleanTitle: 'DATA ATTIVO COMMERCIO',
                dataIndex: 'dataAttivoCommercio',
                key: 'dataAttivoCommercio',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                contentType: 'date',
                width: 150,
                sortable: true,
                searchable: true,
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: 'DATA AVS',
                dataIndex: 'dataAvs',
                align: 'center',
                contentType: 'date',
                width: 150,
                dossierTypes: ['NG', 'RG', 'ST', 'XS'],
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>PREZZO</i> VENDITA</span> ,
                cleanTitle: 'PREZZO VENDITA',
                dataIndex: 'pv',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                contentType: 'money',
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: <>
                    COD. FORNITORE
                    <Tooltip title='In caso di multifornitore viene mostrato quello attivo sulla maggior parte dei negozi'>
                        <Icon type="question-circle" theme="filled" style={{color: 'rgba(0, 0, 0, .45)', marginLeft: '10px'}} />
                    </Tooltip>
                </>,
                cleanTitle: 'COD. FORNITORE',
                dataIndex: 'codiceFornitore',
                key: 'codiceFornitore',
                searchable: true,
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                width: 165,
                onHeaderCell: column => ({style: {minWidth: '165px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '165px'}}),
            },
            {
                title: <>
                    NOME FORNITORE
                    <Tooltip title='In caso di multifornitore viene mostrato quello attivo sulla maggior parte dei negozi'>
                        <Icon type="question-circle" theme="filled" style={{color: 'rgba(0, 0, 0, .45)', marginLeft: '10px'}} />
                    </Tooltip>
                </>,
                cleanTitle: 'NOME FORNITORE',
                dataIndex: 'nomeFornitore',
                key: 'nomeFornitore',
                searchable: true,
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                width: 170,
                onHeaderCell: column => ({style: {minWidth: '170px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '170px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP</i> 2500</span> ,
                cleanTitle: 'TOP 2500',
                dataIndex: 'top2500',
                key: 'top2500',
                filtrable: true,
                filters: [
                    {value: true, text: 'Sì'},
                    {value: false, text: 'No'}
                ],
                filterMultiple: false,
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                hidden: true,
                contentType: 'bool',
                width: 60,
                onHeaderCell: column => ({style: {minWidth: '60px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '60px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP</i> EM</span> ,
                cleanTitle: 'TOP EM',
                dataIndex: 'topEm',
                key: 'topEm',
                filtrable: true,
                filters: [
                    {value: true, text: 'Sì'},
                    {value: false, text: 'No'}
                ],
                filterMultiple: false,
                align: 'center',
                contentType: 'bool',
                width: 60,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '60px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '60px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP</i> Expo</span> ,
                cleanTitle: 'TOP Expo',
                dataIndex: 'topExpo',
                key: 'topExpo',
                align: 'center',
                filtrable: true,
                filterMultiple: false,
                filters: [
                    {value: true, text: 'Sì'},
                    {value: false, text: 'No'}
                ],
                contentType: 'bool',
                width: 60,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '60px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '60px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP</i> MDH</span> ,
                cleanTitle: 'TOP MDH',
                dataIndex: 'topMdh',
                key: 'topMdh',
                filtrable: true,
                filters: [
                    {value: true, text: 'Sì'},
                    {value: false, text: 'No'}
                ],
                filterMultiple: false,
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                hidden: false,
                align: 'center',
                contentType: 'bool',
                width: 60,
                onHeaderCell: column => ({style: {minWidth: '75px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '75px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP</i> XSELLING</span> ,
                cleanTitle: 'TOP XSELLING',
                dataIndex: 'topxs',
                key: 'topxs',
                filterMultiple: false,
                contentType: 'bool',
                filtrable: true,
                filters: [
                    { value: true, text: 'Si' },
                    { value: false, text: 'No' }
                ],
                hidden: true,
                align: 'center',
                width: 150,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>TOP XSELLING</i> FUTURO</span> ,
                cleanTitle: 'TOP XSELLING FUTURO',
                dataIndex: 'topxsToBe',
                key: 'topxsToBe',
                editable: !dossier || (dossier.type !== 'NG' && user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier)),
                editableSelect: true,
                editableSelectList: [
                    {id: 'yes', label: 'SI'},
                    {id: 'no', label: 'NO'}
                ],
                filtrable: true,
                filters: [
                    { value: true, text: 'Si' },
                    { value: false, text: 'No' }
                ],
                filterMultiple: false,
                editableSelectOpts: {
                    allowClear: true
                },
                align: 'center',
                contentType: 'bool',
                dossierTypes: [],
                width: 150,
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: 'QUARTILI',
                dataIndex: 'quartili',
                dossierTypes: ['NG', 'RG', 'XS', 'ST'],
                align: 'center',
                contentType: 'integer',
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'CONDIZIONAMENTO',
                dataIndex: 'condizionamento',
                align: 'center',
                dossierTypes: ['NG', 'RG', 'ST'],
                contentType: 'decimal',
                visibleTo: [ROLE_CP, ROLE_MERCH, ROLE_GUEST, ROLE_CS, ROLE_GUEST],
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            // {
            //     title: 'CONDIZIONAMENTO NEGOZIO',
            //     dataIndex: 'pcbNegozio',
            //     align: 'center',
            //     visibleTo: [ROLE_CS, ROLE_GUEST],
            //     width: 150,
            //     dossierTypes: ['NG', 'RG', 'ST'],
            //     onHeaderCell: column => ({style: {minWidth: '150px'}}),
            //     onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            // },
            {
                title: () => <span className="special-table-head-title"><i>TIPO</i> IMBALLO</span> ,
                cleanTitle: 'TIPO IMBALLO',
                hidden: true,
                dataIndex: 'tipoImballo',
                key: 'tipoImballo',
                filtrable: true,
                filters: availableFilters.tipoImballo ? availableFilters.tipoImballo.map(opt => ({value: opt, text: opt})) : [],
                align: 'center',
                width: 140,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '140px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '140px'}}),
            },
            {
                title: () => <span className="special-table-head-title"><i>DIMENSIONI</i> IMBALLO</span> ,
                cleanTitle: 'DIMENSIONI IMBALLO',
                dataIndex: 'dimensioniImballo',
                hidden: true,
                align: 'center',
                width: 60,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '60px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '60px'}}),
                render: (value, item) => `${item.profPackaging !== null ? item.profPackaging : 'N/A'} x ${item.largPackaging !== null ? item.largPackaging : 'N/A'} x ${item.altPackaging !== null ? item.altPackaging : 'N/A'}`
            },
            {
                title: () => <span className="special-table-head-title"><i>DIMENSIONI</i> REF</span> ,
                cleanTitle: 'DIMENSIONI REF',
                dataIndex: 'dimensioniRef',
                hidden: true,
                align: 'center',
                width: 60,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '60px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '60px'}}),
                render: (value, item) => `${item.prof !== null ? item.prof : 'N/A'} x ${item.larg !== null ? item.larg : 'N/A'} x ${item.alt !== null ? item.alt : 'N/A'}`
            },
            {
                title: 'CLASSEMENT',
                dataIndex: 'classement',
                key: 'classement',
                sortable: true,
                align: 'center',
                width: 150,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '150px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            },
            {
                title: 'TIPO GESTIONE ORDINI',
                dataIndex: 'modGestion',
                align: 'center',
                width: 100,
                dossierTypes: ['NG', 'RG', 'ST'],
                valueFormatter: params => {
                    switch (params.value) {
                        case '0':
                            return 'Automatico';
                        case '1':
                            return 'Manuale';
                        case '2':
                            return 'Convalida';
                        default:
                            return '';
                    }
                },
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            // {
            //     title: () => <span className="special-table-head-title"><i>TIPO GESTIONE</i> NEGOZIO</span> ,
            //     cleanTitle: 'TIPO GESTIONE NEGOZIO',
            //     dataIndex: 'modGestionNegozio',
            //     align: 'center',
            //     visibleTo: [ROLE_CS, ROLE_GUEST],
            //     width: 150,
            //     dossierTypes: ['NG', 'RG', 'ST'],
            //     onHeaderCell: column => ({style: {minWidth: '150px'}}),
            //     onCell: (record, rowIndex) => ({style: {minWidth: '150px'}}),
            // },
            {
                title: 'UNITÀ DI CAPIENZA',
                dataIndex: 'unitaCapienza',
                key: 'unitaCapienza',
                filtrable: true,
                filters: availableFilters.unitaCapienza ? availableFilters.unitaCapienza.map(opt => ({value: opt, text: opt})) : [],
                align: 'center',
                width: 110,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '110px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '110px'}}),
            },
            {
                title: 'QUANTITÀ DI CAPIENZA',
                dataIndex: 'quantitaCapienza',
                contentType: 'decimal',
                align: 'center',
                width: 110,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '110px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '110px'}}),
            },
            {
                title: 'TIPOLOGIA REFERENZA',
                dataIndex: 'tipoReferenza',
                key: 'tipoReferenza',
                filtrable: true,
                filters: [{value: 'STANDARD', text: 'Standard'}, {value: 'LOTTO', text: 'Lotto'}],
                filterMultiple: false,
                align: 'center',
                width: 120,
                dossierTypes: ['NG', 'RG', 'ST'],
                onHeaderCell: column => ({style: {minWidth: '120px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '120px'}}),
                render: (value, record) => record.lotto ? 'Lotto' : 'Standard'
            },
            {
                title: 'NOTE CP',
                dataIndex: 'noteCp',
                editable: !dossier || (user.role === ROLE_CP && ['CR', 'R1'].includes(dossier.statoDossier)),
                contentType: 'note',
                align: 'center',
                dossierTypes: ['NG', 'RG', 'ST'],
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'NOTE MERCH',
                editable: !dossier || (user.role === ROLE_MERCH && ['CR', 'R1'].includes(dossier.statoDossier)),
                dataIndex: 'noteMerch',
                contentType: 'note',
                align: 'center',
                dossierTypes: ['NG', 'RG', 'ST'],
                visibleTo: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_GUEST],
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'NOTE CS',
                editable: !dossier || user.role === ROLE_CS && ((dossier.type === 'PL' && dossier.statoDossier === 'CR') || (dossier.type !== 'PL' && dossier.statoDossier === 'CC')),
                dataIndex: 'noteCs',
                contentType: 'note',
                align: 'center',
                customVisibility: dossier && dossier.type !== 'XS' && dossier.idStore,
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'NOTE CP XS',
                editable: !dossier || (user.role === ROLE_CPXS && ['CR', 'R1'].includes(dossier.statoDossier)),
                dataIndex: 'noteCpxs',
                contentType: 'note',
                align: 'center',
                dossierTypes: ['XS'],
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'NOTE CS XS',
                editable: !dossier || user.role === ROLE_CS,
                dataIndex: 'noteCsxs',
                contentType: 'note',
                align: 'center',
                dossierTypes: ['XS'],
                dossierStates: ['CC'],
                width: 100,
                onHeaderCell: column => ({style: {minWidth: '100px'}}),
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
            {
                title: 'FILE IN ALLEGATO',
                dataIndex: 'attachments',
                contentType: 'files',
                editable: user.role === ROLE_MERCH,
                dossierTypes: ['NG','RG','ST'],
                width: 100,
                hidden: true,
                onCell: (record, rowIndex) => ({style: {minWidth: '100px'}}),
            },
        ]

        if (dossier) {
            columns = columns.filter(column => {
                if (column.dossierTypes && !column.dossierTypes.includes(dossier.type)) {
                    return false
                }

                if (column.dossierStates && !column.dossierStates.includes(dossier.statoDossier)) {
                    return false
                }

                if (column.customVisibility !== undefined && !column.customVisibility) {
                    return false
                }

                if (column.visibleTo && !column.visibleTo.includes(user.role)) {
                    return false
                }

                if (cluster && column.cluster && column.cluster !== cluster.cluster) {
                    return false
                }

                return true
            });
        }

        console.log(columns.map(column => ({ headerName: column.cleanTitle ?? column.title, field: column.dataIndex })))

        return columns
    };

    /**
     * Handle column visibility change
     * @param column
     */
    handleColumnVisibilityChange = (fieldName, isVisible) => {
        const {visibleColumns} = this.state;

        const updatedVisibleColumns = [ ...visibleColumns ];

        if (isVisible && !updatedVisibleColumns.includes(fieldName)) {
            updatedVisibleColumns.push(fieldName);
        } else if (!isVisible && updatedVisibleColumns.includes(fieldName)) {
            updatedVisibleColumns.splice(updatedVisibleColumns.indexOf(fieldName), 1);
        }

        this.setState({visibleColumns: updatedVisibleColumns});
    };

    /**
     * Retrieve data for table
     */
    fetchData = () => new Promise(resolve => {
        const {dossier} = this.props;
        const {page, sorter, filters} = this.state;

        if (_.isNil(dossier)) {
            return;
        }

        const processedFilters = transformFilters(filters, this.getColumns(dossier), sorter)

        const postObj = {
            nPage: page - 1,
            dimPage: 25,
            ...processedFilters
        };

        this.setState({tableLoading: true});

        api.dossiers.getRefs(dossier.id, postObj).then(result => {
            this.setState({
                tableLoading: false,
                items: _.get(result, 'data.elements', []).map(item => ({key: item.idReferenza, ...item, $errors: []})),
                totalItems: _.get(result, 'data.numTotElements', 0),
                availableFilters: result.data.filters
            }, resolve)
        });

        if (!['XS', 'PL'].includes(dossier.type)) {
            api.references.count(dossier.idFamily, dossier.department, dossier.id).then(result => {
                const sessionNewRefsCount = getFromSession([`Dossier-${dossier.id}`, 'References', 'Count'], null);

                if (_.isNil(sessionNewRefsCount) || sessionNewRefsCount !== result.data) {
                    this.setState({newRefsCount: result.data});
                    deleteFromSession([`Dossier-${dossier.id}`, 'References', 'Count']);
                }
            });
        }
    })

    /**
     * Handle page change
     * @param page
     * @param pageSize
     */
    handlePageChange = (page, pageSize) => {
        this.setState({page: page});
    };

    /**
     * Handle table change
     * @param filters
     * @param sorter
     */
    handleTableChange = (filters, sorter) => {
        this.setState({
            nPage: 1,
            filters,
            sorter
        });
    };

    /**
     * Set selected keys
     * @param selectedKeys
     * @param selectedRows
     */
    // handleSelectionChange = (selectedKeys, selectedRows) => {
    //     this.setState({selectedKeys: selectedKeys});
    // };

    /**
     * Set selected/deselected keys
     * @param record
     * @param selected
     */
    handleSelect = (record, selected) => {
        const {selectedAll} = this.state

        if (selectedAll) {
            if (selected)  {
                this.setState(state => ({deselectedKeys: state.deselectedKeys.filter(key => key !== record.key)}))
            } else {
                this.setState(state => ({deselectedKeys: [...state.deselectedKeys, record.key]}))
            }
        } else {
            if (selected)  {
                this.setState(state => ({selectedKeys: [...state.selectedKeys, record.key]}))
            } else {
                this.setState(state => ({selectedKeys: state.selectedKeys.filter(key => key !== record.key)}))
            }
        }
    }

    deleteRecords = () => {
        const { dossier } = this.props;
        const {selectedNodes} = this.state;

        this.agTable.current.state.gridApi.showLoadingOverlay();

        api.dossiers.deleteRefs(dossier.id, 'checked', {}, selectedNodes.map(node => node.data.idReferenza)).then(() => {
            this.agTable.current.state.gridApi.applyTransaction({
                remove: [...selectedNodes.map(node => node.data)]
            });
            this.agTable.current.state.gridApi.hideOverlay();
            handleServerSuccess();
        }, handleServerError);
    };

    exportDossier = () => {
        const { dossier } = this.props;
        const { visibleColumns } = this.state;
        api.dossiers.export(dossier.id, visibleColumns)
          .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file excel con le referenze del dossier sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
          .catch(handleServerError);
    };

    viewPlanogram = () => {
        const { dossier } = this.props;
        window.open(dossier.planogramA, '_blank');
    };

    exportDashboard = () => {
        const { dossier } = this.props;
        api.dossiers.exportDashboard(dossier.idDossierSede !== null ? dossier.idDossierSede : dossier.id)
          .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file del cruscotto sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
          .catch(handleServerError);
    };

    alignParametersWithBackoffice = () => {
        const { selectedNodes } = this.state;
        const { dossier } = this.props;

        const productIds = selectedNodes.map(selectedNode => selectedNode.data.idReferenza);
        const dossierId = dossier.id;

        this.agTable.current.state.gridApi.showLoadingOverlay();

        api.dossiers.alignWithBackoffice(dossierId, productIds).then(response => {
            const updates = [];
            const productUpdates = response.data.reduce((acc, curr) => ({
                ...acc,
                [curr.idReferenza]: curr
            }), {});

            selectedNodes.forEach(selectedNode => {
                if (!productUpdates.hasOwnProperty(selectedNode.data.idReferenza)) {
                    return;
                }

                const productUpdate = productUpdates[selectedNode.data.idReferenza];
                selectedNode.data.facingA = productUpdate.facingA;
                selectedNode.data.facingAb = productUpdate.facingAb;
                selectedNode.data.facingAbc = productUpdate.facingAbc;
                selectedNode.data.topReappro = productUpdate.topReappro;
                selectedNode.data.stock = productUpdate.stock;

                updates.push(selectedNode.data);
            });

            this.agTable.current.state.gridApi.applyTransaction({
                update: updates
            });

            feedbackNotification('info', 'Operazione eseguita', 'I dati delle referenze selezionate sono stati allineati con i dati di Backoffice.');
        }).catch(error => {
            console.log(error);
            feedbackNotification('error', 'Operazione fallita', 'Abbiamo riscontrato dei problemi durante l\'operazione');
        });
    };

    saveRecords = () => {
        const {recordChanges} = this.state
        const {dossier} = this.props

        this.agTable.current.state.gridApi.showLoadingOverlay();

        api.dossiers.saveProducts(dossier.id, Object.keys(recordChanges).map(key => recordChanges[key].data)).then(response => {
            const updates = [];
            const updatedRecords = response.data;
            const changedRowIds = [];
            const updatedRecordChanges = {...recordChanges};
            for (const recordChangeKey in recordChanges) {
                if (!recordChanges.hasOwnProperty(recordChangeKey)) {
                    continue;
                }

                const recordChange = recordChanges[recordChangeKey];
                const updatedRecord = updatedRecords.find(updatedRecord => updatedRecord.idReferenza === recordChange.data.idReferenza);
                
                if (updatedRecord) {
                    const data = recordChange.data;

                    for (const key in updatedRecord) {
                        if (!updatedRecord.hasOwnProperty(key) || !data.hasOwnProperty(key)) {
                            continue;
                        }

                        data[key] = updatedRecord[key];
                    }

                    if (Array.isArray(data.errors) && data.errors.length > 0) {
                        changedRowIds.push(recordChangeKey);
                    } else {
                        delete updatedRecordChanges[recordChangeKey];
                    }

                    updates.push(data);
                } else {
                    delete updatedRecordChanges[recordChangeKey];
                }
            }
            this.agTable.current.state.gridApi.applyTransaction({
                update: updates
            });
            this.agTable.current.state.gridApi.redrawRows(Object.keys(recordChanges).map(key => recordChanges[key]));
            this.agTable.current.cleanChangedRowIds(changedRowIds);
            this.setState({
                recordChanges: updatedRecordChanges
            });

            // Notifications
            const productsWithErrors = response.data.filter(record => record.errors.length > 0).length;
            let incompleteProducts = 0
            this.agTable.current.state.gridApi.forEachNode((node, index) => {
                if (this.getIncompleteProductWarnings(node.data).length > 0) {
                    incompleteProducts++
                }
            })

            if (productsWithErrors === 0 && incompleteProducts === 0) {
                handleServerSuccess();
            }
            
            if (productsWithErrors > 0) {
                let message = `Ci sono ${productsWithErrors} referenze non salvate a causa di errori`;
                if (productsWithErrors === 1) {
                    message = `C'è una referenza non salvata a causa di errori`;
                }

                feedbackNotification('warning', `Operazione parziale`, message);
            }

            if (incompleteProducts > 0) {
                let message = `Ci sono ${incompleteProducts} referenze ancora da lavorare`;
                if (incompleteProducts === 1) {
                    message = `C'è una referenza ancora da lavorare`;
                }

                feedbackNotification(null, `Referenze incomplete`, message, <Icon type="warning" style={{ color: colors.yellow }}
              />);
            }
        }, handleServerError);

        // api.dossiers.saveRefs(dossier.id, Object.keys(recordChanges).map(key => recordChanges[key].data)).then(response => {
        //     const numRefIncomplete = _.get(response, 'data.numRefIncomplete', 0);
        //     const refNotUpdated = _.get(response, 'data.refNotUpdated', []);
        //     if (numRefIncomplete  > 0) {
        //         feedbackNotification('warning', `Attenzione! ${numRefIncomplete === 1 ? `C'è 1 referenza` : `Ci sono ${numRefIncomplete} referenze`} ancora da lavorare`);
        //     }
        //
        //     if (refNotUpdated.length > 0) {
        //         const reasons = new Set(refNotUpdated.map(ref => ref.reason).filter(reason => reason))
        //         feedbackNotification('warning',
        //             `Attenzione! ${refNotUpdated.length === 1 ? `C'è 1 referenza che non è stata aggiornata` : `Ci sono ${refNotUpdated.length} referenze che non sono state aggiornate`}`,
        //             reasons.size !== 0 ? <>{Array.from(reasons).map(r => <p>{refNotUpdatedReasons[r]}</p>)}</> : null);
        //     }
        //
        //     if (numRefIncomplete === 0 && refNotUpdated.length === 0) {
        //         handleServerSuccess();
        //     }
        //
        //     this.fetchData();
        //     this.setState({changes: [], refNotUpdated: refNotUpdated.map(ref => ref.idReferenza), selectedKeys: [], deselectedKeys: [], selectedAll: false});
        // }, handleServerError);
    }

    /**
     * Handle select all
     * @param selected
     * @param selectedRows
     * @param changeRows
     */
    handleSelectAll = (selected, selectedRows, changeRows) => {
        this.setState({selectedAll: selected, selectedKeys: [], deselectedKeys: []})
    };

    /**
     * Edit multiple rows
     */
    editMultipleRows = () => {
        const {selectedKeys, deselectedKeys, selectedAll, filters, sorter} = this.state;
        let references = selectedKeys;

        if (selectedAll) {
            references = deselectedKeys;
        }

        _.invoke(this.props, 'onBatchEdit', selectedAll === true ? 'unchecked' : 'checked', filters, references, sorter);
    };

    buildRowChange = (changes, value, record, dataIndex) => {
        const changesClone = _.cloneDeep(changes);
        const changesIndex = changes.findIndex(change => change.idReferenza === record.key);
        if (changesIndex === -1) {
            // If item is being changed from the original (i.e. no change is recorded), push new change
            const change = { idReferenza: record.key }

            // Send all editable properties to allow nullification
            const editableProps = this.getColumns()
                .filter(column => column.editable)
                .map(column => column.dataIndex)

            for (let prop of editableProps) {
                change[prop] = record[prop]
            }

            change[dataIndex] = value

            changesClone.push(change);
       /*
            REMOVED!!!
            This function is called twice with the same value on the last digit: once when pressed last digit
            , another when field lose focus
        } else if (value === item[dataIndex]) {
            // otherwise, if property is being reverted to original value, delete it
            const changesToThisItem = changesClone[changesIndex];
            delete changesToThisItem[dataIndex];
            // (if "idReferenza" is the only property left, remove object entirely)
            if (Object.keys(changesToThisItem).length === 1) {
                changesClone.splice(changesIndex, 1)
            }*/
        } else {
            // otherwise (item is already changed from the original and is not being reverted), add/modify property change
            const changesToThisItem = changesClone[changesIndex];
            changesToThisItem[dataIndex] = value;
        }

        return changesClone;
    };

    handleRowChange = (value, record, dataIndex) => {
        const {changes} = this.state;

        let changesClone = this.buildRowChange(changes, value, record, dataIndex);

        if (dataIndex === 'gammaToBe') {
            let newValue = null;

            if (value === 'D') {
                newValue = 2;
            } else if (value === 'S' || value === 'R') {
                newValue = 0;
            }

            if (newValue !== null) {
                changesClone = this.buildRowChange(changesClone, newValue, record, 'precotopReapproToBe');
            }
        }

        if (dataIndex === 'precotopReapproToBe' && value === 1) {
            const newValues = {}

            if (record.gammaToBe === 'A' && (_.isNil(record.facingA) || record.facingA === 0)) {
                newValues.facingA = 1;
            }

            if (['A', 'B'].includes(record.gammaToBe) && (_.isNil(record.facingAb) || record.facingAb === 0)) {
                newValues.facingAb = 1;
            }

            if (['A', 'B', 'C'].includes(record.gammaToBe) && (_.isNil(record.facingAbc) || record.facingAbc === 0)) {
                newValues.facingAbc = 1;
            }

            if (_.isNil(record.stock) || record.stock === 0) {
                newValues.stock = 1;
            }

            for (const column in newValues) {
                if (newValues.hasOwnProperty(column)) {
                    changesClone = this.buildRowChange(changesClone, newValues[column], record, column);
                }
            }
        }

        if (dataIndex === 'precotopReapproToBe' && value === 2) {
            changesClone = this.buildRowChange(changesClone, 0, record, 'facingA');
            changesClone = this.buildRowChange(changesClone, 0, record, 'facingAb');
            changesClone = this.buildRowChange(changesClone, 0, record, 'facingAbc');
        }

        this.setState({changes: changesClone});
    }

    mergeItemsWithChanges = () => {
        const {items, changes} = this.state;
        const result = _.cloneDeep(items)


        for (let item of result) {
            const index = changes.findIndex(change => change.idReferenza === item.key)
            if (index !== -1) {
                Object.assign(item, changes[index])
            }
        }

        return result
    }

    handleOnError = (errors) => {
        this.setState({canSave: errors.length === 0});
    };

    confirmFacingChange = (facing) => {
        const {dossier, updateClusterCallback} = this.props;
        api.stores.changeCluster(dossier.id, facing).then(response => {
            handleServerSuccess();
            updateClusterCallback(facing);
            this.getAllProducts();
        }).catch();
    };

    canConfirmFacing = () => {
        return this.state.chosenCluster !== null;
    };

    showModalFacing = () => {
        // const {chosenCluster} = this.state;
        //
        // Modal.info({
        //     title: 'Scelta cluster lineare',
        //     content: (
        //         <div>
        //             <p>Per procedere si deve selezionare il cluster lineare per la famiglia merchandising del dossier</p>
        //             <Radio.Group buttonStyle="solid" defaultValue={chosenCluster} onChange={value => this.setState({chosenCluster: value})}>
        //                 <Radio.Button value="A">Lineare A</Radio.Button>
        //                 <Radio.Button value="AB">Lineare AB</Radio.Button>
        //                 <Radio.Button value="ABC">Lineare ABC</Radio.Button>
        //             </Radio.Group>
        //         </div>
        //     ),
        //     onOk() {
        //         return false
        //     },
        //     okText: 'Conferma'
        // });
    };

    getSelectedKeys = () => {
        const { selectedAll, items, selectedKeys, deselectedKeys } = this.state

        if (selectedAll) {
            return items.filter(item => !deselectedKeys.includes(item.key)).map(item => item.key)
        }

        return selectedKeys
    }

    getAllProducts() {
        this.agTable.current.state.gridApi.showLoadingOverlay();
        const {dossier} = this.props;

        if (_.isNil(dossier)) {
            return;
        }

        api.dossiers.getAllProducts(dossier.id).then((response) => {
            this.agTable.current.loadData(response.data);
            this.agTable.current.state.gridApi.hideOverlay();
        }).catch();
    }

    onAgTableChange = (change) => {
        const { user, dossier } = this.props;

        switch (change.action) {
            case 'cellChange':
                this.applyTriggers(change)
                this.setState({
                    recordChanges: {
                        ...this.state.recordChanges,
                        [change.node.id]: change.node
                    }
                });

                if(user.role === "MERCH"){
                    if (change.colDef.field === 'fileAllegati') {
                        change.node.setDataValue('attachments', change.newValue);
                    }
                }

                if (user.role === 'CS') {
                    if (change.colDef.field === 'topReappro') {
                        if (change.newValue === 2) {
                            change.node.setDataValue('facingA', 0);
                            change.node.setDataValue('facingAb', 0);
                            change.node.setDataValue('facingAbc', 0);
                        } else if (change.newValue === 1) {
                            change.node.setDataValue('facingA', 1);
                            change.node.setDataValue('facingAb', 1);
                            change.node.setDataValue('facingAbc', 1);
                        }
                    }

                    if (change.colDef.field === 'facingA' && dossier.type === 'PL' && (change.newValue === 0 || change.newValue === null || change.newValue === undefined || change.newValue === '') && change.node.data.prevVMM !== change.newValue) {
                        change.node.setDataValue('prevVMM', change.newValue);
                    }

                    if (change.colDef.field === 'prevVMM' && dossier.type === 'PL' && (change.newValue === 0 || change.newValue === null || change.newValue === undefined || change.newValue === '') && change.node.data.facingA !== change.newValue) {
                        change.node.setDataValue('facingA', change.newValue);
                    }
                }

                if (change.colDef && change.colDef.field !== 'errors') {
                    this.validateProduct(change);
                } else {
                    let canSave = true;

                    for (const key in this.state.recordChanges) {
                        if (!this.state.recordChanges.hasOwnProperty(key)) {
                            continue;
                        }

                        if (this.state.recordChanges[key].data.errors.length > 0) {
                            canSave = false;
                            break;
                        }
                    }

                    this.setState({ canSave });
                }

                break;

            case 'selectionChange':
                this.setState({
                    selectedNodes: [...change.nodes]
                });

                break;

            case 'gridLoaded':
                this.getAllProducts();
                break;

            case 'columnVisibilityChange':
                this.handleColumnVisibilityChange(change.column.colId, change.column.visible);
                break;

            default:
        }
    };

    applyTriggers = (change) => {
        const node = change.node
        const changes = {}

        switch (change.colDef.field) {
            case 'gammaToBe':
                switch (change.newValue) {
                    case 'D':
                    case 'R':
                    case 'S':
                        if (node.data.precotopReapproToBe !== 2) {
                            changes.precotopReapproToBe = 2;
                        }

                        break

                    default:
                        break
                }
    
                break

            case 'precotopReapproToBe':
                switch (change.newValue) {
                    case 1:
                        if (node.data.gammaToBe === 'A' && (_.isNil(node.data.facingA) || node.data.facingA === 0)) {
                            changes.facingA = 1;
                        }
            
                        if (['A', 'B'].includes(node.data.gammaToBe) && (_.isNil(node.data.facingAb) || node.data.facingAb === 0)) {
                            changes.facingAb = 1;
                        }
            
                        if (['A', 'B', 'C'].includes(node.data.gammaToBe) && (_.isNil(node.data.facingAbc) || node.data.facingAbc === 0)) {
                            changes.facingAbc = 1;
                        }
            
                        if (_.isNil(node.data.stock) || node.data.stock === 0) {
                            changes.stock = 1;
                        }

                        break

                    case 2:
                        if (node.data.facingA !== 0) {
                            changes.facingA = 0
                        }

                        if (node.data.facingAb !== 0) {
                            changes.facingAb = 0
                        }

                        if (node.data.facingAbc !== 0) {
                            changes.facingAbc = 0
                        }

                        break

                    default:
                        break
                }

                break

            default:
                break
        }

        for (const prop of Object.getOwnPropertyNames(changes)) {
            node.setDataValue(prop, changes[prop])
        }
    }

    validateProduct = (change) => {
        const { user, dossier } = this.props;
        const node = change.node;

        const errors = [];

        switch (user.role) {
            case "CP":
                switch (dossier.type) {
                    case "NG":
                    case "RG":
                    case "ST":
                        break;

                    default:
                        break;
                }

                break;

            case "MERCH":
                switch (dossier.type) {
                    case "NG":
                    case "RG":
                    case "ST":
                        if (node.data.precotopReapproToBe != null && node.data.precotopReapproToBe === 1 && (node.data.stock == null || node.data.stock === 0)) {
                            errors.push(PRECOTOP_REAPPRO_TO_BE_1_AND_STOCK_NULL_OR_ZERO);
                        }

                        if (dossier.department !== 12) {
                            const takenNumPdd = [];

                            this.agTable.current.state.gridApi.forEachNode((currentNode, index) => {
                                if (errors.includes(NUM_PDD_DUPLICATE) || currentNode.data.idReferenza === node.data.idReferenza) {
                                    return;
                                }

                                if (!currentNode.data.numPdd) {
                                    return;
                                }

                                if (!takenNumPdd.includes(currentNode.data.numPdd)) {
                                    takenNumPdd.push(currentNode.data.numPdd);
                                }
                            });

                            if (node.data.precotopReapproToBe === 1 && takenNumPdd.includes(node.data.numPdd)) {
                                errors.push(NUM_PDD_DUPLICATE);
                            }
                        }

                        break;
                    default:
                        break;
                }

                break;

            case "CS":
                switch (dossier.type) {
                    case "NG":
                    case "RG":
                    case "ST":
                        if (node.data.gammaToBe != null && node.data.gammaToBe === 'A' && dossier.cluster === 'A' && node.data.facingA === 0 && node.data.topReappro === 1) {
                            errors.push(GAMMA_TO_BE_A_CLUSTER_A_AND_FACING_A_ZERO);
                        }

                        if (node.data.gammaToBe != null && node.data.gammaToBe === 'A' && dossier.cluster === 'AB' && node.data.facingAb === 0 && node.data.topReappro === 1) {
                            errors.push(GAMMA_TO_BE_A_CLUSTER_AB_AND_FACING_AB_ZERO);
                        }

                        if (node.data.gammaToBe != null && node.data.gammaToBe === 'A' && dossier.cluster === 'ABC' && node.data.facingAbc === 0 && node.data.topReappro === 1) {
                            errors.push(GAMMA_TO_BE_A_CLUSTER_ABC_AND_FACING_ABC_ZERO);
                        }

//                        if (node.data.precotopReapproToBe != null && node.data.precotopReapproToBe == 2 && dossier.cluster === 'A' && node.data.facingA > 1) {
//                            errors.push(Error.PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_A_GREATER_THAN_1);
//                        }
//
//                        if (node.data.precotopReapproToBe != null && node.data.precotopReapproToBe == 2 && dossier.cluster === 'AB' && node.data.facingAb > 1) {
//                            errors.push(Error.PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_AB_GREATER_THAN_1);
//                        }
//
//                        if (node.data.precotopReapproToBe != null && node.data.precotopReapproToBe == 2 && dossier.cluster === 'ABC'  && node.data.facingAbc > 1) {
//                            errors.push(Error.PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_ABC_GREATER_THAN_1);
//                        }

                        break;

                    case "PL":
                        if (node.data.prevVMM == null) {
                            errors.push(PL_PREV_VMM_NULL);
                        }

                        if (node.data.facingA == null) {
                            errors.push(PL_SPT_NULL);
                        }

//                        if ((p.getPrevVMM() != null && p.getPrevVMM() < 0)) {
//                            violatedConstraints.add(Constraint.PREV_VMM_LESS_THAN_ZERO);
//                        }
//
//                        if (p.facingA != null && p.facingA < 1) {
//                            violatedConstraints.add(Constraint.FACING_LESS_THAN_ONE);
//                        }

                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }

        const column = this.getColumns(dossier).find(column => column.dataIndex === change.colDef.field);

        // if (column && column.meetsRequirements) {
        //     const result = column.meetsRequirements(change.newValue, change.node.data, change.colDef);

        //     if (result.success !== true) {
        //         const error = CUSTOM_ERROR;
        //         error.custom = result.message;
        //         error.fields.push(change.colDef.field);
        //         errors.push(error);
        //     }
        // }

        node.setDataValue('errors', errors);
    };

    getIncompleteProductWarnings = product => {
        const warnings = [];

        switch (this.props.dossier.type) {
            case 'NG':
            case 'RG':
                if (isNaN(parseInt(product.facingA)) || parseInt(product.facingA) < 0) {
                    warnings.push(FACING_A_NULL);
                }

                if (isNaN(parseInt(product.facingAb)) || parseInt(product.facingAb) < 0) {
                    warnings.push(FACING_AB_NULL);
                }

                if (isNaN(parseInt(product.facingAbc)) || parseInt(product.facingAbc) < 0) {
                    warnings.push(FACING_ABC_NULL);
                }

                switch (this.props.user.role) {
                    case ROLE_CP:
                        if (product.precotopReapproToBe === 1 && _.isNil(product.prevVendutoAnnuo)) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_AND_PREV_VENDUTO_ANNUO_NULL)
                        }
                        if (product.idRefSostituita && _.isNil(product.tipoSostituzione)) {
                            warnings.push(ID_REF_SOSTITUITA_NOT_NULL_AND_TIPO_SOSTITUZIONE_NULL)
                        }
                        if ([REFERENCE_STATE.INI.key, REFERENCE_STATE.PRE.key].includes(product.stato)) {
                            warnings.push(STATO_INI_OR_PRE)
                        }
                        break
                    case ROLE_MERCH:
                        if (product.precotopReapproToBe === 1 && product.gammaToBe === 'A' && !product.facingA && !product.topEm) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_A_NULL_OR_ZERO)
                        }
                        if (product.precotopReapproToBe === 1 && product.gammaToBe === 'A' && !product.facingAb && !product.topEm) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_AB_NULL_OR_ZERO)
                        }
                        if (product.precotopReapproToBe === 1 && product.gammaToBe === 'A' && !product.facingAbc && !product.topEm) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_ABC_NULL_OR_ZERO)
                        }
                        if (product.precotopReapproToBe === 1 && product.gammaToBe === 'B' && !product.facingAb && !product.topEm) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_B_AND_FACING_AB_NULL_OR_ZERO)
                        }
                        if (product.precotopReapproToBe === 1 && product.gammaToBe === 'B' && !product.facingAbc && !product.topEm) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_B_AND_FACING_ABC_NULL_OR_ZERO)
                        }
                        if (product.precotopReapproToBe === 1 && product.gammaToBe === 'C' && !product.facingAbc && !product.topEm) {
                            warnings.push(PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_C_AND_FACING_ABC_NULL_OR_ZERO)
                        }

                        if (_.isNil(product.numPdd) && !product.topEm && product.precotopReapproToBe !== 2) {
                            warnings.push(NUM_PDD_NULL)
                        }
                        if ([REFERENCE_STATE.INI.key, REFERENCE_STATE.PRE.key].includes(product.stato)) {
                            warnings.push(STATO_INI_OR_PRE)
                        }
                        break
                    default:
                        break
                }
                break;

            case 'ST':
                switch (this.props.user.role) {
                    case ROLE_CP:
                    case ROLE_MERCH:
                        if ([REFERENCE_STATE.INI.key, REFERENCE_STATE.PRE.key].includes(product.stato)) {
                            warnings.push(STATO_INI_OR_PRE)
                        }
                        break
                    default:
                        break
                }
                break;

            case 'XS':
                switch (this.props.user.role) {
                    case ROLE_CPXS:
                        if ([REFERENCE_STATE.INI.key, REFERENCE_STATE.PRE.key].includes(product.stato)) {
                            warnings.push(STATO_INI_OR_PRE)
                        }
                        break
                    default:
                        break
                }
                break;

            default:
                break
        }

        return warnings
    }

    selectAllRefNotNew = () => {
        this.agTable.current.state.gridApi.forEachNode(node => {
            if (node.data.newReferenza) {
                node.setSelected(false);
            } else {
                node.setSelected(!this.state.allRefNotNewSelected);
            }

            this.setState({ allRefNotNewSelected: !this.state.allRefNotNewSelected });
        });
    };

    /**
     * Component rendering
     * @returns {*}
     */
    render() {
        const {
            visibleColumns, totalItems, selectedAll, selectedKeys,
            columnsMenuVisible, tableLoading, changes, records, fullscreen,
            page, refNotUpdated, selectedNodes, newRefsCount, canSave, showFacingModal, chosenCluster, facingModalLoading, filters, sorter
        } = this.state;

        const {onAddRefs, user, dossier, cluster} = this.props;

        let extraStyles = {};

        if (fullscreen) {
            extraStyles = {
                width: '100%',
                height: '100%',
                position: 'fixed',
                backgroundColor: '#ffffff',
                top: 0,
                left: 0,
                padding: '25px 25px 0 25px',
                zIndex: 999
            };
        }

        return (
            <Wrapper style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', ...extraStyles }}>
                {user.role === ROLE_CS && !['XS', 'PL'].includes(dossier.type) && dossier.idStore !== null &&
                <ModalConfirm
                    title="Scelta taglia cluster"
                    visible={showFacingModal}
                    okProps={{
                        disabled: chosenCluster === null,
                        loading: facingModalLoading
                    }}
                    onOk={() => {
                        this.setState({facingModalLoading: true});
                        api.stores.changeCluster(dossier.id, chosenCluster).then(() => {
                            this.props.updateClusterCallback(chosenCluster);
                            this.getAllProducts();
                        })
                    }}
                >
                    <div>
                        <p>Per procedere si deve selezionare la taglia del cluster per la famiglia merchandising del
                            dossier</p>
                        <Radio.Group buttonStyle="solid" defaultValue={chosenCluster}
                                     onChange={ev => this.setState({chosenCluster: ev.target.value})}>
                            <Radio value="A">S</Radio>
                            <Radio value="AB">M</Radio>
                            <Radio value="ABC">L</Radio>
                        </Radio.Group>
                    </div>
                </ModalConfirm>
                }
                <ActionBar>
                    <Row gutter={[16, 16]}>
                        <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
                            {AppAccess.isAuthorized('add_ref_dossier', {stato: dossier.statoDossier, type: dossier.type}) &&
                            <Popconfirm
                              title="Le modifiche non salvate andranno perse"
                              onConfirm={onAddRefs}
                              okText="OK"
                              cancelText="Annulla"
                              disabled={changes.length === 0}>
                                <Badge count={newRefsCount}>
                                    <Button type="primary" icon="file-add" onClick={changes.length === 0 ? onAddRefs : null}>Aggiungi</Button>
                                </Badge>
                            </Popconfirm>}
                            {AppAccess.isAuthorized('edit_dossier', {stato: dossier.statoDossier, type: dossier.type, storeId: dossier.idStore}) &&
                            <Button icon="save" disabled={Object.keys(this.state.recordChanges).length === 0 || !canSave} onClick={this.saveRecords} >Salva</Button>}
                            {AppAccess.isAuthorized('del_ref_dossier', {stato: dossier.statoDossier, type: dossier.type}) &&
                            <Popconfirm
                                title="Sei sicuro di voler eliminare le referenze selezionate?"
                                onConfirm={this.deleteRecords}
                                okText="Sì"
                                cancelText="No"
                                disabled={selectedNodes.length === 0}>
                                <Button icon="delete" disabled={selectedNodes.length === 0}>Elimina</Button>
                            </Popconfirm>}
                            {/*<Button icon="check-square" onClick={this.selectAllRefNotNew}>{!this.state.allRefNotNewSelected ? 'Seleziona allineo BO' : 'Deseleziona allineo BO'}</Button>*/}
                            {/*{['NG', 'ST', 'RG'].indexOf(dossier.type) > -1 && dossier.statoDossier === 'CC' && dossier.idDossierSede !== null &&*/}
                            {/*  <Popconfirm*/}
                            {/*    title="Sei sicuro di voler sovrascrivere i parametri del dossier (Facing, SP, Top Reappro) con quelli presenti a BO?"*/}
                            {/*    onConfirm={this.alignParametersWithBackoffice}*/}
                            {/*    okText="Si"*/}
                            {/*    cancelText="No"*/}
                            {/*    disabled={selectedNodes.length === 0 || selectedNodes.filter(o => !o.data.newReferenza).length !== selectedNodes.length}>*/}
                            {/*      <Button disabled={selectedNodes.length === 0 || selectedNodes.filter(o => !o.data.newReferenza).length !== selectedNodes.length} icon="import">Allinea Facing/SP BO</Button>*/}
                            {/*  </Popconfirm>}*/}
                        </Col>
                        <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
                            {user.role === ROLE_CS && !['XS', 'PL'].includes(dossier.type) && dossier.idStore !== null &&
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{marginRight: 10}}>Cluster taglia:</span>
                                <Radio.Group buttonStyle="solid" value={cluster ? cluster.cluster : ''} disabled={!AppAccess.isAuthorized('edit_dossier', {stato: dossier.statoDossier, type: dossier.type, storeId: dossier.idStore})}>
                                    <Popconfirm
                                        title="Attenzione :  eventuali modifiche al facing di negozio fatte in precedenza saranno perdute"
                                        onConfirm={() => this.confirmFacingChange('A')}
                                        onCancel={() => {
                                        }}
                                        okText="Conferma"
                                        cancelText="Annulla"
                                    >
                                        <Radio value="A">S</Radio>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Attenzione :  eventuali modifiche al facing di negozio fatte in precedenza saranno perdute"
                                        onConfirm={() => this.confirmFacingChange('AB')}
                                        onCancel={() => {
                                        }}
                                        okText="Conferma"
                                        cancelText="Annulla"
                                    >
                                        <Radio value="AB">M</Radio>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Attenzione :  eventuali modifiche al facing di negozio fatte in precedenza saranno perdute"
                                        onConfirm={() => this.confirmFacingChange('ABC')}
                                        onCancel={() => {
                                        }}
                                        okText="Conferma"
                                        cancelText="Annulla"
                                    >
                                        <Radio value="ABC">L</Radio>
                                    </Popconfirm>
                                </Radio.Group>
                            </div>
                            }
                            <Button icon={fullscreen ? 'fullscreen-exit' : 'fullscreen'} onClick={() => this.setState({fullscreen: !fullscreen})} />
                            {dossier.pxLoaded && <Button icon="file-image" onClick={this.viewPlanogram}>Visualizza planogramma</Button>}
                            {['NG', 'ST', 'XS', 'RG'].indexOf(dossier.type) > -1 && <Button icon="download" onClick={this.exportDossier}>Scarica</Button>}
                            {['NG', 'ST', 'XS', 'RG'].indexOf(dossier.type) > -1 && ((dossier.statoDossier === 'CC' && dossier.idDossierSede === null) || dossier.idDossierSede !== null) && <Button icon="dashboard" onClick={this.exportDashboard}>Cruscotto</Button>}
                        </Col>
                    </Row>
                </ActionBar>
                <Row style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', }}>
                    <Col style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <AgTable
                          canMakeSelection={AppAccess.isAuthorized('mass_ref_dossier', { stato: dossier.statoDossier, type: dossier.type }) ||
                          AppAccess.isAuthorized('del_ref_dossier', { stato: dossier.statoDossier, type: dossier.type })}
                          ref={this.agTable}
                          columns={this.getColumns(dossier)}
                          emitChange={(change) => this.onAgTableChange(change)}
                          getIncompleteProductWarnings={this.getIncompleteProductWarnings}
                        />
                    </Col>
                </Row>
            </Wrapper>
        )
    }
}

DossierReferencesTable.propTypes = {
    dossier: PropTypes.object
};

export default DossierReferencesTable;
