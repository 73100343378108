import styled from 'styled-components';
import {Col, Row, Typography} from "antd";
import { colors } from 'Common/constants';
const { Text } = Typography;

export const CardInformationContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -16px;
`;

export const CardInformationItem = styled.div`
    margin: 16px;
    
    label {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0,0,0,0.25);
        display: inline-block;
    }
    
    span {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        margin-left: 16px;
    }

    .highlighted {
        background-color: ${colors.red}
        color: white;
    }
`;

export const PaginationCol = styled(Col)`
    text-align: center;
`;

export const PaginationRow = styled(Row)`
    margin-top: 16px;
    margin-bottom: 16px;
`;

export const StyledText = styled(Text)`
    color: #66cc33 !important;
`;