import * as actionTypes from '../action-types'
import api from 'Common/api'
import axiosConfig from 'Common/axiosConfig'
import { handleServerError } from 'Common/utils'
import { SET_USER } from '../action-types'

export const validateToken = (token, selectedStoreId) => {
  return async dispatch => {
    return await api.user
      .authenticate(token, selectedStoreId)
      .then(res => {
          // TODO: Remove role after we gather the data from backend
        const user = { ...res.data, token}
        if (res.status === 200) {
          const headers = { Authorization: `Bearer ${token}` }
          if (user.store > 0 || selectedStoreId > 0) {
            headers['LM-Store'] = user.store || Number(selectedStoreId);
          }
          axiosConfig.defaults.headers = headers;
          dispatch({ type: actionTypes.VALIDATE_TOKEN, payload: user })
          dispatch(setUserAuthorization());
        }
      })
      .catch(err => handleServerError(err))
  }
}

export const setUserAuthorization = () => {
  return async dispatch => {
    try {
      const res = await api.user.getSpecialAuthorization();
      dispatch({
        type: actionTypes.SET_SPECIAL_AUTH,
        payload: res.data
      });
    } catch (e) {
      handleServerError(e);
    }
  };
};

export const setUser = (user, token, selectedStore) => {
  return async dispatch => {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (selectedStore) {
      headers['LM-Store'] = selectedStore;
      sessionStorage.setItem('LeroyMerlin.selectedStoreId', selectedStore);
    }

    axiosConfig.defaults.headers = headers;

    dispatch(setUserAuthorization());

    dispatch({
      type: SET_USER,
      payload: {
        ...user,
        token
      }
    });
  }
}
