import { css } from '@emotion/css'
import { Icon } from 'antd'
import React from 'react'

const containerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100%;
  justify-content: center;
`;

export default function productImageCellRenderer(props) {

  if (!props.value) {
    return <></>;
  }

  return (
    <div className={containerStyle}>
      <img src={`https://media.adeo.com/media/${props.value}/filename.jpg?width=50&height=50`} alt={`Immagine prodotto ${props.data.libArt}`} style={{maxWidth: '100%', height: 'auto'}} />
    </div>
  )
}
