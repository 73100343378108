export const validateSimulationRowData = data => {
  const result = {};
  let invalid = false;

  if (data.qteVteDossier !== data.qteVteSim) {
    result.qteVteDossier = 'Quantità futura diversa dalla quantità simulata';
    invalid = true;
  }

  return {
    result,
    valid: !invalid,
  };
};
