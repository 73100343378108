import React from 'react'
import { StyledRadio, StyledRadioGroup, StyledInputNumber } from './styled'
import { Select, Input, DatePicker, Checkbox } from 'antd'
import { StyledButton } from 'Common/styled'
import { DATE_LOCALE } from 'Common/globals'
import * as moment from 'moment'
import * as _ from 'lodash'

const { Option } = Select

const OPERATORS = [
  {
    key: 'EQUALS',
    label: 'Uguale a',
    applicableTypes: ['integer', 'decimal', 'money', 'string', 'date'],
    defaultFor: ['integer', 'decimal', 'money', 'date'],
    appliesToLists: false,
  },
  {
    key: 'EQUALS_NULL',
    label: 'Vuoto',
    applicableTypes: ['integer', 'decimal', 'money', 'string', 'date'],
    defaultFor: [],
    appliesToLists: false,
  },
  {
    key: 'GREATER_THAN',
    label: 'Maggiore di',
    applicableTypes: ['integer', 'decimal', 'money', 'date'],
    defaultFor: [],
    appliesToLists: false,
  },
  {
    key: 'LESS_THAN',
    label: 'Minore di',
    applicableTypes: ['integer', 'decimal', 'money', 'date'],
    defaultFor: [],
    appliesToLists: false,
  },
  {
    key: 'LIKE',
    label: 'Contiene',
    applicableTypes: ['string'],
    defaultFor: ['string'],
    appliesToLists: false,
  },
  {
    key: 'NOT_LIKE',
    label: 'Non contiene',
    applicableTypes: ['string'],
    defaultFor: [],
    appliesToLists: false,
  },
  {
    key: 'IN',
    label: 'Contenuto in',
    applicableTypes: ['string'],
    defaultFor: [],
    appliesToLists: true,
  },
]

const getApplicableOperators = (contentType, multiple) =>
  OPERATORS.filter(o => {
    if (multiple) {
      return o.applicableTypes.includes(contentType)
    } else {
      return o.applicableTypes.includes(contentType) && !o.appliesToLists
    }
  })

const getDefaultOperator = contentType =>
  OPERATORS.filter(o => o.defaultFor && o.defaultFor.includes(contentType))[0]

const getCurrentOperator = (selectedKeys, contentType) =>
  selectedKeys.length !== 0
    ? OPERATORS.filter(o => o.key === selectedKeys[0].operator)[0]
    : getDefaultOperator(contentType)

const trimValue = (setSelectedKeys, selectedKeys) => {
  if (selectedKeys.length !== 0 && selectedKeys[0].value) {
    setSelectedKeys([{
      operator: selectedKeys[0].operator,
      value: selectedKeys[0].value.trim()
    }])
  }
}

const getValue = (
  filterType,
  filters,
  multiple,
  title,
  cleanTitle,
  contentType,
  precision,
  setSelectedKeys,
  selectedKeys,
  confirm,
  applicableOperators,
  defaultOperator,
  currentOperator
) => {
  if (currentOperator.key === 'EQUALS_NULL') {
    return '';
  }

  if (contentType === 'string' && !multiple) {
    return <Input
      placeholder={`Cerca ${cleanTitle ? cleanTitle : title}`}
      value={selectedKeys.length !== 0 ? selectedKeys[0].value : null}
      onChange={e =>
        setSelectedKeys([
          {
            operator:
              selectedKeys.length !== 0
                ? selectedKeys[0].operator
                : defaultOperator.key,
            value: e.target.value,
          },
        ])
      }
      onPressEnter={() => {
        trimValue(setSelectedKeys, selectedKeys)
        confirm()
      }}
    />
  }

  if (contentType === 'string' && multiple) {
    if (currentOperator && currentOperator.appliesToLists) {
      return <Select
        placeholder={`Cerca ${cleanTitle ? cleanTitle : title}`}
        value={selectedKeys.length !== 0 ? selectedKeys[0].value : []}
        onChange={value =>
          setSelectedKeys([
            {
              operator: selectedKeys[0].operator,
              value: value,
            },
          ])
        }
        mode="tags"
        maxTagCount={1}
        allowClear={true}
        open={false}
        getInputElement={() => (
          <input
            onPaste={ev => {
              const clipboardText = ev.clipboardData.getData('Text')

              if (_.isString(clipboardText)) {
                const split = clipboardText.split('\n')
                const value = selectedKeys[0].value

                split.forEach(item => {
                  const trim = _.trim(item)

                  if (
                    _.isNil(trim) ||
                    trim.length === 0 ||
                    value.indexOf(trim) > -1
                  ) {
                    return
                  }

                  value.push(trim)
                })

                setSelectedKeys([
                  {
                    operator: selectedKeys[0].operator,
                    value: value,
                  },
                ])
              }
              ev.preventDefault()
              return false
            }}
          />
        )}></Select>;
    } else {
      return <Input
        placeholder={`Cerca ${cleanTitle ? cleanTitle : title}`}
        value={selectedKeys.length !== 0 ? selectedKeys[0].value : null}
        onChange={e =>
          setSelectedKeys([
            {
              operator:
                selectedKeys.length !== 0
                  ? selectedKeys[0].operator
                  : defaultOperator.key,
              value: e.target.value,
            },
          ])
        }
        onPressEnter={() => {
          trimValue(setSelectedKeys, selectedKeys)
          confirm()
        }}
      />;
    }
  }

  if (['integer', 'decimal', 'money'].includes(contentType)) {
    return <StyledInputNumber
      placeholder={`Cerca ${cleanTitle ? cleanTitle : title}`}
      value={selectedKeys.length !== 0 ? selectedKeys[0].value : null}
      precision={
        precision ? precision : contentType === 'decimal' ? 2 : 0
      }
      decimalSeparator=","
      onChange={value =>
        setSelectedKeys([
          {
            operator:
              selectedKeys.length !== 0
                ? selectedKeys[0].operator
                : defaultOperator.key,
            value: value,
          },
        ])
      }
      onPressEnter={confirm}
    />;
  }

  if (contentType === 'date') {
    return <DatePicker
      value={
        selectedKeys.length !== 0 ? moment(selectedKeys[0].value) : null
      }
      onChange={(date, dateString) =>
        setSelectedKeys([
          {
            operator:
              selectedKeys.length !== 0
                ? selectedKeys[0].operator
                : defaultOperator.key,
            value: date.format('YYYY-MM-DD'),
          },
        ])
      }
      locale={DATE_LOCALE}
    />
  }

  return '';
}

const getInputFields = (
  filterType,
  filters,
  multiple,
  title,
  cleanTitle,
  contentType,
  precision,
  setSelectedKeys,
  selectedKeys,
  confirm
) => {
  switch (filterType) {
    case 'search':
      const applicableOperators = getApplicableOperators(contentType, multiple)
      const defaultOperator = getDefaultOperator(contentType)
      const currentOperator = getCurrentOperator(selectedKeys, contentType)

      return (
        <>
          {/* Operator field */}
          <Select
            className="operator-select"
            value={
              selectedKeys.length !== 0
                ? selectedKeys[0].operator
                : defaultOperator.key
            }
            onChange={operatorKey => {
              const operator = OPERATORS.filter(o => o.key === operatorKey)[0]

              // Value should be reset when switching between "single" and "multiple" operators (e.g. "EQUALS" <-> "IN")
              const shouldResetValue =
                operator.appliesToLists !== currentOperator.appliesToLists

              let value
              if (selectedKeys.length !== 0 && !shouldResetValue) {
                value = selectedKeys[0].value
              } else {
                value = operator.appliesToLists ? [] : null
              }

              setSelectedKeys([
                {
                  operator: operatorKey,
                  value: value,
                },
              ])
            }}>
            {applicableOperators.map(o => (
              <Option value={o.key}>{o.label}</Option>
            ))}
          </Select>

          {/* Value field */}

          {getValue(
            filterType,
            filters,
            multiple,
            title,
            cleanTitle,
            contentType,
            precision,
            setSelectedKeys,
            selectedKeys,
            confirm,
            applicableOperators,
            defaultOperator,
            currentOperator
          )}
        </>
      )

    case 'filter':
      return multiple === false ? (
        <StyledRadioGroup
          onChange={e => setSelectedKeys([e.target.value])}
          value={selectedKeys[0]}>
          {filters.map(item => (
            <StyledRadio value={item.value}>{item.text}</StyledRadio>
          ))}
        </StyledRadioGroup>
      ) : (
        <ul className="ant-dropdown-menu ant-dropdown-menu-without-submenu ant-dropdown-menu-root ant-dropdown-menu-vertical">
          {filters.map(item => (
            <li
              key={item.value}
              className="ant-dropdown-menu-item"
              role="menuitem">
              <Checkbox
                className="ant-custom-checkbox-wrapper"
                onClick={e =>
                  e.target.checked
                    ? setSelectedKeys([item.value, ...selectedKeys])
                    : setSelectedKeys(
                        selectedKeys.filter(
                          selectedKey => selectedKey !== item.value
                        )
                      )
                }
                checked={selectedKeys.includes(item.value)}>
                {item.text}
              </Checkbox>
            </li>
          ))}
        </ul>
      )

    default:
      break
  }
}

const canSearch = (filterType, selectedKeys, contentType) => {
  switch (filterType) {
    case 'search':
      const currentOperator = getCurrentOperator(selectedKeys, contentType)

      if (currentOperator.appliesToLists) {
        return (
          selectedKeys.length !== 0 &&
          selectedKeys[0].operator &&
          selectedKeys[0].value &&
          selectedKeys[0].value.length !== 0
        )
      } else {
        return (
          selectedKeys.length !== 0 &&
          selectedKeys[0].operator &&
          (selectedKeys[0].value || selectedKeys[0].value === 0 || currentOperator.key === 'EQUALS_NULL')
        )
      }

    case 'filter':
      return true

    default:
      break
  }
}

/**
 * @param {string} filterType - 'search' for searchable fields, 'filter' for filtrable ones.
 * @param {Object[]} filters - choices for filtrable fields, each with a value and a text property.
 * @param {boolean} multiple - true if filter supports list values, false otherwise.
 * @param {string|ReactNode} title - field name.
 * @param {string} cleanTitle - field name as string, provided if title is a ReactNode.
 * @param {string} contentType - field content type, can be one of: string, integer, decimal, money, date.
 * @param {number} precision - precision of decimal fields.
 * @param {Object[]} selectedKeys - current filter value (controlled prop).
 */
const FilterDropdown = ({
  filterType,
  filters,
  multiple,
  title,
  cleanTitle,
  contentType,
  precision,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  return (
    <div className="ant-custom-table-filters ant-table-filters search">
      <div className="input-container">
        {getInputFields(
          filterType,
          filters,
          multiple,
          title,
          cleanTitle,
          contentType,
          precision,
          setSelectedKeys,
          selectedKeys,
          confirm
        )}
      </div>
      <div className="buttons-container">
        <StyledButton
          type="primary"
          onClick={() => {
            if (contentType === 'string' && !getCurrentOperator(selectedKeys, contentType).appliesToLists) {
              trimValue(setSelectedKeys, selectedKeys)
            }

            if (selectedKeys.length !== 0 && selectedKeys[0].operator === 'EQUALS_NULL') {
              setSelectedKeys([{
                operator: selectedKeys[0].operator,
                value: [0]
              }]);
            }

            confirm()
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
          disabled={!canSearch(filterType, selectedKeys, contentType)}>
          Cerca
        </StyledButton>
        <StyledButton onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </StyledButton>
      </div>
    </div>
  )
}

export default FilterDropdown
