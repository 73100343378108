export const rankedSearch = (search, data, fields) => {
  const normalisedSearch = search.toLowerCase()
  const matches = fields.filter(field => field.ranked === true && (!field.minLen || (field.minLen && normalisedSearch.length >= field.minLen))).map(_ => [])
  const unrankedMatches = []
  const unrankedFields = fields.filter(field => field.ranked === false && (!field.minLen || (field.minLen && normalisedSearch.length >= field.minLen)))

  data.forEach(obj => {
    fields.filter(field => field.ranked === true && (!field.minLen || (field.minLen && normalisedSearch.length >= field.minLen))).forEach((field, index) => {
      const value = obj[field.name].toString().toLowerCase()

      if (field.match === 0 && value === normalisedSearch) {
        matches[index].push(obj)
      } else if (field.match === 1 && value.startsWith(normalisedSearch)) {
        matches[index].push(obj)
      } else if (field.match === 2 && value.includes(normalisedSearch)) {
        matches[index].push(obj)
      }
    })

    let objSearchString = ''

    unrankedFields.forEach(field => {
      objSearchString += obj[field.name].toString().toLowerCase()
    })

    if (unrankedFields.length > 0 && objSearchString.length > 0 && objSearchString.includes(normalisedSearch)) {
      unrankedMatches.push(obj)
    }
  })

  const results = []

  matches.forEach(match => {
    results.push(...match)
  })

  results.push(...unrankedMatches.filter(match => results.indexOf(match) === -1))

  return results
}
