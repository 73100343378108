import { DatePicker, Form, Input, InputNumber, message, Modal } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { cloneDeep } from 'lodash'

const SimulazioneHeaderChangeModal = ({ simulation, visible, onHide, onConfirm }) => {

  const [formValue, setFormValue] = useState({
    dateFrom: undefined,
    dateTo: undefined,
    title: undefined,
    storesA: undefined,
    storesAb: undefined,
    storesAbc: undefined,
  });

  useEffect(() => {
    if (!simulation) return;
    setFormValue({
      dateFrom: moment(simulation.dateFrom),
      dateTo: moment(simulation.dateTo),
      title: simulation.title,
      storesA: simulation.storesA,
      storesAb: simulation.storesAb,
      storesAbc: simulation.storesAbc,
    });
  }, [simulation])

  useEffect(() => {
    const dateTo = formValue?.dateTo;

    if (!dateTo) {
      setFormValue((prev) => ({
        ...prev,
        dateFrom: undefined,
      }));

      return;
    }

    setFormValue((prev) => ({
      ...prev,
      dateFrom: cloneDeep(dateTo).subtract(1, 'year'),
    }));
  }, [formValue?.dateTo]);

  const isFormValid = formValue.title && formValue.dateFrom && formValue.dateTo && formValue.storesA !== undefined && formValue.storesAb !== undefined && formValue.storesAbc !== undefined && formValue.storesA >= 0 && formValue.storesAb >= 0 && formValue.storesAbc >= 0 && formValue.storesA <= 50 && formValue.storesAb <= 50 && formValue.storesAbc <= 50 && formValue.dateFrom.isBefore(formValue.dateTo);

  const handleSave = useCallback(() => {
    if (!isFormValid) return;
    onConfirm(formValue);
  }, [formValue, onConfirm]);

  const handleCancel = useCallback(() => {
    setFormValue({
      dateFrom: moment(simulation.dateFrom),
      dateTo: moment(simulation.dateTo),
      title: simulation.title,
      storesA: simulation.storesA,
      storesAb: simulation.storesAb,
      storesAbc: simulation.storesAbc,
    });
    onHide();
  }, [onHide, simulation.dateFrom, simulation.dateTo, simulation.title, simulation.storesA]);

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      cancelText="Annulla"
      okText="Conferma"
      onOk={handleSave}
      okButtonProps={{ disabled: !isFormValid }}
    >
      <Form.Item label="Titolo" style={{ marginBottom: '8px' }}>
        <Input
          placeholder="Inserisci titolo simulazione"
          value={formValue.title}
          onChange={(e) => setFormValue({ ...formValue, title: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Data da" style={{ marginBottom: '8px' }}>
        <DatePicker
          value={formValue.dateFrom}
          disabled
        />
      </Form.Item>
      <Form.Item label="Data a" style={{ marginBottom: '8px' }}>
        <DatePicker
          placeholder="Seleziona data"
          value={formValue.dateTo}
          onChange={(date) => setFormValue({ ...formValue, dateTo: date })}
        />
      </Form.Item>
      <Form.Item label="Negozi con gamma A" style={{ marginBottom: '8px' }}>
        <InputNumber
          placeholder="Inserisci un valore compreso tra 0 e 50"
          value={formValue.storesA}
          onChange={(value) => setFormValue({ ...formValue, storesA: value })}
        />
      </Form.Item>
      <Form.Item label="Negozi con gamma A+B" style={{ marginBottom: '8px' }}>
        <InputNumber
          placeholder="Inserisci un valore compreso tra 0 e 50"
          value={formValue.storesAb}
          onChange={(value) => setFormValue({ ...formValue, storesAb: value })}
        />
      </Form.Item>
      <Form.Item label="Negozi con gamma A+B+C" style={{ marginBottom: '8px' }}>
        <InputNumber
          placeholder="Inserisci un valore compreso tra 0 e 50"
          value={formValue.storesAbc}
          onChange={(value) => setFormValue({ ...formValue, storesAbc: value })}
        />
      </Form.Item>
    </Modal>
  );
};

export default SimulazioneHeaderChangeModal;
