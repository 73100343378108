export const GENERIC_ERROR = { fields: [], description: 'GENERIC_ERROR' }
export const GENERIC_DATABASE_CONSTRAINT_VIOLATION = {
  fields: [],
  description: 'GENERIC_DATABASE_CONSTRAINT_VIOLATION'
}
export const NUM_PDD_DUPLICATE = { fields: ['numPdd'], description: 'NUM_PDD_DUPLICATE' }
export const DATA_INIZIOXS_AND_DATA_FINEXS_DECOUPLED = {
  fields: ['dataInizioxs', 'dataFinexs'],
  description: 'DATA_INIZIOXS_AND_DATA_FINEXS_DECOUPLED'
}
export const ID_REF_SOSTITUITA_AND_TIPO_SOSTITUZIONE_DECOUPLED = {
  fields: ['idRefSostituita', 'tipoSostituzione'],
  description: 'ID_REF_SOSTITUITA_AND_TIPO_SOSTITUZIONE_DECOUPLED'
}
export const FACING_LESS_THAN_ONE = { fields: ['facingA'], description: 'FACING_LESS_THAN_ONE' }
export const PREV_VMM_LESS_THAN_ZERO = { fields: ['prevVmm'], description: 'PREV_VMM_LESS_THAN_ZERO' }
export const PRECOTOP_REAPPRO_TO_BE_NOT_NULL_AND_GAMMA_TO_BE_D_R_S = {
  fields: ['gammaToBe', 'precotopReapproToBe'],
  description: 'PRECOTOP_REAPPRO_TO_BE_NOT_NULL_AND_GAMMA_TO_BE_D_R_S'
}
export const PRECOTOP_REAPPRO_TO_BE_1_AND_STOCK_NULL_OR_ZERO = {
  fields: ['stock'],
  description: 'PRECOTOP_REAPPRO_TO_BE_1_AND_STOCK_NULL_OR_ZERO'
}
export const GAMMA_TO_BE_A_CLUSTER_A_AND_FACING_A_ZERO = {
  fields: ['facingA'],
  description: 'GAMMA_TO_BE_A_CLUSTER_A_AND_FACING_A_ZERO'
}
export const GAMMA_TO_BE_A_CLUSTER_AB_AND_FACING_AB_ZERO = {
  fields: ['facingAb'],
  description: 'GAMMA_TO_BE_A_CLUSTER_AB_AND_FACING_AB_ZERO'
}
export const GAMMA_TO_BE_A_CLUSTER_ABC_AND_FACING_ABC_ZERO = {
  fields: ['facingAbc'],
  description: 'GAMMA_TO_BE_A_CLUSTER_ABC_AND_FACING_ABC_ZERO'
}
export const PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_A_GREATER_THAN_1 = {
  fields: ['facingA'],
  description: 'PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_A_GREATER_THAN_1'
}
export const PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_AB_GREATER_THAN_1 = {
  fields: ['facingAb'],
  description: 'PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_AB_GREATER_THAN_1'
}
export const PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_ABC_GREATER_THAN_1 = {
  fields: ['facingAbc'],
  description: 'PRECOTOP_REAPPRO_TO_BE_2_CLUSTER_A_AND_FACING_ABC_GREATER_THAN_1'
}

export const DATA_INIZIO_EVENTO_NON_VALIDA = {fields: ['dataInizioEvento'], description: 'DATA_INIZIO_EVENTO_NON_VALIDA'};
export const DATA_COMPLETAMENTO_NON_VALIDA = {fields: ['dataCompletamentoNegozio'], description: 'DATA_COMPLETAMENTO_NON_VALIDA'};
export const DATA_COMPLETAMENTO_GTE_DATA_INIZIO = {fields: ['dataCompletamentoNegozio'], description: 'DATA_COMPLETAMENTO_GTE_DATA_INIZIO'};
export const DATA_FINE_EVENTO_NON_VALIDA = {fields: ['dataFineEvento'], description: 'DATA_FINE_EVENTO_NON_VALIDA'};
export const STOCK_LT_STOCK_SEDE = {fields: ['stock'], description: 'STOCK_LT_STOCK_SEDE'};
export const PL_PREV_VMM_NULL = {fields: ['prevVMM'], description: 'PL_PREV_VMM_NULL'};
export const PL_SPT_NULL = {fields: ['facingA'], description: 'PL_SPT_NULL'};

export const PL_PREV_TOT_NULL_OR_ZERO = {fields: ['prevVtot'], description: 'PL_PREV_TOT_NULL_OR_ZERO'};
export const PL_PREV_TOT_PERC_NULL_OR_ZERO = {fields: ['prevVtotPerc'], description: 'PL_PREV_TOT_PERC_NULL_OR_ZERO'};
export const STOCK_SEDE_OBL = {fields: ['stock'], description: 'STOCK_SEDE_OBL'};

export const PREV_VENDUTO_TOT_OBL = {fields: ['prevVtot','prevVtotPerc'], description: 'PREV_VENDUTO_TOT_OBL'};

export const CUSTOM_ERROR = {
  fields: [],
  description: 'CUSTOM_ERROR',
  custom: null
};
