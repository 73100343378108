import React, { Component } from 'react'
import { Modal, Button, Table } from 'antd'

export default class OpecomIncompleteReferencesModal extends Component {
    render() {
        return (
            <Modal
                title="Avviso referenze incomplete (prev. venduto totale e/o spt obbligatori)"
                visible={this.props.visible}
                closable={false}
                maskClosable={false}
                footer={[
                <Button key="back" onClick={this.props.onCancel}>
                    Chiudi
                </Button>,
                ]}>
                <Table
                style={{ height: '200px', overflow: 'scroll', overflowX: 'hidden' }}
                pagination={false}
                showHeader={false}
                size="small"
                dataSource={this.props.items}
                columns={[
                    { dataIndex: 'refNomeReparto' },
                    { dataIndex: 'refCount' },
                ]}
                rowKey={r =>
                    `${r.refNomeReparto}${r.refCount}`
                }></Table>
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '16px',
                }}>
                </div>
            </Modal>
        )
    }
}
