import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import MainLayout from 'Hoc/MainLayout'
import { StyledButton } from 'Common/styled'
import Loader from 'Components/Loader'
import { EditOpecomRefsModal } from 'Components/EditOpecomRefsModal'
import api from 'Common/api'
import { Button, Descriptions, Modal, notification } from 'antd'
import { handleServerSuccess, handleServerError, getOpecomTitle, transformFilters, feedbackNotification } from 'Common/utils'
import { StyledDescriptions, StyledTitle } from './styled'
import ErrorPage from 'Components/ErrorPage'
import OpecomReferencesTable from 'Components/OpecomReferencesTable'
import { opecomStates, refNotUpdatedReasons } from 'Common/constants'
import * as moment from 'moment'
import { fetchStores } from 'Actions/data-actions'
import * as _ from 'lodash';
import {
  getOpecomInfo,
  OPECOM_NEGO_RICEZIONE_RELEX,
  OPECOM_NEGO_ULTIMO_INVIO_RELEX,
  OPECOM_SEDE_ULTIMA_RICEZIONE_RELEX,
  OPECOM_SEDE_ULTIMO_INVIO_RELEX,
  ROLE_CP
} from 'Common/constants/global'

/**
 * Container for opecom details page
 */
class EditOpecomDetails extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    isLoading: true,
    status: null,
    redirectTo: null,
    opecomHeader: null,
    batchEditMode: null,
    filters: null,
    batchEditRefIds: null,
    isRefsModalVisible: false,
    cluster: null,
  }

  componentDidMount() {
    const opecomChangeNotification = localStorage.getItem('LeroyMerlin.Cooperate.OpecomChangeNotification');
    if (opecomChangeNotification !== 'CONFIRMED') {
      const notificationkey = `open${Date.now()}`;
      const confirmNewNomenclatura = () => {
        notification.close(notificationkey);
        localStorage.setItem('LeroyMerlin.Cooperate.OpecomChangeNotification', 'CONFIRMED');
      };
    }
    const id = this.props.match.params.id

    let promises = [
      api.opecom.getOne(id)
    ]

    if (this.props.stores.length === 0) {
      promises.push(this.props.fetchStores())
    }

    Promise.all(promises)
      .then(responses => {
        if (responses[0].data.numTotElements === 1) {
          const opecomHeader = responses[0].data.elements[0]

          if (
            !opecomStates
              .filter(state => state.key === opecomHeader.statoOpecom)[0]
              .canView.includes(this.props.user.role)
          ) {
            this.setState({ status: '403' })
          } else {
            const expirationDay = moment(opecomHeader.startDate)
              .subtract(56, 'days')
              .startOf('day')
            const daysLeft =
              expirationDay.diff(moment().startOf('day'), 'days') + 1
            if (daysLeft >= 1 && daysLeft <= 10 && this.props.user.role === ROLE_CP) {
              const msg =
                daysLeft === 1
                  ? "Oggi è l'ultimo giorno per completare il dossier."
                  : `Mancano ${daysLeft} giorni alla data di completamento prevista per il ${expirationDay.format(
                      'DD/MM/YYYY'
                    )}.`
              Modal.warning({
                title: 'Scadenza lavorazione dossier',
                content: msg,
              })
            }

            this.setState({
              opecomHeader: opecomHeader,
              isLoading: false,
            })
          }
        } else {
          this.setState({ status: '404' })
        }
      })
      .catch(error => {
        const status = error.response ? error.response.status : null
        if (status >= 500 && status < 600) {
          this.setState({ status: '500' })
        } else {
          this.setState({ status: 'error' })
        }
      })
  }

  hideRefsModal = () => {
    this.setState({ isRefsModalVisible: false })
  }

  handleBatchEdit = (mode, filters, refIds) => {
    this.setState({
      batchEditMode: mode,
      filters: filters,
      batchEditRefIds: refIds,
      isRefsModalVisible: true,
    })
  }

  batchSaveRefs = () => {
    const opecomId = this.props.match.params.id
    const { batchEditMode, filters, batchEditRefIds, opecomHeader, sorter } = this.state;
    const values = this.refsModal.props.form.getFieldsValue()
    this.setState({ isBatchSavingRefs: true })

    const filtersDto = transformFilters(filters, this.table.getColumns(opecomHeader), sorter);

    api.opecom
      .batchSaveRefs(opecomId, batchEditMode, filtersDto, batchEditRefIds, values)
      .then(response => {
        const refNotUpdated = _.get(response, 'data.refNotUpdated', [])

        if (refNotUpdated.length > 0) {
          const reasons = new Set(refNotUpdated.map(ref => ref.reason).filter(reason => reason))
          feedbackNotification(
            'warning',
            `Attenzione! ${
              refNotUpdated.length === 1
                ? `C'è 1 referenza che non è stata aggiornata`
                : `Ci sono ${refNotUpdated.length} referenze che non sono state aggiornate`
            }`,
            reasons.size !== 0 ? <>{Array.from(reasons).map(r => <p>{refNotUpdatedReasons[r]}</p>)}</> : null
          )
        } else {
          handleServerSuccess()
        }

        this.table.setState({selectedKeys: [], deselectedKeys: [], selectedAll: false, refNotUpdated: refNotUpdated.map(ref => ref.idReferenza)})
        this.setState({ isRefsModalVisible: false })
        this.table.fetchData().then(() => this.mergeBatchChangesIntoState(response.data.productIds, values))
      }, handleServerError)
      .finally(() => this.setState({ isBatchSavingRefs: false }))
  }

  mergeBatchChangesIntoState = (productIds, values) => {
    const changes = _.cloneDeep(this.table.state.changes)
    const items = this.table.state.items

    for (const id of productIds) {
      const changeIndex = changes.findIndex(change => change.idReferenza === id)
      const itemIndex = items.findIndex(item => item.key === id)

      if (changeIndex !== -1) {
        for (const field in values) {
          if (values[field]) {
            changes[changeIndex][field] = items[itemIndex][field]
          }
        }
      }      
    }

    this.table.setState({changes: changes})
  }

  redirectToAddRefs = () => {
    const id = this.props.match.params.id
    this.setState({ redirectTo: `/dossier/${id}/add` })
  }

  getRefsModalFields = () =>
    this.table
      ? this.table
          .getColumns()
          .filter(column => column.editable && column.batchEditable)
      : []

  /**
   * Render method
   * @returns {*}
   */
  render() {
    const {
      isLoading,
      status,
      redirectTo,
      opecomHeader,
      isRefsModalVisible,
      isBatchSavingRefs,
    } = this.state
    const { user, stores } = this.props

    const information = getOpecomInfo({ startDate: opecomHeader?.startDate, code: opecomHeader?.code });

    return redirectTo !== null ? (
      <Redirect
        push
        to={{
          pathname: redirectTo,
          state: { referrer: '/edit-opecom' },
        }}
      />
    ) : status ? (
      <ErrorPage status={status} />
    ) : isLoading ? (
      <Loader />
    ) : (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        defaultCurrentPage={'opecom'}>
        <StyledDescriptions
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <StyledButton
                icon="left"
                onClick={() => {
                  const countRecordChanges = Object.keys(_.get(this.table, 'state.recordChanges', {})).length;

                  if (countRecordChanges > 0) {
                    const btn = (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button size="small" onClick={() => {
                          notification.close('saved-changes-notification');
                        }}>
                          No
                        </Button>

                        <Button type="primary" size="small" onClick={() => {
                          notification.close('saved-changes-notification');
                          this.setState({ redirectTo: '/search-opecom' });
                        }} style={{ marginLeft: 10 }}>
                          Si
                        </Button>
                      </div>
                    );

                    notification.open({
                      message: 'Modifiche non salvate',
                      description: 'Ci sono delle modifiche non salvate nella tabella. Vuoi procedere?',
                      btn,
                      duration: 0,
                      key: 'saved-changes-notification',
                      onClose: () => {}
                    });
                  } else {
                    this.setState({ redirectTo: '/search-opecom' })
                  }
                }}>
                Indietro
              </StyledButton>
              <StyledTitle>
                {getOpecomTitle(opecomHeader, stores)}
              </StyledTitle>
            </div>
          }
          size="small"
          bordered
          column={{ xs: 1, sm: 2, md: 3, xl: 4 }}>
          <Descriptions.Item label="Data inizio">
            {moment(opecomHeader.startDate).format('DD/MM/YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label="Data fine">
            {moment(opecomHeader.endDate).format('DD/MM/YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label="Stato">
            {
              opecomStates.filter(
                state => state.key === opecomHeader.statoOpecom
              )[0].label
            }
          </Descriptions.Item>
          <Descriptions.Item label={opecomHeader.idStore === null ? "Data fine lavorazione sede" : "Data fine lavorazione negozio"}>
            {opecomHeader.idStore === null && information?.lastHqCompletionDate.format('DD/MM/YYYY')}
            {opecomHeader.idStore !== null && information?.lastStoreCompletionDate.format('DD/MM/YYYY')}
          </Descriptions.Item>
          {opecomHeader.idStore === null && (
            <Descriptions.Item label="Data ultimo aggiornamento da Opecom">
              {information?.lastOpecomUpdateDate.format('DD/MM/YYYY')}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Periodo completamento negozio">
            {information?.storeCompletionFrom.format('DD/MM/YYYY')} - {information?.storeCompletionTo.format('DD/MM/YYYY')}
          </Descriptions.Item>
        </StyledDescriptions>
        <OpecomReferencesTable
          ref={ref => {
            this.table = ref
          }}
          opecom={opecomHeader}
          onBatchEdit={this.handleBatchEdit}
          user={user}
          stores={stores}
        />
        <EditOpecomRefsModal
          wrappedComponentRef={ref => {
            this.refsModal = ref
          }}
          visible={isRefsModalVisible}
          onCancel={this.hideRefsModal}
          onSave={this.batchSaveRefs}
          isBatchSavingRefs={isBatchSavingRefs}
          user={user}
          fields={this.getRefsModalFields()}
        />
      </MainLayout>
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => {
  return {
    user: state.User,
    stores: state.Data.stores
  }
}

/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => ({
  fetchStores: () => dispatch(fetchStores())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditOpecomDetails))
