import { AutoComplete, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { steps } from 'Containers/Home/constants'
import styles from 'Containers/Simulazione/Simulazione.module.scss'
import { StyledH1 } from 'Common/styled'
import MainLayout from 'Hoc/MainLayout'
import api from 'Common/api'
import DossierUnicoHeader from 'Components/DossierUnico/DossierUnicoHeader'
import DossierUnicoData from 'Components/DossierUnico/DossierUnicoData'
import SearchProductsModal from 'Components/DossierUnico/SearchProductsModal'

export const DossierUnicoPage = () => {
  const [dataSource, setDataSource] = useState([])
  const [filteredDataSource, setFilteredDataSource] = useState([])
  const [selectedDossier, setSelectedDossier] = useState(null)
  const [hideHeader, setHideHeader] = useState(false)

  useEffect(() => {
    api.dossierUnico.getAll().then(({ data }) => {
      setDataSource(data)
      setSelectedDossier(data.find(item => item.id === 7))
    })
  }, [])

  const onSelect = value => {
    const dossier = dataSource.find(item => item.id.toString() === value)
    if (!dossier) return
    setSelectedDossier(dossier)
  }

  const onSearch = searchText => {
    if (searchText.length < 3) return
    setFilteredDataSource(
      dataSource
        .filter(item =>
          item.nome.toLowerCase().includes(searchText.toLowerCase()),
        )
        .slice(0, 20),
    )
  }

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="simulazione">
      <div
        className={styles.root}
        style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <StyledH1 className={`${styles.title} ${styles.titleWithMargin}`}>
          Dossier Unico
        </StyledH1>
        {!hideHeader && (
          <Row type="flex" justify="center" align="center">
            <Col xs={22} md={12} xl={12} xxl={12} className={styles.cardCol}>
              <AutoComplete
                style={{ width: '100%' }}
                size="large"
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Cerca il dossier unico...">
                {filteredDataSource.map(item => (
                  <AutoComplete.Option key={item.id} value={item.id.toString()}>
                    {item.nome}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </Col>
          </Row>
        )}
        {selectedDossier && (
          <>
            {!hideHeader && (
              <Row style={{ marginTop: '16px' }}>
                <Col xs={24}>
                  <DossierUnicoHeader dossier={selectedDossier} />
                </Col>
              </Row>
            )}
            <DossierUnicoData
              dossier={selectedDossier}
              hideHeader={hideHeader}
              setHideHeader={setHideHeader}
            />
          </>
        )}
      </div>
    </MainLayout>
  )
}

export default DossierUnicoPage
