import React, {useRef, useState, useImperativeHandle, forwardRef} from 'react';
import * as _ from 'lodash';
import { Button, Popover } from 'antd'
import { NotePopupIcon, NoteTextarea } from 'Components/LMTable/styled'

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      refresh: params => {
        if (params.value !== value) {
          setValue(value);
        }

        return true;
      }
    }
  });

  return (
    <Popover
      placement="left"
      onVisibleChange={visible => {
        if (visible) {
          props.api.dispatchEvent({
            type: 'cellEditingStarted',
            ...props
          });
        } else {
          const oldValue = props.value;

          if (oldValue !== value) {
            props.node.setDataValue(props.colDef.field, value);
            props.api.dispatchEvent({
              type: 'cellEditingStopped',
              ...props,
              value,
              oldValue,
              newValue: value
            });
            props.api.dispatchEvent({
              type: 'cellValueChanged',
              ...props,
              value,
              oldValue,
              newValue: value
            });
          }
        }
      }}
      content={
        <NoteTextarea
          rows={5}
          onChange={(ev) => {
            setValue(ev.target.value);
          }}
          defaultValue={value}
          placeholder="Inserisci una nota"
        />
      }
      trigger="click"
    >
      <Button type="link" size="small" title="Inserisci una nota">
        <NotePopupIcon type="form" active={value !== null && value.length > 0} />
      </Button>
    </Popover>
  );
});
