import React from 'react';

export default class AgTableProductImageRenderer extends React.Component {
  render() {
    const { value, data } = this.props;

    if (!data) {
      return '';
    }

    return <img src={`https://media.adeo.com/media/${value}/filename.jpg?width=50&height=50`} alt={`Immagine prodotto ${data.idReferenza}`} style={{maxWidth: '100%', height: 'auto'}} />;
  }
}
