import MainLayout from 'Hoc/MainLayout'
import React, { useEffect, useState } from 'react'
import { StyledH1 } from 'Common/styled'
import { Card, Col, Row } from 'antd'
import { Doughnut } from 'react-chartjs-2'
import api from 'Common/api'
import { AgGridReact } from 'ag-grid-react'
import { AG_GRID_LOCALE_IT } from 'Common/ag-grid.locale'

const gridOptionsBase = {
  localeText: AG_GRID_LOCALE_IT,
  columnDefs: [
    {
      headerName: 'ID',
      field: 'id',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true,
      width: 100,
      cellRenderer: params => '<a href="/edit-dossier/' + params.data.id + '">' + params.data.id + '</a>'
    },
    {
      headerName: 'Tipo',
      field: 'type',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true,
      width: 150
    },
    {
      headerName: 'Anno',
      field: 'year',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true,
      width: 150
    },
    {
      headerName: 'Reparto',
      field: 'department',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true
    },
    {
      headerName: 'Descrizione',
      field: 'description',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true,
      minWidth: 400,
      flex: 1
    },
    {
      headerName: 'Completamento Da',
      field: 'storeCompletionFrom',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true
    },
    {
      headerName: 'Completamento A',
      field: 'storeCompletionTo',
      lockPosition: true,
      lockVisible: true,
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilter: true
    }
  ],
  enableRangeSelection: true,
  suppressRowClickSelection: true,
  suppressContextMenu: true
};

export default function Dashboard() {
  const [hqDossiers, setHqDossiers] = useState([]);
  const [storeDossiers, setStoreDossiers] = useState([]);
  const [hqDossiersCount, setHqDossiersCount] = useState({});
  const [storeDossiersCount, setStoreDossiersCount] = useState({});
  const [hqGridApi, setHqGridApi] = useState(null);
  const [storeGridApi, setStoreGridApi] = useState(null);

  const onHqGridReady = (params) => {
    setHqGridApi(params.api);
  };

  const hqGridOptions = {
    ...gridOptionsBase,
    rowData: hqDossiers,
    onGridReady: params => setHqGridApi(params.api)
  };

  const storeGridOptions = {
    ...gridOptionsBase,
    columnDefs: [
      ...gridOptionsBase.columnDefs,
      {
        headerName: 'Negozio',
        field: 'idStore',
        lockPosition: true,
        lockVisible: true,
        sortable: true,
        filter: true,
        editable: false,
        resizable: false,
        suppressMenu: true,
        suppressMovable: true,
        floatingFilter: true
      }
    ],
    rowData: storeDossiers,
    onGridReady: params => setStoreGridApi(params.api)
  };

  useEffect(() => {
    api.analytics.getDashboard().then(response => {
      setHqDossiers(response.data.hqDossiers);
      setStoreDossiers(response.data.storeDossiers);
      setHqDossiersCount(response.data.hqDossiersCount);
      setStoreDossiersCount(response.data.storeDossiersCount);
    }).catch(console.error);
  }, []);

  useEffect(() => {
    if (hqGridApi !== null) {
      hqGridApi.setRowData(hqDossiers);
    }
  }, [hqGridApi, hqDossiers]);

  useEffect(() => {
    if (storeGridApi !== null) {
      storeGridApi.setRowData(storeDossiers);
    }
  }, [hqGridApi, storeDossiers]);

  return (
    <MainLayout
      tourSteps={[]}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage={'dashboard'}>
      <div
        style={{
          display: 'block',
          position: 'relative'
        }}>
        <StyledH1>Dashboard</StyledH1>
        <div style={{ marginTop: 50 }}>
          <Row gutter={16}>
            <Col span={12} style={{ marginTop: 20 }}>
              <Card title="DOSSIER IN SCADENZA - SEDE">
                <Doughnut data={{
                  labels: ['NG', 'RG', 'ST'],
                  datasets: [
                    {
                      label: 'In Scadenza',
                      data: [(hqDossiersCount.NG || 0), (hqDossiersCount.RG || 0), (hqDossiersCount.ST || 0)],
                      backgroundColor: [
                        'rgba(102,204,51,0.2)',
                        'rgba(51,191,204,0.2)',
                        'rgba(204,51,94,0.2)'
                      ],
                      borderColor: [
                        'rgb(102,204,51)',
                        'rgb(51,191,204)',
                        'rgb(204,51,94)'
                      ],
                      borderWidth: 1,
                    },
                  ]
                }} />
              </Card>
            </Col>
            <Col span={12} style={{ marginTop: 20 }}>
              <Card title="DOSSIER IN SCADENZA - NEGOZIO">
                <Doughnut data={{
                  labels: ['NG', 'RG', 'ST'],
                  datasets: [
                    {
                      label: 'In Scadenza',
                      data: [(storeDossiersCount.NG || 0), (storeDossiersCount.RG || 0), (storeDossiersCount.ST || 0)],
                      backgroundColor: [
                        'rgba(102,204,51,0.2)',
                        'rgba(51,191,204,0.2)',
                        'rgba(204,51,94,0.2)'
                      ],
                      borderColor: [
                        'rgb(102,204,51)',
                        'rgb(51,191,204)',
                        'rgb(204,51,94)'
                      ],
                      borderWidth: 1,
                    },
                  ]
                }} />
              </Card>
            </Col>
            <Col span={24} style={{ marginTop: 20 }}>
              <Card title="DOSSIER SEDE IN SCADENZA">
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: 15 }}>
                  <AgGridReact gridOptions={hqGridOptions} />
                </div>
              </Card>
            </Col>
            <Col span={24} style={{ marginTop: 20 }}>
              <Card title="DOSSIER NEGOZIO IN SCADENZA">
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: 15 }}>
                  <AgGridReact gridOptions={storeGridOptions} />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </MainLayout>
  )
}
