import { dossierTypes } from './constants';
import {Checkbox, Col, Input, notification, Select, Tooltip, Icon} from 'antd'
import moment from 'moment'
import {StyledFormItem} from "Common/styled";
import React from "react";
import * as _ from 'lodash';
import Numeral from "numeral";
import "numeral/locales/it";
import AutoCompleteWrapper from "Components/AutoCompleteWrapper";
Numeral.locale('it');

export const getQueryParam = param => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

export const feedbackNotification = (type, title, subtitle, icon) => {
  if (type) {
    return notification[type]({
      message: title,
      description: subtitle,
      duration: 7.5
    })
  }

  return notification.open({
    message: title,
    description: subtitle,
    duration: 7.5,
    icon: icon
  })
}

export const handleServerSuccess = () => {
  return feedbackNotification('success', 'Operazione riuscita')
}

export const handleServerError = error => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        switch (error.response.data.type) {
          case 'dossierProduct.duplicateValue':
            return feedbackNotification(
              'error',
              'Salvataggio non riuscito',
              `Ci sono valori duplicati per il campo ${error.response.data.error}`
            )
          default:
            return feedbackNotification(
              'error',
              'Invio non riuscito',
              'Controlla i parametri e riprova'
            )
        }
      case 409:
        switch (error.response.data.type) {
          case 'family.nameError':
            return feedbackNotification(
              'error',
              'Salvataggio non riuscito',
              'Esiste già una famiglia con questo nome'
            )
          case 'family.compositionError':
            return feedbackNotification(
              'error',
              'Salvataggio non riuscito',
              "La famiglia contiene sottotipi già assegnati a un'altra famiglia"
            )
          case 'dossier.nameError':
            return feedbackNotification(
              'error',
              'Salvataggio non riuscito',
              'Esiste già un dossier con questo titolo'
            )
          case 'dossier.duplicateXSError':
            return feedbackNotification(
              'error',
              'Salvataggio non riuscito',
              `Esistono già dossier XS relativi allo stesso reparto nello stesso periodo: ${error.response.data.error.join(', ')}`
            )
          default:
            return feedbackNotification(
              'error',
              'Salvataggio non riuscito',
              'Controlla i parametri e riprova'
            )
        }
      default:
        return feedbackNotification(
          'error',
          'Si è verificato un errore',
          'Riprova'
        )
    }
  }
  return feedbackNotification('error', 'Si è verificato un errore', 'Riprova')
}

export const formatDate = (date, inFormat, outFormat) =>
  date ? moment(date, inFormat ? inFormat : null).format(
    outFormat ? outFormat : 'YYYY-MM-DD'
  ) : null

export const subtractDays = (date, days) => moment(date).subtract(days, 'days')

export const daysBetween = (from, to) => moment(to).startOf('day').diff(moment(from).startOf('day'), 'days')

export const daysFromToday = date => moment(date).diff(moment(), 'days')

export const parseDate = date => date ? moment(date, 'YYYY-MM-DD') : null

export const getYear = date => moment(date).year()

export const validatePeriod = (rule, value) => {
  if (!value || value.length === 0) {
    return true
  }
  const [from, to] = value
  return daysBetween(from, to) > 0
}

export const validateFutureDates = (rule, value) => {
  if (!value || value.length === 0) {
    return true
  }
  const from = value[0]
  return daysFromToday(from) >= 0
}

export const validateInstallation = (rule, value) => {
  if (!value || value.length === 0) {
    return true
  }
  const from = value[0]
  return daysFromToday(from) >= 37
}

export const validateStoreCompletion = (rule, value, installationPeriod) => {
  if (
    !value ||
    value.length === 0 ||
    !installationPeriod ||
    installationPeriod.length === 0 ||
    !this.validatePeriod(null, value)
  ) {
    return true
  }
  const storeCompletionTo = value[1]
  const installationFrom = installationPeriod[0]
  return daysBetween(storeCompletionTo, installationFrom) >= 30
}

export const getFormFieldByType = (item, form) => {
    let component;
    let props = {};

    if (item.multiple) {
      props.mode = 'multiple';
      props.maxTagCount = 1;
      props.maxTagTextLength = form.getFieldValue(item.key) && form.getFieldValue(item.key).length > 1 ? 8 : 15;
    }

    props = {...props, ..._.get(item, 'props', {})};

    switch (item.type) {
      case 'autocomplete':
        component = (
            <AutoCompleteWrapper {...props} key={item.key} data={item.data} />
        );

        break;

      case 'list':
        component = (
            <Select {...props}>
              {item.selectAll && item.data.length > 0 ? <Select.Option key="all">{item.all ? item.all : 'Seleziona tutti'}</Select.Option> : ''}
              {item.data.map((option, index) => {
                if (option.isGroup) {
                  return (
                      <Select.OptGroup label={option.label} key={option.id}>
                        {option.data.map((groupOption, groupIndex) => {
                          return (
                            <Select.Option key={item.getOptionKey ? item.getOptionKey(groupOption, index, groupIndex) : groupOption.id} value={item.getOptionValue ? item.getOptionValue(groupOption, index, groupIndex) : groupOption.id}>
                              {item.disableIdInLabel ? groupOption.label : item.getLabel ? item.getLabel(groupOption) : `${groupOption.id}-${groupOption.label}`}
                            </Select.Option>
                        )})}
                      </Select.OptGroup>
                  )
                } else {
                  return (
                      <Select.Option key={item.getOptionKey ? item.getOptionKey(option, index) : option.id} value={item.getOptionValue ? item.getOptionValue(option, index) : option.id}>
                        {item.disableIdInLabel ? option.label : item.getLabel ? item.getLabel(option, index) : `${option.id}-${option.label}`}
                      </Select.Option>
                  );
                }
              })}
            </Select>
        );
        break;

      case 'boolean':
      case 'bool':
      case 'check':
      case 'checkbox':
        component = (
            <Checkbox {...props}>{item.label}</Checkbox>
        );
        break;

      default:
        component = (
            <Input {...props} />
        );
    }

    return component;
};

export const getFormFields = (fields, getFieldDecorator, form) => {
  const components = [];

  fields.forEach(item => {
    const styledFormItemProps = {};
    const colStyle = {
      alignSelf: 'center'
    };

    if (['bool', 'boolean', 'check', 'checkbox'].indexOf(item.type) === -1) {
      styledFormItemProps.label = (
        <>
          {item.label}
          {item.tooltip && (
            <Tooltip title={item.tooltip}>
              <Icon
                type="question-circle"
                theme="filled"
                style={{ color: 'rgba(0, 0, 0, .45)', margin: '3px 0 0 10px' }}
              />
            </Tooltip>
          )}
        </>
      )
    } else {
      styledFormItemProps.style = {
        marginTop: '20px'
      };
    }

    components.push(
        <Col span={8} key={item.key} style={colStyle} {..._.get(item, 'colProps', {})}>
          <StyledFormItem {...styledFormItemProps} {..._.get(item, 'formItemProps', {})}>
            {getFieldDecorator(item.key, item.fieldDecorator)(getFormFieldByType(item, form))}
          </StyledFormItem>
        </Col>
    );
  });

  return components;
};

export const num = Numeral;
export const getDossierTitle = (dossierHeader, stores) => {
  const type = dossierHeader.type
  const year = dossierHeader.year
  const department = `${dossierHeader.department.toString().padStart(2, '0')}`
  const description = dossierHeader.description
  const store = dossierHeader.idStore === null ? '' : ` (Store ${dossierHeader.idStore}-${stores.filter(store => store.id === dossierHeader.idStore)[0].name})`

  return `${type}-${year}-${department}-${description}${store}`
}

export const getOpecomTitle = (opecomHeader, stores) => {
  const code = opecomHeader.code
  const description = opecomHeader.description
  const opecomStore = stores.find(store => store.id === opecomHeader.idStore);
  const store = opecomHeader.idStore === null ? '' : ` (Store ${opecomHeader.idStore}-${opecomStore ? opecomStore.name : ''})`

  return `${code}-${description}${store}`
}

export const getDepartmentLabel = (departmentId, departmentList) => {
  if (departmentId === 0) {
    return `Tutti`
  } else {
    const department = departmentList.filter(d => d.id === departmentId)[0]
    return `${departmentId}-${department.label}`
  }
}

export const getDossierTypeLabel = dossierTypeKey => {
  const dossierType = dossierTypes.filter(t => t.key === dossierTypeKey)[0]
  return `${dossierTypeKey}-${dossierType.label}`
}

export const hasChanged = (keys, oldObj, newObj) => {
  for (const key of keys) {
    if (!_.isEqual(oldObj[key], newObj[key])) {
      return true;
    }
  }

  return false;
};

export const getSessionObj = () => {
  let sessionObj = sessionStorage.getItem('LM.COOP');

  if (_.isNil(sessionObj)) {
    sessionObj = '{}';
  }

  try {
    sessionObj = JSON.parse(sessionObj);
  } catch {
    sessionObj = {};
  }

  return sessionObj;
};

export const getFromSession = (path, defaultValue = null) => {
  const sessionObj = getSessionObj();

  return _.get(sessionObj, path, defaultValue);
};

export const setToSession = (path, value) => {
  const sessionObj = getSessionObj();

  _.set(sessionObj, path, value);

  sessionStorage.setItem('LM.COOP', JSON.stringify(sessionObj));
};

export const deleteFromSession = (path) => {
  const sessionObj = getSessionObj();

  if (_.has(sessionObj, path)) {
    _.unset(sessionObj, path);
    sessionStorage.setItem('LM.COOP', JSON.stringify(sessionObj));
  }
};

export const isEmpty = obj => {
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    if (obj[key] && obj[key].length !== 0) return false;
  }

  return true;
}

export const transformFilters = (filters, columns, sorter) => {
  const filtersDto = {};
  const filterMultipleFields = columns.filter(column => column.filtrable && [undefined, true].includes(column.filterMultiple)).map(column => column.dataIndex);

  for (const key in filters) {
    if (!filters.hasOwnProperty(key) || filters[key] === null) continue;

    if (filterMultipleFields.includes(key) && filters[key].length !== 0) {
      filtersDto[key] = filters[key];
    } else if (!filterMultipleFields.includes(key)) {
      filtersDto[key] = filters[key][0]
    }
  }

  if (sorter && sorter.order) {
    filtersDto.sort = sorter.columnKey
    filtersDto.sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC'
  }

  return filtersDto;
}

export const compareVersions = (a, b) => {
  const [a_major, a_minor, a_patch] = a.split('.').map(e => Number(e))
  const [b_major, b_minor, b_patch] = b.split('.').map(e => Number(e))

  // Compare major

  if (a_major < b_major) {
    return 1
  }

  if (a_major > b_major) {
      return -1
  }

  // Compare minor

  if (a_minor < b_minor) {
      return 1
  }

  if (a_minor > b_minor) {
      return -1
  }

  // Compare patch

  if (a_patch < b_patch) {
      return 1
  }

  if (a_patch === b_patch) {
      return 0
  }

  if (a_patch > b_patch) {
      return -1
  }

}

export const getProductInfoTranslatedDescriptions = (messages) => {
  return messages.map(message => getProductInfoDescription(message));
};

export const getProductInfoDescription = (message) => {
  switch (message.description) {
    case 'GENERIC_ERROR':
      return 'Non è stato possibile salvare il prodotto a causa di un errore generico';
    case 'GENERIC_DATABASE_CONSTRAINT_VIOLATION':
      return 'Non è stato possibile salvare il prodotto a causa di un errore di record duplicato';
    case 'NUM_PDD_DUPLICATE':
      return 'Il num pdd di questo prodotto esiste già nel dossier';
    case 'PRECOTOP_REAPPRO_TO_BE_NOT_NULL_AND_GAMMA_TO_BE_D_R_S':
      return 'Il precotop reappro futuro non può essere vuoto se la gamma futura è D o R o S';
    case 'PRECOTOP_REAPPRO_TO_BE_1_AND_STOCK_NULL_OR_ZERO':
      return 'Il precotop reappro futuro è 1 e stock presentazione è vuoto o zero';
    case 'GAMMA_TO_BE_A_CLUSTER_A_AND_FACING_A_ZERO':
      return 'La gamma futura è A e il facing cluter S è zero';
    case 'GAMMA_TO_BE_A_CLUSTER_AB_AND_FACING_AB_ZERO':
      return 'La gamma futura è A e il facing cluster M è zero';
    case 'GAMMA_TO_BE_A_CLUSTER_ABC_AND_FACING_ABC_ZERO':
      return 'La gamma futura è A e il facing cluster L è zero';
    case 'GAMMA_TO_BE_NOT_A_B_C_D_L_P_R_S':
      return 'La gamma futura deve essere A, B, C, D, L, P, R o S';
    case 'PRECOTOP_REAPPRO_TO_BE_NOT_1_OR_2':
      return 'Il precotop reappro futuro deve essere 1 o 2';
    case 'TOP_REAPPRO_NOT_1_OR_2':
      return 'Il top reappro deve essere 1 o 2';
    case 'TIPO_SOSTITUZIONE_NOT_A_VALID_OPTION':
      return 'La scelta fatta per tipo sostituzione non è valida';
    case 'ID_REF_SOSTITUITA_DOES_NOT_EXIST':
      return 'La referenza sostituita non esiste';
    case 'CLUSTERXS_NOT_A_VALID_OPTION':
      return 'La scelta del cluster XS non è valida';
    case 'TIPO_EVENTOXS_NOT_A_VALID_OPTION':
      return 'La scelta del tipo evento XS non è valida';
    case 'SUGGESTED_FAMILIES_MORE_THAN_5':
      return 'Le famiglie suggerite non possono essere più di 5';
    case 'SUGGESTED_DEPARTMENTS_MORE_THAN_5':
      return 'I reparti suggeriti non possono essere più di 5';
    case 'DATA_INIZIOXS_EMPTY':
      return 'Data inizio XS deve essere impostato';
    case 'DATA_INIZIOXS_NOT_BETWEEN_INSTALLATION_DATE':
      return 'Data inizio XS deve essere compresa tra data installazione inizio e fine';
    case 'DATA_FINEXS_GREATER_THAN_INSTALLATION_TO_DATE':
      return 'Data fine XS non può essere maggiore della data installazione fine';
    case 'DATA_INIZIO_EVENTO_NON_VALIDA':
      return 'La data inizio evento deve essere maggiore o uguale alla data odierna + 37 giorni + il limite aggiornamento negozi';
    case 'DATA_COMPLETAMENTO_NON_VALIDA':
      return 'La data completamento negozio deve essere maggiore o uguale alla data odierna + il limite aggiornamento negozi';
    case 'DATA_COMPLETAMENTO_GTE_DATA_INIZIO':
      return 'La data completamento negozio deve essere minore della data inizio evento';
    case 'DATA_FINE_EVENTO_NON_VALIDA':
      return 'La data fine evento deve essere maggiore o uguale alla data di inizio evento';
    case 'DATA_INIZIOXS_AND_DATA_FINEXS_DECOUPLED':
      return 'Data inizio e fine Xselling vanno compilate in coppia';
    case 'ID_REF_SOSTITUITA_AND_TIPO_SOSTITUZIONE_DECOUPLED':
      return 'Ref sostituita e tipo sostituzione vanno compilate in coppia';  
    case 'CUSTOM_ERROR':
      return message.custom ? message.custom : 'Prodotto non valido';
    case 'FORBIDDEN':
      return 'Operazione non autorizzata';
    case 'OPECOM_PREV_VTOT_PERC_ALTERNATE':
      return 'Prev Venduto Totale (QTA) e Prev Venduto Totale (%) non possono essere entrambi valorizzati';
    case 'OPECOM_PRODUCT_NOT_FOUND':
      return 'Prodotto Opecom non trovato';
    case 'OPECOM_PRODUCT_STOCK_INVALID_CHANGE':
      return 'Stock di presentazione negozio è modificabile solo per prodotti STAR con Reparto = 1 e NO STAR';
    case 'OPECOM_PRODUCT_STOCK_INVALID_ZERO':
      return 'Stock di presentazione negozio azzerabile solo se previsione venduto >= 0';
    case 'OPECOM_PRODUCT_STOCK_INVALID_PREV':
      return 'Stock di presentazione negozio modificabile (diverso da 0) solo se previsione venduto > 0';
    case 'OPECOM_PRODUCT_PREVVTOT_INVALID_RELEX':
      return 'Prev Venduto Negozio (QTA) modificabile solo al rialzo rispetto al valore di Relex';
    case 'OPECOM_PRODUCT_PREVVTOT_INVALID_P_NOSTAR':
      return 'Prev Venduto Negozio (QTA) per gamma P NO STAR modificabile al rialzo e ribasso ma non azzerabile';
    case 'PREV_VENDUTO_TOT_OBL':
      return 'Prev Venduto Totale (QTA) oppure Prev Venduto Totale (%) sono campi obbligatori';
    case 'STOCK_SEDE_OBL':
        return 'Stock Presentazione Promo è un campo obbligatorio';
    case 'STOCK_LT_STOCK_SEDE':
      return 'La quantità stock negozio non può essere minore della quantità stock sede';
    case 'PL_PREV_VMM_NULL':
      return 'La previsione di venduto deve essere maggiore o uguale a 0';
    case 'PL_SPT_NULL':
      return 'La quantità SPT deve essere maggiore o uguale a 0';
    case 'PL_PREV_TOT_NULL_OR_ZERO':
      return 'Prev Venduto Totale QTA obbligatorio';
    case 'PL_PREV_TOT_PERC_NULL_OR_ZERO':
      return 'Prev Venduto Totale PERC obbligatorio';
    default:
      return message.description;
  }
};

export function objectToQueryString(obj) {
  if (!obj) {
    return '';
  }

  return Object.keys(obj)
    .filter(key => obj[key])
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(Array.isArray(obj[key]) ? obj[key].join(',') : obj[key])}`)
    .join('&');
}

export function objectToQueryStringInUrl(obj) {
  const queryString = objectToQueryString(obj);

  return queryString.length > 0 ? '?' + queryString : '';
}

function serializeParamItem(key, value) {
  let serialized = '';

  if (Array.isArray(value)) {
    for (const item of value) {
      serialized += (serialized.length > 0 ? '&' : '') + serializeParamItem(key, item);
    }
  } else {
    serialized = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  return serialized;
}

export function serializeParams(params) {
  let queryString = '';

  for (const key in params) {
    if (!params.hasOwnProperty(key)) continue;

    if (queryString.length > 0) {
      queryString += '&';
    }

    queryString += serializeParamItem(key, params[key]);
  }

  return queryString;
}

export function agNumberFormatter(params) {
  let value = params.value;

  if (value !== 'number') {
    value = Number(value);
  }

  if (isNaN(value)) {
    return '';
  }

  return num(value).format('0,0.00');
}

export function agPercentageFormatter(params) {
  let value = params.value;

  if (value !== 'number') {
    value = Number(value);
  }

  if (isNaN(value)) {
    return '';
  }

  return num(value).format('0.00%');
}

export function agBooleanFormatter(params) {
  if (typeof params.value === 'string' && (params.value === 'true' || params.value === '1')) {
    return 'Si';
  }

  if (typeof params.value === 'boolean' && params.value === true) {
    return 'Si';
  }

  if (typeof params.value === 'number' && params.value === 1) {
    return 'Si';
  }

  return 'No';
}

export function divideNumbers(a, b) {
  const aSafe = Number(a);
  const bSafe = Number(b);

  if (isNaN(aSafe) || isNaN(bSafe) || bSafe <= 0) {
    return 0;
  }

  return aSafe / bSafe;
}

export function deepCloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}
