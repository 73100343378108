import React from 'react';
import styles from './ModernCard.module.css';
import { Button, Col, Icon, Row, Tag } from 'antd'

export default function SimulationCard({ title, tagLabel, tagColor, statistics, simulationId }) {
  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{title}</h1>
        <Tag color={tagColor} style={{ marginLeft: 'auto' }}>{tagLabel}</Tag>
      </div>
      <div className={styles.statisticsContainer}>
        <Row gutter={16}>
          <Col sm={24} md={6}>
            <span style={{ opacity: 0.5 }}>Prog % CA</span>
            <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{statistics.progCa}</span>
          </Col>
          <Col sm={24} md={6}>
            <span style={{ opacity: 0.5 }}>MG %</span>
            <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{statistics.mgPerc}</span>
          </Col>
          <Col sm={24} md={6}>
            <span style={{ opacity: 0.5 }}>MG €</span>
            <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{statistics.mge}</span>
          </Col>
          <Col sm={24} md={6}>
            <span style={{ opacity: 0.5 }}>Redd %</span>
            <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{statistics.reddPerc}</span>
          </Col>
        </Row>
      </div>
      <div className={styles.linkBar}>
        <div className={styles.linkContainer + ' ' + styles.linkContainerBorderRight}>
          <a href={`/simulazione/detail/${simulationId}`} className={styles.link}>
            <Icon type="unordered-list" className={styles.linkIcon} />
            <span className={styles.linkName}>Dettaglio</span>
          </a>
        </div>
        <div className={styles.linkContainer + ' ' + styles.linkContainerBorderRight}>
          <a href="#" className={styles.link}>
            <Icon type="message" className={styles.linkIcon} />
            <span className={styles.linkName}>Commenti</span>
          </a>
        </div>
        <div className={styles.linkContainer}>
          <a href={`/simulazione/summary/${simulationId}`} className={styles.link}>
            <Icon type="area-chart" className={styles.linkIcon} />
            <span className={styles.linkName}>Sintesi</span>
          </a>
        </div>
      </div>
    </div>
  )
}
