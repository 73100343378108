import styled from 'styled-components';
import {Button, Icon, Card, Form, Pagination, Alert} from "antd";
import { colors } from 'Common/constants';

export const StyledButton = styled(Button)`
    text-transform: uppercase;
`;

export const StyledIcon = styled(Icon)`
    color: ${colors.grey4};
`;

export const StyledPagination = styled(Pagination)`
    @media (max-width: 767px) {
        .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-jump-prev {
            display: none;
        }
        
        .ant-pagination-prev, .ant-pagination-next {
            height: 64px;
            min-width: 64px;
            font-size: 28px;
            line-height: 64px;
            
            .ant-pagination-item-link {
                font-size: 24px;
            }
        }
    }
`;

export const StyledCard = styled(Card)`
    background: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.05);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
    border-radius: 2px;
    
    @media (max-width: 767px) {
        .ant-card-extra {
            float: none;
            width: 100%;
            display: block;
            
            button {
                width: 100%;
            }
        }
        
        .ant-card-head-title {
            float: none;
            width: 100%;
            display: block;
            overflow: visible;
            white-space: normal;
        }
        
        .ant-card-head-wrapper {
            flex-wrap: wrap;
        }
    }
`;

export const StyledCardNoBody = styled(Card)`
    background: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.05);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
    border-radius: 2px;
  
  .ant-card-body {
    display: none;
  }
    
    @media (max-width: 767px) {
        .ant-card-extra {
            float: none;
            width: 100%;
            display: block;
            
            button {
                width: 100%;
            }
        }
        
        .ant-card-head-title {
            float: none;
            width: 100%;
            display: block;
            overflow: visible;
            white-space: normal;
        }
        
        .ant-card-head-wrapper {
            flex-wrap: wrap;
        }
    }
`;

export const StyledForm = styled(Form)`
`;

export const StyledFormItem = styled(Form.Item)`
    .ant-form-item-label {
        line-height: normal;
    }
    
    .ant-form-item-label label {
        text-transform: uppercase;
        font-weight: 500;
        color: ${colors.grey4};
    }
`;

export const StyledCollapse = styled(Button)`
    position: absolute !important;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    z-index: 2;
`;

export const StyledH1 = styled.h1`
  font-size: 1.7rem;
  color: ${colors.main};
  text-align: center;
`;

/**
 * Leroy Merlin grid row styled div
 */
export const GridRow = styled.div`
  display: grid;
  grid-gap: 16px;
`;

export const Wrapper = styled.div``;

export const StyledLinkButton = styled(Button)`
    color: #53ac26;
    padding: 0;
    margin: 0;
    display: inline-block;
    
    &:hover, &:active {
        color: #40881c;
    }
`;

export const StyledAlert = styled(Alert)`
    .ant-alert-icon, .ant-alert-close-icon {
        top: 50%;
        transform: translateY(-50%);
    }
`;
