import styled from 'styled-components'
import { colors } from 'Common/constants'
import { Icon } from 'antd'

export const StyledDiv = styled.div`
  background-color: ${colors.grey1};
  margin: 0 -1rem 2.5rem -1rem;
  padding: 2rem 2rem 2.5rem 2rem;
`

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const Title = styled.span`
  font-size: 1.1rem;
  font-weight: bold;
`

export const StyledIcon = styled(Icon)`
  font-size: 1.5rem;
  color: ${colors.main};
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`