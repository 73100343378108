import React from 'react'
import { Modal, Button, Table, message, Icon } from 'antd'
import { colors } from 'Common/constants'

/**
 * Component description
 */
class DuplicateDossierNotification extends React.Component {
  copyToClipboard = e => {
    navigator.clipboard
      .writeText(
        this.props.items
          .map(i => `${i.productId}\t${i.description}\t${i.dossierTitle}`)
          .join('\n')
      )
      .then(() => message.success('Testo copiato'))
  }

  render() {
    const itemCount = new Set(this.props.items.map(i => i.productId)).size

    return (
      <Modal
        title={
          <>
            <Icon
              type={this.props.success ? 'check-circle' : 'close-circle'}
              style={{
                color: this.props.success ? colors.green : colors.red,
                marginRight: '8px',
              }}
            />
            <span>
              {this.props.success
                ? 'Dossier duplicato'
                : 'Dossier non duplicato'}
            </span>
          </>
        }
        visible={this.props.visible}
        closable={false}
        maskClosable={false}
        width={780}
        footer={<Button onClick={this.props.onOk}>Chiudi</Button>}>
        <p>
          {this.props.success
            ? itemCount === 1
              ? `1 referenza è stata esclusa perché già presente in altri dossier nello stesso periodo d'impianto`
              : `${itemCount} referenze sono state escluse perché già presenti in altri dossier nello stesso periodo d'impianto`
            : "Tutte le referenze sono già presenti in altri dossier nello stesso periodo d'impianto"}
        </p>
        <Table
          style={{ height: '200px', overflow: 'scroll', overflowX: 'hidden' }}
          pagination={false}
          showHeader={false}
          size="small"
          dataSource={this.props.items}
          columns={[
            { dataIndex: 'productId' },
            { dataIndex: 'description' },
            { dataIndex: 'dossierTitle' },
          ]}
          rowKey={r => `${r.productId}${r.dossierTitle}`}></Table>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}>
          <Button size="small" icon="copy" onClick={this.copyToClipboard}>
            Copia
          </Button>
        </div>
      </Modal>
    )
  }
}

export default DuplicateDossierNotification
