import React, {useRef, useState,useEffect, useImperativeHandle,useLayoutEffect, forwardRef} from 'react';
import * as _ from 'lodash';
import { Button, Popover, Icon, Alert } from 'antd'
import { NotePopupIcon, NoteTextarea } from 'Components/LMTable/styled'
import { render } from 'less';
import { forEach } from 'lodash';
import api from 'Common/api';
import { getConfirmLocale } from 'antd/lib/modal/locale';

export default forwardRef((props, ref) => {

  const [value, setValue] = useState(props.value);
  const [editable] = useState(props.colDef.editable);
  const [showAlert, setShowAlert] = useState(false);
  const [filesLinksList, setFilesLinksList] = useState({links: props.data.attachments || []});
  const [filesAboutToChangeMap, setFilesAboutToChangeMap] = useState({files: new Map()});
  const [isLoading, setIsloading] = useState(false);
  const [filesSelectedList, setFilesSelectedList] = useState([]);


  let tempFilesLinksList = [];
  let tempFilesAboutToChangeMap = new Map();

  useImperativeHandle(ref, () => {
    return {
      refresh: params => {

        if (params.value !== value) {
          setValue(value);
        }

        return true;
      }
    }
  });

  const triggerAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 6000);
  }

  const handleRemoveFileSelected = (key) => {
    let tempAboutToChangeMap = filesAboutToChangeMap.files
    tempAboutToChangeMap.delete(key);
    setFilesAboutToChangeMap({files: tempAboutToChangeMap})
  }

  const handleRemoveFile = (count) => {
    let doesValueExists = false;
    let keyToNotRemove = null;

    let tempAboutToChangeMap = filesAboutToChangeMap.files

    for (let [key, value] of filesAboutToChangeMap.files.entries()) {
      if (value == count){
        doesValueExists = true;
        keyToNotRemove = key;
      }
    }

    if(doesValueExists){
      tempAboutToChangeMap.delete(keyToNotRemove)
    }else{
      let newKey = 'ToDel';
      let index = new String(tempAboutToChangeMap.size+1);
      newKey += index.padStart(2, '0');
      tempAboutToChangeMap.set(newKey,count);
    }
    setFilesAboutToChangeMap({files: tempAboutToChangeMap})
  }
  
  const handleAddFile = (event) => {

    if(filesAboutToChangeMap != undefined){
    let tempAboutToChangeMap = filesAboutToChangeMap.files;

      if(typeof event.target.files[0] != 'undefined'){
        let formData = new FormData();
        formData.append("fileData",event.target.files[0],event.target.files[0].name);

        let size = Array.from(formData.entries(), ([key, prop]) => (
          {[key]: {
            "ContentLength": prop.size
          }}
          ))[0].fileData.ContentLength;
          console.log(size)
          if(size > 25600000){
            triggerAlert();

          } else {

          let newKey = 'ToAdd';
          let number = 0;
          let indexNum,index,finalKey = '';
          do{
            number++
            indexNum = new String(tempAboutToChangeMap.size+number);
            index = indexNum.padStart(2, '0');
            finalKey = newKey + index;
          }while(tempAboutToChangeMap.has(finalKey))
          tempAboutToChangeMap.set(finalKey,formData)
          setFilesAboutToChangeMap({files: tempAboutToChangeMap})
        }
      }
    }
  }
  async function saveAttachments(valueInput){
    let data = await api.attached.saveAttachments(props.data.dossierId, props.data.idReferenza, valueInput).then((data) => {
      return data.data;
    });
    return data;
  }

  async function handleSetValue(){

    let tempLinksList = {}
    if(filesLinksList != undefined){
    tempLinksList = filesLinksList.links;
    }
    if(filesAboutToChangeMap != undefined){

      let tempAboutToChangeMap = filesAboutToChangeMap.files;

      for(let [key, value] of tempAboutToChangeMap) {
        let checkKey = key.substring(0,5);
        if(checkKey == 'ToDel'){
          tempLinksList.splice(value,1)
          tempAboutToChangeMap.delete(key);
        }
      }
      setIsloading(true);
      for(let [key, value] of tempAboutToChangeMap) {
        let data = await saveAttachments(value);
        tempLinksList.push(data)
      }
      setIsloading(false);
      setFilesAboutToChangeMap({files: new Map()})
      setFilesLinksList({links: tempLinksList})
    }
    let attachemntsValue = [];
    tempLinksList.map(fileData => {
      attachemntsValue.push(fileData)
    })
    setValue(attachemntsValue);
  }

  function FilesToSave(){
    let container = [];
    let rows = [];
    rows.unshift(<Button type="ant-btn ant-btn-primary" loading={isLoading} style={{float:"right"}} onClick={handleSetValue}>Applica Modifiche</Button>);

    if(editable){
      container.push(<div> {rows} </div>)
    } else {
      container.push(<div></div>)
    }

    return(
      container
    );
  }

  function FilesCounter(){
    return(
            <p style={{float: "right"}}>{filesLinksList.links.length}</p>
    );
  }

  function FilesSelector(){     
    let rows = [];
    let container = [];
    let counterToAdd = 0;
    let filesAboutToBeingAdded = false;

    if(filesAboutToChangeMap != undefined){

      for(let [key, value] of filesAboutToChangeMap.files){
        let checkKey = key.substring(0,5);
          if(checkKey == 'ToAdd'){
            counterToAdd++
          }
      }

        let counter = 0;

        for(let i=0;i<filesAboutToChangeMap.files.size;i++){

          let fileName = "";
          let rowContent = [];

          for(let [key, value] of filesAboutToChangeMap.files){
            let checkKey = key.substring(0,5);
            if(checkKey == 'ToAdd'){

              let fileRow = []

              for(let file of value.entries()){
                fileName = file[1].name;
              }

              if(counter < counterToAdd){

                if(editable){
                  fileRow.push(<Button style={{float: "right"}} icon="delete" type="ant-btn ant-btn-dashed ant-btn-circle " onClick={() => handleRemoveFileSelected(key)}/>)
                }

                fileRow.push(<span style={{backgroundColor: "rgb(250,250,255)", border: "4px outset rgba(180,180,255,0.5)", borderRadius: "4px"}} key = {fileName}>{fileName}</span>)

                let fileContainer = <div style={{width: "100%"}}> {fileRow} </div>;
                rowContent.push(fileContainer);
              }
              counter++
            }
          }
          rows.push(<div style={{display: "flex", alignItems: "center", justifyContent: "center", flexFlow: "column wrap", alignContent: "stretch"}}>{rowContent}</div>);
        }
        for(let [key, value] of filesAboutToChangeMap.files){
          let checkKey = key.substring(0,5);
          if(checkKey == 'ToAdd'){
            filesAboutToBeingAdded = true;
            break;
          }
        }
    }
    rows.push(<input style={{color: "rgba(0, 0, 0, 0)"}} onChange={handleAddFile} type="file" name="file"/>)

    if(filesAboutToBeingAdded){
      rows.unshift(<div style={{}}><h4 style={{textAlign: "center"}}>Files selezionati per l'upload</h4></div>)
    } else {
      rows.unshift(<div style={{}}><h4 style={{textAlign: "center"}}>Selezionare un File...</h4></div>)
    }

    if(editable){
        container.push(<div style= {{borderRadius: "5px", padding: "3px", leftPadding: "2px", background: "linear-gradient(#2bff00, #c5ffb8)"}}><div style= {{borderRadius: "5px", padding: "10px", margin:'auto', backgroundColor: "rgb(240,240,240)"}}> {rows} </div></div>)
      }

    return(
        container
      )
    
  }

  function FilesLinks(){
    let rows = [];

    if(filesLinksList.links.length != 0){
      for(let i =0;i<filesLinksList.links.length;i++){
        let content = [];
        let removeFromDelete = false;

        content.push(<a href={filesLinksList.links[i].fileLinkStore}> {filesLinksList.links[i].fileName} </a>)

        if(filesAboutToChangeMap != undefined){
          for (let [key, value] of filesAboutToChangeMap.files.entries()) {
            if (value == i){
              removeFromDelete = true;
            }
          }
        }

        if(editable){
          if(removeFromDelete){
            content.push(<Button icon="stop" type="ant-btn ant-btn-dashed ant-btn-circle " style={{float: 'right', backgoundColor: 'red'}} onClick={() => handleRemoveFile(i)}></Button>)
          }else{
            content.push(<Button icon="delete" type="ant-btn ant-btn-dashed ant-btn-circle " style={{float: 'right'}} onClick={() => handleRemoveFile(i)}></Button>)
          }
        }
        rows.push(<div style={{paddingBottom: '12px'}}>{content}</div>);
      }

      if(rows.length > 0){
        rows.unshift(<div><h4 style={{textAlign: "center"}}>Files in Cloud</h4></div>)
      }
    } else {
      rows.push(<div><h4 style={{textAlign: "center"}}>Nessun File In Cloud...</h4></div>)
    }
    return(
      <div style= {{borderRadius: "5px", padding: "3px", leftPadding: "2px", marginTop:"15px", background: "linear-gradient(#4ac232, #2bff00)"}}><div style= {{borderRadius: "5px", padding: "10px", margin:'auto', backgroundColor: "rgb(240,240,240)"}}> {rows} </div></div>
    )
  }

  function FilesMenu(){
    let rows = [];
    let titleLinks = <div><Icon type="cloud-download-o" style={{float: 'left', fontSize: '32px', color: '#08c' }}/><h3> Cloud </h3><FilesLinks/><br/></div>
    let titleSelector = <div style={{}}><Icon type="cloud-upload-o" style={{float: 'left', fontSize: '32px', color: '#08c' }}/><h3> Upload </h3><FilesSelector/></div>
    rows.push(titleLinks);
    if(editable){
      rows.push(titleSelector);
    }
    if(showAlert){
      let message = `il peso del file è superiore ai 25 MB.`
      rows.push(<Alert message={message} type="warning" showIcon style={{ marginBottom: 10 }} />)
    }
    return(
      <div><FilesToSave/>{rows}</div>
    )
  }

  return (
    <Popover
      placement="left"
      onVisibleChange={visible => {
        if (visible) {
          props.api.dispatchEvent({
            type: 'cellEditingStarted',
            ...props
          });
        } else {
          const oldValue = props.value;

          if (oldValue !== value) {
            props.node.setDataValue(props.colDef.field, value);
            props.api.dispatchEvent({
              type: 'cellEditingStopped',
              ...props,
              value,
              oldValue,
              newValue: value
            });
            props.api.dispatchEvent({
              type: 'cellValueChanged',
              ...props,
              value,
              oldValue,
              newValue: value
            });
          }
        }
      }}
      content= {<FilesMenu/>}
      trigger="click">
      <FilesCounter/>
      <Button type="link " size="small" title="Inserisci un allegato">
        <NotePopupIcon type="form" active={value !== null && value.length > 0} />
      </Button>
    </Popover>
  );
});
