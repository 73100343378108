import styled from 'styled-components'
import {InputNumber, Table, Select, Input, Icon, DatePicker} from 'antd'
import {colors} from "Common/constants/color-constants";

export const StyledTable = styled(Table)`
    table {
        min-width: 100%;
        width: auto !important;
        table-layout: fixed;
    }
    
    .ant-table-column-title {
        font-size: 12px;
    }
    
    .special-table-head-title i {
        font-style: normal;
        display: block;
        color: rgba(0, 0, 0, .45);
    }
    
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 4px 16px;
    }
    
    .ant-spin-container {
        text-align: center;
    }
    
    .ant-spin-container .ant-pagination {
        float: none;
    }
`;

export const TableInputNumber = styled(InputNumber)`
  width: 100% !important;
  border-color:  ${props => props.warning ? 'rgba(250, 173, 20, 1) !important' : '#d9d9d9'}
`;

export const TableInput = styled(Input)`
    width: 100% !important;
`;

export const TableSelect = styled(Select)`
  width: ${props => props.warning ? '75%' : '100%'};
  .ant-select-selection {
    border-color:  ${props => props.warning ? 'rgba(250, 173, 20, 1)' : '#d9d9d9'}
  }
`;

export const TableDatePicker = styled(DatePicker)`
  div input {
    border-color: ${props => props.className.split(' ').includes('ant-input-error') ? '#ff4d4f' : '#d9d9d9'}
  }
`;

export const NoteTextarea = styled(Input.TextArea)`
  width: 500px !important;
`;

export const NotePopupIcon = styled(Icon)`
  font-size: 18px;
  color: ${props => (props.active ? colors.main : colors.grey3)};
`;