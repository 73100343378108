import * as actionTypes from '../action-types'

const initialState = {
  specialAuth: {
    dossiersReport: false
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.VALIDATE_TOKEN:
      return {
        ...state,
        ...payload
      }

    case actionTypes.SET_USER:
      return {
        ...state,
        ...payload
      };

    case actionTypes.SET_SPECIAL_AUTH:
      return {
        ...state,
        specialAuth: {
          ...state.specialAuth,
          ...payload
        }
      };

    default:
      return state
  }
}

export default reducer
