import styled from 'styled-components'
import { Descriptions } from 'antd'

export const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-title {
    margin-bottom: 16px;
  }
  margin-bottom: 16px;
`

export const StyledTitle = styled.div`
  flex-grow: 1;
  padding: 0 16px;
  font-size: 20px;
`
