import styled from 'styled-components';
import {Button} from 'antd';

export const ActionBar = styled.div`
    .ant-col {
        display: flex;
        flex-direction: row;
        
        > button, > .ant-badge, > .ant-radio-group {
            display: block;
            margin-right: 16px;
            
            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    .ant-col:nth-of-type(2) {
        justify-content: flex-end;
    }
    
    @media (max-width: 575px) {
        .ant-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            > button, > .ant-badge, > .ant-radio-group {
                height: 64px;
                line-height: 64px;
                font-size: 16px;
                margin-right: 0;
                margin-bottom: 16px;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export const StyledButton = styled(Button)`
    margin-right: 16px !important;
`;
