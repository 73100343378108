import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import * as _ from 'lodash';
import { Button, Modal, Popover } from 'antd'
import { NotePopupIcon, NoteTextarea } from 'Components/LMTable/styled'
import { update } from 'ramda'
import { num } from 'Common/utils'

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [temporaryValue, setTemporaryValue] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [total, setTotal] = useState(0);
  const { colDef } = props;
  const { cellRendererParams } = colDef;
  const { title, content } = cellRendererParams;

  useEffect(() => {
    let total = 0;

    temporaryValue.forEach(item => {
      total += item.qta;
    });

    setTotal(total);
  }, [temporaryValue]);

  useEffect(() => {
    if (displayModal) {
      props.api.dispatchEvent({
        type: 'cellEditingStarted',
        ...props
      });
    } else {
      const oldValue = props.value;

      if (oldValue !== value) {
        props.node.setDataValue(props.colDef.field, value);
        props.api.dispatchEvent({
          type: 'cellEditingStopped',
          ...props,
          value,
          oldValue,
          newValue: value
        });
        props.api.dispatchEvent({
          type: 'cellValueChanged',
          ...props,
          value,
          oldValue,
          newValue: value
        });
      }
    }
  }, [displayModal, props, value]);

  useImperativeHandle(ref, () => {
    return {
      refresh: params => {
        if (params.value !== value) {
          setValue(value);
        }

        return true;
      }
    }
  });

  const handleOk = () => {
    setValue(temporaryValue);
    setDisplayModal(false);
  };

  const updateTotalStock = total => setTotal(total);

  const updateTemporaryValue = value => setTemporaryValue(value);

  if (cellRendererParams.storeId > 0) {
    const item = value.find(o => o.storeId === cellRendererParams.storeId);

    if (item && item.qta) {
      return <div style={{ textAlign: 'center' }}>{parseFloat(item.qta).toFixed(2).replace('.', ',')}</div>
    }

    return null
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Button type="link" size="small" title="Modifica" onClick={() => setDisplayModal(true)}>
        <NotePopupIcon type="form" style={{ color: value.reduce((a, c) => a + c.qta, 0) > 0 ? '#66CC33' : undefined }} />
      </Button>
      <Modal
        maskClosable={false}
        closable={false}
        title={ title || 'Modifica' }
        visible={displayModal}
        cancelText="Annulla"
        okText="Conferma"
        onCancel={() => setDisplayModal(false)}
        onOk={handleOk}
        okButtonProps={{
          disabled: total > props.data.prevVtot
        }}
      >
        {React.cloneElement(content, {
          defaultValue: value,
          updateTemporaryValue,
          temporaryValue,
          updateTotalStock,
          parentProps: props,
          cellRendererParams
        })}
      </Modal>
    </div>
  );
});
