import React, { Component } from 'react'
import { Modal, Button, Table } from 'antd'
import * as moment from 'moment';

export default class OpecomDeadLineModal extends Component {
    render() {

        return (
            <Modal
                title="Avviso scadenza di lavorazione"
                visible={this.props.visible}
                closable={false}
                maskClosable={false}
                footer={[
                <Button key="back" onClick={this.props.onCancel}>
                    Chiudi
                </Button>,
                ]}>
                <div>
                    <p style={{textAlign: 'center'}}>Il dossier opecom <strong>{this.props.title}</strong> è in scadenza di lavorazione. La scadenza prevista è il <b>{this.props.endDate.format('DD/MM/YYYY')}</b>. </p>
                </div>
            </Modal>
        )
    }
}
