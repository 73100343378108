import styled from 'styled-components'
import { Form, InputNumber } from 'antd'
import { colors } from 'Common/constants'

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label label {
    font-weight: 500;
    color: ${colors.grey4};
`

export const StyledInputNumber = styled(InputNumber)`
  width: 100% !important;
`
