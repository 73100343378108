import { useCallback, useRef } from 'react'

const useMemoizedFunction = (fn) => {
  const ref = useRef(null);
  const cache = useRef(new Map());

  const memoizedFn = useCallback((...args) => {
    const key = JSON.stringify(args);

    if (!cache.current.has(key)) {
      cache.current.set(key, fn(...args));
    }

    return cache.current.get(key);
  }, [fn]);

  if (!ref.current) {
    ref.current = memoizedFn;
  }

  return ref.current;
};

export default useMemoizedFunction;
