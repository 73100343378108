import React, { Component } from 'react'
import { Form, Button, Select, Tooltip } from 'antd'
import { StyledModal, StyledFormItem } from './styled'

const { Option } = Select

export const AddSubcategoryModal = Form.create({
  name: 'add_subcategory_modal'
})(
  class extends Component {
    state = { isLoadingSubcategories: false }

    handleSelectCategory = value => {
      this.props.form.setFieldsValue({ idSubCategory: [] })
      this.setState({ isLoadingSubcategories: true })
      this.props
        .onSelectCategory(value)
        .then(() => this.setState({ isLoadingSubcategories: false }))
    }

    handleSelectSubcategory = value => {
      if (value === 'all') {
        this.props.form.setFieldsValue({
          idSubCategory: this.props.subcategories.map(
            subcategory =>
              `${subcategory.categoryId}.${subcategory.subCategoryId}`
          )
        })
      }
    }

    render() {
      const {
        visible,
        onCancel,
        onOk,
        form,
        categories,
        subcategories
      } = this.props
      const { isLoadingSubcategories } = this.state
      const { getFieldDecorator } = form
      const formItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
        labelAlign: 'left'
      }
      const subcategorySelect = (
        <Select
          mode="multiple"
          allowClear
          onSelect={this.handleSelectSubcategory}
          disabled={!form.getFieldValue('idCategory')}
          loading={isLoadingSubcategories}>
          {subcategories.length !== 0 && (
            <Option key={'all'}>Tutti i sottotipi</Option>
          )}
          {subcategories.map(subcategory => (
            <Option
              key={`${subcategory.categoryId}.${subcategory.subCategoryId}`}>
              {`${subcategory.subCategoryId}-${subcategory.label}`}
            </Option>
          ))}
        </Select>
      )

      return (
        <StyledModal
          visible={visible}
          title="Seleziona il tipo e i sottotipi"
          onCancel={onCancel}
          onOk={onOk}
          maskClosable={false}
          afterClose={() => form.resetFields()}
          footer={[
            <Button key="submit" type="primary" onClick={onOk}>
              OK
            </Button>
          ]}>
          <Form {...formItemLayout}>
            <StyledFormItem label="Tipo" colon={false}>
              {getFieldDecorator('idCategory', {
                rules: [{ required: true, message: 'Scegli un tipo' }]
              })(
                <Select onSelect={this.handleSelectCategory}>
                  {categories.length !== 0 && (
                    <Option key={'all'}>Tutti i tipi</Option>
                  )}
                  {categories.map(category => (
                    <Option
                      key={category.id}
                      value={
                        category.id
                      }>{`${category.id}-${category.label}`}</Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
            <StyledFormItem label="Sottotipi" colon={false}>
              {getFieldDecorator('idSubCategory', {
                rules: [
                  { required: true, message: 'Scegli almeno un sottotipo' }
                ]
              })(
                form.getFieldValue('idCategory') ? (
                  subcategorySelect
                ) : (
                  <Tooltip title="Seleziona un tipo">
                    {subcategorySelect}
                  </Tooltip>
                )
              )}
            </StyledFormItem>
          </Form>
        </StyledModal>
      )
    }
  }
)
