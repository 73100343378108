import * as actionTypes from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const fetchValidityPeriods = () => {
  return async dispatch => {
    try {
      const res = await api.validityPeriods.get()
      dispatch({
        type: actionTypes.FETCH_VALIDITY_PERIODS,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchDepartments = () => {
  return async dispatch => {
    try {
      const res = await api.departments.get()
      dispatch({
        type: actionTypes.FETCH_DEPARTMENTS,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchSubdepartments = departmentId => {
  return async dispatch => {
    try {
      const res = await api.subdepartments.get(departmentId)
      dispatch({
        type: actionTypes.FETCH_SUBDEPARTMENTS,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchCategories = (departmentId, subdepartmentId) => {
  return async dispatch => {
    try {
      const res = await api.categories.get(departmentId, subdepartmentId)
      dispatch({
        type: actionTypes.FETCH_CATEGORIES,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchSubcategories = (
  departmentId,
  subdepartmentId,
  categoryIds,
  unassigned
) => {
  return async dispatch => {
    try {
      const res = await api.subcategories.get(
        departmentId,
        subdepartmentId,
        categoryIds,
        unassigned
      )
      dispatch({
        type: actionTypes.FETCH_SUBCATEGORIES,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchFamilies = () => {
  return async dispatch => {
    try {
      const res = await api.families.getAll()
      dispatch({
        type: actionTypes.FETCH_FAMILIES,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchDossiers = (dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, page, pageSize) => {
  return async dispatch => {
    try {
      const res = await api.dossiers.get(dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, page, pageSize)
      dispatch({
        type: actionTypes.FETCH_DOSSIERS,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchStores = () => {
  return async dispatch => {
    try {
      const res = await api.stores.get()
      dispatch({
        type: actionTypes.FETCH_STORES,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const fetchProductIds = () => {
  return async dispatch => {
    try {
      const res = await api.products.getIds()
      dispatch({
        type: actionTypes.FETCH_PRODUCT_IDS,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}
