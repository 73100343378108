import * as actionTypes from '../action-types'

export const initialState = {
  isLoading: true,
  buildInfo: null
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case actionTypes.FETCH_BUILD_INFO:
      return {
        ...state,
        buildInfo: payload
      }
    default:
      return state
  }
}

export default reducer
