import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Input, List, Popover } from 'antd'

export default forwardRef((props, ref) => {
  const {formatValue, stopEditing, colDef} = props;
  const [value, setValue] = useState([]);
  const [displayPopover, setDisplayPopover] = useState(true);
  const [newElementValue, setNewElementValue] = useState('');
  const { cellEditorParams } = colDef;

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => {
        setValue(props.value);
      }
    };
  });

  const addElement = () => {
    if (value.indexOf(newElementValue) === -1) {
      if(newElementValue.includes(',')){
        let newElementsValuesArray = newElementValue.split(',')
        if(!(newElementsValuesArray.length + value.length > 3)) {
          let newValues = value;
          newElementsValuesArray.forEach((newElementValueOfArray) => {
            newElementValueOfArray = newElementValueOfArray.replace(/\s+/g, '');
            newValues.push(newElementValueOfArray)
          });
          setValue([
            ...newValues
          ]);
        }
      } else {
        setValue([
          ...value,
          newElementValue
        ]);
      }
    }

    setNewElementValue('');
  };

  const removeElement = item => {
    setValue(value.filter(o => o !== item));
  };

  const exitEditing = () => {
    setDisplayPopover(false);
    stopEditing();
  };

  const handleChange = e => {
    if (cellEditorParams.validateValue && !cellEditorParams.validateValue(e.target.value)) {
      return;
    }

    setNewElementValue(e.target.value);
  };

  const popoverContent = (
    <div>
      <Input placeholder="Aggiungi elemento" value={newElementValue} disabled={cellEditorParams.maxElements && cellEditorParams.maxElements <= value.length} style={{ marginBottom: 15 }} suffix={
        <Button type="dashed" shape="circle" icon="plus" size={"small"} disabled={newElementValue.length === 0 || value.indexOf(newElementValue) > -1 || (cellEditorParams.maxElements && cellEditorParams.maxElements <= value.length)} style={{ cursor: 'pointer' }} onClick={addElement} />
      } onChange={handleChange} onPressEnter={addElement} />
      <List
        style={{ maxHeight: 250, overflowY: 'auto' }}
        size={"small"}
        bordered
        dataSource={value}
        renderItem={item => (
          <List.Item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>{item}</span>
            <Button icon="delete" size={"small"} style={{ marginLeft: 'auto' }} onClick={() => removeElement(item)} />
          </List.Item>
        )}
      />
    </div>
  );

  return <Popover
    title={'Modifica elementi'}
    content={popoverContent}
    visible={displayPopover}
    placement={'top'}
    overlayStyle={{
      width: 300
    }}
  >
    <span
      onClick={exitEditing}
      style={{
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        textTransform: displayPopover && 'uppercase',
        textAlign: displayPopover && 'center',
        opacity: displayPopover && 0.5,
        cursor: 'pointer'
      }}>
      {displayPopover ? 'Clicca per chiudere' : formatValue}
    </span>
  </Popover>
});
