import * as actionTypes from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const setLoading = loading => {
  return { type: actionTypes.SET_LOADING, payload: loading }
}

export const fetchBuildInfo = () => {
  return async dispatch => {
    try {
      const res = await api.about.getBuildInfo()
      dispatch({
        type: actionTypes.FETCH_BUILD_INFO,
        payload: res.data
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}