import React  from 'react';
import { Icon, Popover } from 'antd'
import { getProductInfoTranslatedDescriptions } from '../../../common/utils'
import { colors } from 'Common/constants';

export default (props) => {
  const { value } = props;

  if (value.length === 0) {
    return '';
  }

  return (
    <Popover placement="rightTop" title={false} content={value.map((warning, index) => <p key={index}>{warning.description}</p>)} trigger="click">
      <Icon type="warning" theme="filled" style={{ color: colors.yellow }} />
    </Popover>
  );
};
