import React from 'react';
import { Icon } from 'antd';
import { css } from '@emotion/css';

const containerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100%;
`;

export default function standardCellRenderer(props) {

  return (
    <div className={containerStyle}>
      {props.colDef.editable && ((typeof props.colDef.editable === 'function' && props.colDef.editable(props)) || props.colDef.editable === true) && <Icon type="edit" />}
      <span style={{ marginLeft: 10 }}>{props.valueFormatted ? props.valueFormatted : props.value}</span>
    </div>
  )
}
