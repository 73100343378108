import React from 'react'
import {
  StyledCard,
  StyledSpin,
  LoadingIcon,
  StyledDiv,
  StyledH1,
  StyledP
} from './styled'
import { Icon, Popconfirm } from 'antd'

const bodyStyle = {
  padding: '0',
  flexGrow: '1',
  display: 'flex',
  justifyContent: 'space-between'
}

const SubcategoryCard = props => (
  <StyledCard
    className="subcategory-card"
    bodyStyle={bodyStyle}
    actions={[
      props.loading ? (
        <StyledSpin
          indicator={<LoadingIcon type="loading-3-quarters" spin />}
        />
      ) : (
        <Icon
          type="edit"
          key="edit"
          onClick={() => props.onEdit(props.category)}
        />
      ),
      <Popconfirm
        title="Sei sicuro di voler eliminare il tipo?"
        onConfirm={() => props.onDelete(props.category)}
        okText="Sì"
        cancelText="No">
        <Icon type="delete" key="delete" />
      </Popconfirm>
    ]}>
    <StyledDiv>
      <StyledH1>Tipo</StyledH1>
      <StyledP>{props.category}</StyledP>
    </StyledDiv>
    <StyledDiv>
      <StyledH1>Sottotipo</StyledH1>
      <StyledP>
        {props.subcategories.map(subcategory => subcategory.id).join(', ')}
      </StyledP>
    </StyledDiv>
  </StyledCard>
)

export default SubcategoryCard
