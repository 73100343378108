import { ROLE_CP, ROLE_CPXS, ROLE_CS } from "Common/constants/global";

export default {
  '1.5.9': {
    body: 'Implementazioni varie',
    updates: [
      'Inserimento bacheca aggiornamenti applicativo',
      'Inserimento messaggio che mostra l\'ambiente operativo (preprod & test)',
      'Implementato log di cambiamenti per l\'applicativo',
      'Aggiunta filtri su campi facing, SP e top reappro',
      'Aggiunta warning per prodotti in precotop 1 messi in top 2',
      'Gestione top reappro XS per prodotti in gamma P'
    ]
  },
  '1.5.10': {
    body: 'Implementazioni varie',
    updates: [
      'Lista negozi modificabile per referenze locali',
      'Aggiunto vincolo stock > 0 se precotop reappro = 1'
    ]
  },
  '1.5.13': {
    body: 'Aggiornamento accesso applicativo',
    updates: [
      'Inserimento scelta negozio in caso di utenza con negozi multipli',
      'Aggiornamento token di accesso'
    ]
  },
  '1.5.14': {
    body: 'Aggiornamento griglia lavorazione dossier',
    updates: [
      'Funzionalità copia/incolla da e a Excel',
      'Eliminato paginazione referenze',
      'Inserito nuova modalità modifica dati referenze',
      'Implementato errore per prodotto',
      'Nuovo sistema di gestione filtri e colonne'
    ]
  },
  '1.5.15': {
    body: 'Aggiornamento griglia lavorazione dossier',
    updates: [
      'Corretto bug su colonna negozi'
    ]
  },
  '1.6.0': {
    body: 'Implementazioni varie',
    updates: [
      'Funzionalità di duplica dossier (CP/CS)',
      'Funzionalità di annullamento dossier (CP)',
      'Funzionalità di eliminazione famiglia merceologica (MERCH)',
      'Miglioramenti alla ricerca dossier e alert dossier in scadenza (tutti i ruoli)',
      "Nuovi criteri di assegnabilità referenze a dossier basati su periodo d'impianto (CP/CS)",
      'Promo Nazionali: Passaggio alla nuova tabella già implementata sui dossier gamma (CP/AZ.COMM/CS)',
      'BUGFIX: Corretto bug scorrimento orizzontale su tabella dossier (tutti i ruoli)',
      'BUGFIX: Ripristinato alert referenze ancora da lavorare su tabella dossier (tutti i ruoli)',
      'Risoluzione altri bug minori'
    ],
    tutorial: {
      link: 'https://drive.google.com/uc?export=download&id=1hx7k3BekwBWFXg01z0gzKHch3K1g6QrU',
      targetRoles: [ROLE_CP, ROLE_CPXS, ROLE_CS]
    }
  },
  '1.7.0': {
    body: 'Implementazioni varie',
    updates: [
      'Visualizzazione fornitore nella tabella dettaglio opecom',
      'Disabilitazione modifica e invio campi SPT e Prev. Venduto nel caso di referenza lotto'
    ]
  },
  '1.7.1': {
    body: 'Fix varie',
    updates: [
      'Autorizzazioni e visualizzaizone Promo Locali, Opecom, Prodotti Simbolo'
    ]
  },
  '1.8.0': {
    body: 'Anagrafica Prodotti, Bugfix e Modifiche Varie',
    updates: [
      'Disponibile la gestione SP anagrafica per i ruoli MERCH e CP XS',
      'Vari bugfix sulla ricerca prodotti e gestione dossier',
      'Vari bugfix e cambiamenti gestione opecom'
    ]
  },
  '1.8.1': {
    body: 'Bugfix, aggiunta scadenze opecom in visualizzazione',
    updates: [
      'Nei risultati del dossier opecom e testata sono visibile le date di scadenza per sede e negozio',
      'Vari bugfix e cambiamenti applicativo'
    ]
  },
  '1.8.2': {
    body: 'Implementazioni varie',
    updates: [
      'Ricerca dossier: aggiunti filtri per referenza e negozio',
      'Dossier Opecom: inserimento priorità per referenza'
    ]
  }
};
