import React from 'react';
import { StyledMenuItem, StyledMenu } from "Components/NavigationTabs/styled";
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {Redirect, withRouter} from "react-router";
import AppAccess from 'Common/appAccess';
import { PATHS } from 'src/routes/constants';

/**
 * NavigationTabs - top header navigation menu.
 * @reactProps {Function} onNavigationChange - Callback function when page key changes
 */
class NavigationTabs extends React.Component {
    state = {
        currentPage: _.isNil(this.props.defaultCurrentPage) ? this.props.location.pathname === '/dossier' ? 'dossier' : 'family' : this.props.defaultCurrentPage,
        redirectTo: null
    };

    tabs = [
        {
            key: 'simulazione',
            label: 'Simulazione Dossier',
            isVisible: AppAccess.isAuthorized('sng')
        },
        {
            key: 'family',
            label: 'Gestione Famiglie',
            isVisible: AppAccess.isAuthorized('family')
        },
        {
            key: 'family-store',
            label: 'Gestione ML',
            isVisible: AppAccess.isAuthorized('family-store')
        },
        {
            key: 'dossier',
            label: 'Gestione Dossier',
            isVisible: AppAccess.isAuthorized('dossier')
        },
        {
            key: 'opecom',
            label: 'Gestione Opecom',
            isVisible: AppAccess.isAuthorized('opecom')
        },
        {
            key: 'prodotti-simbolo',
            label: 'Prodotti Simbolo',
            isVisible: AppAccess.isAuthorized('prodotti_simbolo')
        },
        {
            key: 'anagrafica',
            label: 'Anagrafica Prodotti',
            isVisible: AppAccess.isAuthorized('anagrafica')
        },
        {
            key: 'news',
            label: 'News',
            isVisible: true
        }
    ]

    componentDidMount() {
        if (_.isFunction(this.props.onNavigationChange)) {
            this.props.onNavigationChange(this.state.currentPage);
        }

        const currentTab = this.tabs.find(o => o.key === this.state.currentPage);

        if (currentTab && !currentTab.isVisible) {
            this.setState({currentPage: 'dossier'});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentPage !== this.state.currentPage && _.isFunction(this.props.onNavigationChange)) {
            this.props.onNavigationChange(this.state.currentPage);
        }
    }

    handleClick = (val) => {
        switch (val.key) {
            case 'family':
                if (_.get(this.props, 'location.pathname', null) !== '/family') {
                    this.props.history.push('/family');
                }
                break;

            case 'dossier':
                if (_.get(this.props, 'location.pathname', null) !== '/dossier') {
                    this.props.history.push('/dossier');
                }
                break;

            case 'opecom':
                if (_.get(this.props, 'location.pathname', null) !== PATHS.SearchOpecom) {
                    this.props.history.push(PATHS.SearchOpecom);
                }
                break;

            case 'family-store':
                if (_.get(this.props, 'location.pathname', null) !== PATHS.Simulazione) {
                    this.props.history.push(PATHS.FamilyStore);
                }

                break;

            case 'simulazione':
                if (_.get(this.props, 'location.pathname', null) !== PATHS.Simulazione) {
                    this.props.history.push(PATHS.Simulazione);
                }

                break;

            case 'prodotti-simbolo':
                if (_.get(this.props, 'location.pathname', null) !== '/prodotti-simbolo') {
                    this.props.history.push('/prodotti-simbolo');
                }
                break;

            case 'anagrafica':
                if (_.get(this.props, 'location.pathname', null) !== '/anagrafica') {
                    this.props.history.push('/anagrafica');
                }
                break;

            case 'news':
                if (_.get(this.props, 'location.pathname', null) !== PATHS.ChangeLog) {
                    this.props.history.push(PATHS.ChangeLog);
                }
                break;
        
            default:
                break;
        }
    };

    render() {
        const { redirectTo, currentPage } = this.state;

        if (redirectTo) {
            this.props.location.state = currentPage;
            return <Redirect to={{
                pathname: redirectTo,
                state: {currentPage: currentPage}
            }} />
        }

        return (
            <StyledMenu onClick={this.handleClick} selectedKeys={[currentPage]} mode="horizontal">
                {this.tabs.map(tab => tab.isVisible && <StyledMenuItem key={tab.key}>
                        {tab.label}
                    </StyledMenuItem>)
                }
            </StyledMenu>
        );
    }
}

NavigationTabs.propTypes = {
    onNavigationChange: PropTypes.func
};

export default withRouter(NavigationTabs);
