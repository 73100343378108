import React, { Component } from 'react'
import { Form, Button, Select } from 'antd'
import { StyledModal, StyledFormItem } from './styled'

const { Option } = Select

export const SubdepartmentModal = Form.create({ name: 'subdepartment_modal' })(
  class extends Component {
    render() {
      const { visible, onCancel, onOk, form, subdepartments } = this.props
      const { getFieldDecorator } = form
      const formItemLayout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
        labelAlign: 'left'
      }
      return (
        <StyledModal
          visible={visible}
          title="Seleziona il sottoreparto"
          onCancel={onCancel}
          onOk={onOk}
          maskClosable={false}
          afterClose={() => form.resetFields()}
          footer={[
            <Button key="submit" type="primary" onClick={onOk}>
              OK
            </Button>
          ]}>
          <Form {...formItemLayout}>
            <StyledFormItem label="Sottoreparto" colon={false}>
              {getFieldDecorator('idSubDepartment', {
                rules: [
                  { required: true, message: 'Scegli un sottoreparto' }
                ]
              })(
                <Select>
                  {subdepartments.map(subdepartment => (
                    <Option key={subdepartment.id} value={subdepartment.id}>
                      {`${subdepartment.id}-${subdepartment.label}`}
                    </Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
          </Form>
        </StyledModal>
      )
    }
  }
)
