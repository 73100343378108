import React from 'react'
import { Form, Icon, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import {
  StyledForm,
  CollapsedFormMask,
  StyledFormRow,
  StyledFormItem,
  StyledButton,
  StyledCollapse,
} from './styled'
import { Col, Input, DatePicker } from 'antd'
import { DATE_LOCALE } from 'Common/globals'
import { ROLE_CS, ROLE_CS_WEB } from 'Common/constants/global'

/**
 * Component for opecom search filters
 */
export const SearchOpecomFilters = Form.create({
  name: 'opecom_filters_form',
    mapPropsToFields(props) {
      return {
        code: Form.createFormField({
          value: props.filters.code,
        }),
        description: Form.createFormField({
          value: props.filters.description,
        }),
        startDate: Form.createFormField({
          value: props.filters.startDate,
        }),
        endDate: Form.createFormField({
          value: props.filters.endDate,
        }),
        type: Form.createFormField({
          value: props.filters.type || "all"
        }),
        opecomId: Form.createFormField({
          value: props.filters.opecomId
        }),
        storeId: Form.createFormField({
          value: props.filters.storeId || props.user && props.user.role === ROLE_CS_WEB ? props.user.store : null,
        }),
        productCode: Form.createFormField({
          value: props.filters.productCode
        })
      }
    },
})(
  class extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func,
      isSearching: PropTypes.bool,
    }

    state = { collapsed: false }

    handleReset = () => {
      this.props.form.resetFields()
    }

    handleCollapse = () => {
      this.setState(state => ({
        collapsed: !state.collapsed,
      }))
    }

    render() {
      const { form, onSubmit, isSearching, stores, user } = this.props
      const { getFieldDecorator } = form
      const { collapsed } = this.state
      return (
        <StyledForm
          className={collapsed ? 'collapsed' : null}
          onSubmit={onSubmit}>
          {collapsed && <CollapsedFormMask />}
          <StyledFormRow gutter={16} type="flex">
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Codice"
                colon={false}>
                {getFieldDecorator('code')(<Input />)}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={9}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Descrizione"
                colon={false}>
                {getFieldDecorator('description')(<Input />)}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Data inizio"
                colon={false}>
                {getFieldDecorator('startDate')(
                  <DatePicker
                    className={'generic-datepicker'}
                    locale={DATE_LOCALE}
                    format="DD/MM/YYYY"
                    inputReadOnly={true}
                    disabledDate={currentDate => {
                      const endDate = this.props.form.getFieldValue('endDate')
                      return endDate && currentDate.startOf('day').isSameOrAfter(endDate.startOf('day'))
                    }}
                  />
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Data fine"
                colon={false}>
                {getFieldDecorator('endDate')(
                  <DatePicker
                    className={'generic-datepicker'}
                    locale={DATE_LOCALE}
                    format="DD/MM/YYYY"
                    inputReadOnly={true}
                    disabledDate={currentDate => {
                      const startDate = this.props.form.getFieldValue('startDate')
                      return startDate && currentDate.startOf('day').isSameOrBefore(startDate.startOf('day'))
                    }}
                  />
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Tipologia"
                colon={false}>
                {getFieldDecorator('type')(
                  <Select>
                    <Select.Option key="all">Tutti</Select.Option>
                    <Select.Option key="S">Sede</Select.Option>
                    <Select.Option key="N">Negozi</Select.Option>
                  </Select>
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="ID Opecom"
                colon={false}>
                {getFieldDecorator('opecomId', {
                  rules: [],
                  normalize: value => value ? value.replace(/\D/g, '') : ''
                })(<Input />)}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Negozio"
                colon={false}>
                {getFieldDecorator('storeId')(
                  <Select
                    disabled={user.role === ROLE_CS_WEB}
                    allowClear
                  >
                    {user.role !== ROLE_CS_WEB && <Select.Option key="0">Tutti</Select.Option>}
                    {(stores || []).filter(store => user.role !== ROLE_CS_WEB || (user.role === ROLE_CS_WEB && user.store === store.id)).map(store => (
                      <Select.Option
                        key={store.id} value={store.id}>{`${store.id}-${store.name}`}</Select.Option>
                    ))}
                  </Select>
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Referenza"
                colon={false}>
                {getFieldDecorator('productCode', {
                  rules: [],
                  normalize: value => value ? value.replace(/\D/g, '') : ''
                })(<Input />)}
              </StyledFormItem>
            </Col>
          </StyledFormRow>
          <Row type="flex" justify="end" gutter={16}>
            <Col xs={12} sm={6} md={4}>
              <StyledButton onClick={this.handleReset}>Reset</StyledButton>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <StyledButton
                type="primary"
                htmlType="submit"
                loading={isSearching}>
                Cerca
              </StyledButton>
            </Col>
          </Row>
          <StyledCollapse shape="circle" onClick={this.handleCollapse}>
            <Icon type={collapsed ? 'down' : 'up'}></Icon>
          </StyledCollapse>
        </StyledForm>
      )
    }
  }
)
