import React from 'react'
import { Redirect } from 'react-router'
import { Col, Row, Empty } from 'antd'
import {
  StyledInformationContainer,
  StyledInformationItem,
  PaginationCol,
  PaginationRow,
  StyledCollapse,
  StyledCollapsePanel,
  StyledDiv,
  StyledCheckbox,
  StyledControlsContainer,
  StyledButton,
  StyledEmpty,
  DisabledMask,
  StyledTag
} from 'Components/SearchProductsResults/styled'
import { StyledPagination } from 'Common/styled'
import * as _ from 'lodash'
import PropTypes from 'prop-types'

class SearchProductsResults extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    totalItems: PropTypes.number,
    departments: PropTypes.array,
    onPageChange: PropTypes.func,
    onSave: PropTypes.func,
    isSearching: PropTypes.bool,
    isChangingPage: PropTypes.bool,
    isSaving: PropTypes.bool
  }

  state = {
    currentPage: 1,
    addBySubtraction: true, // If true, add all filtered items except unckecked ones. Otherwise add checked items only
    checkedItems: [],
    uncheckedItems: [],
    redirectTo: null
  }

  reset = () => {
    this.setState({
      currentPage: 1,
      addBySubtraction: true,
      checkedItems: [],
      uncheckedItems: []
    })
  }

  handleCheckAll = e => {
    const checked = e.target.checked
    this.setState({
      addBySubtraction: checked,
      checkedItems: [],
      uncheckedItems: []
    })
  }

  handleCheck = (id, e) => {
    const checked = e.target.checked
    if (this.state.addBySubtraction) {
      if (checked) {
        this.setState(state => ({
          uncheckedItems: state.uncheckedItems.filter(i => i !== id)
        }))
      } else {
        this.setState(state => {
          const uncheckedItems = state.uncheckedItems.slice()
          uncheckedItems.push(id)
          return { uncheckedItems: uncheckedItems }
        })
      }
    } else {
      if (checked) {
        this.setState(state => {
          const checkedItems = state.checkedItems.slice()
          checkedItems.push(id)
          return { checkedItems: checkedItems }
        })
      } else {
        this.setState(state => ({
          checkedItems: state.checkedItems.filter(i => i !== id)
        }))
      }
    }
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page })
    // Invoke parent onPageChange callback
    _.invoke(this.props, 'onPageChange', page, pageSize)
  }

  render() {
    const {
      currentPage,
      addBySubtraction,
      checkedItems,
      uncheckedItems,
      redirectTo
    } = this.state
    const {
      items,
      totalItems,
      totalAssignableItems,
      onSave,
      isSearching,
      isChangingPage,
      isSaving,
      dossier
    } = this.props

    const allChecked =
      (addBySubtraction && uncheckedItems.length === 0) ||
      checkedItems.length === totalItems

    const someChecked =
      (uncheckedItems.length !== 0 && uncheckedItems.length < totalItems) ||
      (checkedItems.length !== 0 && checkedItems.length < totalItems)

    const getCheckedStatus = id => {
      return (
        (addBySubtraction && !uncheckedItems.includes(id)) ||
        checkedItems.includes(id)
      )
    }

    const disabled = isSearching || isChangingPage || isSaving

    return redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : (
      <StyledDiv>
        {items.length === 0 ? (
          <Row gutter={[0, 16]}>
            <Col
              xs={{ span: 22, offset: 1 }}
              xl={{ span: 18, offset: 3 }}
              xxl={{ span: 12, offset: 6 }}>
              <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          </Row>
        ) : (
          <div className={disabled ? 'unclickable' : null}>
            {disabled && <DisabledMask />}
            <Row gutter={[0, 16]}>
              <Col
                xs={{ span: 22, offset: 1 }}
                xl={{ span: 18, offset: 3 }}
                xxl={{ span: 12, offset: 6 }}>
                <StyledControlsContainer>
                  <StyledCheckbox
                    checked={totalAssignableItems !== 0 && allChecked}
                    indeterminate={someChecked}
                    onChange={this.handleCheckAll}
                    disabled={totalAssignableItems === 0}
                  />
                  <StyledButton
                      id="save-btn"
                    type="primary"
                    disabled={!(allChecked || someChecked)}
                    onClick={() =>
                      onSave(addBySubtraction, checkedItems, uncheckedItems)
                    }
                    loading={isSaving}>
                    Salva
                  </StyledButton>
                </StyledControlsContainer>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col
                xs={{ span: 22, offset: 1 }}
                xl={{ span: 18, offset: 3 }}
                xxl={{ span: 12, offset: 6 }}>
                <StyledCollapse expandIconPosition="right">
                  {items.map(item => (
                    <StyledCollapsePanel
                      key={item.id}
                      header={<>
                        <span>{`${item.id} - ${item.descrizione}`}</span>
                        {!item.assignable && <StyledTag onClick={() => this.setState({redirectTo: `/edit-dossier/${item.dossierId}`})}>
                          {item.dossierTitle}
                        </StyledTag>}
                        </>
                      }
                      extra={
                        <StyledCheckbox
                          checked={item.assignable && getCheckedStatus(item.id)}
                          onClick={e => e.stopPropagation()}
                          onChange={e => this.handleCheck(item.id, e)}
                          disabled={!item.assignable}
                        />
                      }
                      inactive={!item.assignable}>
                      <StyledInformationContainer>
                        <StyledInformationItem>
                          <label>Famiglia</label>
                          <div>{item.nomeFamiglia}</div>
                        </StyledInformationItem>
                        <StyledInformationItem>
                          <label>Reparto</label>
                          <div>{item.nomeReparto}</div>
                        </StyledInformationItem>
                        <StyledInformationItem>
                          <label>Sottoreparto</label>
                          <div>{item.nomeSottoreparto}</div>
                        </StyledInformationItem>
                        <StyledInformationItem>
                          <label>Tipo</label>
                          <div>{item.nomeTipo}</div>
                        </StyledInformationItem>
                        <StyledInformationItem>
                          <label>Sottotipo</label>
                          <div>{item.nomeSottotipo}</div>
                        </StyledInformationItem>
                        <StyledInformationItem>
                          <label>Gamma</label>
                          <div>{item.gamma}</div>
                        </StyledInformationItem>
                        {dossier.type !== 'XS' && dossier.type !== 'PL' && (
                          <StyledInformationItem>
                            <label>Top XS</label>
                            <div>{item.topXs ? 'Sì' : 'No'}</div>
                          </StyledInformationItem>
                        )}
                      </StyledInformationContainer>
                    </StyledCollapsePanel>
                  ))}
                </StyledCollapse>
              </Col>
            </Row>
            <PaginationRow>
              <PaginationCol
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 14, offset: 5 }}>
                <StyledPagination
                  current={currentPage}
                  onChange={(page, pageSize) =>
                    this.handlePageChange(page, pageSize)
                  }
                  total={_.get(this.props, 'totalItems', 0)}
                  pageSize={10}
                  showLessItems={true}
                  hideOnSinglePage={true}
                />
              </PaginationCol>
            </PaginationRow>
          </div>
        )}
      </StyledDiv>
    )
  }
}

export default SearchProductsResults
