import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button, { Layout, message } from 'antd'
import Tour from 'reactour'
import * as changelog from '../../changelog';

import { initTours, resetTours } from 'Actions/tour-actions'

import Header from 'Components/Header'
import Footer from 'Components/Footer'
import { PaddedContent } from './styled'

import { colors } from 'Common/constants'
import Notifications from 'Containers/Notifications'
import { compareVersions } from 'Common/utils'
import { fetchBuildInfo } from 'Actions/app-actions'
import Loader from 'Components/Loader'
import { Tutorial } from 'Components/Tutorial'

class MainLayout extends Component {
  static propTypes = {
    closeTour: PropTypes.func,
    initTours: PropTypes.func,
    isTourAvailable: PropTypes.bool,
    isTourOpen: PropTypes.bool,
    resetTours: PropTypes.func,
    tourSteps: PropTypes.array,
    user: PropTypes.object
  }

  state = {
    isLoading: true
  }

  componentDidMount() {
    this.props.initTours()

    this.props.fetchBuildInfo().then(() =>
      this.setState({
        isLoading: false
      })
    )

    const changelogKeys = Object.keys(changelog.default).sort(compareVersions);

    if (changelogKeys.length > 0) {
      const changelogLastVersion = changelogKeys[0];
      const lastChangelogId = localStorage.getItem('LM.COOP.LAST_CHANGELOG');

      if (changelogLastVersion !== lastChangelogId) {
        message.info(<span>L'applicativo è stato aggiornato alla versione <strong>{changelogLastVersion}</strong>. <a href="/news">Scopri di più</a> <button style={{ marginLeft: '10px', backgroundColor: 'white', border: '1px solid #66CC33', borderRadius: '4px', color: '#66CC33', boxShadow: 'none', cursor: 'pointer' }} type="button" onClick={() => message.destroy()}>CHIUDI</button></span>, 0);
        localStorage.setItem('LM.COOP.LAST_CHANGELOG', changelogLastVersion);
      }
    }
  }
  render() {
    const {
      app,
      user,
      children,
      tourSteps,
      isTourOpen,
      isTourAvailable,
      resetTours,
      onNavigationChange,
      closeTour,
      defaultCurrentPage
    } = this.props

    const {isLoading} = this.state

    return isLoading ? (
      <Loader />
    ) : (
      <Layout style={{minHeight: '100vh'}}>
        <Header
          user={user}
          resetTours={resetTours}
          isTourAvailable={isTourAvailable}
          onNavigationChange={onNavigationChange}
          defaultCurrentPage={defaultCurrentPage}
        />
        <PaddedContent style={{ display: 'flex', flexDirection: 'column' }}>{children}</PaddedContent>
        <Footer buildInfo={app.buildInfo} />
        <Tour
          accentColor={colors.main}
          steps={tourSteps}
          startAt={0}
          isOpen={isTourOpen}
          onRequestClose={closeTour}
        />
        <Notifications />
        <Tutorial
          role={user.role}
        />
      </Layout>
    )
  }
}
const mapStateToProps = state => {
  return {
    app: state.App,
    user: state.User
  }
}
const mapDispatchToProps = dispatch => {
  return {
    initTours: () => dispatch(initTours()),
    resetTours: () => dispatch(resetTours()),
    fetchBuildInfo: () => dispatch(fetchBuildInfo())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
