import React, { Component } from 'react'
import * as changelog from '../../changelog'
import { compareVersions } from 'Common/utils'
import { Modal } from 'antd'

export class Tutorial extends Component {
  state = {
    showTutorial: false,
    tutorialVersion: null
  }

  componentDidMount() {
    const changelogKeys = Object.keys(changelog.default).sort(compareVersions)

    if (changelogKeys.length > 0) {
      // Find latest tutorial targeted to current user role
      const latestVersion = changelogKeys.find(
        key => 
          changelog.default[key].hasOwnProperty('tutorial') &&
          changelog.default[key].tutorial.targetRoles.includes(this.props.role)
      )

      if (latestVersion) {
        // Check if user has already dismissed it
        if (latestVersion !== sessionStorage.getItem('LeroyMerlin.TutorialVersion') && latestVersion !== localStorage.getItem('LeroyMerlin.TutorialVersion')) {
          this.setState({ showTutorial: true, tutorialVersion: latestVersion })
        }
      }
    }
  }

  render() {
    const { showTutorial, tutorialVersion } = this.state
    return (
      showTutorial && (
        <Modal
          title={`Tutorial versione ${tutorialVersion}`}
          visible={showTutorial}
          width={608}
          cancelText="Visualizza più tardi"
          okText="Conferma"
          closable={false}
          maskClosable={false}
          onOk={() => {
            localStorage.setItem(
              'LeroyMerlin.TutorialVersion',
              tutorialVersion
            )
            this.setState({
              showTutorial: false,
            })
          }}
          onCancel={() => {
            sessionStorage.setItem(
              'LeroyMerlin.TutorialVersion',
              tutorialVersion
            )
            this.setState({
              showTutorial: false,
            })
          }}>
          <video preload={true} width={560} height={315} controls={true}>
            <source
              src={changelog.default[tutorialVersion].tutorial.link}
              type="video/mp4"
            />
          </video>
        </Modal>
      )
    )
  }
}
