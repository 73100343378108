import styled from 'styled-components'
import { Button, Collapse, Col, Row, Typography, Checkbox, Empty, Tag } from 'antd'
import { colors } from 'Common/constants'
const { Text } = Typography

export const StyledTag = styled(Tag)`
  cursor: pointer !important;
  &:hover {
    color: ${colors.main} !important;
    border-color: ${colors.main} !important;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    margin-bottom: 3px;
  }
`

export const StyledCollapse = styled(Collapse)`
  background-color: white !important;
  .ant-collapse-header {
    display: flex;
    align-items: center;
  }
  .ant-collapse-extra {
    order: -1;
  }
`

export const StyledCollapsePanel = styled(Collapse.Panel)`
  background: ${props => props.inactive ? '#f5f5f5' : 'white'};
  .ant-collapse-header > span {
    color: ${props => props.inactive ? '#b8b8b8' : colors.main};
    margin-left: 16px;
    margin-bottom: 2px;
  }
`

export const StyledInformationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -16px;
`

export const StyledInformationItem = styled.div`
  margin: 16px;
  text-transform: uppercase;
  font-size: 11px;
  width: 150px;

  label {
    font-weight: 500;
    margin-bottom: 8px;
    display: inline-block;
  }

  div {
    color: rgba(64, 64, 64, 0.65);
  }
`

export const PaginationCol = styled(Col)`
  text-align: center;
`

export const PaginationRow = styled(Row)`
  margin-top: 16px;
  margin-bottom: 16px;
`

export const StyledText = styled(Text)`
  color: #66cc33 !important;
`

export const StyledControlsContainer = styled.div`
  padding-left: 16.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`

export const StyledEmpty = styled(Empty)`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin: 0;
  padding: 24px;
`

export const StyledDiv = styled(Row)`
  margin-top: 32px;
  .unclickable {
    pointer-events: none;
  }
`

export const DisabledMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 1;
`
