import * as actionTypes from '../action-types'

export const initialState = {
  family: {},
  dossier: {}
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.EDIT_FAMILY:
      return {
        ...state,
        family: payload
      }
    case actionTypes.FETCH_FAMILY:
      return {
        ...state,
        family: payload
      }
    case actionTypes.SAVE_FAMILY:
      return {
        ...state,
        family: payload
      }
    case actionTypes.EDIT_DOSSIER:
      return {
        ...state,
        dossier: payload
      }
    case actionTypes.FETCH_DOSSIER:
      return {
        ...state,
        dossier: payload
      }
    case actionTypes.SAVE_DOSSIER:
      return {
        ...state,
        dossier: payload
      }
    default:
      return state
  }
}

export default reducer
