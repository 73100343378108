import React from 'react';
import { Icon } from 'antd';
import { css } from '@emotion/css';

const containerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100%;
`;

export default function changeDetectionCellRenderer(props) {

  const historical = props.data._historical || {};
  let color = undefined;
  let changePercentage = 0;

  if (historical[props.colDef.field] && historical[props.colDef.field] > props.value) {
    color = '#a62121';
    changePercentage = ((props.value - historical[props.colDef.field]) / historical[props.colDef.field]) * 100;
  } else if (historical[props.colDef.field] && historical[props.colDef.field] < props.value) {
    color = '#49a621';
    changePercentage = ((props.value - historical[props.colDef.field]) / historical[props.colDef.field]) * 100;
  }

  return (
    <div className={containerStyle}>
      {props.colDef.editable && <Icon type="edit" />}
      <span style={{ marginLeft: 10 }}>{props.valueFormatted ? props.valueFormatted : props.value} {changePercentage !== 0 && <small style={{ color, transform: 'translateY(-25%) translateX(10%)', display: 'inline-block' }}>{parseInt(changePercentage)}%</small>}</span>
    </div>
  )
}
