import React from 'react';
import {Button, Modal, Icon} from "antd";
import * as _ from 'lodash';


class ModalConfirm extends React.Component {
    render() {
        return (
            <Modal
                className={'ant-modal-confirm ant-modal-confirm-info'}
                visible={_.get(this.props, 'visible', false)}
                closable={false}
                cancelText={false}
                footer={false}
            >
                <div className="ant-modal-confirm-body-wrapper">
                    <div className="ant-modal-confirm-body">
                        <Icon type="info-circle" />
                        {_.has(this.props, 'title') && <span className="ant-modal-confirm-title">{this.props.title}</span>}
                        <div className="ant-modal-confirm-content">
                            {_.get(this.props, 'children', '')}
                        </div>
                    </div>
                    <div className="ant-modal-confirm-btns">
                        <Button type="primary" {..._.get(this.props, 'okProps', {})} onClick={() => _.invoke(this.props, 'onOk')}>{_.get(this.props, 'okText', 'OK')}</Button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ModalConfirm;
