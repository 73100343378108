import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import * as _ from 'lodash'
import MainLayout from 'Hoc/MainLayout'
import { StyledH1 } from 'Common/styled'
import { SearchFamilyFilters } from 'Components/SearchFamilyFilters'
import SearchFamilyResults from 'Components/SearchFamilyResults'
import { StyledRow } from './styled'
import { Col } from 'antd'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'
import AppAccess from 'Common/appAccess'
import ErrorPage from 'Components/ErrorPage'
import { saveFamilyFilters } from 'Actions/search-actions'
import {
  fetchCategories,
  fetchDepartments,
  fetchStores,
  fetchSubcategories,
  fetchSubdepartments
} from 'Actions/data-actions'
import Loader from 'Components/Loader'
import moment from 'moment'

/**
 * Container for opecom search page
 */
class SearchFamily extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    isLoading: true,
    redirectTo: null,
    isSearching: false,
    showResults: false,
    currentPage: 1,
    items: null,
    totalItems: null,
    status: null,
    filters: {},
  }

  componentDidMount = () => {
    if (!AppAccess.isAuthorized('family')) {
      this.setState({ status: '403' })
    } else {
      const referrer = this.props.location.state
        ? this.props.location.state.referrer
        : undefined
      const prevSearch = this.props.prevSearch

      if (referrer === '/edit-opecom' && prevSearch.filters) {
        this.setState(prevSearch)
        const { code, description, startDate, endDate } = prevSearch.filters
        api.opecom
          .get(
            code,
            description,
            startDate,
            endDate,
            prevSearch.currentPage - 1,
            10
          )
          .then(response =>
            this.setState({
              items: response.data.elements,
              totalItems: response.data.numTotElements,
              showResults: true,
              isSearching: false,
            })
          )
          .catch(err => {
            this.setState({ isSearching: false })
            handleServerError(err)
          })
      }
    }

    this.props.fetchDepartments();

    if (this.props.stores.length === 0) {
      this.props.fetchStores().then(() =>
        this.setState({
          isLoading: false
        })
      )
    } else {
      this.setState({
        isLoading: false
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form } = this.filters.props
    form.validateFields((err, values) => {
      if (!err) {
        const filters = {
          idFamiglia: null,
          nomeFamiglia: null,
          idReparto: null,
          idSottoreparto: null,
          idTipo: null,
          idSottotipo: null,
          inizioValidita: null,
          fineValidita: null
        };

        if (values.familyId) {
          filters.idFamiglia = values.familyId;
        }

        if (values.familyName && values.familyName.length > 0) {
          filters.nomeFamiglia = values.familyName;
        }

        if (values.department) {
          filters.idReparto = values.department;
        }

        if (values.subdepartment) {
          filters.idSottoreparto = values.subdepartment;
        }

        if (values.category) {
          filters.idTipo = values.category;
        }

        if (values.subcategory) {
          filters.idSottotipo = values.subcategory;
        }

        if (values.validityStart && moment(values.validityStart).isValid()) {
          filters.inizioValidita = values.validityStart;
        }

        if (values.validityEnd && moment(values.validityEnd).isValid()) {
          filters.fineValidita = values.validityEnd;
        }

        this.setState({ isSearching: true, filters: _.cloneDeep(filters) });
        api.families.search(filters, this.state.currentPage, 10, true).then(response => {
          const { data } = response;

          this.setState({
            items: data.data || [],
            totalItems: data.totalElements,
            showResults: true,
            isSearching: false,
            currentPage: 1
          });
        }).catch(error => {
          this.setState({
            isSearching: false
          });
          handleServerError(error);
        });
      }
    })
  }

  handleViewClick = (item) => {
    // this.props.saveFamilyFilters({
    //   filters: this.state.filters,
    //   currentPage: this.state.currentPage,
    // })
    this.setState({ redirectTo: `/view-family/`+ item.idFamiglia})
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page, isSearching: true });
    api.families.search(this.state.filters, page, 10, true).then(response => {
      const { data } = response;

      this.setState({
        items: data.data || [],
        isSearching: false
      });
    }).catch(error => {
      this.setState({
        isSearching: false
      });
      handleServerError(error);
    });
  }

  /**
   * Render method
   * @returns {*}
   */
  render() {
    const { stores, departments } = this.props

    const {
      isLoading,
      redirectTo,
      showResults,
      items,
      totalItems,
      currentPage,
      isSearching,
      status,
      filters,
    } = this.state

    return isLoading ? (
      <Loader />
    ) : redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : status ? (
      <ErrorPage status={status} />
    ) : (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        defaultCurrentPage={'family'}>
        <StyledH1>Ricerca famiglia merceologica</StyledH1>
        <StyledRow type="flex" justify="center">
          <Col xs={22} xl={18} xxl={12}>
            <SearchFamilyFilters
              wrappedComponentRef={ref => {
                this.filters = ref
              }}
              onSubmit={this.handleSubmit}
              isSearching={isSearching}
              departments={departments}
              filters={filters}
              //   user={user}
            />
          </Col>
        </StyledRow>
        {showResults && (
          <SearchFamilyResults
            items={items}
            onViewClick={this.handleViewClick}
            onPageChange={this.handlePageChange}
            totalItems={totalItems}
            currentPage={currentPage}
            stores={stores}
          />
        )}
      </MainLayout>
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => {
  return {
    prevSearch: state.Search.opecom,
    stores: state.Data.stores,
    departments: state.Data.departments
  }
}
/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => {
  return {
    saveFamilyFilters: filters => dispatch(saveFamilyFilters(filters)),
    fetchStores: () => dispatch(fetchStores()),
    fetchDepartments: () => dispatch(fetchDepartments())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchFamily))
