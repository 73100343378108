import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import MainLayout from 'Hoc/MainLayout'
import { StyledH1 } from 'Common/styled'
import AppAccess from 'Common/appAccess'
import ErrorPage from 'Components/ErrorPage'
import { fetchProductIds, fetchStores } from 'Actions/data-actions'
import Loader from 'Components/Loader'
import { Col, Button, DatePicker, Descriptions, Icon, Input, Row, Select, Modal, InputNumber } from 'antd'
import { SearchOpecomFilters } from 'Components/SearchOpecomFilters'
import { StyledRow } from 'Containers/SearchOpecom/styled'
import {
  CollapsedFormMask,
  StyledButton,
  StyledCollapse, StyledForm,
  StyledFormItem,
  StyledFormRow
} from 'Components/SearchOpecomFilters/styled'
import { DATE_LOCALE } from 'Common/globals'
import { StyledFormFake } from 'Containers/ProdottiSimbolo/styled'
import api from 'Common/api'
import { getOpecomTitle, handleServerError, handleServerSuccess,feedbackNotification } from 'Common/utils'
import { StyledDescriptions, StyledTitle } from 'Containers/EditOpecomDetails/styled'
import * as moment from 'moment'
import { opecomStates } from 'Common/constants'
import FamiliesTable from 'Containers/Families/FamiliesTable'
import { ROLE_AZ_COMM, ROLE_CS } from 'Common/constants/global'

/**
 * Container for opecom search page
 */
class Families extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    isLoading: true,
    redirectTo: null,
    family: null,
    status: null
  }

  componentDidMount = () => {
    if (!AppAccess.isAuthorized('family')) {
      this.setState({ status: '403' });
      return;
    }

    const newState = {};
    console.log(this.props.match.params.id)
    this.setState({isLoading: false}) 
  }

  goRoot() {
    this.props.history.push(`/`);
  }

  exportDossier = () => {
    const { dossier } = this.props;
    const { visibleColumns } = this.state;
    api.families.export(dossier.id, visibleColumns)
      .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file excel con le referenze del dossier sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
      .catch(handleServerError);
};

handleColumnVisibilityChange = (column) => {
  const {visibleColumns} = this.state;

  if (visibleColumns.indexOf(column.dataIndex) > -1) {
      this.setState({visibleColumns: [...visibleColumns.filter(value => {
          if (column.groupWith) {
              return value !== column.dataIndex && value !== column.groupWith;
          }

          return value !== column.dataIndex;
      })]});
  } else {
      const newVisibleColumns = [...visibleColumns, column.dataIndex];

      if (column.groupWith) {
          newVisibleColumns.push(column.groupWith);
      }

      this.setState({visibleColumns: newVisibleColumns});
  }
};

  getContent() {
    
    const { user } = this.props;
      return (
        <>
          <FamiliesTable user={user} />
        </>
      )
    

    return null
    
  }

 /* getTestata() {
    const { dossier } = this.state;

    return (
      <StyledDescriptions
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Button
              icon="left"
              onClick={() => this.goRoot()}>
              Indietro
            </Button>
            <StyledTitle>
              {dossier.description}
            </StyledTitle>
            {dossier.idStore === 0 && AppAccess.isAuthorized('change_header_prodotti_simbolo') && <Button
              style={{ marginLeft: 'auto' }}
              onClick={this.showHeaderModal}>
              Modifica Testata
            </Button>}
          </div>
        }
        size="small"
        bordered
        column={{ xs: 1, sm: 2, md: 3, xl: 4 }}>
        <Descriptions.Item label="Limite aggiornamento negozi">
          {dossier.changeLimit} {dossier.changeLimit === 1 ? 'giorno' : 'giorni'}
        </Descriptions.Item>
      </StyledDescriptions>
    )
  }*/





  /**
   * Render method
   * @returns {*}
   */
  render() {
    const {
      isLoading,
      redirectTo,
      status
    } = this.state

    return isLoading ? (
      <Loader />
    ) : redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : status !== null ? (
      <ErrorPage status={status} />
    ) : (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        defaultCurrentPage={'family'}>
        <StyledH1>Famiglia Merceologica</StyledH1>
        <Col>
          <Button
              icon="left"
              onClick={() => this.goRoot()}>
              Indietro
          </Button>
        </Col>
       {this.getContent()}
      </MainLayout>
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => {
  return {
    stores: state.Data.stores,
    productIds: state.Data.productIds,
    user: state.User
  }
}
/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => {
  return {
    fetchStores: () => dispatch(fetchStores()),
    fetchProductIds: () => dispatch(fetchProductIds())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Families))
