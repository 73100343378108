import React, { useState, useEffect, useRef } from 'react'
import { StyledH1 } from 'Common/styled'
import MainLayout from 'Hoc/MainLayout'
import Loader from 'Components/Loader'
import ErrorPage from 'Components/ErrorPage'
import AppAccess from 'Common/appAccess'
import SearchProductsForm from 'Components/SearchProductsForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'Store'
import { fetchDepartments, fetchFamilies } from 'Actions/data-actions'
import api from 'Common/api'
import AnagraficaProdottiTable from 'Components/AnagraficaProdottiTable/AnagraficaProdottiTable'
import { Button, Col } from 'antd'

export default function AnagraficaProdotti() {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [products, setProducts] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [searching, setSearching] = useState(false);

  const productsTableRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const departments = useSelector(state => state.Data.departments);
  const families = useSelector(state => state.Data.families);

  useEffect(() => {
    if (!AppAccess.isAuthorized('anagrafica')) {
      setStatus('403');
      return;
    }
  }, [])

  useEffect(() => {
    if (departments.length === 0) {
      dispatch(fetchDepartments());
    }
  }, [departments]);

  useEffect(() => {
    if (families.length === 0) {
      dispatch(fetchFamilies());
    }
  }, [families]);

  useEffect(() => {
    if (departments.length > 0 && families.length > 0 && user) {
      setIsLoading(false);
    }
  }, [departments, families, user]);

  const handleProductSearch = searchObj => {
    setSearching(true);

    api.references.search({
      ...searchObj,
      assignableOnly: false
    }).then(response => {
      setProducts((response.data || []).map(o => ({
        ...o,
        errors: []
      })));
      setShowTable(true);
      setSearching(false);
    }).catch(error => {
      console.error(error);
      setProducts([]);
      setSearching(false);
    });
  }

  if (isLoading) {
    return <Loader />
  }

  if (status) {
    return <ErrorPage status={status} />
  }

  return (
    <MainLayout
      tourSteps={[]}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage={'anagrafica'}>
      <div
        style={{
          display: 'block',
          position: 'relative'
        }}>
        {showTable && (
          <Button
            style={{ left: 0, top: '50%', transform: 'translateY(-50%)', position: 'absolute' }}
            icon="left"
            onClick={() => {
              setProducts([]);
              setShowTable(false);
            }}>
            Indietro
          </Button>
        )}
        <StyledH1>Anagrafica Referenze</StyledH1>
      </div>
      {!showTable && (
        <SearchProductsForm user={user} departments={departments} families={families} onSubmit={handleProductSearch} isSearching={searching} defaultValue={{ state: 'AC' }} disabledFields={{ state: true }} />
      )}
      {showTable && (
        <AnagraficaProdottiTable ref={productsTableRef} user={user} products={products} />
      )}
    </MainLayout>
  )
}
