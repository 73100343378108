import * as actionTypes from '../action-types'

export const initialState = {
  validityPeriods: [],
  departments: [],
  subdepartments: [],
  categories: [],
  subcategories: [],
  families: [],
  dossiers: {},
  stores: [],
  productIds: []
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_VALIDITY_PERIODS:
      return {
        ...state,
        validityPeriods: payload
      }
    case actionTypes.FETCH_DEPARTMENTS:
      return {
        ...state,
        departments: payload
      }
    case actionTypes.FETCH_SUBDEPARTMENTS:
      return {
        ...state,
        subdepartments: payload
      }
    case actionTypes.FETCH_CATEGORIES:
      return {
        ...state,
        categories: payload
      }
    case actionTypes.FETCH_SUBCATEGORIES:
      return {
        ...state,
        subcategories: payload
      }
    case actionTypes.FETCH_FAMILIES:
      return {
        ...state,
        families: payload
      }
    case actionTypes.FETCH_DOSSIERS:
      return {
        ...state,
        dossiers: payload
      }
    case actionTypes.FETCH_STORES:
      return {
        ...state,
        stores: payload
      }
    case actionTypes.FETCH_PRODUCT_IDS:
      return {
        ...state,
        productIds: payload
      }
    default:
      return state
  }
}

export default reducer
