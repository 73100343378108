import React from 'react'
import { Button, Result } from 'antd'
import { Redirect } from 'react-router'

const statuses = {
  '401': '403',
  '403': '403',
  '404': '404',
  '500': '500',
  error: 'error',
}

const titles = {
  '401': '401',
  '403': '403',
  '404': '404',
  '500': '500',
  error: 'Si è verificato un errore',
}

const subTitles = {
  '401': 'Utente non abilitato',
  '403': 'Non sei autorizzato ad accedere a questa pagina',
  '404': 'La pagina che cerchi non esiste.',
  '500': 'Si è verificato un errore sul server.',
  error: 'Riprova più tardi.',
}

const BackHomeButton = props => (
  <Button type="primary" onClick={props.onClick}>
    Torna alla home
  </Button>
)

class ErrorPage extends React.Component {
  state = { redirectHome: false }

  render() {
    const { redirectHome } = this.state
    const { status } = this.props

    return redirectHome ? (
      <Redirect to="/" />
    ) : (
      <Result
        status={statuses[status]}
        title={titles[status]}
        subTitle={subTitles[status]}
        extra={
          ['403', '404', '500'].includes(status) && (
            <BackHomeButton
              onClick={() => this.setState({ redirectHome: true })}
            />
          )
        }
      />
    )
  }
}

export default ErrorPage
