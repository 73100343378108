import React, {useRef, useState, useImperativeHandle, forwardRef} from 'react';
import {Input, InputNumber, DatePicker, Checkbox} from 'antd';
import * as _ from 'lodash';
import * as moment from 'moment'

export default forwardRef((props, ref) => {
  const inputRef = useRef();
  const {colDef, node, stopEditing, data} = props;
  const [value, setValue] = useState(_.get(colDef, 'cellEditorParams.mode', null) === 'multiple' ? [] : '');
  const extra = _.get(colDef, 'cellEditorParams.extra', {});
  const cellEditorParams = _.get(colDef, 'cellEditorParams', {});

  function inputHandler(value) {

    console.log(value);

    if (typeof value === 'string' && value.length === 0) {
      value = null;
    }

    if (cellEditorParams.minValue && typeof cellEditorParams.minValue === 'function' && value !== null && cellEditorParams.minValue(data) > value) {
      value = cellEditorParams.minValue(data);
    }

    if (cellEditorParams.maxValue && typeof cellEditorParams.maxValue === 'function' && value !== null && cellEditorParams.maxValue(data) && cellEditorParams.maxValue(data) < value) {
      value = cellEditorParams.maxValue(data);
    }

    if (typeof value === 'number' && isNaN(Number(value))) {
      value = undefined;
    }

    setValue(value);

    inputRef.current.focus();
  }

  function blurHandler() {
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => {
        setValue(props.value);
        inputRef.current.focus();
      }
    };
  });

  const colType = colDef.type || null;

  switch (colType) {
    case 'moneyColumn':
    case 'numberColumn':
      return <InputNumber ref={inputRef} onBlur={blurHandler} style={{ width: '100%' }} min={0} value={value}
                    onChange={inputHandler} {...extra} />;

    case 'decimalColumn':
      return <InputNumber ref={inputRef} onBlur={blurHandler} style={{ width: '100%' }} min={cellEditorParams.minValue && typeof cellEditorParams.minValue !== 'function' ? cellEditorParams.minValue : 0} max={cellEditorParams.maxValue && typeof cellEditorParams.maxValue !== 'function' ? cellEditorParams.maxValue : undefined}
                    precision={cellEditorParams.precision ? cellEditorParams.precision : 2} decimalSeparator=','
                    value={value}
                    onChange={inputHandler} {...extra} />;

    case 'integerColumn':
      return <InputNumber ref={inputRef} onBlur={blurHandler} style={{ width: '100%' }} min={cellEditorParams.minValue ? cellEditorParams.minValue(data) : 0} max={cellEditorParams.maxValue ? cellEditorParams.maxValue(data) : undefined} precision={0} value={value}
                    onChange={inputHandler} {...extra} />;

    case 'dateColumn':
      return <DatePicker allowClear ref={inputRef} onBlur={blurHandler} format={cellEditorParams.format ? cellEditorParams.format : 'DD/MM/YYYY'} style={{ width: '100%' }} value={value ? moment(value) : null} disabledDate={cellEditorParams.disabledDate ? current => cellEditorParams.disabledDate(current, props) : undefined}
                    onChange={(date) => inputHandler(date ? date.format('YYYY-MM-DD') : null)} {...extra} />;

    case 'boolColumn':
    case 'booleanColumn':
      return <Checkbox ref={inputRef} checked={value === true} onChange={(e) => inputHandler(e.target.checked === true)} />;

    default:
      return <Input ref={inputRef} onBlur={blurHandler} style={{ width: '100%' }} value={value}
                    onChange={(ev) => inputHandler(ev.target.value ? ev.target.value : null)} {...extra} />;
  }
});
