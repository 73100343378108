import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react'
import { agNumberFormatter, num } from 'Common/utils'
import SimulazioneUtils from 'Common/simulazioneUtils'

export default function SimulazioneStatisticsBar({ history, data }) {

  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    setStatistics(SimulazioneUtils.getCalculatedStatistics(data));
  }, [data]);

  return (
    <div className={css`
      padding: 15px 24px;
      margin: 0;
      background-color: #f1f1f1;
      border-radius: 5px;
      display: flex;
      font-size: 14px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}>
      {SimulazioneUtils.getStatisticLabels(history).map(label => (
        <div className={css`margin: 0 15px;`} key={label.field}>
          <span className={css`margin-right: 10px; opacity: 0.5; display: block;`}>{label.name}</span>
          <span className={css`font-weight: bold; display: block;`}>{label.format ? label.format({value: statistics[label.field] || 0}) : agNumberFormatter({value: statistics[label.field] || 0})}</span>
        </div>
      ))}
    </div>
  )
}
