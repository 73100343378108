export const AG_GRID_LOCALE_IT = {
  // Set Filter
  selectAll: '(Seleziona tutto)',
  selectAllSearchResults: '(Select All Search Results)',
  searchOoo: 'Ricerca...',
  blanks: '(Blanks)',
  noMatches: 'Nessuna corrispondenza',
  // Number Filter & Text Filter
  filterOoo: 'Filtro...',
  equals: 'Uguale',
  notEqual: 'Diverso',
  empty: 'Seleziona Un',
  // Number Filter
  lessThan: 'Minore di',
  greaterThan: 'Maggiore di',
  lessThanOrEqual: 'Minore e uguale a ',
  greaterThanOrEqual: 'Maggiore e uguale a',
  inRange: 'E\' compreso',
  inRangeStart: 'Da',
  inRangeEnd: 'A',
  // Text Filter
  contains: 'Contiene',
  notContains: 'Non contiene',
  startsWith: 'Inizia con',
  endsWith: 'Finisce con',
  // Date Filter
  dateFormatOoo: 'dd-mm-yyyy',
  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',
  // Filter Buttons
  applyFilter: 'Applica',
  resetFilter: 'Reset',
  clearFilter: 'Pulisci',
  cancelFilter: 'Cancella',
  // Filter Titles
  textFilter: 'Ricerca testo',
  numberFilter: 'Ricerca per numero',
  dateFilter: 'Ricerca per data',
  setFilter: 'Imposta filtro',
  // Side Bar
  columns: 'Colonne',
  filters: 'Filtri',
  // columns tool panel
  pivotMode: 'Pivot Mode',
  groups: 'Raggruppa righe',
  rowGroupColumnsEmptyMessage: 'Trascina qui per impostare raggruppamento righe',
  values: 'Valori',
  valueColumnsEmptyMessage: 'Trascina qui per raggruppare',
  pivots: 'Descrizione colonne',
  pivotColumnsEmptyMessage: 'Trascina qui per impostare le colonne',
  // Header of the Default Group Column
  group: 'Group',
  // Other
  loadingOoo: 'Caricamento...',
  noRowsToShow: 'Nessun risultato',
  enabled: 'Abilitato',
  // Menu
  pinColumn: 'Blocca Colonna',
  pinLeft: 'A sinistra',
  pinRight: 'A destra',
  noPin: 'Reset',
  valueAggregation: 'Raggruppa valori',
  autosizeThiscolumn: 'Ridimensiona colonna',
  autosizeAllColumns: 'Ridimensiona tutte le colonne',
  groupBy: 'Raggruppa',
  ungroupBy: 'Non raggruppare',
  resetColumns: 'Reset',
  expandAll: 'Espandi tutto',
  collapseAll: 'Chiudi tutto',
  copy: 'Copia',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copia con intestazioni',
  paste: 'Incolla',
  ctrlV: 'Ctrl+V',
  export: 'Export',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export (.xlsx)',
  excelXmlExport: 'Excel Export (.xml)',
  // Enterprise Menu Aggregation and Status Bar
  sum: 'Somma',
  min: 'Minore',
  max: 'Massimo',
  none: 'Nessuno',
  count: 'Conteggio',
  avg: 'Media',
  filteredRows: 'Righe filtrate',
  selectedRows: 'Righe selezionate',
  totalRows: 'Righe totali',
  totalAndFilteredRows: 'Righe',
  more: 'più',
  to: 'a',
  of: 'di',
  page: 'Pagina',
  nextPage: 'Successiva',
  lastPage: 'Ultima',
  firstPage: 'Prima',
  previousPage: 'Precedente',
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',
  columnChart: 'Column',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',
  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',
  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',
  line: 'Line',
  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',
  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',
  histogramChart: 'Histogram',
  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.'
}
