import { Button, Dropdown, Menu } from 'antd'
import React from 'react';
import { css } from '@emotion/css';

const containerStyle = css`
  width: 100%;
  height: auto;
  text-align: center;
`;

export default function contextMenuRenderer(props) {

  const menu = (
    <Menu onClick={param => props.colDef.cellRendererParams.onMenuItemClick(param.key, props.data, props.rowIndex)}>
      {props.colDef.cellRendererParams.items.filter(item => item.isVisible ? item.isVisible(props) : true).map(item => <Menu.Item key={item.key}>{item.label}</Menu.Item>)}
    </Menu>
  );

  return (
    <div className={containerStyle}>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button icon="more" shape="circle" />
      </Dropdown>
    </div>
  )
}
