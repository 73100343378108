import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as _ from 'lodash'
import styles from './Home.module.css'

import { setTour } from 'Actions/tour-actions'
import {
  fetchValidityPeriods,
  fetchDepartments,
  fetchFamilies,
  fetchDossiers
} from 'Actions/data-actions'
import { editFamily } from 'Actions/edit-actions'

import MainLayout from 'Hoc/MainLayout'
import { StyledH1, StyledDiv, StyledButton } from './styled'
import { CreateFamilyModal } from 'Components/CreateFamilyModal'
import { EditFamilyModal } from 'Components/EditFamilyModal'
import Loader from 'Components/Loader'

import { steps } from './constants'
import { TOUR_HOME } from 'Common/constants'
import { withRouter } from 'react-router'
import AppAccess from "Common/appAccess";
import { Content } from 'Components/SubdepartmentRow/styled'
import { Button, Row, Col, Modal, Select } from 'antd'
import { feedbackNotification } from 'Common/utils'
import api from 'Common/api'

class Home extends Component {
  static propTypes = {
    tour: PropTypes.object,
    setTour: PropTypes.func,
    fetchValidityPeriods: PropTypes.func,
    fetchDepartments: PropTypes.func,
    editFamily: PropTypes.func
  }

  state = {
    isLoading: true,
    isCreateModalVisible: false,
    isEditModalVisible: false,
    editedFamilyId: null,
    selectedNavigationItem: null,
    redirectTo: null,
    modalDossierReport: {
      visible: false,
      selectedDepartments: []
    }
  }

  defaultCurrentPage = null

  componentDidMount() {
    if (!AppAccess.isAuthorized('dossier') && !AppAccess.isAuthorized('family') && AppAccess.isAuthorized('opecom')) {
      this.setState({ redirectTo: '/search-opecom' });
    }
    
    this.defaultCurrentPage = _.get(
      this.props,
      'location.state.currentPage',
      null
    )
    Promise.all([
      this.props.fetchDepartments(),
      this.props.fetchFamilies()
    ]).then(() => this.setState({ isLoading: false }))
  }

  showCreateModal = () => {
    this.setState({ isCreateModalVisible: true })
  }

  showEditModal = () => {
    this.setState({ isEditModalVisible: true })
  }

  showViewModal = () => {
    this.setState({
      redirectTo: '/view-family'
    });
  }

  handleCreateModalCancel = () => {
    this.setState({ isCreateModalVisible: false })
  }

  handleEditModalCancel = () => {
    this.setState({ isEditModalVisible: false })
  }

  handleCreateModalOk = () => {
    const { form } = this.createModal.props
    form.validateFields((err, values) => {
      if (!err) {
        values.name = values.name.trim()
        const family = { ...values, subdepartments: [] }
        this.props.editFamily(family)
        this.setState({
          isCreateModalVisible: false,
          redirectTo: '/new-family'
        })
      }
    })
  }

  handleEditModalOk = () => {
    const { form } = this.editModal.props
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isEditModalVisible: false,
          redirectTo: `/edit-family/${values.family}`
        })
      }
    })
  }

  onNavigationChange = val => {
    this.setState({
      selectedNavigationItem: val
    })
  }

  handleCreateDossier = () => {
    this.setState({
      redirectTo: '/new-dossier'
    })
  }

  handleEditDossier = () => {
    this.setState({
      redirectTo: '/search-dossier'
    })
  }

  getContentFamily = () => {
    const { departments, families } = this.props
    return (
      <>
        <StyledH1>Gestione famiglia</StyledH1>
        <StyledDiv>
          <StyledButton onClick={this.showCreateModal}>
            Crea nuova famiglia merch
          </StyledButton>
          <StyledButton onClick={this.showEditModal}>
            Modifica famiglia merch esistente
          </StyledButton>
          <StyledButton onClick={this.showViewModal}>
            Visualizza famiglie
          </StyledButton>
        </StyledDiv>
        <CreateFamilyModal
          wrappedComponentRef={ref => {
            this.createModal = ref
          }}
          visible={this.state.isCreateModalVisible}
          onOk={this.handleCreateModalOk}
          onCancel={this.handleCreateModalCancel}
          departments={departments}
        />
        <EditFamilyModal
          wrappedComponentRef={ref => {
            this.editModal = ref
          }}
          visible={this.state.isEditModalVisible}
          onOk={this.handleEditModalOk}
          onCancel={this.handleEditModalCancel}
          families={families}
          departments={departments}
        />
      </>
    )
  }

  handleCancelModalDossierReport = () => {
    const { modalDossierReport } = this.state;

    this.setState({
      modalDossierReport: {
        ...modalDossierReport,
        visible: false
      }
    });
  };

  handleOkModalDossierReport = () => {
    const { modalDossierReport } = this.state;

    api.dossiers.sendDossierReport(modalDossierReport.selectedDepartments).then(() => {
      feedbackNotification('success', 'Operazione completata', 'Abbiamo inviato la richiesta di invio report e lo riceverai a breve nella tua casella postale');
      this.setState({
        modalDossierReport: {
          ...modalDossierReport,
          visible: false
        }
      });
    }).catch(error => {
      console.error(error);
      feedbackNotification('error', 'Operazione fallita', 'La richiesta di invio report non è andata a buon fine');
    });
  };

  handleModalDossierReportDepartmentsChange = value => {
    const { modalDossierReport } = this.state;

    if (!value) {
      this.setState({
        modalDossierReport: {
          ...modalDossierReport,
          selectedDepartments: []
        }
      });

      return;
    }

    this.setState({
      modalDossierReport: {
        ...modalDossierReport,
        selectedDepartments: [value]
      }
    });
  };

  handleModalDossierReportShow = value => {
    const { modalDossierReport } = this.state;

    this.setState({
      modalDossierReport: {
        ...modalDossierReport,
        visible: true
      }
    });
  };

  getContentDossier = () => {
    const { departments } = this.props;

    return (
      <>
        <StyledH1>Gestione dossier</StyledH1>
        <StyledDiv>
          {
            AppAccess.isAuthorized('create_dossier') &&
            <StyledButton onClick={this.handleCreateDossier}>
              Crea nuovo dossier
            </StyledButton>
          }
          <StyledButton onClick={this.handleEditDossier}>
            Ricerca dossier
          </StyledButton>
        </StyledDiv>
        
        {this.props.userAuth && this.props.userAuth.dossiersReport && (
          <>
            <Content className={styles.commandContainer}>
              <Row gutter={16} style={{ width: '100%' }}>
                <Col span={24}>
                  <hr style={{ marginBottom: 25, borderColor: 'rgba(0, 0, 0, 0.15)' }} />
                  <Button className={styles.commandButton} onClick={this.handleModalDossierReportShow}>Invio report dossier</Button>
                </Col>
              </Row>
            </Content>
            <Modal
              title="Invio report dossier"
              visible={this.state.modalDossierReport.visible}
              onOk={this.handleOkModalDossierReport}
              onCancel={this.handleCancelModalDossierReport}
            >
              <Select onSelect={this.handleModalDossierReportDepartmentsChange} style={{ width: '100%' }} defaultValue={null}>
                <Select.Option value={null}>Tutti i reparti</Select.Option>
                {departments.map(department => (
                  <Select.Option key={`dossier-id-${department.id}`} value={department.id}>{(department.id + '').padStart(2, '0')} - {department.label}</Select.Option>
                ))}
              </Select>
            </Modal>
          </>
        )}
      </>
    )
  }

  getContent = () => {
    if (this.props.location.pathname === '/dossier') {
      return this.getContentDossier();
    }

    if (this.props.location.pathname === '/family') {
      return this.getContentFamily();
    }

    switch (this.state.selectedNavigationItem) {
      case 'family':
        if (!AppAccess.isAuthorized('family')) {
          return '';
        }

        return this.getContentFamily()

      case 'dossier':
        return this.getContentDossier()

      default:
        return ''
    }
  }

  render() {
    const { /* tour, */ setTour } = this.props
    const { isLoading, redirectTo } = this.state
    // const isTourAvailable = TOUR_HOME in tour
    // const isTourOpen = isTourAvailable && !tour[TOUR_HOME]
    return isLoading ? (
      <Loader />
    ) : redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : (
      <MainLayout
        tourSteps={steps}
        isTourAvailable={false}
        isTourOpen={false}
        onNavigationChange={this.onNavigationChange}
        defaultCurrentPage={this.defaultCurrentPage}
        closeTour={() => setTour(TOUR_HOME)}>
        {this.getContent()}
      </MainLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    tour: state.Tour,
    departments: state.Data.departments,
    families: state.Data.families,
    dossiers: state.Data.dossiers,
    user: state.User,
    userAuth: state.User.specialAuth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTour: value => dispatch(setTour(value)),
    fetchValidityPeriods: () => dispatch(fetchValidityPeriods()),
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchFamilies: () => dispatch(fetchFamilies()),
    editFamily: family => dispatch(editFamily(family)),
    fetchDossiers: () => dispatch(fetchDossiers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
