import { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchDepartments, fetchSubdepartments } from 'Actions/data-actions'
import api from 'Common/api'
import moment from 'moment'
import { forkJoin, from } from 'rxjs'
import { getFromSession } from 'Common/utils'

const DEFAULT_FORM_VALUE = {
  dateFrom: undefined,
  dateTo: undefined,
  departmentId: null,
  subDepartmentId: [],
  categoryId: [],
  subCategoryId: [],
  gammaAStores: 50,
  gammaABStores: 50,
  gammaABCStores: 50,
  familyId: undefined,
  title: undefined
};

export default function SimulazioneFilters({ onChange, create }) {

  const dispatch = useDispatch();
  const departments = useSelector(store => store.Data.departments);
  const [subDepartments, setSubDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subDepartmentsMap, setSubDepartmentsMap] = useState({});
  const [categoriesMap, setCategoriesMap] = useState({});
  const [families, setFamilies] = useState([]);
  const [formValue, setFormValue] = useState({ ...DEFAULT_FORM_VALUE });

  useEffect(() => setSubDepartmentsMap(subDepartments.reduce((p, c) => ({ ...p, [c.id]: c.label }), {})), [subDepartments]);
  useEffect(() => setCategoriesMap(categories.reduce((p, c) => ({ ...p, [c.id]: c.label }), {})), [categories]);

  useEffect(() => {
    if (onChange) {
      onChange(formValue);
    }
  }, [formValue]);

  useEffect(() => {
    const sessionFilters = getFromSession('Simulazione.Filters');

    if (departments.length === 0) {
      dispatch(fetchDepartments());
    }

    const newFormValue = {
      ...formValue
    };

    if (create) {
      const currentYear = moment().format('YYYY');
      newFormValue.dateFrom = moment().subtract(1, 'year');
      newFormValue.dateTo = moment();
    }

    if (sessionFilters) {
      newFormValue.departmentId = sessionFilters.departmentId;
      newFormValue.subDepartmentId = sessionFilters.subDepartmentId || [];
      newFormValue.categoryId = sessionFilters.categoryId || [];
      newFormValue.subCategoryId = sessionFilters.subCategoryId || [];

      if (sessionFilters.departmentId && sessionFilters.departmentId > 0) {
        api
          .subdepartments
          .get(sessionFilters.departmentId)
          .then(response => setSubDepartments(response.data))
          .catch(console.error);
      }

      if (sessionFilters.subDepartmentId && sessionFilters.subDepartmentId.length > 0) {
        forkJoin(
          sessionFilters.subDepartmentId.map(subDepartmentId => from(
            api
              .categories
              .get(sessionFilters.departmentId, subDepartmentId)
          ))
        ).subscribe(results => {
          const arr = [];

          results.forEach((v, i) => {
            arr.push(...v.data.map(o => ({ id: `${sessionFilters.subDepartmentId[i]}.${o.id}`, label: o.label, parentId: sessionFilters.subDepartmentId[i] })));
          });

          setCategories(arr);
        });
      }

      if (sessionFilters.categoryId && sessionFilters.categoryId.length > 0) {
        const valueMap = {};

        for (const item of sessionFilters.categoryId) {
          const split = item.split('.');
          const subDepartmentId = Number(split[0]);
          const categoryId = Number(split[1]);

          if (!valueMap.hasOwnProperty(subDepartmentId)) valueMap[subDepartmentId] = [];

          valueMap[subDepartmentId].push(categoryId);
        }

        const entries = Object.entries(valueMap);

        forkJoin(
          entries.map(([k, v]) => from(
            api
              .subcategories
              .get(sessionFilters.departmentId, Number(k), v, false)
          ))
        ).subscribe(results => {
          const arr = [];

          results.forEach((v, i) => {
            for (const obj of v.data) {
              const subDepartmentId = entries[i][0];
              const categoryId = obj.categoryId;
              const subCategoryId = obj.subCategoryId;

              const id = `${sessionFilters.departmentId}.${subDepartmentId}.${categoryId}.${subCategoryId}`;

              arr.push({ id, label: obj.label, parentId: `${subDepartmentId}.${categoryId}` })
            }

          });

          setSubCategories(arr);
        });
      }
    }

    setFormValue({
      ...newFormValue
    });
  }, []);

  const onValueChange = (key, value) => {
    const newFormValue = {
      ...formValue,
      [key]: value
    };

    if (key === 'departmentId' && value && value > 0) {
      setCategories([]);
      setSubCategories([]);

      api
        .subdepartments
        .get(value)
        .then(response => setSubDepartments(response.data))
        .catch(console.error);
    } else if (key === 'departmentId') {
      setSubDepartments([]);
      setCategories([]);
      setSubCategories([]);
      newFormValue.subDepartmentId = [];
      newFormValue.categoryId = [];
      newFormValue.subCategoryId = [];
    }

    if (key === 'subDepartmentId' && value && value.length > 0) {
      setCategories([]);
      setSubCategories([]);

      forkJoin(
        value.map(subDepartmentId => from(
          api
            .categories
            .get(formValue.departmentId, subDepartmentId)
        ))
      ).subscribe(results => {
        const arr = [];

        results.forEach((v, i) => {
          arr.push(...v.data.map(o => ({ id: `${value[i]}.${o.id}`, label: o.label, parentId: value[i] })));
        });

        setCategories(arr);
      });

      newFormValue.categoryId = [];
      newFormValue.subCategoryId = [];
    } else if (key === 'subDepartmentId') {
      setCategories([]);
      setSubCategories([]);
      newFormValue.categoryId = [];
      newFormValue.subCategoryId = [];
    }

    if (key === 'categoryId' && value && value.length > 0) {
      setSubCategories([]);

      const valueMap = {};

      for (const item of value) {
        const split = item.split('.');
        const subDepartmentId = Number(split[0]);
        const categoryId = Number(split[1]);

        if (!valueMap.hasOwnProperty(subDepartmentId)) valueMap[subDepartmentId] = [];

        valueMap[subDepartmentId].push(categoryId);
      }

      const entries = Object.entries(valueMap);

      forkJoin(
        entries.map(([k, v]) => from(
          api
            .subcategories
            .get(formValue.departmentId, Number(k), v, false)
        ))
      ).subscribe(results => {
        const arr = [];

        results.forEach((v, i) => {
          for (const obj of v.data) {
            const subDepartmentId = entries[i][0];
            const categoryId = obj.categoryId;
            const subCategoryId = obj.subCategoryId;

            const id = `${formValue.departmentId}.${subDepartmentId}.${categoryId}.${subCategoryId}`;

            arr.push({ id, label: obj.label, parentId: `${subDepartmentId}.${categoryId}` })
          }

        });

        setSubCategories(arr);
      });

      newFormValue.subCategoryId = [];
    } else if (key === 'categoryId') {
      setSubCategories([]);
      newFormValue.subCategoryId = [];
    }

    setFormValue(newFormValue);
  };

  return (
    <>
      <Row gutter={16}>
        {create && <Col md={22} lg={8}>
          <Form.Item label="Titolo*">
            <Input
              value={formValue.title}
              placeholder="Inserisci"
              onChange={ev => onValueChange('title', ev.target.value)}
            />
          </Form.Item>
        </Col>}
        <Col md={22} lg={4}>
          <Form.Item label="Data da*">
            <DatePicker
              placeholder="Seleziona data"
              allowClear
              disabled={create}
              value={formValue.dateFrom}
              onChange={(date) => onValueChange('dateFrom', date)}
            />
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label="Data a*">
            <DatePicker
              value={formValue.dateTo}
              disabled={create}
              placeholder="Seleziona data"
              disabledDate={currentDate => formValue.dateFrom ? currentDate.isBefore(moment(formValue.dateFrom).add(1, 'day')) : true}
              onChange={(date) => onValueChange('dateTo', date)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={22} lg={4}>
          <Form.Item label="Reparto*">
            <Select
              style={{ width: '100%' }}
              placeholder="Seleziona"
              value={formValue.departmentId}
              onChange={value => onValueChange('departmentId', Number(value))}
            >
              {departments.map(department => (
                <Select.Option key={`department-${department.id}`} value={department.id}>{department.id.toString().padStart(2, '0')} - {department.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label="Sotto reparto">
            <Select
              disabled={formValue.departmentId < 1 || !formValue.departmentId}
              style={{ width: '100%' }}
              placeholder="Seleziona"
              mode="multiple"
              value={formValue.subDepartmentId}
              onChange={value => onValueChange('subDepartmentId', value)}
            >
              {subDepartments.map(subDepartment => (
                <Select.Option key={`subDepartment-${subDepartment.id}`} value={subDepartment.id}>{subDepartment.id.toString().padStart(2, '0')} - {subDepartment.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label="Tipo">
            <Select
              disabled={formValue.subDepartmentId.length === 0}
              style={{ width: '100%' }}
              value={formValue.categoryId}
              mode="multiple"
              onChange={value => onValueChange('categoryId', value)}
              placeholder="Seleziona"
            >
              {categories.map(o => o.parentId).filter((v, i, s) => s.indexOf(v) === i).map(parentId => (
                <Select.OptGroup key={parentId} label={subDepartmentsMap[parentId]}>
                  {categories.filter(v => v.parentId === parentId).map(category => (
                    <Select.Option key={`category-${category.id}`} value={category.id}>{category.id.toString().substring(category.id.toString().lastIndexOf('.') + 1).padStart(2, '0')} - {category.label}</Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label="Sottotipo">
            <Select
              disabled={formValue.categoryId.length === 0}
              style={{ width: '100%' }}
              placeholder="Seleziona"
              mode="multiple"
              value={formValue.subCategoryId}
              onChange={value => onValueChange('subCategoryId', value)}
            >
              {subCategories.map(o => o.parentId).filter((v, i, s) => s.indexOf(v) === i).map(parentId => (
                <Select.OptGroup key={parentId} label={categoriesMap[parentId]}>
                  {subCategories.filter(v => v.parentId === parentId).map(subCategory => (
                    <Select.Option key={`subCategory-${subCategory.id}`} value={subCategory.id}>{subCategory.id.toString().substring(subCategory.id.toString().lastIndexOf('.') + 1).padStart(2, '0')} - {subCategory.label}</Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {create && <Col md={22} lg={4}>
          <Form.Item label="Famiglia">
            <Select
              disabled={formValue.familyId < 1 || !formValue.familyId}
              style={{ width: '100%' }}
              value={formValue.familyId}
              onChange={value => onValueChange('familyId', Number(value))}
              placeholder="Seleziona"
            >
              {families.map(family => (
                <Select.Option key={`family-${family.id}`} value={family.id}>{family.id.toString().padStart(2, '0')} - {family.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>}
      </Row>
      {create && <Row gutter={16}>
        <Col md={22} lg={4}>
          <Form.Item label="Negozi con gamma A*">
            <InputNumber
              style={{ width: '100%' }}
              value={formValue.gammaAStores}
              onChange={value => onValueChange('gammaAStores', value)}
              placeholder="Inserisci"
              max={50}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label="Negozi con gamma A+B*">
            <InputNumber
              style={{ width: '100%' }}
              value={formValue.gammaABStores}
              onChange={value => onValueChange('gammaABStores', value)}
              placeholder="Inserisci"
              max={50}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label="Negozi con gamma A+B+C*">
            <InputNumber
              style={{ width: '100%' }}
              value={formValue.gammaABCStores}
              onChange={value => onValueChange('gammaABCStores', value)}
              placeholder="Inserisci"
              max={50}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>}
    </>
  )
}
