import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Select } from 'antd'
import { CellBox } from 'Components/DossierUnico/DossierUnicoData'

const AgListCellEditor = memo(
  forwardRef((props, ref) => {
    const { value: initialValue, colDef, stopEditing } = props
    const { cellEditorParams } = colDef
    const [value, setValue] = useState(initialValue)
    const refInput = useRef(null)

    useEffect(() => {
      refInput.current.focus()
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
        isCancelBeforeStart() {
          return cellEditorParams?.isCancelBeforeStart?.(props) ?? false
        },
        isCancelAfterEnd() {
          return cellEditorParams?.isCancelAfterEnd?.(props) ?? false
        },
      }
    })

    return (
      <CellBox>
        <Select
          onChange={setValue}
          value={value}
          ref={refInput}
          onBlur={() => stopEditing?.()}
          style={{ width: '100%' }}>
          {cellEditorParams?.options?.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </CellBox>
    )
  }),
)

export default AgListCellEditor;
