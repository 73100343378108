import React, { useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout';
import styles from './Simulazione.module.scss';
import { StyledH1 } from 'Common/styled'
import { Button, Card, Col, Row } from 'antd';
import SimulazioneFilters from 'Components/SimulazioneFilters/SimulazioneFilters'
import api from 'Common/api'
import { getFromSession, setToSession } from 'Common/utils'

const REQUIRED_FILTERS = ['dateFrom', 'dateTo', 'title', 'departmentId', 'gammaAStores', 'gammaABStores', 'gammaABCStores'];

export default function SimulazioneCreate () {

  const [filtersValue, setFiltersValue] = useState({});

  const onFiltersValueChange = value => setFiltersValue({ ...value });

  const isFormValid = () => {
    if (!filtersValue) return false;

    for (const field of REQUIRED_FILTERS) {
      if (!filtersValue.hasOwnProperty(field)) return false;

      const value = filtersValue[field];

      if (typeof value === 'number' && (value < 0 || isNaN(value))) return false;

      if (typeof value !== 'number' && !value) return false;

      if (typeof value === 'string' && value.length === 0) return false;
    }

    return true;
  }

  const handleCreate = () => {
    if (!isFormValid()) return;

    const payload = {
      dateFrom: filtersValue.dateFrom.format('YYYY-MM-DD'),
      dateTo: filtersValue.dateTo.format('YYYY-MM-DD'),
      title: filtersValue.title,
      departmentId: filtersValue.departmentId,
      subDepartmentIdList: filtersValue.subDepartmentId || [],
      categoryIdList: filtersValue.categoryId || [],
      subCategoryIdList: filtersValue.subCategoryId || [],
      storesA: filtersValue.gammaAStores,
      storesAb: filtersValue.gammaABStores,
      storesAbc: filtersValue.gammaABCStores,
      familyId: filtersValue.familyId
    };

    setToSession('Simulazione.CreaPayload', payload);

    api.simulazione.creaHeader(payload).then(response => {
      setToSession('Simulazione.CreaSimulationId', response.data.id);
      window.location.href = '/simulazione/detail/' + response.data.id;
    }).catch(console.error);
  }

  const getFiltersContent = () => {
    return (
      <>
        <Row type="flex" justify="center" align="center" style={{ marginBottom: 50 }}>
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                icon="left"
                onClick={() => window.location.href = '/simulazione'}>
                Indietro
              </Button>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <StyledH1 className={styles.title}>Simulazione Nuove Gamme</StyledH1>
                <h3 className={styles.subtitle} style={{ marginBottom: 0 }}>Definisci il perimetro d'azione per visualizzare lo storico</h3>
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="center">
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <Card className={styles.filtersCard}>
              <SimulazioneFilters onChange={onFiltersValueChange} create />
              <div className={styles.buttonsBar}>
                <Button type="primary" disabled={!isFormValid()} onClick={handleCreate}>Crea</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="simulazione">
      <div className={styles.root}>
        {getFiltersContent()}
      </div>
    </MainLayout>
  )
}
