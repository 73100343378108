import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Alert, InputNumber, List } from 'antd'
import { useSelector } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import Loader from 'Components/Loader'
import { AG_GRID_LOCALE_IT } from 'Common/ag-grid.locale'

const NumberEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(parseInt(props.value));
  const refInput = useRef(null);

  useEffect(() => {
    setTimeout(() => refInput.current.focus());
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return isNaN(Number(value)) ? null : Number(value);
      }
    };
  });

  return (
    <div className="ag-cell-edit-wrapper">
      <div className="ag-cell-editor ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div className="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input type="number"
                 className="ag-input-field-input ag-text-field-input"
                 ref={refInput}
                 value={value}
                 onChange={event => setValue(event.target.value)}
                 style={{width: "100%"}}
          />
        </div>
      </div>
    </div>
  );
});

export default function OpecomQtaImpegnataModalContent(props) {
  const { cellRendererParams } = props;
  const stores = useSelector(state => state.Data.stores || []);
  const [showAlert, setShowAlert] = useState(false);
  const [totalStock, setTotalStock] = useState(props.defaultValue.reduce((acc, curr) => acc + curr.qta, 0));
  const gridOptions = {
    localeText: AG_GRID_LOCALE_IT,
    columnDefs: [
      {
        headerName: 'Negozio',
        field: 'storeId',
        flex: 1,
        lockPosition: true,
        lockVisible: true,
        sortable: true,
        filter: true,
        editable: false,
        resizable: false,
        suppressMenu: true,
        suppressMovable: true,
        floatingFilter: true,
        valueFormatter: params => params.data.storeLabel
      },
      {
        headerName: 'Quantità',
        field: 'quantity',
        flex: 1,
        editable: cellRendererParams.canEditQtaImpegnata ? () => cellRendererParams.canEditQtaImpegnata(props.parentProps.data) : false,
        cellEditor: 'numberEditor',
        cellClass: 'ag-cell-custom-input-editor',
        lockPosition: true,
        lockVisible: true,
        sortable: false,
        resizable: false,
        suppressMenu: true,
        suppressMovable: true,
        floatingFilter: false,
        cellStyle: {
          textAlign: 'right'
        }
      }
    ],
    enableRangeSelection: true,
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    rowData: stores.map(store => {
      let quantity = props.defaultValue.find(o => o.storeId === store.id);

      if (quantity) {
        quantity = Number(quantity.qta);
      }

      return {
        storeId: store.id,
        storeName: store.name,
        storeLabel: store.id + ' - ' + store.name,
        quantity: isNaN(quantity) ? 0 : quantity
      };
    }),
    frameworkComponents: {
      numberEditor: NumberEditor
    },
    onCellValueChanged: ev => {
      let qtaImpegnata = 0;
      const updatedValue = [];

      ev.api.forEachNode((rowNode, index) => {
        const quantity = Number(rowNode.data.quantity);
        qtaImpegnata += isNaN(quantity) ? 0 : quantity;

        if (quantity > 0) {
          updatedValue.push({
            storeId: rowNode.data.storeId,
            qta: quantity
          });
        }
      });

      if (qtaImpegnata > props.parentProps.data.prevVtot) {
        ev.node.setDataValue('quantity', ev.oldValue);
        triggerAlert();
      } else {
        updatedValue.sort((a, b) => a.storeId > b.storeId ? 1 : -1);

        setTotalStock(qtaImpegnata);
        props.updateTotalStock(qtaImpegnata);
        props.updateTemporaryValue(updatedValue);
      }
    }
  };

  const triggerAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 5000);
  }

  return (
    <React.Fragment>
      {showAlert && (
        <Alert message="Quantità impegnata superiore alla quantità totale" type="warning" showIcon style={{ marginBottom: 10 }} />
      )}
      <strong>PREV VENDUTO TOTALE: {props.parentProps.data.prevVtot}</strong><br />
      <strong>PREV VENDUTO IMPEGNATO: {totalStock}</strong>
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: 15 }}>
        <AgGridReact gridOptions={gridOptions} />
      </div>
    </React.Fragment>
  )
}
