import styled from 'styled-components'
import { colors } from 'Common/constants'
import { Form, Button } from 'antd'

export const StyledH1 = styled.h1`
  font-size: 1.7rem;
  color: ${colors.main};
  text-align: center;
  margin-bottom: 1.5em;
`

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label label {
    font-weight: 500;
    color: ${colors.grey4};
  }
`

export const FormItemUppercase = styled(Form.Item)`
  .ant-form-item-label label {
    font-weight: 500;
    color: ${colors.grey4};
    text-transform: uppercase;
  }
`

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`

export const FormItemCenter = styled(Form.Item)`
  .ant-form-item-control {
    display: flex;
    justify-content: center;
  }
`

export const FormItemFlex = styled(Form.Item)`
  .ant-form-item-label label {
    font-weight: 500;
    color: ${colors.grey4};
    text-transform: uppercase;
  }
  .ant-form-item-children {
    display: flex;
  }
`
