export const colors = {
  main: '#66CC33',
  white: '#FFF',
  grey1: '#FAFAFA',
  grey2: '#D9D9D9',
  grey3: '#B9B9B9',
  grey4: '#6D7278',
  red: '#FF4D4F',
  green: '#52C41A',
  yellow: '#FAAD14'
}
