import * as actionTypes from '../action-types'

export const initialState = {
  dossier: {},
  opecom: {},
  family: {}
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SAVE_DOSSIER_FILTERS:
      return {
        ...state,
        dossier: payload
      }
    case actionTypes.SAVE_OPECOM_FILTERS:
      return {
        ...state,
        opecom: payload
      }
    case actionTypes.SAVE_FAMILY_FILTERS:
      return {
        ...state,
        family: payload
      }
    default:
      return state
  }
}

export default reducer
