import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode';

import { setLoading } from 'Actions/app-actions'
import { setUser, validateToken } from 'Actions/user-actions'

import Loader from 'Components/Loader'

import { getQueryParam, handleServerError } from 'Common/utils'
import config from 'Common/config'
import ErrorPage from "Components/ErrorPage";
import AppAccess from "Common/appAccess";
import api from 'Common/api'
import { Button, Modal, Radio } from 'antd'
import API from 'Common/axiosConfig'

class AuthWrapper extends Component {
  state = {
    showStoreSelection: false,
    selectedStore: null,
    stores: []
  };
  static propTypes = {
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    user: PropTypes.object,
    validateToken: PropTypes.func
  }
  componentDidMount() {
    const { user, location, history, validateToken, setLoading } = this.props
    console.log('AuthWrapper mounted')

    this.checkToken();
  }

  getContent = () => {
    const {user, loading, children} = this.props;

    if (loading) {
      return <Loader/>;
    } else if (isEmpty(user) || !AppAccess.isAuthorized('app')) {
      return <ErrorPage status={401} />;
    }

    return children;
  };

  onSelectedStoreChange = (store) => {
    this.setState({
      selectedStore: store
    });
  };

  onSelectedStoreConfirm = () => {
    this.checkToken();
  }

  checkToken = () => {
    const { user, location, history, validateToken, setLoading, setUser } = this.props;
    const { selectedStore } = this.state;

    let token = sessionStorage.getItem('LeroyMerlin.token');
    let selectedStoreId = Number(sessionStorage.getItem('LeroyMerlin.selectedStoreId', null));

    if (!user.token && location.search.indexOf('token') > -1) {
      token = getQueryParam('token');
      sessionStorage.setItem('LeroyMerlin.token', token);
    }

    if (token === null && !user.token) {
      window.location.href = config.LOGIN_URL
    } else {
      const tokenData = jwt_decode(token);
      if (Array.isArray(tokenData.store) && tokenData.store.length > 1 && (!user.store || user.store === 0) && (isNaN(selectedStoreId) || selectedStoreId <= 0)) {
        selectedStoreId = selectedStore && selectedStore.id.toString();
        api.user.authenticate(token, selectedStoreId).then(response => {
          if (response.data.store === 0) {
            API.get('/v1/stores', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }).then(storeResponse => {
              this.setState({
                showStoreSelection: true,
                stores: storeResponse.data.filter(store => {
                  const storeId = store.id.toString().padStart(3, '0');

                  return tokenData.store.indexOf(storeId) > -1;
                })
              });
            }).catch(handleServerError);
          } else {
            this.setState({ showStoreSelection: false });
            setUser(response.data, token, selectedStoreId);
            setLoading(false);
            history.replace(location.pathname);
          }
        }).catch(handleServerError);
      } else {
        validateToken(token, user.store || selectedStoreId).then(() => setLoading(false));
        history.replace(location.pathname)
      }
    }
  }

  render() {
    const { showStoreSelection, selectedStore, stores } = this.state;

    const content = this.getContent();

    return (
      <>
        {content}
        <Modal
          title="Seleziona il negozio principale"
          visible={showStoreSelection}
          onOk={this.onSelectedStoreConfirm}
          closable={false}
          maskClosable={false}
          cancelText={false}
          cancelButtonProps={{ style: { display: 'none' }}}
          okButtonProps={{ disabled: selectedStore === null }}
        >
          {stores.map((store, index) => <Button key={index} block style={{ marginTop: index === 0 ? 0 : '5px' }} disabled={selectedStore && selectedStore.id === store.id} className={selectedStore && selectedStore.id === store.id ? 'btn-forced-active' : ''} onClick={() => this.onSelectedStoreChange(store)}>{store.id} - {store.name}</Button>)}
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.User,
    loading: state.App.isLoading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setLoading: loading => dispatch(setLoading(loading)),
    validateToken: (token, selectedStoreId) => dispatch(validateToken(token, selectedStoreId)),
    setUser: (user, token, selectedStoreId) => dispatch(setUser(user, token, selectedStoreId))
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthWrapper)
)
