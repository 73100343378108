import { agPercentageFormatter, divideNumbers } from 'Common/utils'

const DEFAULT_STATISTICS = {
  count: 0,
  a1: 0,
  caSim: 0,
  progCaSim: 0,
  qteVteSim: 0,
  ca: 0,
  progCa: 0,
  qteVte: 0,
  caDossier: 0,
  progCaDossier: 0,
  qteVteDossier: 0,
  pvm: 0,
  pvmSim: 0,
  mrg: 0,
  mrgDossier: 0,
  mrg2ca: 0,
  mrg2caSim: 0,
  mrg3Xnet: 0,
  mrg3XnetSim: 0,
  percRedd: 0,
  percReddSim: 0,
  caHt: 0,
  caHtDossier: 0,
  mntBoniRapp: 0,
  caHtRapp: 0,
  mntComplmrgRapp: 0,
  mntRfaRapp: 0,
  caPy: 0,
  caTopMdh: 0,
  rfa: 0,
  boni: 0,
  cm: 0,
  mapPerc: 0,
  rfaPerc: 0,
  boniPerc: 0,
  cmPerc: 0,
  percSconti: 0,
  caTopMdhSim: 0,
  caTopMdhDossier: 0,
  mrgAp: 0,
  mrgApDossier: 0,
  caHtAp: 0,
  caHtApDossier: 0,
  sconti: 0,
  scontiDossier: 0,
};

const SimulazioneUtils = {
  recalculate: (key, data) => {
    switch (key) {
        case 'mrgPerc':
          return divideNumbers(data.mrg, data.caHt);

        case 'rfaPerc':
          return divideNumbers(data.rfaRapp, data.caHtRapp);

        case 'cmPerc':
          return divideNumbers(data.cmRapp, data.caHtRapp);

        case 'boniPerc':
          return divideNumbers(data.boniRapp, data.caHtRapp);

        case 'reddPerc':
          return SimulazioneUtils.recalculate('mrgPerc', data) + SimulazioneUtils.recalculate('boniPerc', data) + SimulazioneUtils.recalculate('cmPerc', data) + SimulazioneUtils.recalculate('rfaPerc', data);

        case 'mapPerc':
          const mapValue = data.mrg + data.sconti;
          return divideNumbers(mapValue, data.caHt);

        case 'discountValue':
          return data.caHt - (data.caHt / (1 + data.scontiPerc ?? 0));

        case 'caHt':
          return data.ca / (1 + (data.txTva || 0));

        default:
          return 0;
    }
  },
  buildProduct: (product) => {
    const caAp = product.qteVte * product.prxVtecns;
    const caHtAp = caAp / (1 + (product.txTva || 0));
    const mrgAp = caHtAp - (product.qteVte * product.prxAchfou);
    const caApDossier = product.qteVteDossier * product.prxVtecnsDossier;
    const caHtApDossier = caApDossier / (1 + (product.txTva || 0));
    const mrgApDossier = caHtApDossier - (product.qteVteDossier * product.prxAchfouDossier);

    return {
      ...product,
      caAp,
      caHtAp,
      mrgAp,
      caApDossier,
      caHtApDossier,
      mrgApDossier,
    };
  },
  getStatisticLabels: (history) => {
    const labels =  [
      { name: 'Qtà Simulata', field: 'qteVteSim' },
      { name: 'CA Simulata', field: 'caSim' },
      { name: 'Prog % CA Simulata', field: 'progCaSim', format: agPercentageFormatter },
      { name: 'Qtà Futuro', field: 'qteVteDossier' },
      { name: 'CA Futuro', field: 'caDossier' },
      { name: 'Prog % CA Futuro', field: 'progCaDossier', format: agPercentageFormatter },
      { name: 'PVM Futuro', field: 'pvmDossier' },
      { name: 'MG€ Futuro', field: 'mrgDossier' },
      { name: 'MG% Futuro', field: 'mrg2caDossier', format: agPercentageFormatter },
      { name: '3xNet € Futuro', field: 'mrg3XnetDossier' },
      { name: 'Redd % Futuro', field: 'percReddDossier', format: agPercentageFormatter }
    ];

    if (history) {
      return labels.filter(o => !o.name.includes('Sim'));
    }

    return labels;
  },
  getDefaultStatistics: () => ({
    caSim: 0,
    progCaSim: 0,
    qteVteSim: 0,
    caDossier: 0,
    progCaDossier: 0,
    qteVteDossier: 0,
    pvm: 0,
    mge: 0,
    mgPerc: 0,
    xnet: 0,
    reddPerc: 0,
    mrgAp: 0,
    mrgApDossier: 0,
    caHtAp: 0,
    caHtApDossier: 0,
  }),
  recalculateRecord: record => {
    const updatedRecord = {
      ...record
    };

    updatedRecord.caSim = updatedRecord.qteVteSim > 0 ? updatedRecord.qteVteSim * updatedRecord.prxVtecnsDossier * (1 - (updatedRecord.percSconti || 0)) : 0;
    updatedRecord.caDossier = updatedRecord.qteVteDossier * updatedRecord.prxVtecnsDossier * (1 -(updatedRecord.percSconti || 0));
    updatedRecord.pvmSim = divideNumbers(updatedRecord.caDossier, updatedRecord.qteVteDossier);
    updatedRecord.progCaSim = updatedRecord.qteVteSim > 0 ? (updatedRecord.caSim - updatedRecord.ca) / updatedRecord.ca : 0;
    updatedRecord.progCaDossier = updatedRecord.qteVteDossier !== 0 ? (updatedRecord.caDossier - updatedRecord.ca) / updatedRecord.ca : 0;

    const caHtDossier = updatedRecord.caDossier / (1 + updatedRecord.txTva);
    updatedRecord.mrgSim = caHtDossier - (updatedRecord.qteVteDossier * updatedRecord.prxAchfouDossier);

    updatedRecord.percReddSim = divideNumbers(updatedRecord.mrgSim, caHtDossier) + (divideNumbers(updatedRecord.mntBoniRapp, updatedRecord.caHtRapp) + divideNumbers(updatedRecord.mntComplmrgRapp, updatedRecord.caHtRapp) + divideNumbers(updatedRecord.mntRfaRapp, updatedRecord.caHtRapp));
    updatedRecord.mrg2caSim = updatedRecord.mrgSim / caHtDossier;
    updatedRecord.mrg3XnetSim = (divideNumbers(updatedRecord.mrgSim, caHtDossier) + divideNumbers(updatedRecord.mntBoniRapp, updatedRecord.caHtRapp) + divideNumbers(updatedRecord.mntComplmrgRapp, updatedRecord.caHtRapp) + divideNumbers(updatedRecord.mntRfaRapp, updatedRecord.caHtRapp)) * caHtDossier;

    const caAp = updatedRecord.qteVte * updatedRecord.prxVtecns;
    const caHtAp = caAp / (1 + (updatedRecord.txTva || 0));
    const mrgAp = caHtAp - (updatedRecord.qteVte * updatedRecord.prxAchfou);
    const caApDossier = updatedRecord.qteVteDossier * updatedRecord.prxVtecnsDossier;
    const caHtApDossier = caApDossier / (1 + (updatedRecord.txTva || 0));
    const mrgApDossier = caHtApDossier - (updatedRecord.qteVteDossier * updatedRecord.prxAchfouDossier);

    updatedRecord.caAp = caAp;
    updatedRecord.caHtAp = caHtAp;
    updatedRecord.mrgAp = mrgAp;
    updatedRecord.caApDossier = caApDossier;
    updatedRecord.caHtApDossier = caHtApDossier;
    updatedRecord.mrgApDossier = mrgApDossier;

    return updatedRecord;
  },
  calculateGeneralStatistics: (groupStatistics) => {
    const generalStatistics = {
      ...groupStatistics,
      pvm: 0,
      pvmSim: 0,
      pvmDossier: 0,
      mrg2ca: 0,
      mrg2caDossier: 0,
      mrg3Xnet: 0,
      mrg3XnetDossier: 0,
      percRedd: 0,
      percReddDossier: 0,
      progCa: 0,
      progCaSim: 0,
      progCaDossier: 0,
      percMap: 0,
      percMapDossier: 0,
      rfa2CaRapp: 0,
      rfa2CaRappDossier: 0,
      cm2CaRapp: 0,
      cm2CaRappDossier: 0,
      boni2CaRapp: 0,
      boni2CaRappDossier: 0,
      quotaMdh: 0,
      quotaMdhDossier: 0
    };

    generalStatistics.pvm = generalStatistics.qteVte > 0 ? generalStatistics.ca / generalStatistics.qteVte : 0;
    generalStatistics.pvmSim = generalStatistics.qteVteSim > 0 ? generalStatistics.caSim / generalStatistics.qteVteSim : 0;
    generalStatistics.pvmDossier = generalStatistics.qteVteDossier > 0 ? generalStatistics.caDossier / generalStatistics.qteVteDossier : 0;

    generalStatistics.mrg2ca = generalStatistics.caHt > 0 ? generalStatistics.mrg / generalStatistics.caHt : 0;
    generalStatistics.mrg2caDossier = generalStatistics.caHtDossier > 0 ? generalStatistics.mrgDossier / generalStatistics.caHtDossier : 0;
    generalStatistics.mrg3Xnet = (divideNumbers(generalStatistics.mrg, generalStatistics.caHt) + divideNumbers(generalStatistics.mntBoniRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntComplmrgRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntRfaRapp, generalStatistics.caHtRapp)) * generalStatistics.caHt;
    generalStatistics.mrg3XnetDossier = (divideNumbers(generalStatistics.mrgDossier, generalStatistics.caHtDossier) + divideNumbers(generalStatistics.mntBoniRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntComplmrgRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntRfaRapp, generalStatistics.caHtRapp)) * generalStatistics.caHtDossier;

    generalStatistics.percRedd = divideNumbers(generalStatistics.mrg, generalStatistics.caHt) + divideNumbers(generalStatistics.mntBoniRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntComplmrgRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntRfaRapp, generalStatistics.caHtRapp);
    generalStatistics.percReddDossier = divideNumbers(generalStatistics.mrgDossier, generalStatistics.caHtDossier) + divideNumbers(generalStatistics.mntBoniRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntComplmrgRapp, generalStatistics.caHtRapp) + divideNumbers(generalStatistics.mntRfaRapp, generalStatistics.caHtRapp);

    generalStatistics.progCa = (generalStatistics.ca - generalStatistics.caPy) / generalStatistics.caPy;
    generalStatistics.progCaSim = (generalStatistics.caSim - generalStatistics.ca) / generalStatistics.ca;
    generalStatistics.progCaDossier = (generalStatistics.caDossier - generalStatistics.ca) / generalStatistics.ca;

    generalStatistics.percMap = SimulazioneUtils.recalculate('mapPerc', {
      mrg: generalStatistics.mrg,
      sconti: generalStatistics.sconti,
      caHt: generalStatistics.caHt
    });

    generalStatistics.percMapDossier = SimulazioneUtils.recalculate('mapPerc', {
      mrg: generalStatistics.mrgDossier,
      sconti: generalStatistics.scontiDossier,
      caHt: generalStatistics.caHtDossier
    });

    generalStatistics.rfa2CaRapp = divideNumbers(generalStatistics.mntRfaRapp, generalStatistics.caHtRapp) || 0;
    generalStatistics.rfa2CaRappDossier = divideNumbers(generalStatistics.mntRfaRapp, generalStatistics.caHtRapp) || 0;
    generalStatistics.cm2CaRapp = divideNumbers(generalStatistics.mntComplmrgRapp, generalStatistics.caHtRapp) || 0;
    generalStatistics.cm2CaRappDossier = divideNumbers(generalStatistics.mntComplmrgRapp, generalStatistics.caHtRapp) || 0;
    generalStatistics.boni2CaRapp = divideNumbers(generalStatistics.mntBoniRapp, generalStatistics.caHtRapp) || 0;
    generalStatistics.boni2CaRappDossier = divideNumbers(generalStatistics.mntBoniRapp, generalStatistics.caHtRapp) || 0;

    generalStatistics.quotaMdh = divideNumbers(generalStatistics.caTopMdh, generalStatistics.ca) || 0;
    generalStatistics.quotaMdhDossier = divideNumbers(generalStatistics.caTopMdhDossier, generalStatistics.caDossier) || 0;

    return generalStatistics;
  },
  calculateRecordStatistics: (record, existingStatistics) => {
    const recordStatistics= {
      count: existingStatistics.count || 0,
      a1: existingStatistics.a1 || 0,
      a1Dossier: existingStatistics.a1Dossier || 0,
      caSim: existingStatistics.caSim || 0,
      progCaSim: existingStatistics.progCaSim || 0,
      qteVteSim: existingStatistics.qteVteSim || 0,
      caDossier: existingStatistics.caDossier || 0,
      progCaDossier: existingStatistics.progCaDossier || 0,
      qteVteDossier: existingStatistics.qteVteDossier || 0,
      ca: existingStatistics.ca || 0,
      progCa: existingStatistics.progCa || 0,
      qteVte: existingStatistics.qteVte || 0,
      pvm: existingStatistics.pvm || 0,
      pvmDossier: existingStatistics.pvmDossier || 0,
      mrg: existingStatistics.mrg || 0,
      mrgDossier: existingStatistics.mrgDossier || 0,
      mrg3Xnet: existingStatistics.mrg3Xnet || 0,
      mrg3XnetDossier: existingStatistics.mrg3XnetDossier || 0,
      caHt: existingStatistics.caHt || 0,
      caHtDossier: existingStatistics.caHtDossier || 0,
      mntBoniRapp: existingStatistics.mntBoniRapp || 0,
      caHtRapp: existingStatistics.caHtRapp || 0,
      mntComplmrgRapp: existingStatistics.mntComplmrgRapp || 0,
      mntRfaRapp: existingStatistics.mntRfaRapp || 0,
      caPy: existingStatistics.caPy || 0,
      caTopMdh: existingStatistics.caTopMdh || 0,
      caTopMdhSim: existingStatistics.caTopMdhSim || 0,
      caTopMdhDossier: existingStatistics.caTopMdhDossier || 0,
      percSconti: existingStatistics.percSconti || 0,
      caHtAp: existingStatistics.caHtAp || 0,
      caHtApDossier: existingStatistics.caHtApDossier || 0,
      mrgAp: existingStatistics.mrgAp || 0,
      mrgApDossier: existingStatistics.mrgApDossier || 0,
      sconti: existingStatistics.sconti || 0,
      scontiDossier: existingStatistics.scontiDossier || 0,
    };

    recordStatistics.count += 1;
    recordStatistics.a1 += record.codGamart === 'A' && record.numTypreappropreco === 1 ? 1 : 0;
    recordStatistics.a1Dossier += record.codGamartDossier === 'A' && record.numTypreapproprecoDossier === 1 ? 1 : 0;
    recordStatistics.caSim += Number(record.caSim) || 0;
    recordStatistics.caDossier += Number(record.caDossier) || 0;
    recordStatistics.qteVteDossier += Number(record.qteVteDossier) || 0;
    recordStatistics.qteVteSim += Number(record.qteVteSim) || 0;
    recordStatistics.ca += Number(record.ca) || 0;
    recordStatistics.qteVte += Number(record.qteVte) || 0;
    recordStatistics.mrg += Number(record.mrg) || 0;
    recordStatistics.mrgDossier += Number(record.mrgSim) || 0;
    recordStatistics.caHt += Number((record.ca || 0) / (1 + (record.txTva || 0))) || 0;
    recordStatistics.mntBoniRapp += Number(record.mntBoniRapp) || 0;
    recordStatistics.caHtRapp += Number(record.caHtRapp) || 0;
    recordStatistics.mntComplmrgRapp += Number(record.mntComplmrgRapp) || 0;
    recordStatistics.mntRfaRapp += Number(record.mntRfaRapp) || 0;
    recordStatistics.caPy += Number(record.caPy) || 0;
    recordStatistics.percSconti += Number(record.percSconti) || 0;
    recordStatistics.caHtDossier += Number((record.caDossier || 0) / (1 + (record.txTva || 0))) || 0;
    recordStatistics.mrg3Xnet += Number(record.mrg3Xnet) || 0;
    recordStatistics.mrg3XnetDossier += Number(record.mrg3XnetSim) || 0;
    recordStatistics.mntMap += Number(record.mntMap) || 0;
    recordStatistics.mntMapDossier += Number(record.mntMapDossier) || 0;
    recordStatistics.caHtAp += Number(record.caHtAp) || 0;
    recordStatistics.caHtApDossier += Number(record.caHtApDossier) || 0;
    recordStatistics.mrgAp += Number(record.mrgAp) || 0;
    recordStatistics.mrgApDossier += Number(record.mrgApDossier) || 0;
    recordStatistics.sconti += SimulazioneUtils.recalculate('discountValue', {
      caHt: recordStatistics.caHt,
      scontiPerc: record.percSconti,
    });
    recordStatistics.scontiDossier += SimulazioneUtils.recalculate('discountValue', {
      caHt: recordStatistics.caHtDossier,
      scontiPerc: record.percSconti,
    });

    if (record.topMdh) {
      recordStatistics.caTopMdh += Number(record.ca) || 0;
      recordStatistics.caTopMdhSim += Number(record.caSim) || 0;
      recordStatistics.caTopMdhDossier += Number(record.caDossier) || 0;
    }

    return recordStatistics;
  },
  getCalculatedStatistics: data => {

    const statistics = {
      caSim: 0,
      caDossier: 0,
      progCaSim: 0,
      progCaDossier: 0,
      qteVteSim: 0,
      qteVteDossier: 0,
      qteVte: 0,
      ca: 0,
      progCa: 0,
      qtot: 0,
      pvm: 0,
      mge: 0,
      mgPerc: 0,
      xnet: 0,
      reddPerc: 0,
      caHt: 0,
      mntBoniRapp: 0,
      caHtRapp: 0,
      mntComplmrgRapp: 0,
      mntRfaRapp: 0,
      caPy: 0,
      caTopMdh: 0,
      split: {
        in: {
          ...DEFAULT_STATISTICS
        },
        out: {
          ...DEFAULT_STATISTICS
        },
        existing: {
          ...DEFAULT_STATISTICS
        }
      }
    };

    for (const record of data) {

      if (record.added) {
        statistics.split.in = SimulazioneUtils.calculateRecordStatistics(record, statistics.split.in);
      } else if (record.removed) {
        statistics.split.out = SimulazioneUtils.calculateRecordStatistics(record, statistics.split.out);
      } else {
        statistics.split.existing = SimulazioneUtils.calculateRecordStatistics(record, statistics.split.existing);
      }

    }

    statistics.split.in = SimulazioneUtils.calculateGeneralStatistics(statistics.split.in);
    statistics.split.out = SimulazioneUtils.calculateGeneralStatistics(statistics.split.out);
    statistics.split.existing = SimulazioneUtils.calculateGeneralStatistics(statistics.split.existing);

    statistics.caHtDossier = statistics.split.existing.caHtDossier + statistics.split.in.caHtDossier;
    statistics.scontiDossier = statistics.split.existing.scontiDossier + statistics.split.in.scontiDossier;
    statistics.sconti = statistics.split.existing.sconti + statistics.split.in.sconti;
    statistics.qteVteSim = statistics.split.existing.qteVteSim + statistics.split.in.qteVteSim;
    statistics.qteVteDossier = statistics.split.existing.qteVteDossier + statistics.split.in.qteVteDossier;
    statistics.ca = statistics.split.existing.ca + statistics.split.out.ca;
    statistics.caHt = statistics.split.existing.caHt + statistics.split.out.caHt;
    statistics.caSim = statistics.split.existing.caSim + statistics.split.in.caSim;
    statistics.caDossier = statistics.split.existing.caDossier + statistics.split.in.caDossier;
    statistics.caTopMdh = statistics.split.existing.caTopMdh + statistics.split.in.caTopMdh;
    statistics.caTopMdhSim = statistics.split.existing.caTopMdhSim + statistics.split.in.caTopMdhSim;
    statistics.caTopMdhDossier = statistics.split.existing.caTopMdhDossier + statistics.split.in.caTopMdhDossier;
    statistics.progCaSim = (statistics.caSim - statistics.ca) / statistics.ca;
    statistics.progCaDossier = (statistics.caDossier - statistics.ca) / statistics.ca;
    statistics.pvmDossier = statistics.qteVteDossier > 0 ? statistics.caDossier / statistics.qteVteDossier : 0;
    statistics.mrgDossier = statistics.split.existing.mrgDossier + statistics.split.in.mrgDossier;
    statistics.mrg2caDossier = statistics.caHtDossier > 0 ? statistics.mrgDossier / statistics.caHtDossier : 0;
    statistics.mrg3XnetDossier = statistics.split.existing.mrg3XnetDossier + statistics.split.in.mrg3XnetDossier;

    statistics.percReddDossier = SimulazioneUtils.recalculate('reddPerc', {
      mrg: statistics.split.existing.mrgDossier + statistics.split.in.mrgDossier,
      cmRapp: statistics.split.existing.mntComplmrgRapp + statistics.split.in.mntComplmrgRapp,
      boniRapp: statistics.split.existing.mntComplmrgRapp + statistics.split.in.mntComplmrgRapp,
      rfaRapp: statistics.split.existing.mntRfaRapp + statistics.split.in.mntRfaRapp,
      caHtRapp: statistics.split.existing.caHtRapp + statistics.split.in.caHtRapp,
      caHt: statistics.split.existing.caHtDossier + statistics.split.in.caHtDossier
    });

    statistics.percMap = SimulazioneUtils.recalculate('mapPerc', {
      mrg: statistics.split.existing.mrg + statistics.split.out.mrg,
      sconti: statistics.sconti,
      caHt: statistics.caHt
    });

    statistics.percMapDossier = SimulazioneUtils.recalculate('mapPerc', {
      mrg: statistics.mrgDossier,
      sconti: statistics.scontiDossier,
      caHt: statistics.caHtDossier
    });


    console.log(statistics);
    return statistics;
  },
  getSummaryStatistics: (products, statistics) => {
    return {
      refs: {
        in: products.filter(o => o.added).length,
        out: products.filter(o => o.removed).length,
        existing: products.filter(o => o.added !== true && o.removed !== true).length
      },
      a1: {
        in: statistics.split.in.a1,
        out: statistics.split.out.a1,
        existing: statistics.split.existing.a1
      },
      a1Dossier: {
        in: statistics.split.in.a1Dossier,
        out: statistics.split.out.a1Dossier,
        existing: statistics.split.existing.a1Dossier
      },
      qta: {
        in: statistics.split.in.qtot,
        out: statistics.split.out.qtot,
        existing: statistics.split.existing.qtot
      },
      ca: {
        in: statistics.split.in.ca,
        out: statistics.split.out.ca,
        existing: statistics.split.existing.ca
      },
      caHt: {
        in: statistics.split.in.caHt,
        out: statistics.split.out.caHt,
        existing: statistics.split.existing.caHt
      },
      pvm: {
        in: statistics.split.in.pvm,
        out: statistics.split.out.pvm,
        existing: statistics.split.existing.pvm
      },
      mdh: {
        in: statistics.split.in.caTopMdh,
        out: statistics.split.out.caTopMdh,
        existing: statistics.split.existing.caTopMdh
      },
      mg: {
        in: statistics.split.in.mge,
        out: statistics.split.out.mge,
        existing: statistics.split.existing.mge
      },
      rfa: {
        in: statistics.split.in.rfa,
        out: statistics.split.out.rfa,
        existing: statistics.split.existing.rfa
      },
      cm: {
        in: statistics.split.in.cm,
        out: statistics.split.out.cm,
        existing: statistics.split.existing.cm
      },
      boni: {
        in: statistics.split.in.boni,
        out: statistics.split.out.boni,
        existing: statistics.split.existing.boni
      },
      xnet: {
        in: statistics.split.in.xnet,
        out: statistics.split.out.xnet,
        existing: statistics.split.existing.xnet
      },
      mapPerc: {
        in: statistics.split.in.mapPerc,
        out: statistics.split.out.mapPerc,
        existing: statistics.split.existing.mapPerc
      },
      mgPerc: {
        in: statistics.split.in.mgPerc,
        out: statistics.split.out.mgPerc,
        existing: statistics.split.existing.mgPerc
      },
      rfaPerc: {
        in: statistics.split.in.rfaPerc,
        out: statistics.split.out.rfaPerc,
        existing: statistics.split.existing.rfaPerc
      },
      cmPerc: {
        in: statistics.split.in.cmPerc,
        out: statistics.split.out.cmPerc,
        existing: statistics.split.existing.cmPerc
      },
      boniPerc: {
        in: statistics.split.in.boniPerc,
        out: statistics.split.out.boniPerc,
        existing: statistics.split.existing.boniPerc
      },
      reddPerc: {
        in: statistics.split.in.reddPerc,
        out: statistics.split.out.reddPerc,
        existing: statistics.split.existing.reddPerc
      }
    };
  },
  getTranslationForState: state => {
    switch (state) {
      case 'CREATED':
        return 'Creato';
      case 'PUBLISHED':
        return 'Pubblicato';
      case 'CONFIRMED':
        return 'Confermato';
      default:
        return '';
    }
  },
  getTranslationColorForState: state => {
    switch (state) {
      case 'CREATED':
        return 'gold';
      case 'PUBLISHED':
        return 'green';
      case 'CONFIRMED':
        return 'geekblue';
      default:
        return '';
    }
  }
}

export default SimulazioneUtils;
