import React from 'react'
import * as _ from 'lodash'
import { Alert, Col, Row } from 'antd'
import {
  CardInformationContainer,
  CardInformationItem,
  PaginationCol,
  PaginationRow,
  StyledText,
} from 'Components/SearchDossierResults/styled'
import { StyledButton, StyledPagination, StyledCard, StyledCardNoBody } from 'Common/styled'
import moment from 'moment'
import PropTypes from 'prop-types'
import { opecomStates } from 'Common/constants'
import AppAccess from 'Common/appAccess'
import { getOpecomTitle } from 'Common/utils'

/**
 * Component for opecom search results
 */
class SearchFamilyResults extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    onViewClick: PropTypes.func,
    onPageChange: PropTypes.func,
    totalItems: PropTypes.number,
    currentPage: PropTypes.number,
  }

  state = {
    updated: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.items !== this.props.items && !this.state.updated) {
      this.setState({ updated: true })
    }
  }

  /**
   * View click handler
   * @param {object} item
   * @param {string|number} key
   */
  handleViewClick = (item, key) => {
    if (_.isFunction(this.props.onViewClick)) {
      this.props.onViewClick(item, key)
    }
  }

  /**
   * Generate title from an opecom
   * @param item
   * @returns {string}
   */
  getTitleFromItem = item => {
    return (
      <StyledText>
        {item.code}-{item.description} {item.idStore == null ? '' : `(Store ${item.idStore})`}
      </StyledText>
    )
  }

  /**
   * Handle Modifica button for an opecom, based on statoOpecom
   * @param item
   * @returns {string}
   */
  getModificaButton = item => {
    return (
      <StyledButton
        type="primary"
        onClick={e => this.handleViewClick(item, item.id)}>
        {AppAccess.isAuthorized('edit_family')
          ? 'Tratta'
          : 'Visualizza'}
      </StyledButton>
    )
  }

  /**
   * Page change handler
   * @param {number} page
   * @param {number} pageSize
   */
  handlePageChange = (page, pageSize) => {
    // Invoke parent onPageChange callback
    _.invoke(this.props, 'onPageChange', page, pageSize)
  }

  render() {
    const items = _.get(this.props, 'items', [])
    const { updated } = this.state

    if (items.length === 0 && updated) {
      return (
        <div>
          <Row gutter={[0, 16]}>
            <Col
              xs={{ span: 22, offset: 1 }}
              xl={{ span: 18, offset: 3 }}
              xxl={{ span: 12, offset: 6 }}>
              <Alert
                message="Non ci sono risultati per la ricerca"
                type="info"
              />
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div>
        <Row gutter={[0, 16]}>
          {items.map(item => (
            <Col
              key={item.code}
              xs={{ span: 22, offset: 1 }}
              xl={{ span: 18, offset: 3 }}
              xxl={{ span: 12, offset: 6 }}>
              <StyledCardNoBody
                bordered={false}
                title={<StyledText>{item.nomeFamiglia} [{item.idFamiglia}]</StyledText>}
                extra={this.getModificaButton(item)}
              />
            </Col>
          ))}
        </Row>
        <PaginationRow>
          <PaginationCol
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 14, offset: 5 }}>
            <StyledPagination
              onChange={(page, pageSize) =>
                this.handlePageChange(page, pageSize)
              }
              total={_.get(this.props, 'totalItems', 0)}
              pageSize={10}
              showLessItems={true}
              hideOnSinglePage={true}
              current={this.props.currentPage}
            />
          </PaginationCol>
        </PaginationRow>
      </div>
    )
  }
}

export default SearchFamilyResults
