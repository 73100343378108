import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import * as _ from 'lodash'

import { setTour } from 'Actions/tour-actions'
import { fetchDepartments, fetchFamilies } from 'Actions/data-actions'
import { editDossier, saveDossier, fetchDossier } from 'Actions/edit-actions'

import { Form, Select, Row, Col, Input, DatePicker, Tooltip, Switch } from 'antd'
import MainLayout from 'Hoc/MainLayout'
import {
  StyledH1,
  StyledFormItem,
  StyledButton,
  FormItemCenter,
  FormItemUppercase
} from './styled'
import Loader from 'Components/Loader'

import { steps } from './constants'
import { TOUR_HOME, dossierTypes, LM_EMAIL_SUFFIX, LM_EMAIL_PREFIX_REGEX } from 'Common/constants'
import {
  formatDate,
  subtractDays,
  daysBetween,
  daysFromToday,
  parseDate,
  getYear
} from 'Common/utils'
import moment from "moment";
import {DATE_LOCALE} from "Common/globals";
import { ROLE_CPXS, ROLE_CS } from 'Common/constants/global'
import AppAccess from 'Common/appAccess'

const { Option } = Select
const { RangePicker } = DatePicker

const StepOneForm = Form.create({ name: 'step_one_form' })(
  class extends Component {
    static propTypes = {
      onCreate: PropTypes.func
    }

    render() {
      const { form, onCreate, user } = this.props
      const { getFieldDecorator } = form
      return (
        <Form layout="vertical">
          <StyledFormItem label="Seleziona tipo dossier" colon={false}>
            {getFieldDecorator('type', {
              rules: [{ required: true, message: 'Scegli un tipo' }],
              initialValue: user.role === ROLE_CPXS ? 'XS' : user.role === ROLE_CS ? 'PL' : null
            })(
              <Select>
                {dossierTypes.filter(type => type.canCreate.includes(user.role)).map(type => (
                  <Option key={type.key}>{`${type.key}-${type.label}`}</Option>
                ))}
              </Select>
            )}
          </StyledFormItem>
          <FormItemCenter>
            <StyledButton type="primary" onClick={onCreate}>
              Crea
            </StyledButton>
          </FormItemCenter>
        </Form>
      )
    }
  }
)

const StepTwoForm = Form.create({
  name: 'step_two_form',
  mapPropsToFields(props) {
    const {
      dossier: {
        department,
        idFamily,
        description,
        installationFrom,
        installationTo,
        installationDate,
        merchArrivalDate,
        storeCompletionFrom,
        storeCompletionTo
      }
    } = props
    return {
      department: Form.createFormField({
        value: department
      }),
      idFamily: Form.createFormField({
        value: idFamily
      }),
      description: Form.createFormField({
        value: description
      }),
      installation:
        installationFrom && installationTo
          ? Form.createFormField({
              value: [parseDate(installationFrom), parseDate(installationTo)]
            })
          : null,
        installationDate: Form.createFormField({
            value: parseDate(installationDate)
        }),
        merchArrivalDate: Form.createFormField({
            value: !_.isNil(merchArrivalDate) ? parseDate(merchArrivalDate) : moment().add(31, 'days')
        }),
      storeCompletion:
        storeCompletionFrom && storeCompletionTo
          ? Form.createFormField({
              value: [
                parseDate(storeCompletionFrom),
                parseDate(storeCompletionTo)
              ]
            })
          : null,
      clusterGarden: Form.createFormField({
        value: false
      }),
      okr: Form.createFormField({
        value: false
      })
    }
  }
})(
  class extends Component {
    static propTypes = {
      dossier: PropTypes.object,
      departments: PropTypes.array,
      families: PropTypes.array,
      onSave: PropTypes.func,
      isEditing: PropTypes.bool
    }

    state = {
      hasTypedDescription: this.props.isEditing,
      hasPickedStoreCompletionPeriod: this.props.isEditing
    }

    handleSelectFamily = value => {
      if (
        !this.props.form.getFieldValue('description') ||
        !this.state.hasTypedDescription
      ) {
        this.props.form.setFieldsValue({
          description: this.props.families.filter(
            family => family.idFamily === value
          )[0].name
        })
      }
    }

    handleChangeInstallationPeriod = value => {
      if (value.length !== 0) {
        const installationFrom = value[0]
        const installationTo = value[1]

        let storeCompletionFrom = subtractDays(installationFrom, 67)
        if (storeCompletionFrom.isBefore(moment().add(1, 'days'), 'day')) {
          storeCompletionFrom = moment().add(1, 'days')
        }
        const storeCompletionTo = subtractDays(installationFrom, 37)

        let maxInstallationDate
        if (this.props.dossier.type === 'ST') {
          const installationPeriodLength = daysBetween(installationFrom, installationTo)
          if (installationPeriodLength >= 7) {
            maxInstallationDate = installationFrom.clone().add(7, 'days')
          } else {
            maxInstallationDate = installationFrom.clone().add(installationPeriodLength, 'days')
          }
        }

        this.props.form.setFieldsValue({
          storeCompletion: [storeCompletionFrom, storeCompletionTo],
          installationDate: installationFrom,
          ...(maxInstallationDate && {maxInstallationDate: maxInstallationDate})
        })
      } else {
        this.props.form.setFieldsValue({
          storeCompletion: null,
          installationDate: null,
          maxInstallationDate: null
        })
      }
    }

    handleChangeProposedInstallationDate = value => {
    };

    handleKeyUpDescription = event => {
      event.persist()
      if (event.target.value) {
        this.setState({ hasTypedDescription: true })
      } else {
        // If user clears field, reset flag
        this.setState({ hasTypedDescription: false })
      }
    }

    handleChangeStoreCompletionPeriod = () => {
      this.setState({ hasPickedStoreCompletionPeriod: true })
    }

    validatePeriod = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const [from, to] = value
      return daysBetween(from, to) > 0
    }

    validateFutureDates = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const from = value[0]
      return daysFromToday(from) >= 0
    }

    validateInstallation = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const from = value[0]
      return daysFromToday(from) >= 37
    }

    validateStoreCompletion = (rule, value) => {
      const installationPeriod = this.props.form.getFieldValue('installation')
      if (
        !value ||
        value.length === 0 ||
        !installationPeriod ||
        installationPeriod.length === 0 ||
        !this.validatePeriod(null, value)
      ) {
        return true
      }
      const storeCompletionTo = value[1]
      const installationFrom = installationPeriod[0]
      return daysBetween(storeCompletionTo, installationFrom) >= 30
    }

    filterFamily = family => {
      const department = this.props.form.getFieldValue('department')
      if (department) {
        return family.idDepartment === department
      }
      return true
    }

    getProposedInstallationDateField = () => {
        const installationPeriod = this.props.form.getFieldValue('installation');
        const installationPeriodExists = !_.isNil(installationPeriod) && _.size(installationPeriod) === 2;

        const DatePickerComponent = <DatePicker
            disabled={!installationPeriodExists}
            className={'generic-datepicker'}
            locale={DATE_LOCALE}
            format="DD/MM/YYYY"
            inputReadOnly={true}
            onChange={this.handleChangeProposedInstallationDate}
            disabledDate={currentDate => {
                const installationPeriod = this.props.form.getFieldValue('installation');

                if (_.isNil(installationPeriod) || _.size(installationPeriod) !== 2) {
                    return true;
                }

                if (!currentDate.isBetween(installationPeriod[0], installationPeriod[1], 'day', '[]')) {
                    return true;
                }

                return false;
            }}
        />;

        if (installationPeriodExists) {
            return DatePickerComponent;
        }

        return (
            <Tooltip title="Devi selezionare un periodo d'impianto">
                {DatePickerComponent}
            </Tooltip>
        );
    };

    getMaxInstallationDateField = () => {
      const installationPeriod = this.props.form.getFieldValue('installation');
      const storeCompletionPeriod = this.props.form.getFieldValue('storeCompletion');
      const installationPeriodExists = !_.isNil(installationPeriod) && _.size(installationPeriod) === 2;
      const storeCompletionPeriodExists = !_.isNil(storeCompletionPeriod) && _.size(storeCompletionPeriod) === 2;

      const DatePickerComponent = <DatePicker
          disabled={!installationPeriodExists}
          className={'generic-datepicker'}
          locale={DATE_LOCALE}
          format="DD/MM/YYYY"
          inputReadOnly={true}
          disabledDate={currentDate => {
              return !currentDate.isBetween(storeCompletionPeriod[1], installationPeriod[1], 'day', '(]')
          }}
      />;

      if (installationPeriodExists && storeCompletionPeriodExists) {
          return DatePickerComponent;
      }

      let msg
      if (!installationPeriodExists && !storeCompletionPeriodExists) {
        msg = "Devi selezionare stagione e periodo completamento negozio"
      } else if (!installationPeriodExists) {
        msg = "Devi selezionare una stagione"
      } else {
        msg = "Devi selezionare un periodo completamento negozio"
      }

      return (
          <Tooltip title={msg}>
              {DatePickerComponent}
          </Tooltip>
      );
    };

    render() {
      const { form, departments, families, onSave, dossier } = this.props
      const { getFieldDecorator } = form

      const type = dossier.type ? dossier.type : ''
      const year = form.getFieldValue('installation')
        ? `-${getYear(form.getFieldValue('installation')[0])}`
        : ''
      const department = form.getFieldValue('department') > -1
        ? `-${form
            .getFieldValue('department')
            .toString()
            .padStart(2, '0')}`
        : ''
      const description = form.getFieldValue('description')
        ? `-${form.getFieldValue('description')}`
        : '';

      return (
        <Form layout="vertical">
          <FormItemUppercase label="Titolo" colon={false}>
            <span className="ant-form-text">{`${type}${year}${department}${description}`}</span>
          </FormItemUppercase>
          <FormItemUppercase label="Reparto" colon={false}>
            {getFieldDecorator('department', {
              rules: [{ required: true, message: 'Scegli un reparto' }]
            })(
              <Select>
                {dossier.type === 'XS' && (
                  <Option key={0} value={0}>
                    Tutti
                  </Option>
                )}
                {departments.map(department => (
                  <Option
                    key={department.id}
                    value={
                      department.id
                    }>{`${department.id}-${department.label}`}</Option>
                ))}
              </Select>
            )}
          </FormItemUppercase>
            {dossier.type !== 'PL' && <FormItemUppercase label="Famiglia merchandising" colon={false}>
            {getFieldDecorator('idFamily', {
              rules: [{ required: true, message: 'Scegli una famiglia' }]
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={true}
                onSelect={this.handleSelectFamily}>
                {families.filter(this.filterFamily).map(family => (
                  <Option key={family.idFamily} value={family.idFamily}>
                    {family.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItemUppercase>}
          <FormItemUppercase label="Descrizione" colon={false}>
            {getFieldDecorator('description', {
              rules: [{ required: true, message: 'Inserisci una descrizione' }]
            })(<Input onKeyUp={this.handleKeyUpDescription} allowClear />)}
          </FormItemUppercase>
            {dossier.type === 'PL' && <FormItemUppercase label="Data arrivo merce" colon={false}>
                {getFieldDecorator('merchArrivalDate', {
                    rules: [
                        { required: true, message: 'Inserisci una data' }
                    ]
                })(
                    <DatePicker
                        className={'generic-datepicker'}
                        locale={DATE_LOCALE}
                        format="DD/MM/YYYY"
                        inputReadOnly={true}
                        onChange={() => {}}
                        allowClear={false}
                        disabledDate={currentDate => {
                          if (currentDate.isBefore(moment().add(31, 'day'), 'day')) {
                            return true;
                          }

                          if (form.getFieldValue('installation') && currentDate.isSameOrAfter(form.getFieldValue('installation')[0], 'day')) {
                            return true;
                          }

                          return false;
                        }}
                    />
                )}
            </FormItemUppercase>}
          <FormItemUppercase label={dossierTypes.filter(type => type.key === dossier.type)[0].installationLabel} colon={false}>
            {getFieldDecorator('installation', {
              rules: [
                { required: true, message: 'Inserisci un periodo' },
                {
                  validator: this.validatePeriod,
                  message: 'Il periodo deve essere di almeno 2 gg'
                }
              ].filter((item, index) => !(index > 0 && dossier.type === 'PL'))
            })(
              <RangePicker
                format="DD/MM/YYYY"
                onChange={dossier.type === 'PL' ? () => {} : this.handleChangeInstallationPeriod}
                placeholder={["Data d'inizio", 'Data di fine']}
                allowClear={false}
                disabledDate={currentDate => {
                  if (dossier.type !== 'PL' && currentDate.isBefore(moment().add(39, 'days'), 'day')) {
                    return true
                  }

                  if (dossier.type === 'PL' && currentDate.isSameOrBefore(form.getFieldValue('merchArrivalDate'), 'day')) {
                    return true;
                  }

                  return false;
                }}
              />
            )}
          </FormItemUppercase>
          {(dossier.type !== 'ST' && dossier.type !== 'PL') && <FormItemUppercase label="Data suggerita inizio impianto" colon={false}>
              {getFieldDecorator('installationDate', {
                  rules: [
                      { required: true, message: 'Inserisci una data' }
                  ]
              })(
                  this.getProposedInstallationDateField()
              )}
          </FormItemUppercase>}
          {dossier.type === 'ST' && <FormItemUppercase label="Data massima impianto" colon={false}>
              {getFieldDecorator('maxInstallationDate', {
                  rules: [
                      { required: true, message: 'Inserisci una data' }
                  ]
              })(
                  this.getMaxInstallationDateField()
              )}
          </FormItemUppercase>}
            {dossier.type !== 'PL' && <FormItemUppercase
            label="Periodo completamento negozio"
            colon={false}>
            {getFieldDecorator('storeCompletion', {
              rules: [
                { required: true, message: 'Inserisci un periodo' },
                {
                  validator: this.validatePeriod,
                  message: 'Il periodo deve essere di almeno 2 gg'
                }
              ]
            })(
              <RangePicker
                format="DD/MM/YYYY"
                onChange={this.handleChangeStoreCompletionPeriod}
                placeholder={["Data d'inizio", 'Data di fine']}
                disabled={!this.props.form.getFieldValue('installation') || this.props.form.getFieldValue('installation').length === 0}
                disabledDate={currentDate => {
                  if (currentDate.isBefore(moment().add(1, 'days'), 'day')) {
                    return true
                  }

                  if (currentDate.isAfter(this.props.form.getFieldValue('installation')[0].clone().subtract(37, 'days'), 'day')) {
                    return true
                  }

                  return false;
                }}
              />
            )}
          </FormItemUppercase>}
          {['NG', 'RG', 'ST'].includes(dossier.type) && <FormItemUppercase label="Merch di riferimento" colon={false}>
            {getFieldDecorator('referenceMerch', {
              rules: [
                { required: true, message: 'Inserisci un nome utente' },
                { pattern: LM_EMAIL_PREFIX_REGEX, message: 'Inserisci un nome utente nel formato nome.cognome' },
              ],
            })(<Input addonAfter={LM_EMAIL_SUFFIX} allowClear />)}
          </FormItemUppercase>}
          {AppAccess.isAuthorized('edit_cluster_garden', {
            dossierType: this.props.dossier.type,
            dossierState: 'CR',
            dossierStore: null
          }) && (
            <FormItemUppercase label="Includi cluster Garden" colon={false}>
              {getFieldDecorator('clusterGarden', { valuePropName: 'checked' })(
                <Switch />
              )}
            </FormItemUppercase>
          )}
          {AppAccess.isAuthorized('edit_okr', {
            dossierType: this.props.dossier.type,
          }) && (
            <FormItemUppercase label="OKR" colon={false}>
              {getFieldDecorator('okr', { valuePropName: 'checked' })(
                <Switch />
              )}
            </FormItemUppercase>
          )}
          <FormItemCenter>
            <StyledButton type="primary" onClick={onSave}>
              Salva
            </StyledButton>
          </FormItemCenter>
        </Form>
      )
    }
  }
)

class EditDossier extends Component {
  static propTypes = {
    tour: PropTypes.object,
    setTour: PropTypes.func,
    departments: PropTypes.array,
    families: PropTypes.array,
    dossier: PropTypes.object,
    fetchDepartments: PropTypes.func,
    fetchFamilies: PropTypes.func,
    editDossier: PropTypes.func,
    saveDossier: PropTypes.func
  }

  state = {
    isLoading: true,
    step: 1,
    redirectTo: null
  }

  componentDidMount() {
    const id = this.props.match.params.id
    if (id) {
      Promise.all([
        this.props.fetchDossier(id),
        this.props.fetchDepartments(),
        this.props.fetchFamilies()
      ]).then(() => this.setState({ isLoading: false, step: 2 }))
    } else {
      Promise.all([
        this.props.fetchDepartments(),
        this.props.fetchFamilies()
      ]).then(() => this.setState({ isLoading: false }))
    }
  }

  handleCreate = () => {
    this.stepOneForm.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.editDossier(values)
        this.setState({ step: 2 })
      }
    })
  }

  handleSave = () => {
      const {dossier} = this.props;
    this.stepTwoForm.props.form.validateFields((err, values) => {
      if (!err) {
        const formattedValues = {
          department: values.department,
          idFamily: values.idFamily,
          family: dossier.type !== 'PL' ? this.props.families.filter(
            family => family.idFamily === values.idFamily
          )[0].name : null,
          description: values.description.trim(),
          installationFrom: formatDate(values.installation[0]),
          installationTo: formatDate(values.installation[1]),
          installationDate: formatDate(values.installationDate),
          merchArrivalDate: formatDate(values.merchArrivalDate),
          maxInstallationDate: formatDate(values.maxInstallationDate),
          storeCompletionFrom: dossier.type !== 'PL' ? formatDate(values.storeCompletion[0]) : null,
          storeCompletionTo: dossier.type !== 'PL' ? formatDate(values.storeCompletion[1]) : null,
          referenceMerch: values.referenceMerch ? values.referenceMerch + LM_EMAIL_SUFFIX : null,
          clusterGarden: values.clusterGarden === true,
          okr: values.okr === true
        }
        this.props
          .saveDossier({
            ...this.props.dossier,
            ...formattedValues
          })
          .then(
            () =>
              this.setState({
                redirectTo: `/edit-dossier/${this.props.dossier.id}`
              }),
            () => {}
          )
      }
    })
  }

  render() {
    const { isLoading, step, redirectTo } = this.state
    const {
      match: {
        params: { id }
      },
      dossier,
      departments,
      families,
      user
    } = this.props
    return redirectTo ? (
      <Redirect to={redirectTo} />
    ) : isLoading ? (
      <Loader />
    ) : id && _.isEmpty(dossier) ? (
      <Redirect to="/" />
    ) : (
      <MainLayout
        tourSteps={steps}
        isTourAvailable={false}
        isTourOpen={false}
        closeTour={() => setTour(TOUR_HOME)}
        defaultCurrentPage="dossier">
        <StyledH1>Gestione dossier</StyledH1>
        <Row type="flex" justify="center">
          <Col span={6}>
            {step === 1 && (
              <StepOneForm
                wrappedComponentRef={ref => {
                  this.stepOneForm = ref
                }}
                onCreate={this.handleCreate}
                user={user}
              />
            )}
            {step === 2 && (
              <StepTwoForm
                wrappedComponentRef={ref => {
                  this.stepTwoForm = ref
                }}
                dossier={dossier}
                departments={departments}
                families={families}
                onSave={this.handleSave}
                isEditing={id ? true : false}
              />
            )}
          </Col>
        </Row>
      </MainLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    tour: state.Tour,
    departments: state.Data.departments,
    families: state.Data.families,
    dossier: state.Edit.dossier,
    user: state.User
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTour: value => dispatch(setTour(value)),
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchFamilies: () => dispatch(fetchFamilies()),
    editDossier: dossier => dispatch(editDossier(dossier)),
    saveDossier: dossier => dispatch(saveDossier(dossier)),
    fetchDossier: dossier => dispatch(fetchDossier(dossier))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDossier)
