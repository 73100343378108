import styled from 'styled-components'
import { Form } from 'antd'
import { colors } from 'Common/constants'

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label label {
    text-transform: uppercase;
    font-weight: 500;
    color: ${colors.grey4};
  }
`
