import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import * as _ from 'lodash'
import MainLayout from 'Hoc/MainLayout'
import { StyledH1 } from 'Common/styled'
import { SearchOpecomFilters } from 'Components/SearchOpecomFilters'
import SearchOpecomResults from 'Components/SearchOpecomResults'
import { StyledRow } from './styled'
import { Col } from 'antd'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'
import AppAccess from 'Common/appAccess'
import ErrorPage from 'Components/ErrorPage'
import { saveOpecomFilters } from 'Actions/search-actions'
import { fetchStores } from 'Actions/data-actions'
import Loader from 'Components/Loader'

/**
 * Container for opecom search page
 */
class SearchOpecom extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    isLoading: true,
    redirectTo: null,
    isSearching: false,
    showResults: false,
    currentPage: 1,
    items: [],
    totalItems: null,
    status: null,
    filters: {},
  }

  componentDidMount = () => {
    if (!AppAccess.isAuthorized('opecom')) {
      this.setState({ status: '403' })
    } else {
      const referrer = this.props.location.state
        ? this.props.location.state.referrer
        : undefined
      const prevSearch = this.props.prevSearch

      if (referrer === '/edit-opecom' && prevSearch.filters) {
        this.setState(prevSearch)
        const { code, description, startDate, endDate, type, opecomId, storeId, productCode } = prevSearch.filters
        api.opecom
          .get(
            code,
            description,
            startDate,
            endDate,
            type,
            opecomId,
            storeId,
            productCode,
            prevSearch.currentPage - 1,
            10
          )
          .then(response =>
            this.setState({
              items: [...response.data.elements.map(o => ({ ...o }))],
              totalItems: response.data.numTotElements,
              showResults: true,
              isSearching: false,
            })
          )
          .catch(err => {
            this.setState({ isSearching: false })
            handleServerError(err)
          })
      }
    }

    if (this.props.stores.length === 0) {
      this.props.fetchStores().then(() =>
        this.setState({
          isLoading: false
        })
      )
    } else {
      this.setState({
        isLoading: false
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form } = this.filters.props
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isSearching: true, filters: _.cloneDeep(values) })
        const { code, description, startDate, endDate, type, opecomId, storeId, productCode } = values
        api.opecom
          .get(code, description, startDate, endDate, type, opecomId, storeId, productCode, 0, 10)
          .then(response =>
            this.setState({
              items: [...response.data.elements.map(o => ({...o}))],
              totalItems: response.data.numTotElements,
              showResults: true,
              isSearching: false,
              currentPage: 1,
            })
          )
          .catch(err => {
            this.setState({ isSearching: false })
            handleServerError(err)
          })
      }
    })
  }

  handleViewClick = (opecom, opecomId) => {
    this.props.saveOpecomFilters({
      filters: this.state.filters,
      currentPage: this.state.currentPage,
    })
    this.setState({ redirectTo: `/edit-opecom/${opecomId}` })
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page })
    const { code, description, startDate, endDate, type, opecomId, storeId, productCode } = this.state.filters
    api.opecom
      .get(code, description, startDate, endDate, type, opecomId, storeId, productCode, page - 1, pageSize)
      .then(response =>
        this.setState({
          items: [...response.data.elements.map(o => ({...o}))],
          totalItems: response.data.numTotElements,
        })
      )
  }

  /**
   * Render method
   * @returns {*}
   */
  render() {
    const { stores } = this.props

    const {
      isLoading,
      redirectTo,
      showResults,
      items,
      totalItems,
      currentPage,
      isSearching,
      status,
      filters,
    } = this.state

    return isLoading ? (
      <Loader />
    ) : redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : status ? (
      <ErrorPage status={status} />
    ) : (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        defaultCurrentPage={'opecom'}>
        <StyledH1>Ricerca opecom</StyledH1>
        <StyledRow type="flex" justify="center">
          <Col xs={22} xl={18} xxl={12}>
            <SearchOpecomFilters
              wrappedComponentRef={ref => {
                this.filters = ref
              }}
              onSubmit={this.handleSubmit}
              isSearching={isSearching}
              stores={stores}
              filters={filters}
              user={this.props.user}
              //   user={user}
            />
          </Col>
        </StyledRow>
        {showResults && (
          <SearchOpecomResults
            items={items}
            onViewClick={this.handleViewClick}
            onPageChange={this.handlePageChange}
            totalItems={totalItems}
            currentPage={currentPage}
            stores={stores}
          />
        )}
      </MainLayout>
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => {
  return {
    prevSearch: state.Search.opecom,
    stores: state.Data.stores,
    user: state.User,
  }
}
/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => {
  return {
    saveOpecomFilters: filters => dispatch(saveOpecomFilters(filters)),
    fetchStores: () => dispatch(fetchStores())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchOpecom))
