import styled from 'styled-components'
import { colors } from 'Common/constants'
import { Button } from 'antd'

export const StyledH1 = styled.h1`
  font-size: 1.7rem;
  color: ${colors.main};
  text-align: center;
`

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  height: 6rem !important;
  width: 14rem !important;
  margin: 3rem;
  white-space: normal !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  color: ${colors.grey4} !important;
  &:hover {
    color: ${colors.main} !important;
  }
`
