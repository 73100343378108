import React from 'react';
import { Wrapper } from 'Common/styled'
import { ROLE_AZ_COMM, ROLE_CP, ROLE_CPXS, ROLE_CS, ROLE_MERCH } from 'Common/constants/global'
import ModalConfirm from 'Common/ModalConfirm'
import api from 'Common/api'
import { Badge, Button, Col, Popconfirm, Radio, Row } from 'antd'
import { ActionBar } from 'Components/DossierReferencesTable/styled'
import AppAccess from 'Common/appAccess'
import AgTable from 'Components/AgTable/AgTable'
import * as _ from 'lodash'
import { feedbackNotification, handleServerError, handleServerSuccess } from 'Common/utils'
import * as moment from 'moment';
import {
  DATA_COMPLETAMENTO_GTE_DATA_INIZIO,
  DATA_COMPLETAMENTO_NON_VALIDA, DATA_FINE_EVENTO_NON_VALIDA,
  DATA_INIZIO_EVENTO_NON_VALIDA, STOCK_LT_STOCK_SEDE
} from 'Common/constants/product-errors'

class FamiliesTable extends React.Component {
  state = {
    canSave: false,
    originalRecords: {},
    changedRecords: [],
    recordChanges: {},
    selectedNodes: [],
    fullscreen: false,
    columns: [
      {
        title: 'FAMIGLIA',
        width: 200,
        dataIndex: 'famiglia',
        valueGetter: params => `${params.data.idFamiglia} - ${params.data.nomeFamiglia}`
      },
      
      {
        title: 'REPARTO',
        width: 200,
        dataIndex:'reparto',
        valueGetter: params => `${params.data.idReparto} - ${params.data.nomeReparto}`
      },
      {
        title: 'SOTTOREPARTO',
        width: 200,
        dataIndex:'sottoreparto',
        valueGetter: params => `${params.data.idSottoreparto} - ${params.data.nomeSottoreparto}`
      },
      {
        title: 'TIPO',
        width: 200,
        dataIndex:'tipo',
        valueGetter: params => `${params.data.idTipo} - ${params.data.nomeTipo}`
      },
      {
        title: 'SOTTOTIPO',
        width: 200,
        dataIndex:'sottotipo',
        valueGetter: params => `${params.data.idSottotipo} - ${params.data.nomeSottotipo}`
      },
    ]
  }

  constructor(props, context) {
    super(props, context)
    this.agTable = React.createRef();
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      const { changedRecords } = this.state;

      if (changedRecords.length > 0) {
        return true;
      }
    }
  }

 
  getAllProducts() {
    this.agTable.current.state.gridApi.showLoadingOverlay();
    api.families.search().then((response) => {
      
      this.setState({
        
        originalRecords: response.data.data.reduce((acc, curr) => ({
          ...acc,
          ['id:' + curr.id]: {...curr},
          ['idFamiglia:' + curr.idFamiglia]: {...curr}
        }), {})
      });
      this.agTable.current.loadData(response.data.data.map(row => ({
        ...row,
        errors: []
      })));
      this.agTable.current.state.gridApi.hideOverlay();
    }).catch();
  }

  onAgTableChange = (change) => {
    switch (change.action) {
   
      case 'gridLoaded':
        this.getAllProducts();
        break;

      default:
    }
  };

  exportFamilies = () => {
    const { columns } = this.state;
    let exportData = {visibleColumns: columns.map(column => column.dataIndex)};
    api.families.export(exportData)
      .then(response => feedbackNotification('info', 'Operazione eseguita', 'Attenzione! Il file excel con le famiglie sarà disponibile tra poco e verrà inviato via email al tuo indirizzo.'))
      .catch(handleServerError);
    };

  render() {
    const { fullscreen, selectedNodes, recordChanges, canSave, columns, changedRecords } = this.state;
    const { family, user } = this.props;
    let extraStyles = {};

    if (fullscreen) {
      extraStyles = {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: '#ffffff',
        top: 0,
        left: 0,
        padding: '25px 25px 0 25px',
        zIndex: 999
      };
    }

    return (
      <Wrapper style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', ...extraStyles }}>
        <ActionBar>
          <Row gutter={[16, 16]}>
            <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
              
            </Col>
            <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
              <Button icon={fullscreen ? 'fullscreen-exit' : 'fullscreen'} onClick={() => this.setState({fullscreen: !fullscreen})} />
              <Button icon="download" onClick={this.exportFamilies}>Scarica</Button>
            </Col>
          </Row>
        </ActionBar>
        <Row style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', }}>
          <Col style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <AgTable
              canMakeSelection={true}
              ref={this.agTable}
              columns={columns}
              emitChange={(change) => this.onAgTableChange(change)}
              getIncompleteProductWarnings={this.getIncompleteProductWarnings}
            />
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

export default FamiliesTable;
