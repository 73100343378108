import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import MainLayout from 'Hoc/MainLayout'
import { fetchDepartments, fetchFamilies, fetchStores } from 'Actions/data-actions'
import Loader from 'Components/Loader'
import { EditDossierHeaderModal } from 'Components/EditDossierHeaderModal'
import { EditRefsModal } from 'Components/EditRefsModal'
import api from 'Common/api'
import { Descriptions, Modal, Popconfirm, Button, message } from 'antd'
import {
  getDossierTitle,
  getDossierTypeLabel,
  getDepartmentLabel,
  formatDate,
  handleServerSuccess,
  handleServerError, transformFilters, feedbackNotification
} from 'Common/utils'
import { StyledDescriptions, StyledDiv, StyledButton, StyledTitle } from './styled'
import ErrorPage from 'Components/ErrorPage'
import DossierReferencesTable from 'Components/DossierReferencesTable'
import appAccess from "Common/appAccess";
import { dossierTypes, dossierStates, LM_EMAIL_SUFFIX, NO_ASSIGNABLE_PRODUCT } from 'Common/constants'
import { ROLE_CP, ROLE_GUEST } from 'Common/constants/global'
import * as _ from 'lodash';
import { DuplicateDossierModal } from 'Components/DuplicateDossierModal'
import DuplicateDossierNotification from 'Components/DuplicateDossierNotification'

/**
 * Container description
 */
class EditDossierDetails extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    isLoading: true,
    status: null,
    redirectTo: null,
    redirectState: {},
    dossierHeader: null,
    isHeaderModalVisible: false,
    isSavingHeader: false,
    batchEditMode: null,
    filters: null,
    sorter: null,
    batchEditRefIds: null,
    isRefsModalVisible: false,
    cluster: null,
    showIntroModal: false,
    isDeletingDossier: false,
    isDuplicateModalVisible: false,
    isDuplicatingDossier: false,
    isDuplicationSuccessful: null,
    unassignableProducts: []
  }

  introVersion = '1.0';

  componentDidMount() {
    const id = this.props.match.params.id

    if (localStorage.getItem('LeroyMerlin.DossierDetailIntroVersion') !== this.introVersion && sessionStorage.getItem('LeroyMerlin.DossierDetailIntroVersion') !== this.introVersion) {
      this.setState({
        showIntroModal: true
      });
    }

    let promises = [
      this.props.fetchDepartments(),
      this.props.fetchFamilies(),
      api.dossiers.getOne(id)
    ]

    if (this.props.stores.length === 0) {
      promises.push(this.props.fetchStores())
    }

    Promise.all(promises)
      .then(results => {
        if (results[2].data.elements.length > 0) {
          const dossierHeader = results[2].data.elements[0];

          if (!dossierTypes.filter(type => type.key === dossierHeader.type)[0].canView.includes(this.props.user.role)) {
            this.setState({status: '403'})
          }

          if (dossierHeader.idStore === null) {
            this.setState({
              dossierHeader: dossierHeader,
              isLoading: false
            })
          } else {
            this.setState({
              dossierHeader: dossierHeader,
              cluster: dossierHeader.cluster ? {cluster: dossierHeader.cluster, idFamily: dossierHeader.idFamily, idStore: dossierHeader.idStore} : null,
              isLoading: false
            });

            // api.stores.getCluster(dossierHeader.idStore, dossierHeader.idFamily).then(response => {
            //   this.setState({
            //     dossierHeader: results[2].data.elements[0],
            //     cluster: response.data,
            //     isLoading: false
            //   });
            // }).catch(error => {
            //   this.setState({
            //     dossierHeader: results[2].data.elements[0],
            //     cluster: null,
            //     isLoading: false
            //   });
            // });
          }
        } else {
          this.setState({ status: '404' })
        }
      })
      .catch(error => {
        const status = error.response ? error.response.status : null
        if (status >= 500 && status < 600) {
          this.setState({ status: '500' })
        } else {
          this.setState({ status: 'error' })
        }
      })
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const id = this.props.match.params.id
    const prevId = prevProps.match.params.id

    if (prevId && id !== prevId) {
      this.setState({
        redirectTo: null
      })

      api.dossiers.getOne(id).then(result => {
        if (result.data.elements.length > 0) {
          const dossierHeader = result.data.elements[0];

          if (!dossierTypes.filter(type => type.key === dossierHeader.type)[0].canView.includes(this.props.user.role)) {
            this.setState({status: '403'})
          }

          if (dossierHeader.idStore === null) {
            this.setState({
              dossierHeader: dossierHeader,
              isLoading: false
            })
          } else {
            this.setState({
              dossierHeader: dossierHeader,
              cluster: dossierHeader.cluster ? {cluster: dossierHeader.cluster, idFamily: dossierHeader.idFamily, idStore: dossierHeader.idStore} : null,
              isLoading: false
            });
          }
        } else {
          this.setState({ status: '404' })
        }
      })
      .catch(error => {
        const status = error.response ? error.response.status : null
        if (status >= 500 && status < 600) {
          this.setState({ status: '500' })
        } else {
          this.setState({ status: 'error' })
        }
      })
    }
  }

  toggleHeaderModal = () => {
    this.setState(state => ({
      isHeaderModalVisible: !state.isHeaderModalVisible
    }))
  }

  toggleDuplicateModal = () => {
    this.setState(state => ({
      isDuplicateModalVisible: !state.isDuplicateModalVisible
    }))
  }

  hideRefsModal = () => {
    this.setState({ isRefsModalVisible: false })
  }

  saveHeader = () => {
    const { dossierHeader } = this.state
    const { form } = this.headerModal.props
    form.validateFields((err, values) => {
      if (!err) {
        const installation = values.installation ? {
          installationFrom: formatDate(values.installation[0]),
          installationTo: formatDate(values.installation[1])
        } : values.installationTo ? {
          installationTo: formatDate(values.installationTo)
        } : undefined
        const storeCompletion = values.storeCompletion && {
          storeCompletionFrom: formatDate(values.storeCompletion[0]),
          storeCompletionTo: formatDate(values.storeCompletion[1])
        }
        const installationDate = values.installationDate && {
          installationDate: formatDate(values.installationDate)
        }
        const maxInstallationDate = values.maxInstallationDate && {
          maxInstallationDate: formatDate(values.maxInstallationDate)
        }
        const merchArrivalDate = values.merchArrivalDate && {
          merchArrivalDate: formatDate(values.merchArrivalDate)
        }
        const referenceMerch = values.referenceMerch && {
          referenceMerch: values.referenceMerch + LM_EMAIL_SUFFIX
        }
        const clusterGarden = values.clusterGarden === true;
        this.setState({ isSavingHeader: true })
        api.dossiers
          .post({ ...dossierHeader, ...installation, ...storeCompletion, ...installationDate, ...maxInstallationDate, ...merchArrivalDate, ...referenceMerch, clusterGarden })
          .then(response => {
            handleServerSuccess()
            this.setState({ dossierHeader: response.data })
          }, handleServerError)
          .finally(() =>
            this.setState({
              isSavingHeader: false,
              isHeaderModalVisible: false
            })
          )
      }
    })
  }

  duplicateDossier = values => {
    const { dossierHeader } = this.state
    const formattedValues = {
      // department: values.department,
      // idFamily: values.idFamily,
      // family: dossier.type !== 'PL' ? this.props.families.filter(
      //   family => family.idFamily === values.idFamily
      // )[0].name : null,
      type: values.type,
      description: values.description.trim(),
      installationFrom: formatDate(values.installation[0]),
      installationTo: formatDate(values.installation[1]),
      installationDate: formatDate(values.installationDate),
      merchArrivalDate: formatDate(values.merchArrivalDate),
      maxInstallationDate: formatDate(values.maxInstallationDate),
      storeCompletionFrom: dossierHeader.type !== 'PL' ? formatDate(values.storeCompletion[0]) : null,
      storeCompletionTo: dossierHeader.type !== 'PL' ? formatDate(values.storeCompletion[1]) : null,
      referenceMerch: values.referenceMerch ? values.referenceMerch + LM_EMAIL_SUFFIX : null
    }
    this.setState({ isDuplicatingDossier: true })
    api.dossiers.duplicate(dossierHeader.id, formattedValues)
      .then(response => {
        handleServerSuccess()
        this.setState({ isDuplicationSuccessful: true, unassignableProducts: response.data.unassignableProducts, redirectTo: `/edit-dossier/${response.data.newDossierId}`, isDuplicateModalVisible: false })
      })
      .catch(error => {
        if (error.response.data.type === NO_ASSIGNABLE_PRODUCT) {
          this.setState({ isDuplicationSuccessful: false, unassignableProducts: error.response.data.error })
        } else {
          handleServerError(error)
        }
      })
      .finally(() => this.setState({ isDuplicatingDossier: false }))
  }

  handleBatchEdit = (mode, filters, refIds, sorter) => {
    this.setState({
      batchEditMode: mode,
      filters: filters,
      batchEditRefIds: refIds,
      isRefsModalVisible: true,
      sorter: sorter
    })
  }

  batchSaveRefs = () => {
    const dossierId = this.props.match.params.id
    const { batchEditMode, filters, batchEditRefIds, dossierHeader, sorter } = this.state;
    const values = this.refsModal.props.form.getFieldsValue()
    this.setState({ isBatchSavingRefs: true })

    const filtersDto = transformFilters(filters, this.table.getColumns(dossierHeader), sorter);

    api.dossiers
      .batchSaveRefs(dossierId, batchEditMode, filtersDto, batchEditRefIds, values)
      .then(response => {
        if (response.data.numPddUpdated === false) {
          feedbackNotification('error', 'Salvataggio Num PDD non riuscito', 'Alcuni valori sono già stati assegnati ad altre referenze')
        } else {
          handleServerSuccess()
          this.table.setState({selectedKeys: [], deselectedKeys: [], selectedAll: false})
        }
        this.setState({ isRefsModalVisible: false })
        this.table.fetchData().then(() => this.mergeBatchChangesIntoState(response.data.productIds, values))
      }, handleServerError)
      .finally(() => this.setState({ isBatchSavingRefs: false }))
  }

  mergeBatchChangesIntoState = (productIds, values) => {
    const changes = _.cloneDeep(this.table.state.changes)
    const items = this.table.state.items

    for (const id of productIds) {
      const changeIndex = changes.findIndex(change => change.idReferenza === id)
      const itemIndex = items.findIndex(item => item.key === id)

      if (changeIndex !== -1) {
        for (const field in values) {
          if (values[field]) {
            changes[changeIndex][field] = items[itemIndex][field]
          }
        }
      }      
    }

    this.table.setState({changes: changes})
  }

  redirectToAddRefs = () => {
    const id = this.props.match.params.id
    this.setState({ redirectTo: `/dossier/${id}/add` })
  }

  updateCluster = newCluster => {
    const {dossierHeader} = this.state;

    this.setState({cluster: {cluster: newCluster, idFamily: dossierHeader.idFamily, idStore: dossierHeader.idStore}})

    // api.stores.getCluster(dossierHeader.idStore, dossierHeader.idFamily).then(response => {
    //   this.setState({
    //     cluster: response.data
    //   });
    // }).catch(error => {
    //   this.setState({ cluster: null })
    // });
  };

  deleteDossier = async () => {
    const id = this.props.match.params.id
    this.setState({ isDeletingDossier: true })

    try {
      await api.dossiers.delete(id)
      handleServerSuccess()
      this.setState({ redirectTo: '/search-dossier' })
    } catch (e) {
      handleServerError(e)
    } finally {
      this.setState({ isDeletingDossier: false })
    }
  }

  canDuplicate = () => {
    console.log(_.get(this, 'table.agTable.current.state.gridApi.getModel()', null))
  }

  /**
   * Render method
   * @returns {*}
   */
  render() {
    const {
      isLoading,
      status,
      redirectTo,
      redirectState,
      dossierHeader,
      isHeaderModalVisible,
      isSavingHeader,
      isRefsModalVisible,
      isBatchSavingRefs,
      cluster,
      showIntroModal,
      isDeletingDossier,
      isDuplicateModalVisible,
      isDuplicatingDossier,
      isDuplicationSuccessful,
      unassignableProducts
    } = this.state
    const { departments, user, families, stores } = this.props

    const showDuplicationResult = isDuplicationSuccessful === false || (isDuplicationSuccessful === true && unassignableProducts.length > 0)

    return redirectTo !== null ? (
      <Redirect
        push
        to={{
          pathname: redirectTo,
          state: { referrer: '/edit-dossier', ...redirectState }
        }}
      />
    ) : status ? (
      <ErrorPage status={status} />
    ) : isLoading ? (
      <Loader />
    ) : (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        defaultCurrentPage={'dossier'}>
        <StyledDescriptions
          title={
            <StyledDiv>
              <Button
                icon="left"
                onClick={() =>
                  this.setState({ redirectTo: '/search-dossier' })
                }>
                Indietro
              </Button>
              <StyledTitle>{getDossierTitle(dossierHeader, stores)} [{dossierHeader.id}]</StyledTitle>
              {appAccess.isAuthorized('delete_dossier', {
                stato: dossierHeader.statoDossier,
                type: dossierHeader.type,
                creator: dossierHeader.creationBy,
                installationFrom: dossierHeader.installationFrom,
                installationTo: dossierHeader.installationTo
              }) && (
                <Popconfirm
                  title="Sei sicuro di voler annullare il dossier?"
                  onConfirm={this.deleteDossier}
                  okText="Sì"
                  cancelText="No">
                  <StyledButton type="danger" loading={isDeletingDossier}>
                    Annulla dossier
                  </StyledButton>
                </Popconfirm>
              )}
              {appAccess.isAuthorized('duplicate-dossier', {
                type: dossierHeader.type,
                idStore: dossierHeader.idStore
              }) && (
                <StyledButton onClick={() => {
                  // FIXME bubble table changes and disable button accordingly
                  if (this.table.agTable.current.state.gridApi.getModel().rootNode.allLeafChildren.length === 0) {
                    message.error('Il dossier non può essere duplicato perché è vuoto')
                  } else {
                    this.toggleDuplicateModal()
                  }
                }}>
                  Duplica
                </StyledButton>
              )}
              {appAccess.isAuthorized('edit_dossier_header', {
                stato: dossierHeader.statoDossier,
                type: dossierHeader.type,
                creator: dossierHeader.creationBy,
                installationTo: dossierHeader.installationTo
              }) && (
                <StyledButton onClick={this.toggleHeaderModal}>
                  Modifica testata
                </StyledButton>
              )}
            </StyledDiv>
          }
          size="small"
          bordered
          column={{ xs: 1, sm: 2, md: 3, xl: 4 }}>
          <Descriptions.Item label="Tipo">
            {getDossierTypeLabel(dossierHeader.type)}
          </Descriptions.Item>
          <Descriptions.Item label="Reparto">
            {getDepartmentLabel(dossierHeader.department, departments)}
          </Descriptions.Item>
          {dossierHeader.type !== 'PL' && <Descriptions.Item label="Famiglia merchandising">
            {dossierHeader.family}
          </Descriptions.Item>}
          <Descriptions.Item label="Descrizione">
            {dossierHeader.description}
          </Descriptions.Item>
          {dossierHeader.type === 'PL' && (
            <Descriptions.Item label="Data arrivo merce">
              {`${formatDate(
                dossierHeader.merchArrivalDate,
                'YYYY-MM-DD',
                'DD/MM/YYYY'
              )}`}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            label={
              dossierTypes.filter(type => type.key === dossierHeader.type)[0]
                .installationLabel
            }>{`${formatDate(
            dossierHeader.installationFrom,
            'YYYY-MM-DD',
            'DD/MM/YYYY'
          )} ~ ${formatDate(
            dossierHeader.installationTo,
            'YYYY-MM-DD',
            'DD/MM/YYYY'
          )}`}</Descriptions.Item>
          {dossierHeader.type !== 'PL' && <Descriptions.Item label="Periodo completamento negozio">
            {`${formatDate(
              dossierHeader.storeCompletionFrom,
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )} ~ ${formatDate(
              dossierHeader.storeCompletionTo,
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )}`}
          </Descriptions.Item>}
          {cluster && ['NG', 'RG', 'ST'].includes(dossierHeader.type) && (
            <Descriptions.Item label="Data impianto negozio">
              {`${formatDate(
                dossierHeader.installationDate,
                'YYYY-MM-DD',
                'DD/MM/YYYY'
              )}`}
            </Descriptions.Item>
          )}
          {!cluster && dossierHeader.type === 'ST' && (
            <Descriptions.Item label="Data massima impianto">
              {`${formatDate(
                dossierHeader.maxInstallationDate,
                'YYYY-MM-DD',
                'DD/MM/YYYY'
              )}`}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Stato">
            {dossierStates.filter(state => state.key === dossierHeader.statoDossier)[0].label}
          </Descriptions.Item>
          {[ROLE_CP, ROLE_GUEST].includes(user.role) && ['NG', 'RG', 'ST'].includes(dossierHeader.type) && (
            <>
              <Descriptions.Item label="Creato da">
                {dossierHeader.creationBy.slice(0, dossierHeader.creationBy.indexOf('@'))}
              </Descriptions.Item>
              <Descriptions.Item label="Merch di riferimento">
                {dossierHeader.referenceMerch ? dossierHeader.referenceMerch.slice(0, dossierHeader.referenceMerch.indexOf('@')) : null}
              </Descriptions.Item>
            </>
          )}
        </StyledDescriptions>
        <DossierReferencesTable
          ref={ref => {
            this.table = ref
          }}
          updateClusterCallback={this.updateCluster}
          dossier={dossierHeader}
          cluster={cluster}
          onBatchEdit={this.handleBatchEdit}
          onAddRefs={this.redirectToAddRefs}
          user={user}
          departments={departments}
          families={families}
          stores={stores}
        />
        <EditDossierHeaderModal
          wrappedComponentRef={ref => {
            this.headerModal = ref
          }}
          visible={isHeaderModalVisible}
          onCancel={this.toggleHeaderModal}
          onSave={this.saveHeader}
          dossierHeader={dossierHeader}
          isSavingHeader={isSavingHeader}
          user={user}
        />
        <EditRefsModal
          wrappedComponentRef={ref => {
            this.refsModal = ref
          }}
          visible={isRefsModalVisible}
          onCancel={this.hideRefsModal}
          cluster={cluster}
          onSave={this.batchSaveRefs}
          isBatchSavingRefs={isBatchSavingRefs}
          user={user}
          dossierHeader={dossierHeader}
          departments={departments}
          families={families}
        />
        <DuplicateDossierModal
          wrappedComponentRef={ref => {
            this.duplicateModal = ref
          }}
          visible={isDuplicateModalVisible}
          onCancel={this.toggleDuplicateModal}
          onSave={this.duplicateDossier}
          dossierHeader={dossierHeader}
          isDuplicatingDossier={isDuplicatingDossier}
          user={user}
          departments={departments}
          families={families}
        />
        {showDuplicationResult && <DuplicateDossierNotification
          visible={showDuplicationResult}
          success={isDuplicationSuccessful}
          items={unassignableProducts}
          onOk={() => this.setState({isDuplicationSuccessful: null})}
        />}
        <Modal
          title="Tutorial nuova griglia"
          visible={showIntroModal}
          width={608}
          cancelText="Visualizza più tardi"
          okText="Conferma"
          closable={false}
          maskClosable={false}
          onOk={() => {
            localStorage.setItem('LeroyMerlin.DossierDetailIntroVersion', this.introVersion);
            this.setState({
              showIntroModal: false
            });
          }}
          onCancel={() => {
            sessionStorage.setItem('LeroyMerlin.DossierDetailIntroVersion', this.introVersion);
            this.setState({
              showIntroModal: false
            });
          }}
        >
          <video preload={true} width={560} height={315} controls={true}>
            <source src="https://drive.google.com/uc?export=download&amp;id=1lkShjhLgy3UET1iWI5VGPwkfed6ZOVB-" type="video/mp4" />
          </video>
        </Modal>
      </MainLayout>
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => {
  return {
    departments: state.Data.departments,
    families: state.Data.families,
    stores: state.Data.stores,
    user: state.User
  }
}

/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => {
  return {
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchFamilies: () => dispatch(fetchFamilies()),
    fetchStores: () => dispatch(fetchStores())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditDossierDetails))
