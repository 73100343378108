import React, { useEffect, useState } from 'react'
import { AutoComplete } from 'antd'
import useBus from 'use-bus'
import { EVENT_SIM_ADDREF_DIALOG_OPEN } from 'Common/constants/event-constants'
import api from 'Common/api'

export default function AddProductAutoComplete({ onSelectValue, ...props }) {

  const [selectedValue, setSelectedValue] = useState(null);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    if (onSelectValue) {
      onSelectValue(selectedValue);
    }
  }, [selectedValue]);

  const handleSearchChange = value => {
    if (value.length < 3) {
      setProductOptions([]);
      return;
    }

    api.products.search({ codedesc: value }).then(({ data }) => {
      setProductOptions(data.map(item => ({
        value: item.code,
        text: `${item.code} - ${item.description}`
      })));
    }).catch(console.error);
  };

  const handleSearchSelection = value => {
    setSelectedValue(value);
  };

  return (
    <AutoComplete
      dataSource={productOptions}
      placeholder="Cerca per referenza o descrizione"
      style={{ width: '100%' }}
      onChange={handleSearchChange}
      onSelect={handleSearchSelection}
      {...props}
    />
  )
}
