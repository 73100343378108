import React from 'react';
import {Col, Form, Icon, Row} from "antd";
import {StyledAlert, StyledButton, StyledCard, StyledCollapse, StyledForm, StyledLinkButton} from "Common/styled";
import {deleteFromSession, getFormFields, getFromSession, setToSession} from "Common/utils";
import * as _ from 'lodash';
import api from 'Common/api';
import {REFERENCE_STATE} from "Common/globals";

/**
 * Search products form
 * @reactProps {!array} departments - List of departments
 * @reactProps {!array} families - List of families
 */
const SearchProductsForm = Form.create({name: 'search_products_form'})(
class extends React.Component {
    /**
     * Component's state
     * @type {{collapsed: boolean, categories: [], subcategories: [], subdepartments: []}}
     */
    state = {
        collapsed: false,
        subdepartments: [],
        categories: [],
        subcategories: [],
        showNewRefsAlert: false,
        allDepartmentsSelected: false
    };

    /**
     * On component mount
     */
    componentDidMount() {
        const {dossier, form, families, newRefsCount} = this.props;

        if (!_.isNil(dossier)) {
            if (dossier.type !== 'PS') {
                // Set default department and family based on dossier
                const newFieldsValue = {department: dossier.department};
                if (dossier.type !== 'XS' && _.findIndex(families, family => dossier.idFamily === family.idFamily && family.idDepartment === dossier.department) > -1) {
                    newFieldsValue.family = dossier.idFamily;
                }

                form.setFieldsValue(newFieldsValue);
                this.handleOnChangeDepartment(dossier.department);
            }

            const sessionDossierRefsCount = getFromSession([`Dossier-${dossier.id}`, 'References', 'Count'], null);
            let sessionBlock = false;

            if (!_.isNil(sessionDossierRefsCount) && sessionDossierRefsCount !== newRefsCount) {
                deleteFromSession([`Dossier-${dossier.id}`, 'References', 'Count']);
            } else if (!_.isNil(sessionDossierRefsCount)) {
                sessionBlock = true;
            }

            if (!_.isNil(newRefsCount) && newRefsCount > 0 && !sessionBlock) {
                this.setState({showNewRefsAlert: true});
            }
        }
    }

    /**
     * Get which department ids are allowed based on criteria
     * @returns {Array}
     */
    getAllowedDepartmentIds = () => {
        const {form} = this.props;
        const departments = _.get(this.props, 'departments', []);
        const families = _.get(this.props, 'families', []);
        let allowedDepartmentIds = departments.map(item => item.id);

        let familyIds = form.getFieldValue('family');
        if (!_.isArray(familyIds)) familyIds = [];

        if (familyIds.indexOf('all') === -1 && familyIds.length > 0) {
            allowedDepartmentIds = allowedDepartmentIds.filter(item => families.filter(family => familyIds.indexOf(family.idFamily) > -1).map(family => family.idDepartment).indexOf(item) > -1);
        }

        return _.uniq(allowedDepartmentIds);
    };

    /**
     * Get which family ids are allowed based on criteria
     * @returns {Array}
     */
    getAllowedFamilyIds = () => {
        const {form} = this.props;
        const departmentId = form.getFieldValue('department');
        let families = _.get(this.props, 'families', []);

        if (!_.isNil(departmentId)) {
            families = families.filter(family => family.idDepartment === departmentId);
        }

        let allowedFamilyIds = families.map(item => item.idFamily);

        return _.uniq(allowedFamilyIds);
    };

    /**
     * Method to filter departments based on criteria
     * @returns {*}
     */
    getFilteredDepartments = () => {
        const departments = _.get(this.props, 'departments', []);
        const allowedDepartmentIds = this.getAllowedDepartmentIds();

        let filteredDepartments = departments.filter(item => allowedDepartmentIds.indexOf(item.id) > -1);

        if (this.props.user.role === 'CPXS') {
            filteredDepartments.unshift({ id: 0, label: 'TUTTI' });
        }

        return filteredDepartments;
    };

    /**
     * Method to filter families based on criteria
     * @returns {*}
     */
    getFilteredFamilies = () => {
        const families = _.get(this.props, 'families', []);
        const allowedFamilyIds = this.getAllowedFamilyIds();

        return families.filter(item => allowedFamilyIds.indexOf(item.idFamily) > -1);
    };

    /**
     * Map families' array to be valid for form generation
     * @param item
     * @returns {{id: number, label: *}}
     */
    mapFamilies = item => {
        return {
            id: item.idFamily,
            label: item.name
        };
    };

    /**
     * Handler when value changes for department
     * @param value
     */
    handleOnChangeDepartment = (value) => {
        const {form} = this.props;
        let familyIds = form.getFieldValue('family');
        if (!_.isArray(familyIds)) familyIds = [];
        const allowedFamilyIds = this.getAllowedFamilyIds();
        familyIds = familyIds.filter(id => allowedFamilyIds.indexOf(id) > -1);

        if (allowedFamilyIds.indexOf(form.getFieldValue('family')) === -1) {
            form.setFieldsValue({family: null});
        }

        if (value === 0) {
            this.setState({ allDepartmentsSelected: true });
        }
        else {
            this.setState({ allDepartmentsSelected: false });
        }

        this.fetchSubdepartments(value);
    };

    /**
     * Handler when value changes for family
     * @param value
     */
    handleOnChangeFamily = (value) => {
        const {form} = this.props;
        let departmentId = form.getFieldValue('department');
        if (this.getAllowedDepartmentIds().indexOf(departmentId) === -1) {
            form.setFieldsValue({department: undefined});
        }
    };

    /**
     * Handler when value changes for subdepartment
     * @param value
     */
    handleOnChangeSubdepartment = (value) => {
        this.fetchCategories(value).then(() => {});
    };

    /**
     * Handler when value changes for category
     * @param value
     */
    handleOnChangeCategory = (value) => {
        this.fetchSubcategories(value).then(() => {});
    };

    /**
     * Call API and fetch subdepartments
     * @param departmentId
     */
    fetchSubdepartments = (departmentId) => {
        const {form} = this.props;

        if (_.isNil(departmentId)) {
            this.setState({subdepartments: [], categories: [], subcategories: []});
            form.setFieldsValue({subdepartment: [], category: [], subcategory: []});
        } else {
            api.subdepartments.get(departmentId).then(response => {
                this.setState({subdepartments: response.data, categories: [], subcategories: []});
                form.setFieldsValue({subdepartment: [], category: [], subcategory: []});
            });
        }
    };

    /**
     * Call API and fetch categories
     * @param subdepartmentIds
     * @returns {Promise<void>}
     */
    fetchCategories = async (subdepartmentIds) => {
        const {form} = this.props;
        let {subdepartments} = this.state;
        const departmentId = form.getFieldValue('department');
        let categories = [];

        if (!_.isNil(departmentId)) {
            if (!_.isArray(subdepartmentIds)) {
                subdepartmentIds = [];
            }

            subdepartments = subdepartments.filter(subdepartment => subdepartmentIds.indexOf(subdepartment.id) > -1);

            let response;

            for (let a = 0; a < subdepartments.length; a++) {
                const categoryObj = {
                    index: a,
                    ...subdepartments[a],
                    isGroup: true,
                    data: []
                };

                response = await api.categories.get(departmentId, subdepartments[a].id);

                response.data.forEach(category => categoryObj.data.push({
                    id: category.id,
                    label: category.label,
                    idDepartment: departmentId,
                    idSubDepartment: subdepartments[a].id
                }));

                categories.push(categoryObj);
            }

            this.setState({categories: categories, subcategories: []});
            form.setFieldsValue({category: [], subcategory: []});
        }
    };

    /**
     * Call API and fetch subcategories
     * @param keys
     * @returns {Promise<void>}
     */
    fetchSubcategories = async (keys) => {
        const {form} = this.props;
        let {categories} = this.state;
        const departmentId = form.getFieldValue('department');
        let subcategories = [];

        if (!_.isNil(departmentId) && _.isArray(keys)) {
            let response;

            const allowRules = {};

            keys.forEach(key => {
                const split = key.split('.');
                const groupId = Number(split[0]);
                const itemId = Number(split[1]);
                if (!allowRules.hasOwnProperty(groupId)) allowRules[groupId] = [];
                allowRules[groupId].push(itemId);
            });

            categories = categories.filter((item, index) => allowRules.hasOwnProperty(index)).map((item, index) => ({...item, data: item.data.filter((secondItem, secondIndex) => allowRules[index].indexOf(secondIndex) > -1)}));

            for (let a = 0; a < categories.length; a++) {
                const category = categories[a];
                const mapCategoryNames = {};
                category.data.forEach(item => {
                    if (!mapCategoryNames.hasOwnProperty(item.id)) {
                        mapCategoryNames[item.id] = item.label;
                    }
                });
                response = await api.subcategories.get(departmentId, category.id, category.data.map(item => item.id), false);
                const map = {};
                response.data.forEach(subcategory => {
                    if (!map.hasOwnProperty(subcategory.categoryId)) map[subcategory.categoryId] = [];
                    map[subcategory.categoryId].push({
                        id: subcategory.subCategoryId,
                        label: subcategory.label,
                        idCategory: subcategory.categoryId,
                        idSubdepartment: category.id
                    });
                });

                for (const key in map) {
                    if (!map.hasOwnProperty(key)) continue;
                    subcategories.push({
                        id: `${category.id}.${key}`,
                        label: `${category.label} - ${_.get(mapCategoryNames, key, '')}`,
                        isGroup: true,
                        data: map[key]
                    });
                }
            }

            this.setState({subcategories: subcategories});
            form.setFieldsValue({subcategory: []});
        }
    };

    /**
     * Form submit handler
     * @param ev
     * @returns {boolean}
     */
    handleSubmit = (ev = null) => {
        const {form} = this.props;
        const {categories, subcategories} = this.state;
        const values = form.getFieldsValue();

        const searchObj = {
            department: null,
            dossier: null,
            refs: null,
            productDescription: null,
            family: null,
            topxs: null,
            subdepartments: [],
            state: null,
            productLine: [],
            assignableOnly: true,
            flagSimcom: null
        };

        searchObj.department = _.get(values, 'department', null);
        searchObj.productCodes = _.get(values, 'productCodes', []);
        searchObj.productDescription = _.get(values, 'description', null);
        searchObj.family = _.get(values, 'family', null);
        searchObj.topxs = values.topxs === 'yes' ? true : (values.topxs === 'no' ? false : null);
        searchObj.state = _.get(values, 'state', null);
        searchObj.productLine = _.get(values, 'productLine', []);
        searchObj.assignableOnly = _.get(values, 'assignableOnly', 'yes') === 'yes';
        searchObj.flagSimcom = _.get(values, 'flagSimcom', null);

        if (searchObj.flagSimcom === 'simbolo') {
            searchObj.flagSimcom = true;
        } else if (searchObj.flagSimcom === 'complementare') {
            searchObj.flagSimcom = false;
        } else {
            searchObj.flagSimcom = null;
        }
        
        const mapCategoriesBySubDepartment = {};
        const mapSubcategoriesByCategoryAndSubdepartment = {};

        _.get(values, 'category', []).forEach(key => {
            const split = key.split('.');
            const category = _.get(categories, [Number(split[0]), 'data', Number(split[1])], null);
            if (category === null) return;

            if (!mapCategoriesBySubDepartment.hasOwnProperty(category.idSubDepartment)) {
                mapCategoriesBySubDepartment[category.idSubDepartment] = [];
            }
            mapCategoriesBySubDepartment[category.idSubDepartment].push(category.id);
        });

        _.get(values, 'subcategory', []).forEach(key => {
            const split = key.split('.');
            const subcategory = _.get(subcategories, [Number(split[0]), 'data', Number(split[1])], null);
            if (subcategory === null) return;
            if (!mapSubcategoriesByCategoryAndSubdepartment.hasOwnProperty(`${subcategory.idSubdepartment}.${subcategory.idCategory}`)) {
                mapSubcategoriesByCategoryAndSubdepartment[`${subcategory.idSubdepartment}.${subcategory.idCategory}`] = [];
            }
            mapSubcategoriesByCategoryAndSubdepartment[`${subcategory.idSubdepartment}.${subcategory.idCategory}`].push(subcategory.id);
        });

        _.get(values, 'subdepartment', []).forEach(subdepartment => {
            const subdepartmentObj = {
                subdepartment: subdepartment,
                categories: []
            };

            _.get(mapCategoriesBySubDepartment, subdepartment, []).forEach(categoryId => {
                subdepartmentObj.categories.push({
                    category: categoryId,
                    subcategories: _.get(mapSubcategoriesByCategoryAndSubdepartment, `${subdepartment}.${categoryId}`, [])
                });
            });

            searchObj.subdepartments.push(subdepartmentObj);
        });

        _.invoke(this.props, 'onSubmit', searchObj);

        if (!_.isNil(ev)) {
            ev.preventDefault();
        }

        return false;
    };

    /**
     * Method to return all fields
     * @returns {({data: *, label: string, type: string, key: string, required: boolean, props: {onChange: (function(*=): void)}}|{all: string, selectAll: boolean, data: *, multiple: boolean, label: string, type: string, key: string, props: {onChange: (function(*=): void)}}|{all: string, selectAll: boolean, data: *, getOptionValue: (function(*, *, *): string), multiple: boolean, getOptionKey: (function(*, *, *): string), label: string, type: string, key: string, props: {onChange: (function(*=): void)}}|{all: string, selectAll: boolean, data, getOptionValue: (function(*, *, *): string), multiple: boolean, getOptionKey: (function(*, *, *): string), label: string, type: string, key: string}|{data: *, fieldDecorator: {initialValue: null}, label: string, type: string, key: string, props: {onChange: (function(*=): void)}})[]}
     */
    getFields = () => {
        const {subdepartments, categories, subcategories} = this.state;
        const {dossier, form} = this.props;

        const fields = [
            {
                key: 'department',
                label: 'REPARTO',
                required: true,
                type: 'list',
                data: this.getFilteredDepartments(),
                props: {
                    onChange: (value) => this.handleOnChangeDepartment(value)
                }
            },
            {
                key: 'subdepartment',
                label: 'SOTTOREPARTO',
                type: 'list',
                all: 'Tutti i sottoreparti',
                selectAll: false,
                data: subdepartments,
                multiple: true,
                props: {
                    onChange: (value) => this.handleOnChangeSubdepartment(value),
                    disabled: this.state.allDepartmentsSelected
                }
            },
            {
                key: 'category',
                label: 'TIPO',
                type: 'list',
                selectAll: false,
                all: 'Tutti i tipi',
                data: categories,
                multiple: true,
                getOptionKey: (option, index, groupIndex) => `${index}.${groupIndex}`,
                getOptionValue: (option, index, groupIndex) => `${index}.${groupIndex}`,
                props: {
                    onChange: (value) => this.handleOnChangeCategory(value),
                    disabled: this.state.allDepartmentsSelected
                }
            },
            {
                key: 'subcategory',
                label: 'SOTTOTIPO',
                type: 'list',
                selectAll: false,
                all: 'Tutti i sottotipi',
                data: subcategories,
                multiple: true,
                getOptionKey: (option, index, groupIndex) => `${index}.${groupIndex}`,
                getOptionValue: (option, index, groupIndex) => `${index}.${groupIndex}`,
                props: {
                    disabled: this.state.allDepartmentsSelected
                }
            },
            {
                key: 'family',
                label: 'FAMIGLIA MERCEOLOGICA',
                type: 'list',
                data: this.getFilteredFamilies().map(this.mapFamilies),
                props: {
                    onChange: (value) => this.handleOnChangeFamily(value),
                    allowClear: true,
                    showSearch: true,
                    filterOption: (input, option) => {
                        if (_.isNil(input)) {
                            return true;
                        }

                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1
                    }
                }
            },
            {
                key: 'productCodes',
                label: 'REFERENZA',
                type: 'list',
                data: [],
                props: {
                    mode: 'tags',
                    getInputElement: () => <input onPaste={ev => {
                        const clipboardText = ev.clipboardData.getData('Text');

                        if (_.isString(clipboardText)) {
                            const split = clipboardText.split("\n");
                            let val = form.getFieldValue('productCodes');

                            if (!_.isArray(val)) {
                                val = [];
                            }

                            split.forEach(item => {
                                let itemRef = _.trim(item);

                                if (_.isNil(itemRef) || itemRef.length === 0 || val.indexOf(itemRef) > -1) {
                                    return;
                                }

                                val.push(itemRef);
                            });

                            form.setFieldsValue({
                                productCodes: val
                            });
                        }
                        ev.preventDefault();
                        return false;
                    }} />
                }
            },
            {
                key: 'description',
                label: 'DESCRIZIONE',
                type: 'string'
            },
            {
                key: 'topxs',
                label: 'TOP XS',
                type: 'list',
                data: [{id: null, label: 'Tutti'}, {id: 'no', label: 'No'}, {id: 'yes', label: 'Sì'}],
                disableIdInLabel: true,
                fieldDecorator: {
                    initialValue: dossier && dossier.type === 'XS' ? 'yes' : null
                }
            },
            {
                key: 'state',
                label: 'STATO',
                type: 'list',
                data: Object.values(REFERENCE_STATE).map(item => ({id: item.key, label: item.value})),
                fieldDecorator: {
                    initialValue: dossier && dossier.type === 'PL' ? 'AC' : (this.props.defaultValue && this.props.defaultValue.state ? this.props.defaultValue.state : null)
                },
                props: {
                    allowClear: true,
                    disabled: (dossier && dossier.type === 'PL') || (this.props.disabledFields && this.props.disabledFields.state)
                }
            },
            {
                key: 'productLine',
                label: 'GAMMA',
                type: 'list',
                multiple: true,
                disableIdInLabel: true,
                data: [
                    {id: 'A', label: 'A'},
                    {id: 'B', label: 'B'},
                    {id: 'C', label: 'C'},
                    {id: 'D', label: 'D'},
                    {id: 'L', label: 'L'},
                    {id: 'P', label: 'P'},
                    {id: 'R', label: 'R'},
                    {id: 'S', label: 'S'}
                ],
                fieldDecorator: {
                    initialValue: ['A', 'B', 'C', 'L', 'P', 'R', 'S']
                },
                props: {
                    allowClear: true,
                }
            }
        ];

        if (dossier && dossier.type !== 'PS') {
            fields.push({
                key: 'assignableOnly',
                label: 'SOLO ASSEGNABILI',
                type: 'list',
                data: [{id: 'no', label: 'No'}, {id: 'yes', label: 'Sì'}],
                disableIdInLabel: true,
                fieldDecorator: {
                    initialValue: 'yes'
                },
                tooltip: 'Sono assegnabili le referenze non presenti in altri dossier in lavorazione'
            });
        }

        if (dossier && dossier.type === 'PS') {
            fields.push({
                key: 'flagSimcom',
                label: 'TIPOLOGIA REFERENZA',
                type: 'list',
                data: [{id: 'tutto', label: 'Tutto'}, {id: 'simbolo', label: 'Simbolo'}, {id: 'complementare', label: 'Complementare'}],
                disableIdInLabel: true,
                fieldDecorator: {
                    initialValue: 'tutto'
                },
                tooltip: 'Cercare solo prodotti simbolo'
            });
        }

        return fields;
    };

    /**
     * Method to return all fields as components
     * @returns {[]}
     */
    getFieldsMapping = () => {
        const {form} = this.props;
        const {getFieldDecorator} = form;

        return getFormFields(this.getFields(), getFieldDecorator, form);
    };

    /**
     * Returns if search button is disabled or not
     * @returns {boolean}
     */
    isSearchDisabled = () => {
        const {form} = this.props;
        const requiredFields = this.getFields().filter(field => field.required).map(field => field.key);
        const fieldsValue = form.getFieldsValue();

        for (const key in fieldsValue) {
            if (!fieldsValue.hasOwnProperty(key) || requiredFields.indexOf(key) === -1) continue;

            if (_.isNil(fieldsValue[key])) {
                return true;
            }
        }

        return false;
    };

    handleNewRefsAlertDismiss = () => {
        const {dossier, newRefsCount} = this.props;
        setToSession([`Dossier-${dossier.id}`, 'References', 'Count'], newRefsCount);
        this.setState({showNewRefsAlert: false});
    };

    handleNewRefsView = () => {
        const {form, dossier} = this.props;
        form.resetFields();
        form.setFieldsValue({
            family: dossier.idFamily,
            state: REFERENCE_STATE.INI.key,
            productLine: []
        });
        this.handleSubmit();
    };

    /**
     * Component's render
     * @returns {*}
     */
    render() {
        const {collapsed, showNewRefsAlert} = this.state;
        const {form, isSearching} = this.props;

        const buttonColStyle = {
            marginBottom: '28px',
            alignSelf: 'flex-end'
        };

        const buttonStyle = {
            width: '100%'
        };

        return (
            <div>
                <Row gutter={[0, 16]}>
                    <Col xs={{span: 22, offset: 1}} xl={{span: 18, offset: 3}} xxl={{span: 12, offset: 6}}>
                        <StyledCard className={collapsed ? 'form-collapsed' : null}>
                            <div className="container">
                                <StyledForm onSubmit={this.handleSubmit} colon={false}>
                                    {showNewRefsAlert && <Row gutter={16} type="flex" style={{marginBottom: '16px'}}>
                                        <Col span={24}>
                                            <StyledAlert message={(<div>
                                                <span>Ci sono referenze nuove da inserire.</span>
                                                <StyledLinkButton type="link" onClick={this.handleNewRefsView}>Visualizza</StyledLinkButton>
                                            </div>)} type="success" closable showIcon afterClose={this.handleNewRefsAlertDismiss} />
                                        </Col>
                                    </Row>}
                                    <Row gutter={16} type="flex">
                                        {this.getFieldsMapping()}
                                        <Col style={buttonColStyle} span={4} offset={16} onClick={() => form.resetFields()}>
                                            <StyledButton style={buttonStyle}>Reset</StyledButton>
                                        </Col>
                                        <Col style={buttonColStyle} span={4}>
                                            <StyledButton style={buttonStyle} type="primary" htmlType="submit" disabled={this.isSearchDisabled()} loading={isSearching}>Cerca</StyledButton>
                                        </Col>
                                    </Row>
                                </StyledForm>
                            </div>
                            <StyledCollapse shape="circle" onClick={() => this.setState({collapsed: !this.state.collapsed})}>
                                <Icon type={collapsed ? 'down' : 'up'} />
                            </StyledCollapse>
                        </StyledCard>
                    </Col>
                </Row>
            </div>
        );
    }
});

export default SearchProductsForm;
