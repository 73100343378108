import React from 'react'
import { Form, Icon, InputNumber, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import {
  StyledForm,
  CollapsedFormMask,
  StyledFormRow,
  StyledFormItem,
  StyledButton,
  StyledCollapse,
} from './styled'
import { Col, Input, DatePicker } from 'antd'
import { DATE_LOCALE } from 'Common/globals'
import api from 'Common/api'

/**
 * Component for family search filters
 */
export const SearchFamilyFilters = Form.create({
  name: 'family_filters_form',
    mapPropsToFields(props) {
      return {
        familyId: Form.createFormField({
          value: props.filters.familyId,
        }),
        familyName: Form.createFormField({
          value: props.filters.familyName,
        }),
        department: Form.createFormField({
          value: props.filters.department,
        }),
        subdepartment: Form.createFormField({
          value: props.filters.subdepartment,
        }),
        category: Form.createFormField({
          value: props.filters.category,
        }),
        subcategory: Form.createFormField({
          value: props.filters.subcategory,
        }),
        validityStart: Form.createFormField({
          value: props.filters.validityStart,
        }),
        validityEnd: Form.createFormField({
          value: props.filters.validityEnd,
        })
      }
    },
})(
  class extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func,
      isSearching: PropTypes.bool,
    }

    state = {
      collapsed: false,
      departments: [],
      subdepartments: [],
      categories: [],
      subcategories: []
    };

    componentDidMount() {
      const { departments } = this.props;

      this.setState({
        departments: [...(departments || [])]
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.departments !== this.props.departments) {
        this.setState({
          departments: [...(this.props.departments || [])]
        });
      }
    }

    handleReset = () => {
      this.props.form.resetFields()
    }

    handleCollapse = () => {
      this.setState(state => ({
        collapsed: !state.collapsed,
      }))
    }

    handleChangeDepartment = value => {
      if (value) {
        api.subdepartments.get(value).then(r => {
          this.setState({
            subdepartments: r.data,
            categories: [],
            subcategories: []
          });

          this.props.form.setFieldsValue({
            subdepartment: undefined,
            category: undefined,
            subcategory: undefined
          });
        }).catch(e => console.error);
      } else {
        this.setState({
          subdepartments: [],
          categories: [],
          subcategories: []
        });

        this.props.form.setFieldsValue({
          subdepartment: undefined,
          category: undefined,
          subcategory: undefined
        });
      }
    }

    handleChangeSubdepartment = value => {
      const department = this.props.form.getFieldValue('department');

      if (value) {
        api.categories.get(department, value).then(r => {
          this.setState({
            categories: r.data,
            subcategories: []
          });

          this.props.form.setFieldsValue({
            category: undefined,
            subcategory: undefined
          });
        }).catch(e => console.error);
      } else {
        this.setState({
          categories: [],
          subcategories: []
        });

        this.props.form.setFieldsValue({
          category: undefined,
          subcategory: undefined
        });
      }
    }

    handleChangeCategory = value => {
      const department = this.props.form.getFieldValue('department');
      const subdepartment = this.props.form.getFieldValue('subdepartment');

      if (value) {
        api.subcategories.get(department, subdepartment, [value], false).then(r => {
          this.setState({
            subcategories: r.data,
          });

          this.props.form.setFieldsValue({
            subcategory: undefined
          });
        }).catch(e => console.error);
      } else {
        this.setState({
          subcategories: []
        });

        this.props.form.setFieldsValue({
          subcategory: undefined
        });
      }
    }

    render() {
      const { form, onSubmit, isSearching } = this.props
      const { getFieldDecorator } = form
      const { collapsed } = this.state
      return (
        <StyledForm
          className={collapsed ? 'collapsed' : null}
          onSubmit={onSubmit}>
          {collapsed && <CollapsedFormMask />}
          <StyledFormRow gutter={16} type="flex">
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="ID Famiglia"
                colon={false}>
                {getFieldDecorator('familyId')(<InputNumber style={{ width: '100%' }} />)}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Nome"
                colon={false}>
                {getFieldDecorator('familyName')(<Input />)}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Reparto"
                colon={false}>
                {getFieldDecorator('department')(
                  <Select
                    allowClear
                    onChange={this.handleChangeDepartment}>
                    {this.state.departments.map(department => (
                      <Select.Option key={department.id} value={department.id}>
                        {`${department.id}-${department.label}`}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Sottoreparto"
                colon={false}>
                {getFieldDecorator('subdepartment')(
                  <Select
                    allowClear
                    onChange={this.handleChangeSubdepartment}>
                    {this.state.subdepartments.map(o => (
                      <Select.Option key={o.id} value={o.id}>
                        {`${o.id}-${o.label}`}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Tipo"
                colon={false}>
                {getFieldDecorator('category')(
                  <Select
                    allowClear
                    onChange={this.handleChangeCategory}>
                    {this.state.categories.map(o => (
                      <Select.Option key={o.id} value={o.id}>
                        {`${o.id}-${o.label}`}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Sottotipo"
                colon={false}>
                {getFieldDecorator('subcategory')(
                  <Select allowClear>
                    {this.state.subcategories.map(o => (
                      <Select.Option key={o.id} value={o.id}>
                        {`${o.id}-${o.label}`}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Inizio validità"
                colon={false}>
                {getFieldDecorator('validityStart')(
                  <DatePicker
                    className={'generic-datepicker'}
                    locale={DATE_LOCALE}
                    format="DD/MM/YYYY"
                    inputReadOnly={true}
                    disabledDate={currentDate => {
                      const endDate = this.props.form.getFieldValue('validityEnd')
                      return endDate && currentDate.startOf('day').isAfter(endDate.startOf('day'))
                    }}
                  />
                )}
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <StyledFormItem
                className={collapsed ? 'unclickable' : null}
                label="Fine validità"
                colon={false}>
                {getFieldDecorator('validityEnd')(
                  <DatePicker
                    className={'generic-datepicker'}
                    locale={DATE_LOCALE}
                    format="DD/MM/YYYY"
                    inputReadOnly={true}
                    disabledDate={currentDate => {
                      const startDate = this.props.form.getFieldValue('validityStart')
                      return startDate && currentDate.startOf('day').isBefore(startDate.startOf('day'))
                    }}
                  />
                )}
              </StyledFormItem>
            </Col>
            <Col xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
              <StyledButton onClick={this.handleReset}>Reset</StyledButton>
            </Col>
            <Col xs={12} sm={6} md={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
              <StyledButton
                type="primary"
                htmlType="submit"
                loading={isSearching}>
                Cerca
              </StyledButton>
            </Col>
          </StyledFormRow>
          <StyledCollapse shape="circle" onClick={this.handleCollapse}>
            <Icon type={collapsed ? 'down' : 'up'}></Icon>
          </StyledCollapse>
        </StyledForm>
      )
    }
  }
)
