import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class AgTableNotesFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };

    this.valueGetter = this.props.valueGetter;

    this.onChange = this.onChange.bind(this);
  }

  isFilterActive() {
    return this.state.text != null && this.state.text !== '';
  }

  doesFilterPass(params) {
    if(this.valueGetter(params.node) != undefined){
      return this.state.text
      .toLowerCase()
      .split(' ')
      .every(
        (filterWord) =>
          this.valueGetter(params.node)
            .toString()
            .toLowerCase()
            .indexOf(filterWord) >= 0
      );
    }
  }

  getModel() {
    return { value: this.state.text };
  }

  setModel(model) {
    this.state.text = model ? model.value : '';
  }

  afterGuiAttached(params) {
    this.focus();
  }

  focus() {
    window.setTimeout(() => {
      const container = ReactDOM.findDOMNode(this.refs.input);

      if (container) {
        container.focus();
      }
    });
  }

  onChange(event) {
    const newValue = event.target.value;

    if (this.state.text !== newValue) {
      this.setState(
        {
          text: newValue,
        },
        () => {
          this.props.filterChangedCallback();
        }
      );
    }
  }

  render() {
    const style = {
      width: '90%',
      height: '60px',
    };

    return (
      <div style={style}>
        <h4 className='ant-descriptions-item-label ant-descriptions-item-colon' style={{marginTop: '4px' ,textAlign: 'center',fontSize: '15px', fontWeight: '400'}}>Filtra Testo</h4>
        <input
          style={{ marginLeft: '6%' }}
          ref="input"
          value={this.state.text}
          onChange={this.onChange}
          className="ag-input-field-input ag-text-field-input"
        />
      </div>
    );
  }
}