import React from 'react';
import PropTypes from 'prop-types';
import {StyledTable, TableInput, TableInputNumber, TableSelect, TableDatePicker, NoteTextarea, NotePopupIcon} from "./styled";
import * as _ from 'lodash';
import { Icon, Popconfirm, Tooltip, Popover, Button } from 'antd'
import {StyledPagination, Wrapper} from "Common/styled";
import moment from 'moment';
import {num} from 'Common/utils';
import {PaginationCol, PaginationRow} from "Components/SearchDossierResults/styled";
import FilterDropdown from 'Components/FilterDropdown';


/**
 * Component description
 */
class LMTable extends React.Component {
    /**
     * Component's state
     * @type {{searchText: string, data: [], columns: [], selectedKeys: [], changes: [], searchedColumn: string, originalData: [], loading: boolean}}
     */
    state = {
        selectedKeys: [],
        data: [],
        originalData: [],
        columns: [],
        loading: false,
        changes: [],
        searchText: '',
        searchedColumn: '',
        filters: {},
        errors: [],
        confirmationIndex: null,
        confirmationValue: null,
        values: {}
    };

    /**
     * Component mount method
     */
    componentDidMount() {
        const {columns, data} = this.props;
        this.setState({
            columns: this.processColumns(columns),
            values: data.reduce((o, cur) => ({...o, [cur.key]: cur}), {})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {data} = this.props;

        const stateChanges = {};

        if (prevProps.columns !== this.props.columns) {
            const {columns} = this.props;
            stateChanges.columns = this.processColumns(columns)
        }

        if (prevProps.data !== this.props.data) {
            stateChanges.values = data.reduce((o, cur) => ({...o, [cur.key]: cur}), {})
        }

        if (_.size(stateChanges) > 0) {
            this.setState(stateChanges);
        }
    }

    /**
     * Handle page change
     * @param page
     * @param pageSize
     */
    handlePageChange = (page, pageSize) => {
        _.invoke(this.props, 'onPageChange', page, pageSize);
    };

    /**
     * Retrieve pagination component for table
     * @returns {*}
     */
    getPagination = () => {
        return (
            <PaginationRow>
                <PaginationCol xs={{span: 22, offset: 1}} lg={{span: 14, offset: 5}}>
                    <StyledPagination onChange={(page, pageSize) => this.handlePageChange(page, pageSize)} total={_.get(this.props, 'totalItems', 0)} pageSize={25} showLessItems={true} hideOnSinglePage={true} showTotal={(total) => `${total} referenze`} current={this.props.currentPage} />
                </PaginationCol>
            </PaginationRow>
        );
    };

    /**
     * Transform raw columns to structured ones
     * @param {array} columns
     * @returns {[]}
     */
    processColumns = (columns) => {
        const newColumns = [];

        _.forEach(columns, column => {
            let newColumn = _.merge({
                title: null,
                dataIndex: null,
                key: null,
                editable: false,
                contentType: 'string',
                filtrable: false,
                sortable: false,
                searchable: false,
                render: undefined,
                isValid: (record, value) => {
                    return null;
                }
            }, column);

            if (_.isFunction(newColumn.editable)) {
                if (newColumn.customEdit) {
                    const defaultRender = newColumn.render
                    newColumn.render = (value, record) => newColumn.editable(value, record) ? defaultRender(value, record) : this.renderCell(newColumn, record)
                } else {
                    if (newColumn.render) {
                        const defaultRender = newColumn.render
                        newColumn.render = (value, record) => newColumn.editable(value, record) ? this.renderEditCell(newColumn, record) : defaultRender(value, record)
                    } else {
                        newColumn.render = (value, record) => newColumn.editable(value, record) ? this.renderEditCell(newColumn, record) : this.renderCell(newColumn, record)
                    }
                }
            } else if (newColumn.editable && !newColumn.customEdit) {
                newColumn.render = (value, record) => this.renderEditCell(newColumn, record);
            } else if (!newColumn.render) {
                newColumn.render = (value, record) => this.renderCell(newColumn, record);
            }

            // Replaced by block above, kept as fallback

            // if (_.isFunction(newColumn.editable) && newColumn.warning) {
            //     newColumn.render = (value, record) => newColumn.editable(value, record, newColumn) ? this.renderEditCell(newColumn, record) : this.renderCell(newColumn, record);
            // } else if (newColumn.editable === true && !newColumn.customEdit) {
            //     newColumn.render = (value, record) => this.renderEditCell(newColumn, record);
            // } else if (_.isFunction(newColumn.editable) && !newColumn.customEdit) {
            //     newColumn.render = (value, record) => newColumn.editable(value, record) ? this.renderEditCell(newColumn, record) : this.renderCell(newColumn, record);
            // } else if (newColumn.editable && !newColumn.customEdit) {
            //     newColumn.render = (value, record) => this.renderEditCell(newColumn, record);
            // } else if (_.isNil(newColumn.render)) {
            //     newColumn.render = (value, record) => this.renderCell(newColumn, record);
            // }

            if (newColumn.searchable) {
                newColumn = {...newColumn, ...this.getColumnSearchProps(newColumn.dataIndex, newColumn.title, newColumn.cleanTitle, newColumn.contentType, newColumn.precision, newColumn.searchMultiple)};
            }

            if (newColumn.sortable) {
                // newColumn.sorter = (a, b) => this.getColumnSorter(newColumn.dataIndex, newColumn.contentType, a, b);
                newColumn.sorter = true;
                newColumn.sortOrder = this.props.sorter.columnKey === newColumn.dataIndex && this.props.sorter.order
            }

            if (newColumn.filtrable) {
                newColumn = {...newColumn, ...this.getColumnFilterProps(newColumn.dataIndex, newColumn.title, newColumn.filterMultiple)};
            }

            newColumns.push(newColumn);
        });

        return newColumns;
    };

    /**
     * Handle row change
     * @param value
     * @param record
     * @param column
     */
    handleRowChange = (value, record, column) => {
        // const {data} = this.state;
        // const newData = [...data];
        //
        // const index = newData.findIndex(item => item.key === record.key);
        // const item = newData[index];
        // const newItem = {...item};
        // newItem[dataIndex] = value;
        // newData.splice(index, 1, {
        //     ...item,
        //     ...newItem
        // });

        // _.invoke(this.props, 'onRowChange', value, dataIndex, newItem, newData);

        // this.setState({data: newData});
        const {columns, values} = this.state;

        const removeErrors = [];
        const addErrors = [];

        columns.forEach(item => {
            if (item.meetsRequirements !== undefined) {
                const validation = item.meetsRequirements(value, record, column)

                if (validation.success) {
                    removeErrors.push({record, column: item});
                } else {
                    addErrors.push({record, column: item, message: validation.message});
                }
            }
        });

        this.addErrors(addErrors);
        this.removeErrors(removeErrors);

        this.setState({values: {...values, [record.key]: {...values[record.key], [column.dataIndex]: value}}});

        _.invoke(this.props, 'onRowChange', value, record, column.dataIndex);
    };

    /**
     * Get row selection
     * @returns {undefined|{onChange: onChange, getCheckboxProps: (function(*=): *)}}
     */
    getRowSelection = () => {
        /** @type {boolean} selection - Whether the table should have a selection column or not */
        const {selection} = this.props;

        // If selection is false then return undefined
        if (!selection) {
            return null;
        }

        return  {
            /**
             * Method called when selections change
             * @param {array} selectedRowKeys - Selected rows' keys
             * @param {array} selectedRows - Selected rows' objects
             */
            // onChange: (selectedRowKeys, selectedRows) => {
            //     // If parent component sent us an onSelectionChange property, we call it
            //     _.invoke(this.props, 'onSelectionChange', selectedRowKeys, selectedRows);
            // },

            onSelect: (record, selected, selectedRows, nativeEvent) => {
                _.invoke(this.props, 'onSelect', record, selected);
            },

            /**
             * Selected row keys
             */
            selectedRowKeys: _.get(this.props, 'selectedKeys', []),

            /**
             * Method called to retrieve checkbox properties
             * @param {object} record - The row
             * @returns {undefined|object} - Returns an object with checkbox properties or undefined
             */
            getCheckboxProps: record => {
                return _.invoke(this.props, 'selectionCheckboxProps', record);
            },

            /**
             * Pass on select all event
             * @param selected
             * @param selectedRows
             * @param changeRows
             * @returns {*}
             */
            onSelectAll: (selected, selectedRows, changeRows) => _.invoke(this.props, 'onSelectAll', selected, selectedRows, changeRows)
        };
    };

    /**
     * Render edit cell method based on type and options
     * @param col
     * @param record
     * @returns {*}
     */
    renderEditCell = (col, record, extraOpts = {}) => {
        const {errors, confirmationIndex, confirmationValue, values, originalData} = this.state;
        const originalRecord = _.find(originalData, o => o.key === record.key);
        const currentIndex = `${col.dataIndex}.${record.key}`;
        let handleRowChange = value => this.handleRowChange(value, record, col);
        const classes = [];
        extraOpts = {};

        if (_.has(values, [record.key, col.dataIndex])) {
            extraOpts.value = values[record.key][col.dataIndex];
        }

        const errorIndex = errors.findIndex(item => item.column === col.dataIndex && item.record === record.key)

        if (errorIndex > -1) {
            classes.push('ant-input-error');
        }

        let editComponent;
        let warning = false;

        if (_.isFunction(col.warning)) {
            warning = col.warning(record[col.dataIndex], record);
        } else if (_.isBoolean(col.warning)) {
            warning = col.warning;
        }

        if (warning) {
            classes.push('ant-input-warning');
        }

        if (col.removeSpinner === true) {
            classes.push('remove-spinner');
        }

        extraOpts.className = classes.join(' ');

        if (col.validation === true) {
            if (col.validationOpts.onBlurAjaxCall) {
                extraOpts.onBlur = ev => {
                    const target = ev.target;
                    col.validationOpts.onBlurAjaxCall(target.value, record, col).then(response => {
                        // this.handleRowChange(target.value, record, col);
                        this.removeSingleError(record, col);
                        if (col.validationOpts.onSuccess) {
                            col.validationOpts.onSuccess(response, record, this.handleRowChange)
                        }
                    }).catch(error => {
                        this.addSingleError(record, col);
                    });
                }
            }

            if (_.get(col, 'validationOpts.needsConfirmation', false) === true) {
                handleRowChange = value => {
                    if (col.validationOpts.skipConfirmation && col.validationOpts.skipConfirmation(value, record, col) === true) {
                        this.handleRowChange(value, record, col);
                    } else {
                        this.setState({confirmationIndex: currentIndex, confirmationValue: value});
                    }
                }
            }
        }

        if (col.editableSelect) {
            editComponent = (
                <TableSelect {..._.get(col, 'editableSelectOpts', {})} onChange={handleRowChange} defaultValue={_.get(col, 'editableSelectOpts.mode', null) === 'multiple' && !record[col.dataIndex] ? [] : record[col.dataIndex]} {...extraOpts}>
                    {col.editableSelectList.map(item => <TableSelect.Option key={item.id} value={item.id} disabled={_.isArray(record[col.dataIndex]) && record[col.dataIndex].length === col.maxSelectedOpts && !record[col.dataIndex].includes(item.id)}>{item.label}</TableSelect.Option>)}
                </TableSelect>
            )
        } else {
            switch (col.contentType) {
                case 'number':
                case 'money':
                    editComponent = <TableInputNumber min={0} value={record[col.dataIndex]} onChange={handleRowChange} {...extraOpts} />;
                    break;
                case 'integer':
                    editComponent = <TableInputNumber min={col.minValue ? col.minValue(record, originalRecord) : 0} precision={0} value={record[col.dataIndex]} onChange={handleRowChange} {...extraOpts} />;
                    break;
                case 'decimal':
                    editComponent = <TableInputNumber min={col.minValue ? col.minValue(record, originalRecord) : 0} precision={col.precision ? col.precision : 2} decimalSeparator=',' value={record[col.dataIndex]} onChange={handleRowChange} disabled={col.disabled ? col.disabled(record) : false} {...extraOpts} />;
                    break;
                case 'date':
                    editComponent = <TableDatePicker {...extraOpts} value={record[col.dataIndex] ? moment(record[col.dataIndex]) : null} format={col.format ? col.format : 'DD/MM/YYYY'} onChange={date => handleRowChange(date ? date.format('YYYY-MM-DD') : null)} placeholder='Seleziona data' />
                    break;
                case 'note':
                    editComponent = (
                        <Popover
                            placement="left"
                            content={
                                <NoteTextarea
                                    rows={5}
                                    onChange={ev => this.handleRowChange(ev.target.value, record, col)}
                                    defaultValue={record[col.dataIndex]}
                                    placeholder="Inserisci una nota"
                                />
                            }
                            trigger="click"
                        >
                            <Button type="link" size="small" title="Inserisci una nota">
                                <NotePopupIcon type="form" active={record[col.dataIndex]} />
                            </Button>
                        </Popover>
                    )
                    break;
                default:
                    editComponent = <TableInput defaultValue={record[col.dataIndex]} onChange={ev => this.handleRowChange(ev.target.value ? ev.target.value : null, record, col)} {...extraOpts} />
            }
        }

        if (errorIndex > -1 && errors[errorIndex].message) {
            editComponent = <Tooltip title={errors[errorIndex].message}>
                {editComponent}
            </Tooltip>
        }

        if (warning) {
            let warningText = col.warningText;

            if (_.isFunction(warningText)) {
                warningText = warningText(record[col.dataIndex], record)
            }

            editComponent = <Tooltip title={warningText}>
                {editComponent}
                <Icon type="exclamation-circle" theme="filled" style={{marginLeft: '5px', color: 'rgba(250, 173, 20, 1)', position: 'absolute', top: '50%', transform: 'translateY(-50%)', zIndex: 1}}/>
            </Tooltip>
        } else if (_.get(col, 'validationOpts.needsConfirmation', false) === true) {
            editComponent = <Popconfirm
                title={_.get(col, 'validationOpts.confirmationText', 'Sei sicuro di voler procedere con questa operazione?')}
                visible={confirmationIndex === currentIndex}
                onConfirm={() => {
                    this.handleRowChange(confirmationValue, record, col);
                    this.setState({confirmationIndex: null, confirmationValue: null});
                }}
                onCancel={(ev) => {
                    this.setState({confirmationIndex: null, confirmationValue: null});
                }}
                okText="Conferma"
                cancelText="Annulla"
            >
                {editComponent}
            </Popconfirm>
        }

        return editComponent;
    };

    /**
     * Render cell based on type
     * @param col
     * @param record
     * @returns {string|*}
     */
    renderCell = (col, record) => {
        switch (col.contentType) {
            case 'money':
                return _.isNil(record[col.dataIndex]) ? null : num(record[col.dataIndex]).format('$0,0.00');

            case 'date':
                return record[col.dataIndex] !== null ? moment(record[col.dataIndex]).format(_.get(col, 'format', 'DD/MM/YYYY')) : '';

            case 'bool':
            case 'boolean':
                return record[col.dataIndex] === true ? 'Sì' : 'No';

            case 'decimal':
                return record[col.dataIndex] !== null ? parseFloat(record[col.dataIndex]).toFixed(col.precision ? col.precision : 2).replace('.', ',') : '';

            case 'note':
                return (
                    <Popover
                        content={
                            <p style={{marginBottom: 0}}>{record[col.dataIndex]}</p>
                        }
                        trigger="click"
                        placement="left">
                        <Button type="link" size="small" title="Visualizza la nota">
                            <NotePopupIcon type="eye" active={record[col.dataIndex]} />
                        </Button>
                    </Popover>
                )

            default:
                return record[col.dataIndex];
        }
    };

    /**
     * Get column sorter
     * @param dataIndex - key inside data record
     * @param contentType - type of content provided
     * @param a - compare item
     * @param b - item with which to compare a
     * @returns {number}
     */
    getColumnSorter = (dataIndex, contentType, a, b) => {
        return a[dataIndex] - b[dataIndex];
    };

    /**
     * Get search field
     * @param dataIndex
     * @param title
     * @returns {{filterDropdown: (function({setSelectedKeys: *, selectedKeys?: *, confirm?: *, clearFilters?: *}): *), filterIcon: (function(*): *), onFilter: (function(*, *): boolean), onFilterDropdownVisibleChange: onFilterDropdownVisibleChange}}
     */
    getColumnSearchProps = (dataIndex, title, cleanTitle, contentType, precision, searchMultiple) => ({
        filteredValue: this.props.filters[dataIndex] || [],
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <FilterDropdown
                filterType='search'
                multiple={searchMultiple}
                title={title}
                cleanTitle={cleanTitle}
                contentType={contentType ? contentType : 'string'}
                precision={precision}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
            />
        ),
        filterIcon: filtered => (
          <Icon type={contentType === 'date' ? 'calendar' : 'search'} style={{ color: filtered ? '#66CC33' : undefined }} />
        )
    });

    /**
     * Get filter field
     * @param dataIndex
     * @param title
     * @returns *
     */
    getColumnFilterProps = (dataIndex, title, filterMultiple) => ({
        filteredValue: this.props.filters[dataIndex] || [],
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
            <FilterDropdown
                filterType='filter'
                filters={filters}
                multiple={filterMultiple}
                title={title}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
            />
        ),
        filterIcon: filtered => (
            <Icon type="filter" theme="filled" style={{ color: filtered ? '#66CC33' : undefined }} />
        )
    });

    /**
     * Handle search
     * @param selectedKeys
     * @param confirm
     * @param dataIndex
     */
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        _.invoke(this.props, 'onSearch', selectedKeys, dataIndex);
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    /**
     * Handle filtering
     * @param selectedKeys
     * @param setSelectedKeys
     * @param confirm
     * @param dataIndex
     */
    handleFilter = (selectedKeys, setSelectedKeys, confirm, dataIndex) => {
        confirm();
        _.invoke(this.props, 'onFilter', selectedKeys, dataIndex);
    };

    /**
     * Handle filter reset
     * @param clearFilters
     * @param dataIndex
     */
    handleFilterReset = (clearFilters, dataIndex) => {
        clearFilters();
        const filters = {...this.state.filters};
        delete filters[dataIndex];
        this.setState({
            filters: filters
        });
        _.invoke(this.props, 'onFilterReset', dataIndex);
    };

    /**
     * Handle search reset
     * @param clearFilters
     */
    handleSearchReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        _.invoke(this.props, 'onSearchReset');
    };

    handleTableChange = (pagination, filters, sorter) => {
        _.invoke(this.props, 'onTableChange', filters, sorter);
    };

    addError = (record, column, message, errors) => {
        const errorObj = {
            column: column.dataIndex,
            record: record.key,
            message: message
        };

        const errorIndex = errors.findIndex(error => error.column === errorObj.column && error.record === record.key)

        if (errorIndex === -1) {
            errors.push(errorObj);
        } else if (errors[errorIndex].message !== message) {
            errors[errorIndex].message = message
        }

        return errors;
    };

    removeError = (record, column, errors) => {
        return errors.filter(error => error.column !== column.dataIndex || error.record !== record.key);
    };

    addSingleError = (record, column) => {
        const errors = this.addError(record, column, null, this.state.errors);
        _.invoke(this.props, 'onError', errors);
        this.setState({errors: errors});
    };
    removeSingleError = (record, column) => {
        const errors = this.removeError(record, column, this.state.errors);
        _.invoke(this.props, 'onError', errors);
        this.setState({errors: errors});
    };

    addErrors = (errorList) => {
        let {errors} = this.state;

        errorList.forEach(error => errors = this.addError(error.record, error.column, error.message, errors));

        _.invoke(this.props, 'onError', errors);

        this.setState({errors});
    };

    removeErrors = (errorList) => {
        let {errors} = this.state;

        errorList.forEach(error => errors = this.removeError(error.record, error.column, errors));

        _.invoke(this.props, 'onError', errors);

        this.setState({errors});
    };

    /**
     * Represents render method for the component
     * @returns {*}
     */
    render() {
        /**
         * @type {array} data - Records to show in table
         * @type {array} columns - The columns, nothing more nothing less
         * @type {boolean} loading - Whether the table is loading or not
         */
        let {columns} = this.state;
        const {data, loading, visibleColumns} = this.props;

        columns = columns.filter(column => visibleColumns.indexOf(column.dataIndex) > -1);

        return (
            <Wrapper>
                <StyledTable
                    ref={ref => this.table = ref}
                    className="lm-table"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    rowSelection={this.getRowSelection()}
                    onChange={this.handleTableChange}
                    {..._.get(this.props, 'tableOptions', {})}
                />
                {this.getPagination()}
            </Wrapper>
        );
    }
}

LMTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    onSelectedKey: PropTypes.func,
    fetchData: PropTypes.func,
    selection: PropTypes.bool,
    tableOptions: PropTypes.object
};

export default LMTable;
