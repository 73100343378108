import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
    grid-column: 2;
    height: 71px;
    display: flex;
    flex-direction: row;
`;

export const StyledMenuItem = styled(Menu.Item)`
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    border-bottom: none !important;
    
    &:before {
        display: block;
        opacity: 0;
        width: 50%;
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #66cc33;
        height: 4px;
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    
    &:hover, &.ant-menu-item-selected {
        &:before {
            opacity: 1;
        }
    }
`;