import * as actionTypes from '../action-types'

export const initTours = () => {
  return { type: actionTypes.INIT_TOURS }
}
export const setTour = payload => {
  return { type: actionTypes.SET_TOUR, payload }
}
export const resetTours = () => {
  return { type: actionTypes.REMOVE_ALL_TOURS }
}
