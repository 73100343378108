import React from 'react';
import {AutoComplete} from "antd";

class AutoCompleteWrapper extends React.Component {
    state = {
        data: []
    }

    componentDidMount() {
        const { data } = this.props;

        this.setState({data});
    }

    render() {
        const { data } = this.state;
        const { props, key } = this.props;

        return (
            <AutoComplete {...props}>
                {data.map(item => {
                    return (
                        <AutoComplete.Option key={`${key}-${item.id}`} value={item.label}>
                            {item.label}
                        </AutoComplete.Option>
                    );
                })}
            </AutoComplete>
        );
    }
}

export default AutoCompleteWrapper;
