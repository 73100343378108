import React, { Component } from 'react'
import { Button, Form, Select } from 'antd'
import { StyledModal, StyledFormItem } from './styled'

const { Option } = Select

export const EditFamilyModal = Form.create({ name: 'edit_family_modal' })(
  class extends Component {
    handleSelectDepartment = value => {
      // If a family is already selected and is not compatible with selected department, deselect it
      const selectedFamilyId = this.props.form.getFieldValue('family')
      if (
        selectedFamilyId &&
        value !== 'all' &&
        this.props.families.filter(
          family => family.idFamily === selectedFamilyId
        )[0].idDepartment !== value
      ) {
        this.props.form.setFieldsValue({ family: null })
      }
    }

    render() {
      const {
        visible,
        onCancel,
        onOk,
        form,
        families,
        departments
      } = this.props
      const { getFieldDecorator } = form
      const formItemLayout = {
        labelCol: { span: 11 },
        wrapperCol: { span: 13 },
        labelAlign: 'left'
      }
      return (
        <StyledModal
          visible={visible}
          title="Seleziona famiglia esistente"
          onCancel={onCancel}
          onOk={onOk}
          maskClosable={false}
          afterClose={() => form.resetFields()}
          footer={[
            <Button key="submit" type="primary" onClick={onOk}>
              OK
            </Button>
          ]}>
          <Form {...formItemLayout}>
            <StyledFormItem label="Reparto" colon={false}>
              {getFieldDecorator('department', { initialValue: 'all' })(
                <Select onSelect={this.handleSelectDepartment}>
                  <Option key="all">Tutti i reparti</Option>
                  {departments.map(department => (
                    <Option key={department.id} value={department.id}>
                      {`${department.id}-${department.label}`}
                    </Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
            <StyledFormItem label="Nomenclatura famiglia" colon={false}>
              {getFieldDecorator('family', {
                rules: [{ required: true, message: 'Scegli una famiglia' }]
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}>
                  {families
                    .filter(family =>
                      form.getFieldValue('department') === 'all'
                        ? true
                        : family.idDepartment ===
                          form.getFieldValue('department')
                    )
                    .map(family => (
                      <Option key={family.idFamily} value={family.idFamily}>
                        {family.name}
                      </Option>
                    ))}
                </Select>
              )}
            </StyledFormItem>
          </Form>
        </StyledModal>
      )
    }
  }
)
