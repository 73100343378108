
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout';
import styles from './Simulazione.module.scss';
import { StyledH1 } from 'Common/styled'
import { Button, Card, Col, Drawer, Form, Input, List, Menu, message, Modal, Radio, Row, Tag } from 'antd'
import api from 'Common/api'
import { AgGridReact } from 'ag-grid-react'
import {
  agBooleanFormatter,
  agNumberFormatter,
  agPercentageFormatter, deepCloneObject, deleteFromSession, divideNumbers,
  getFromSession,
  num,
  setToSession
} from 'Common/utils'
import SimulazioneStatisticsBar from 'Components/SimulazioneStatisticsBar/SimulazioneStatisticsBar'
import SimulazioneFooterStatisticsBar from 'Components/SimulazioneFooterStatisticsBar/SimulazioneFooterStatisticsBar'
import { useHistory, useLocation, useParams } from 'react-router'
import { flatMap, forkJoin, from, mergeMap, of } from 'rxjs'
import moment from 'moment'
import simulazioneUtils from 'Common/simulazioneUtils'
import { useSelector } from 'react-redux'
import standardCellRenderer from 'Common/renderers/standardCellRenderer'
import contextMenuRenderer from 'Common/renderers/contextMenuRenderer'
import simulationProductStateRenderer from 'Common/renderers/simulationProductStateRenderer'
import AddProductDialog from 'Components/AddProductDialog/AddProductDialog'
import useBus, { dispatch } from 'use-bus'
import { EVENT_SIM_ADDREF_DIALOG_CLOSE, EVENT_SIM_ADDREF_DIALOG_OPEN } from 'Common/constants/event-constants'
import AddProductAutoComplete from 'Components/AddProductAutoComplete/AddProductAutoComplete'
import { getClientSideNodes } from 'Common/agGridUtils'
import changeDetectionCellRenderer from 'Common/renderers/changeDetectionCellRenderer'
import { validateSimulationRowData } from 'Common/validation/simulationValidation'
import NumericEditor from 'Common/editors/NumericEditor'
import PercentageEditor from 'Common/editors/PercentageEditor'
import SimulazioneChat from 'Components/SimulazioneChat/SimulazioneChat'
import SimulazioneSegnapostoForm from 'Containers/Simulazione/SimulazioneSegnapostoForm'
import { update } from 'ramda'
import productImageCellRenderer from 'Common/renderers/productImageCellRenderer'
import { v4 as uuid } from 'uuid';
import SimulazionePublishModal from 'Containers/Simulazione/SimulazionePublishModal'
import SimulazioneHeaderChangeModal from 'Containers/Simulazione/SimulazioneHeaderChangeModal'

const precotopValueSetter = params => {
  const val = Number(params.newValue);

  if (val !== 1 && val !== 2) {
    return false;
  }

  params.data[params.colDef.field] = val;

  return true;
};

const segnapostoFields = ['productId', 'libArt', 'codGamart', 'numTypreappropreco', 'supplier', 'prxAchfou', 'prxVtecns', 'qteVte', 'rfa2CaRapp', 'cm2CaRapp', 'boni2CaRapp'];

export let COLUMNS = [
  { headerName: 'Referenza', field: 'productId', align: 'center', valueGetter: params => params.data.numArt, filter: true, sortable: true, resizable: true, pinned: 'left', checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: 'current page', cellRenderer: 'agGroupCellRenderer', sortingOrder: ['asc'] },
  { headerName: 'Foto', field: 'photoId', align: 'center', filter: false, sortable: false, resizable: false, pinned: 'left', cellRenderer: 'productImageCellRenderer', sortingOrder: ['asc'] },
  { headerName: 'Descrizione', field: 'libArt', align: 'left', filter: true, sortable: true, resizable: true, pinned: 'left' },
  { headerName: 'Stato', field: 'state', cellRenderer: 'simulationProductStateRenderer', filter: true, valueGetter: params => params.data.removed ? 'OUT' : params.data.added ? 'IN' : 'ATTIVO' },
  { headerName: 'Ciclo di Vita Ref', field: 'lifecycle', filter: true, resizable: true, sortable: true },
  { headerName: 'Gamma Storico', field: 'codGamart', width: 100, align: 'center', filter: true, sortable: true, resizable: true },
  { headerName: 'Gamma Futura', field: 'codGamartDossier', width: 100, align: 'center', filter: true, sortable: true, resizable: true, editable: params => params.data.removed !== true, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: ['A', 'B', 'C', 'D', 'L', 'P', 'R', 'S'] } },
  { headerName: 'Precotop Storico', field: 'numTypreappropreco', width: 100, align: 'center', filter: true, sortable: true, resizable: true },
  { headerName: 'Precotop Futuro', field: 'numTypreapproprecoDossier', width: 100, align: 'center', filter: true, sortable: true, editable: params => params.data.removed !== true, resizable: true, valueSetter: precotopValueSetter },
  { headerName: 'Fornitore', field: 'supplier', width: 200, align: 'left', valueGetter: params => params.data.numFoucom + ' - ' + params.data.libFoucom, filter: true, sortable: true, resizable: true },
  { headerName: 'PA Storico', field: 'prxAchfou', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'PA Futuro', field: 'prxAchfouDossier', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: params => params.data.removed !== true },
  { headerName: 'PV Storico', field: 'prxVtecns', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'PV Futuro', field: 'prxVtecnsDossier', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: params => params.data.removed !== true },
  { headerName: 'Sconti %', field: 'percSconti', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, editable: params => params.data.removed !== true },
  { headerName: 'Map % Storico', field: 'percMap', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'Map % Futuro', field: 'percMapDossier', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, editable: false, valueGetter: params => {
    const caHt = simulazioneUtils.recalculate('caHt', { ca: params.data.caDossier, txTva: params.data.txTva });
    const sconti = simulazioneUtils.recalculate('discountValue', { caHt, scontiPerc: params.data.percSconti });
    return simulazioneUtils.recalculate('mapPerc', { caHt, sconti, mrg: params.data.mrgSim });
    } },
  { headerName: 'PVM Storico', field: 'pvm', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'PVM Futuro', field: 'pvmSim', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'QTA Storico', field: 'qteVte', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'QTA Simulata', field: 'qteVteSim', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: false },
  { headerName: 'QTA Futura', field: 'qteVteDossier', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, editable: params => params.data.removed !== true },
  { headerName: 'CA Storico', field: 'ca', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true },
  { headerName: 'CA Simulata', field: 'caSim', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'CA Futuro', field: 'caDossier', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'Prog % CA N-1', field: 'progCa', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true },
  { headerName: 'Prog % CA Simulata', field: 'progCaSim', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'Prog % CA Futuro', field: 'progCaDossier', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'Red % Storico', field: 'percRedd', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'Red % Futuro', field: 'percReddSim', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'MG€ Storico', field: 'mrg', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'MG€ Futuro', field: 'mrgSim', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'MG % Storico', field: 'mrg2Ca', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: 'MG % Futuro', field: 'mrg2caSim', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer' },
  { headerName: '% RFA', field: 'rfa2CaRapp', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer', editable: params => params.data.removed !== true },
  { headerName: '% CM', field: 'cm2CaRapp', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer', editable: params => params.data.removed !== true },
  { headerName: '% Boni', field: 'boni2CaRapp', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filterParams: { valueFormatter: agPercentageFormatter }, cellEditor: 'percentageEditor', filter: true, sortable: true, resizable: true, cellRenderer: 'changeDetectionCellRenderer', editable: params => params.data.removed !== true },
  { headerName: '3xNET € Storico', field: 'mrg3Xnet', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true },
  { headerName: '3xNET € Futuro', field: 'mrg3XnetSim', width: 50, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor', filter: true, sortable: true, resizable: true },
  { headerName: 'Reparto', field: 'department', width: 200, align: 'center', valueGetter: params => params.data.numRay + ' - ' + params.data.libRay, filter: true, sortable: true, resizable: true },
  { headerName: 'Sottoreparto', field: 'subDepartment', width: 200, align: 'center', valueGetter: params => params.data.numSray + ' - ' + params.data.libSray, filter: true, sortable: true, resizable: true },
  { headerName: 'Tipo', field: 'category', width: 200, align: 'center', valueGetter: params => params.data.numTyp + ' - ' + params.data.libTyp, filter: true, sortable: true, resizable: true },
  { headerName: 'Sottotipo', field: 'subCategory', width: 200, align: 'center', valueGetter: params => params.data.numStyp + ' - ' + params.data.libStyp, filter: true, sortable: true, resizable: true },
  { headerName: 'Sostituito da', field: 'replacedBy', align: 'center', editable: params => params.data.removed !== true, filter: true },
  { headerName: 'Sostituisce', field: 'replaces', align: 'center', editable: params => params.data.removed !== true, filter: true, },
  { headerName: 'Similare', field: 'similarProduct', align: 'center', editable: params => params.data.removed !== true, filter: true },
  { headerName: 'Stock', field: 'lastStockQteTot', align: 'center', filter: true, sortable: true },
  { headerName: 'Top MDH', field: 'topMdh', align: 'center', filter: true, sortable: true, valueGetter: params => params.data.topMdh ? 'Si' : 'No' },
  { field: 'removed', hide: true, lockVisible: true, suppressMenu: true, suppressColumnsToolPanel: true, suppressFiltersToolPanel: true, suppressToolPanel: true },
  { field: 'removed', hide: true, lockVisible: true, suppressMenu: true, suppressColumnsToolPanel: true, suppressFiltersToolPanel: true, suppressToolPanel: true },
];

const DETAIL_COLUMNS = [
  { headerName: 'Versione', field: 'version', width: 200, align: 'center', valueFormatter: params => moment(params.value, 'YYYYMMDDHHmmssSSS').format('YYYY-MM-DD HH:mm:ss')},
  { headerName: 'Gamma', field: 'codGamartDossier', width: 200, align: 'center'},
  { headerName: 'Precotop', field: 'numTypreapproprecoDossier', width: 200, align: 'center' },
  { headerName: 'PA', field: 'prxAchfouDossier', width: 200, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor' },
  { headerName: 'PV', field: 'prxVtecnsDossier', width: 200, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor'},
  { headerName: 'QTA Sim', field: 'qteVteSim', width: 200, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor'},
  { headerName: 'QTA', field: 'qteVteDossier', width: 200, align: 'center', valueFormatter: agNumberFormatter, cellEditor: 'numericEditor' }
];

function SimulazioneDetail ({ route }) {
  const [_, refreshUI] = useState(uuid());
  const history = useHistory();
  const departments = useSelector(state => state.Data.departments);
  const [gridReady, setGridReady] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [simulation, setSimulation] = useState({});
  const [showHeaderChangeModal, setShowHeaderChangeModal] = useState(false);
  const [nomenclature, setNomenclature] = useState({
    department: '',
    subDepartment: '',
    category: '',
    subCategory: ''
  });
  const productsRef= useRef([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [editedProductIds, setEditedProductIds] = useState([]);
  const [rowsSelectedCount, setRowsSelectedCount] = useState({ simulation: 0, out: 0 });
  const [simulationVersions, setSimulationVersions] = useState({});
  const [inOutModalData, setInOutModalData] = useState(null);
  const [inOutOptions, setInOutOptions] = useState({
    type: 'existing'
  });
  const [productHistory, setProductHistory] = useState({});
  const [showPublishModal, setShowPublishModal] = useState(false);
  const updatedProductIdsRef = useRef([]);
  const routeParams = useParams();
  const gridRef = useRef();
  const [chatVisible, setChatVisible] = useState(false);
  const segnapostoFormRef = useRef(null);

  const handleChatClosing = useCallback(() => {
    setChatVisible(false);
  }, []);

  const rowContextMenuItems = [
    { key: 'out', label: 'Out', isVisible: (params) => !params.data.removed },
    { key: 'reactivate', label: 'Riattiva', isVisible: (params) => params.data.removed },
    { key: 'inout', label: 'In/Out', isVisible: (params) => !params.data.removed && !params.data.added },
    { key: 'remove', label: 'Remove', isVisible: (params) => params.data.added },
  ];

  useEffect(() => {
    COLUMNS = [
      ...COLUMNS,
      { headerName: '', field: '', width: 100, minWidth: 100, align: 'center', cellRenderer: 'contextMenuRenderer', pinned: 'right', cellRendererParams: { onMenuItemClick: handleContextMenuItemClick, items: rowContextMenuItems }, suppressMenu: true, suppressColumnsToolPanel: true, suppressFiltersToolPanel: true, suppressToolPanel: true }
    ]
  }, []);

  useEffect(() => {

    if (!routeParams.id || !gridReady || !gridRef.current) {
      return;
    }

    const simulationIdCreated = getFromSession('Simulazione.CreaSimulationId', null);
    const simulationCreatePayload = getFromSession('Simulazione.CreaPayload', null);

    if (simulationIdCreated && simulationCreatePayload) {
      deleteFromSession('Simulazione.CreaSimulationId', null);
      deleteFromSession('Simulazione.CreaPayload', null);

      const messageHide = message.loading('Stiamo caricando i prodotti nella simulazione...resta in attesa...', 0);

      forkJoin([
        api.simulazione.get(routeParams.id),
        api.simulazione.fetchProducts(simulationIdCreated, simulationCreatePayload)
      ]).subscribe(([simulazioneResponse, productsResponse]) => {
        setSimulation(simulazioneResponse.data);
        gridRef.current.api.setRowData([...productsResponse.data.map(row => ({ ...simulazioneUtils.buildProduct(row), _validity: validateSimulationRowData(row) }))]);
        setLoaded(true);
        messageHide();
      });
    } else {
      forkJoin([
        api.simulazione.get(routeParams.id),
        api.simulazione.getProducts(routeParams.id)
      ]).subscribe(([simulazioneResponse, productsResponse]) => {
        setSimulation(simulazioneResponse.data);
        gridRef.current.api.setRowData([...productsResponse.data.map(row => ({ ...simulazioneUtils.buildProduct(row), _validity: validateSimulationRowData(row) }))]);
        setLoaded(true);
      });
    }

  }, [gridReady, routeParams.id, gridRef.current]);

  // useEffect(() => {
  //   if (!gridRef.current) return;
  //
  //   // const currentFilterModel = gridRef.current.api.getFilterModel();
  //   // const currentSortModel = gridRef.current.api.getSortModel();
  //   // gridRef.current.api.setRowData([...productsRef.current]);
  //   // gridRef.current.api.setFilterModel(currentFilterModel);
  //   // gridRef.current.api.setSortModel(currentSortModel);
  // }, [productsRef.current, gridRef.current]);

  useEffect(() => {
    const versions = {};

    for (const product of productsRef.current) {
      for (const productVersion of product.versions) {
        if (!versions.hasOwnProperty(productVersion.version)) {
          versions[productVersion.version] = [];
        }

        versions[productVersion.version].push(productVersion);
      }
    }

    setSimulationVersions(versions);
  }, []);

  useEffect(() => {

    if (!simulation.id) {
      return;
    }

    api.simulazione.getAdditionalInformation(simulation.id).then(response => {
      const departments = [];
      const subdepartments = [];
      const categories = [];
      const subcategories = [];

      const processNode = (nodes, level) => {
        for (const node of nodes) {
          if (level === 0) {
            departments.push(`${node.id} - ${node.description}`);
          } else if (level === 1) {
            subdepartments.push(`${node.id} - ${node.description}`);
          } else if (level === 2) {
            categories.push(`${node.id} - ${node.description}`);
          } else if (level === 3) {
            subcategories.push(`${node.id} - ${node.description}`);
          }

          if (node.children && node.children.length > 0) {
            processNode(node.children, level + 1);
          }
        }
      };

      processNode(response.data.nomenclatureTreeNodes, 0);

      setNomenclature({
        department: departments.join(','),
        subDepartment: subdepartments.join(','),
        category: categories.join(','),
        subCategory: subcategories.join(',')
      });
    }).catch(console.error);

  }, [simulation]);

  const onGridReady = useCallback(params => {
    setGridReady(true);
    params.columnApi.applyColumnState({
      state: [{ colId: 'ca', sort: 'desc' }],
      defaultState: { sort: null }
    });
  })

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: 'simulazioneFooterStatisticsBar',
          key: 'footerStatistics'
        }
      ],
    };
  }, []);

  const onSelectionChanged = params => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    setRowsSelectedCount({
      simulation: selectedRows.filter(o => !o.codGamartDossier || ['A', 'B', 'C'].includes(o.codGamartDossier)).length,
      out: selectedRows.length
    });
  };

  const isRowSelectable = rowNode => {
    return !rowNode.data.removed && !rowNode.data.added && simulation.state !== 'PUBLISHED';
  };

  const handleOutInBulk = () => {
    const nodes = gridRef.current.api.getSelectedNodes();

    for (const node of nodes) {
      if (!node.rowIndex) continue;

      const newData = {
        ...node.data,
        removed: true
      };

      node.setData(newData);

      onRowValueChanged({ node, data: newData });
    }

    gridRef.current.api.refreshCells({ rowNodes: nodes });
  };

  const handleSimulate = (allProducts, force = false) => {

    if (allProducts && simulation.lastSimulation !== null && !force) {
      gridRef.current.api.hideOverlay();
      return;
    }

    gridRef.current.api.showLoadingOverlay();
    let selectedRows = [];
    let selectedNodes = [];

    if (allProducts === true) {

      gridRef.current.api.forEachNode(node => {
        if (!node.selectable) return;
        selectedRows.push(node.data);
        selectedNodes.push(node);
      });

    } else {
      selectedRows = gridRef.current.api.getSelectedRows().filter(it => ['A', 'B', 'C'].includes(it.codGamartDossier) || !it.codGamartDossier);
      selectedNodes = gridRef.current.api.getSelectedNodes().filter(it => ['A', 'B', 'C'].includes(it.data.codGamartDossier) || !it.data.codGamartDossier);
    }

    let fcstStart = moment().format('YYYY-MM') + '-01';

    if (parseInt(moment().format('DD')) < 10) {
      fcstStart = moment().subtract(1, 'months').format('YYYY-MM') + '-01';
    }

    const params = {
      num_arts: selectedRows.map(o => Number(o.numArt)),
      fcst_start: fcstStart
    };

    if (simulation.storesA > 0) {
      params.gamma_a = simulation.storesA;
    }

    if (simulation.storesAb > 0) {
      params.gamma_ab = simulation.storesAb;
    }

    if (simulation.storesAbc > 0) {
      params.gamma_abc = simulation.storesAbc;
    }

    const messageHide = message.loading('Stiamo simulando i dati di venduto...resta in attesa...', 0);

    api.simulazione.simulate(simulation.departmentId, params).then(response => {

      const updatedSimulation = {
        ...simulation,
        lastSimulation: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
      };

      if (allProducts && simulation.lastSimulation === null) {
        api.simulazione.updateSimulation(updatedSimulation).then(() => {
          handleSave();
        }).catch();
        setSimulation(updatedSimulation);
      }

      const productsDataPrediction = {};

      for (const prediction of response.data.predictions) {
        if (!productsDataPrediction.hasOwnProperty(prediction.numArt.toString())) {
          productsDataPrediction[prediction.numArt.toString()] = 0;
        }

        productsDataPrediction[prediction.numArt.toString()] += prediction.pred;
      }

      for (const rowNode of selectedNodes) {
        if (!productsDataPrediction.hasOwnProperty(rowNode.data.numArt)) {
          continue;
        }

        const recordClone = deepCloneObject(rowNode.data);
        delete recordClone._historical;
        const updatedRecordData = simulazioneUtils.recalculateRecord({
          ...rowNode.data,
          qteVteSim: productsDataPrediction[rowNode.data.numArt]
        });

        updatedRecordData._validity = validateSimulationRowData(updatedRecordData);

        if (allProducts) {
          updatedRecordData.qteVteDossier = productsDataPrediction[rowNode.data.numArt];
        }

        updatedRecordData._historical = recordClone;

        rowNode.setData(updatedRecordData);

        onRowValueChanged({ data: updatedRecordData, node: rowNode });
      }

      gridRef.current.api.hideOverlay();

      messageHide();
      message.success('La simulazione è stata eseguita senza problemi');
    }).catch(error => {
      messageHide();
      message.error('La simulazione non è andata a buon fine')
      console.error(error);
    });
  };

  const getAllRowData = (ids = []) => {
    if (!gridRef.current) return [];
    let rowData = [];
    gridRef.current.api.forEachNode(node => {
      if (ids.length > 0 && !ids.includes(node.data.numArt)) return;
      rowData.push(node.data);
    });
    return rowData;
  };

  const handleSave = () => {
    const productsToUpdate = [];

    gridRef.current.api.forEachNode(node => {
      if (!updatedProductIdsRef.current.includes(node.data.numArt)) return;
      productsToUpdate.push(node.data);
    });

    const loading = message.loading('Stiamo salvando i dati della tabella...resta in attesa...', 0);

    api.simulazione.updateProducts(simulation.id, productsToUpdate).then(response => {
      message.success(`I dati sono stati aggiornati`);

      const updatedProducts = [...getAllRowData()];
      const existingProductsMap = updatedProducts.reduce((acc, curr, index) => ({
        ...acc,
        [curr.numArt]: index
      }), {});

      for (const productToUpdate of response.data) {
        if (!existingProductsMap.hasOwnProperty(productToUpdate.numArt)) {
          updatedProducts.push(productToUpdate);
          continue;
        }

        updatedProducts[existingProductsMap[productToUpdate.numArt]] = productToUpdate;
      }

      updatedProductIdsRef.current = [];
      refreshUI(uuid());
    }).catch(error => {
      console.error(error);
      message.error(`L'aggiornamento dei prodotti non è andato a buon fine`);
    }).finally(() => loading());
  };

  const handleContextMenuItemClick = (key, data, index) => {

    if (key === 'out') {
      const node = gridRef.current.api.getRowNode(data.numArt);

      const newData = {
        ...node.data,
        removed: true
      };

      node.setData(newData);

      onRowValueChanged({ node, data: newData })

      gridRef.current.api.refreshCells({ rowNodes: [node] });
    } else if (key === 'reactivate') {
      const node = gridRef.current.api.getRowNode(data.numArt);

      const newData = {
        ...node.data,
        removed: false
      };

      node.setData(newData);

      onRowValueChanged({ node, data: newData })

      // gridRef.current.api.refreshCells({ rowNodes: [node] });
    } else if (key === 'inout') {
      setInOutModalData(data);
    } else if (key === 'remove') {
      api.simulazione.removeProducts(data.simulationId, [data.numArt]).then(response => {
        message.success('Prodotto rimosso senza problemi.');

        gridRef.current.api.applyTransaction({
          remove: [{ numArt: data.numArt }]
        });
      }).catch(console.error);
    }
  };

  const handleRowDataChange = ({ data, node, colDef, oldValue, newValue }) => {
    const recordClone = deepCloneObject(data);
    delete recordClone._historical;

    let newData = {
      ...data
    };

    const recalculateGamartOnly = ['A', 'B', 'C'];

    if (
        colDef?.field === 'codGamartDossier'
        && oldValue !== newValue
        && recalculateGamartOnly.includes(oldValue)
        && recalculateGamartOnly.includes(newValue)
    ) {
      const oldStoresKey = oldValue === 'A' ? 'storesA' : oldValue === 'B' ? 'storesAb' : 'storesAbc';
      const newStoresKey = newValue === 'A' ? 'storesA' : newValue === 'B' ? 'storesAb' : 'storesAbc';
      newData.qteVteSim = divideNumbers(newData.qteVteSim, simulation[oldStoresKey]) * simulation[newStoresKey];
      newData.qteVteDossier = divideNumbers(newData.qteVteDossier, simulation[oldStoresKey]) * simulation[newStoresKey];
    }

    newData = {
      ...simulazioneUtils.recalculateRecord(newData),
      _historical: recordClone
    };

    newData._validity = validateSimulationRowData(newData);

    node.setData(newData);

    if (!updatedProductIdsRef.current.includes(data.numArt)) {
      updatedProductIdsRef.current.push(data.numArt);
    }

    refreshUI(uuid());

  };

  const onCellValueChanged = params => {
    handleRowDataChange(params);
  };

  const onRowValueChanged = params => {
    handleRowDataChange(params);
  };

  const handleHistory = () => {
    setShowHistoryModal(true);
  };

  const navigateToSummary = () => {
    window.location.href = `/simulazione/detail/${simulation.id}/summary`;
  };

  const navigateToDashboard = () => {
    window.location.href = `/simulazione/detail/${simulation.id}/dashboard`;
  };

  const handleInOutModalConfirm = () => {

    if (inOutOptions.type === 'existing') {

      if (!inOutOptions.productId) {
        message.error(`Prima di procedere devi inserire una referenza da sostituire`);
        return;
      }

      const loading = message.loading('Stiamo sostituendo il prodotto selezionato con il nuovo...resta in attesa...', 0);

      api.simulazione.replaceProduct(simulation.id, inOutModalData.numArt, inOutOptions.productId).then(response => {
        const [existingProduct, newProduct] = response.data;
        const [ existingProductReference, newProductReference ] = getAllRowData([existingProduct.numArt, newProduct.numArt]);

        const transaction = {
          update: [
            {
              ...existingProductReference,
              ...existingProduct
            }
          ]
        };

        if (newProductReference) {
          transaction.update.push({
            ...newProductReference,
            ...newProduct
          });
        } else {
          transaction.add = [{
            ...newProduct,
          }];
        }

        gridRef.current.api.applyTransaction(transaction);

        message.success('Prodotto sostituito senza problemi.');
        setInOutModalData(null);
      }).catch(console.error).finally(() => loading());

    } else if (inOutOptions.type === 'segnaposto') {
      const value = segnapostoFormRef.current;
      if (!value) return;

      if ((value?.supplier ?? '').length > 0) {
        const supplierSplit = value.supplier.split('-');
        value.numFoucom = Number(supplierSplit[0].trim());
        value.libFoucom = supplierSplit[1].trim();
      }

      api.simulazione.replaceProductWithPlaceholder(simulation.id, inOutModalData.numArt, simulazioneUtils.recalculateRecord(value)).then(response => {
        const [existingProduct, newProduct] = response.data;

        const existingProductReference = getAllRowData([existingProduct.numArt])?.[0];

        gridRef.current.api.applyTransaction({
          update: [{
            ...existingProductReference,
            ...existingProduct
          }],
          add: [{
            ...newProduct
          }]
        });

        message.success('Prodotto sostituito senza problemi.');
        setInOutModalData(null);
      });
    }
  };

  const resetFilters = () => {
    if (!gridRef.current) {
      return;
    }

    gridRef.current.api.setFilterModel(null);
  };

  const addProduct = () => {
    dispatch(EVENT_SIM_ADDREF_DIALOG_OPEN);
  };

  const handleOnAddProductConfirmation = (value, typeOfProduct) => {
    if (!value) return;

    if (typeOfProduct === 'existing') {
      if (getClientSideNodes(gridRef).filter(o => o.data.numArt === value).length > 0) {
        message.warning('Il prodotto che sta per inserire esiste già nella simulazione.');
        return;
      }

      const loadingMessage = message.loading('Stiamo aggiungendo il prodotto alla simulazione...resta in attesa...', 0);

      api.simulazione.addProducts(simulation.id, [value])
        .then(response => {
          message.success('Prodotto aggiunto');
          dispatch(EVENT_SIM_ADDREF_DIALOG_CLOSE);
          gridRef.current.api.applyTransaction({
            add: [...response.data]
          });
        })
        .catch(error => {
          console.error(error);
          message.error('Non siamo riusciti ad aggiungere il prodotto a causa di un errore.');
        })
        .finally(() => {
          loadingMessage();
        })
    } else if (typeOfProduct === 'segnaposto') {
      if ((value?.supplier ?? '').length > 0) {
        const supplierSplit = value.supplier.split('-');
        value.numFoucom = Number(supplierSplit[0].trim());
        value.libFoucom = supplierSplit[1].trim();
      }
      api.simulazione.addPlaceholder(simulation.id, simulazioneUtils.recalculateRecord(value)).then(response => {
        message.success('Prodotto aggiunto');
        dispatch(EVENT_SIM_ADDREF_DIALOG_CLOSE);
        const recalculatedData = simulazioneUtils.recalculateRecord(response.data);
        gridRef.current.api.applyTransaction({
            add: [recalculatedData]
        });
        api.simulazione.updateProducts(simulation.id, [recalculatedData]).then().catch(console.error);
      });
    }
  };

  const onFirstDataRenderedHandle = e => {
    e.columnApi.autoSizeAllColumns();
    handleSimulate(true);
  };

  const navigateBack = () => {
    if (updatedProductIdsRef.current.length > 0) {
      message.warn('Ci sono dei dati non salvati, per procedere bisogna salvare i cambiamenti.');
      return;
    }

    window.location.href = '/simulazione/list';
  };

  const rollbackData = version => {
    if (!simulationVersions.hasOwnProperty(version)) {
      return;
    }

    const versionProducts = simulationVersions[version];
    const updatedEditedRows = [
      ...editedProductIds
    ];

    for (const productVersion of versionProducts) {
      const node = gridRef.current.api.getRowNode(productVersion.numArt);
      const updatedProductData = {
        ...node.data,
        ...productVersion,
        versions: node.data.versions
      };

      delete updatedProductData.version;

      node.setData(updatedProductData);

      const index = updatedEditedRows.findIndex(o => o.numArt === updatedProductData.numArt);

      if (index > -1) {
        updatedEditedRows[index] = updatedProductData;
      } else {
        updatedEditedRows.push(updatedProductData);
      }
    }

    updatedProductIdsRef.current = [
      ...updatedProductIdsRef.current,
      ...updatedEditedRows.current.map(o => o.numArt)
    ];

    refreshUI(uuid());

    setEditedProductIds(updatedEditedRows);

    message.success(`La versione ${version} è stata ripristinata. Per confermare le modifiche, salvare i dati.`);
  };

  const handlePublish = useCallback((value) => {
    setIsPublishing(true);
    const messageHide = message.loading('Stiamo pubblicando la simulazione...resta in attesa...', 0);
    api.simulazione.publish(simulation.id, {
      installationFrom: value.installationFrom.format('YYYY-MM-DD'),
      installationTo: value.installationTo.format('YYYY-MM-DD'),
      suggestedStartDate: value.suggestedStartDate.format('YYYY-MM-DD'),
      storeCompletionFrom: value.storeCompletionFrom.format('YYYY-MM-DD'),
      storeCompletionTo: value.storeCompletionTo.format('YYYY-MM-DD'),
      familyId: Number(value.familyId),
    }).then(({ data }) => {
      message.success('Simulazione pubblicata con successo');
      history.push(`/edit-dossier/${data.id}`);
    }).catch(error => {
      message.error('Non siamo riusciti a pubblicare la simulazione per dati non validi');
      console.error(error);
    }).finally(() => {
      messageHide();
      setIsPublishing(false);
    })
  }, [simulation.id, history]);

  const togglePublishModal = useCallback(() => {
    setShowPublishModal(!showPublishModal);
  }, [showPublishModal]);

  const handleHeaderChange = () => {
    setShowHeaderChangeModal(true);
  };

  const handleHeaderChangeConfirm = useCallback((values) => {
    const loadingMessage = message.loading('Stiamo facendo i cambiamenti alla simulazione...resta in attesa', 0);
    api.simulazione.changeHeader(simulation.id, {
      ...values,
      dateFrom: values.dateFrom.format('YYYY-MM-DD'),
      dateTo: values.dateTo.format('YYYY-MM-DD'),
    }).then(async ({ data }) => {
      setSimulation(data.simulation);

      loadingMessage();
      message.success('I dati della simulazione sono stati aggiornati con successo');

      if (!data.refreshSimulationData) {
        setShowHeaderChangeModal(false);
        return;
      }

      const { data: newProducts } = await api.simulazione.getProducts(simulation.id);

      gridRef.current.api.setRowData([...newProducts.map(row => ({ ...simulazioneUtils.buildProduct(row), _validity: validateSimulationRowData(row) }))]);

      setShowHeaderChangeModal(false);

      handleSimulate(true, true);
    }).catch(console.error);
  }, [simulation.id]);

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="simulazione">
      <div className={styles.root}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Button
            icon="left"
            onClick={navigateBack}>
            Indietro
          </Button>
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <StyledH1 className={styles.title}>{simulation.title}</StyledH1>
            <h3 className={styles.subtitle}>Periodo dal {simulation.dateFrom} al {simulation.dateTo}</h3>
          </div>
          <Button
            style={{ marginLeft: 'auto' }}
            icon="edit"
            type="primary"
            onClick={handleHeaderChange}>
            Modifica dati simulazione
          </Button>
        </div>
        <Row style={{ marginBottom: 25 }}>
          <Col>
            <div className="header-grid">
              <div>
                <span className="label">Reparto</span>
                <span className="value">{nomenclature.department.length > 0 ? nomenclature.department : 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Sottoreparto</span>
                <span className="value">{nomenclature.subDepartment.length > 0 ? nomenclature.subDepartment : 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Tipo</span>
                <span className="value">{nomenclature.category.length > 0 ? nomenclature.category : 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Sottotipo</span>
                <span className="value">{nomenclature.subCategory.length > 0 ? nomenclature.subCategory : 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Negozi A</span>
                <span className="value">{simulation.storesA || 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Negozi AB</span>
                <span className="value">{simulation.storesAb || 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Negozi ABC</span>
                <span className="value">{simulation.storesAbc || 'Tutti'}</span>
              </div>
              <div>
                <span className="label">Stato</span>
                <span className="value">{simulazioneUtils.getTranslationForState(simulation.state)}</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 25 }}>
          <Col>
            <SimulazioneStatisticsBar data={getAllRowData()} />
          </Col>
        </Row>
        <Row style={{ marginBottom: 25 }}>
          <Col style={{ display: 'flex', flexDirection: 'row' }} span={8}>
            {loaded && simulation.state !== 'PUBLISHED' && (
              <Button style={{ marginRight: 10 }} icon="plus" type="primary" onClick={addProduct}>Aggiungi</Button>
            )}
            <Button icon="file-text" onClick={navigateToSummary}>Sintesi</Button>
            <Button icon="undo" style={{ marginLeft: 10 }} onClick={resetFilters}>Reset filtri</Button>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} span={16}>
            {loaded && simulation.state !== 'PUBLISHED' && (
              <>
                <Button style={{ marginLeft: 'auto' }} type="primary" icon="play-circle" disabled={rowsSelectedCount.simulation === 0} onClick={() => handleSimulate()}>Simula</Button>
                <Button style={{ marginLeft: 10 }} type="default" icon="minus-circle" disabled={rowsSelectedCount.out === 0} onClick={() => handleOutInBulk()}>OUT</Button>
                <Button style={{ marginLeft: 10 }} type="default" icon="save" onClick={handleSave} disabled={updatedProductIdsRef.current.length === 0}>Salva</Button>
                <Button style={{ marginLeft: 10 }} type="default" icon="check-circle" onClick={togglePublishModal}>Pubblica</Button>
              </>
            )}
            <Button style={{ marginLeft: 10 }} type="default" icon="bell" onClick={() => setChatVisible(true)}>Notifiche</Button>
            <Button style={{ marginLeft: 10 }} type="default" icon="history" onClick={handleHistory}>Cronologia</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 400, width: '100%' }} className="ag-theme-alpine modern-ag-grid">
              <AgGridReact
                enableRangeSelection
                rowMultiSelectWithClick={false}
                suppressRowClickSelection={true}
                onFirstDataRendered={onFirstDataRenderedHandle}
                onCellValueChanged={onCellValueChanged}
                isRowSelectable={isRowSelectable}
                suppressColumnVirtualisation={true}
                getRowNodeId={params => params.numArt}
                ref={gridRef}
                getRowStyle={({ data }) => {
                  if (data._validity?.valid === false) {
                    return { borderColor: '#fc5757' };
                  }

                  return null;
                }}
                defaultColDef={{
                  floatingFilter: true,
                  minWidth: 150,
                  cellRenderer: 'standardCellRenderer',
                  cellStyle: ({ data, colDef }) => {

                    const style = {};

                    if (data._validity?.valid === false && Object.prototype.hasOwnProperty.call(data._validity?.result, colDef.field)) {
                      style.borderColor = 'red';
                    }

                    if (data.removed && data.lastUpdate !== null) {
                      style.backgroundColor = '#ffc2c2';
                    } else if (data.removed && data.lastUpdate === null) {
                      style.backgroundColor = '#d3d3d3';
                    } else if (data.added && data.lastUpdate !== null) {
                      style.backgroundColor = '#c2e6ff';
                    } else if (data.added && data.lastUpdate === null) {
                      style.backgroundColor = '#c8ffc2';
                    } else {
                      style.backgroundColor = '#ffefc2';
                    }

                    return style;
                  }
                }}
                components={{
                  simulazioneFooterStatisticsBar: SimulazioneFooterStatisticsBar,
                  standardCellRenderer: standardCellRenderer,
                  changeDetectionCellRenderer: changeDetectionCellRenderer,
                  contextMenuRenderer: contextMenuRenderer,
                  simulationProductStateRenderer: simulationProductStateRenderer,
                  productImageCellRenderer: productImageCellRenderer,
                  numericEditor: NumericEditor,
                  percentageEditor: PercentageEditor,
                }}
                getRowId={params => params.data.numArt}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                statusBar={statusBar}
                columnDefs={COLUMNS.map(column => ({ ...column, editable: simulation.state === 'PUBLISHED' ? false : column.editable }))}
                pagination={true}
                paginationPageSize={500}
                onGridReady={onGridReady}
                masterDetail={true}
                detailCellRendererParams={{
                  detailGridOptions: {
                    columnDefs: DETAIL_COLUMNS
                  },
                  getDetailRowData: params => params.successCallback(params.data.versions)
                }}
              />
            </div>
          </Col>
        </Row>
        <Modal
          title="Cronologia variazioni simulazione"
          visible={showHistoryModal}
          footer={false}
          onCancel={() => setShowHistoryModal(false)}
        >
          <List
            dataSource={Object.entries(simulationVersions)}
            renderItem={([key, value]) => (
              <List.Item
                actions={[
                  <Button icon="rollback" shape="circle-line" type="danger" onClick={() => rollbackData(key)} />
                ]}
              >
                <List.Item.Meta
                  description={`Versione ${moment(key, 'YYYYMMDDHHmmssSSS').format('DD/MM/YYYY HH:mm:ss')} | ${value.length} modific${value.length === 1 ? 'a' : 'che'}`}
                />
              </List.Item>
            )}
          />
        </Modal>
      </div>
      <Modal
        visible={inOutModalData !== null}
        onCancel={() => setInOutModalData(null)}
        cancelText="Annulla"
        okText="Conferma"
        onOk={handleInOutModalConfirm}
      >
        <Radio.Group onChange={e => setInOutOptions({
          ...inOutOptions,
          type: e.target.value
        })} value={inOutOptions.type}>
          <Radio value="existing">Referenza esistente</Radio>
          <Radio value="segnaposto">Segnaposto</Radio>
        </Radio.Group>
        {inOutOptions.type === 'existing' && (
          <AddProductAutoComplete onSelectValue={ref => {
            setInOutOptions({
              ...inOutOptions,
              productId: ref
            });
          }} style={{ marginTop: 25, width: '100%' }} />
        )}
        {inOutOptions.type === 'segnaposto' && (
          <SimulazioneSegnapostoForm valueChanged={value => { segnapostoFormRef.current = value; }} />
        )}
      </Modal>
      <AddProductDialog onConfirm={handleOnAddProductConfirmation} withSegnaposto={true} />
      <SimulazioneChat
        simulationId={simulation.id}
        visible={chatVisible}
        onClose={handleChatClosing}
      />
      <SimulazionePublishModal
        visible={showPublishModal}
        onOk={handlePublish}
        onCancel={togglePublishModal}
        publishing={isPublishing}
      />
      <SimulazioneHeaderChangeModal
        visible={showHeaderChangeModal}
        simulation={simulation}
        onHide={() => setShowHeaderChangeModal(false)}
        onConfirm={handleHeaderChangeConfirm}
      />
    </MainLayout>
  );
}

SimulazioneDetail.whyDidYouRender = true;

export default React.memo(SimulazioneDetail);
