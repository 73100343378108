import { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDepartments, fetchSubdepartments } from 'Actions/data-actions'
import api from 'Common/api'
import moment from 'moment'
import { forkJoin, from } from 'rxjs'
import { getFromSession } from 'Common/utils'

const DEFAULT_FORM_VALUE = {
  dateFrom: undefined,
  dateTo: undefined,
  departmentId: undefined,
  subDepartmentId: undefined,
  categoryId: undefined,
  subCategoryId: undefined,
  familyId: undefined,
  title: undefined,
  simulationId: undefined,
  published: false,
}

export default function SimulazioneSearchForm({ onChange }) {

  const dispatch = useDispatch()
  const departments = useSelector(store => store.Data.departments)
  const [subDepartments, setSubDepartments] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [subDepartmentsMap, setSubDepartmentsMap] = useState({})
  const [categoriesMap, setCategoriesMap] = useState({})
  const [formValue, setFormValue] = useState({ ...DEFAULT_FORM_VALUE })

  useEffect(() => setSubDepartmentsMap(subDepartments.reduce((p, c) => ({
    ...p,
    [c.id]: c.label
  }), {})), [subDepartments])
  useEffect(() => setCategoriesMap(categories.reduce((p, c) => ({ ...p, [c.id]: c.label }), {})), [categories])

  useEffect(() => {
    if (onChange) {
      onChange(formValue)
    }
  }, [formValue])

  useEffect(() => {

    if (departments.length === 0) {
      dispatch(fetchDepartments())
    }

  }, []);

  useEffect(() => {

    const newFormValue = {
      ...formValue
    };

    if (formValue.departmentId) {
      api
        .subdepartments
        .get(formValue.departmentId)
        .then(response => {
          setSubDepartments(response.data);
        })
        .catch(console.error);
    } else {
      setSubDepartments([]);
    }

    setCategories([]);
    setSubCategories([]);
    newFormValue.subDepartmentId = undefined;
    newFormValue.categoryId = undefined;
    newFormValue.subCategoryId = undefined;

    setFormValue(newFormValue);

  }, [formValue.departmentId]);

  useEffect(() => {

    const newFormValue = {
      ...formValue
    };

    if (formValue.subDepartmentId) {
      api
        .categories
        .get(formValue.departmentId, formValue.subDepartmentId)
        .then(response => {
          setCategories(response.data);
        })
        .catch(console.error);
    } else {
      setCategories([]);
    }

    setSubCategories([]);
    newFormValue.categoryId = undefined;
    newFormValue.subCategoryId = undefined;

    setFormValue(newFormValue);

  }, [formValue.subDepartmentId]);

  useEffect(() => {

    const newFormValue = {
      ...formValue
    };

    if (formValue.categoryId) {
      api
        .subcategories
        .get(formValue.departmentId, formValue.subDepartmentId, [formValue.categoryId], false)
        .then(response => {
          setSubCategories(response.data);
        })
        .catch(console.error);
    } else {
      setSubCategories([]);
    }

    newFormValue.subCategoryId = undefined;

    setFormValue(newFormValue);

  }, [formValue.categoryId]);

  const onValueChange = (key, value) => {
    const newFormValue = {
      ...formValue,
      [key]: value
    }

    setFormValue(newFormValue)
  }

  return (
    <>
      <Row gutter={16}>
        <Col md={22} lg={8}>
          <Form.Item label='Titolo'>
            <Input
              value={formValue.title}
              placeholder='Filtra'
              onChange={ev => onValueChange('title', ev.target.value)}
            />
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label='Data da'>
            <DatePicker
              placeholder='Seleziona data'
              allowClear
              value={formValue.dateFrom}
              onChange={(date) => onValueChange('dateFrom', date)}
            />
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label='Data a'>
            <DatePicker
              value={formValue.dateTo}
              placeholder='Seleziona data'
              disabledDate={currentDate => formValue.dateFrom ? currentDate.isBefore(moment(formValue.dateFrom).add(1, 'day')) : true}
              onChange={(date) => onValueChange('dateTo', date)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={22} lg={4}>
          <Form.Item label='Reparto*'>
            <Select
              style={{ width: '100%' }}
              placeholder='Seleziona'
              value={formValue.departmentId}
              onChange={value => onValueChange('departmentId', Number(value))}
            >
              {departments.map(department => (
                <Select.Option key={`department-${department.id}`}
                               value={department.id}>{department.id.toString().padStart(2, '0')} - {department.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label='Sotto reparto'>
            <Select
              disabled={formValue.departmentId < 1 || !formValue.departmentId}
              style={{ width: '100%' }}
              placeholder='Seleziona'
              value={formValue.subDepartmentId}
              onChange={value => onValueChange('subDepartmentId', Number(value))}
            >
              {subDepartments.map(subDepartment => (
                <Select.Option key={`subDepartment-${subDepartment.id}`}
                               value={subDepartment.id}>{subDepartment.id.toString().padStart(2, '0')} - {subDepartment.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label='Tipo'>
            <Select
              disabled={!formValue.subDepartmentId}
              style={{ width: '100%' }}
              value={formValue.categoryId}
              onChange={value => onValueChange('categoryId', Number(value))}
              placeholder='Seleziona'
            >
              {categories.map(category => (
                <Select.Option key={`category-${category.id}`}
                               value={category.id}>{category.id.toString().padStart(2, '0')} - {category.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label='Sottotipo'>
            <Select
              disabled={!formValue.categoryId}
              style={{ width: '100%' }}
              placeholder='Seleziona'
              value={formValue.subCategoryId}
              onChange={value => onValueChange('subCategoryId', Number(value))}
            >
              {subCategories.map(subCategory => (
                <Select.Option key={`subCategory-${subCategory.id}`}
                               value={subCategory.id}>{subCategory.id.toString().padStart(2, '0')} - {subCategory.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={22} lg={4}>
          <Form.Item label='Famiglia'>
            <Select
              style={{ width: '100%' }}
              value={formValue.familyId}
              onChange={value => onValueChange('familyId', Number(value))}
              placeholder='Seleziona'
            >
            </Select>
          </Form.Item>
        </Col>
        <Col md={22} lg={4}>
            <Form.Item label='ID Simulazione'>
                <InputNumber
                    style={{ width: '100%' }}
                    value={formValue.simulationId}
                    onChange={value => onValueChange('simulationId', value)}
                    placeholder="Filtra" />
            </Form.Item>
        </Col>
        <Col md={22} lg={4}>
          <Form.Item label='Mostra pubblicate'>
            <Switch checked={formValue.published} onChange={value => onValueChange('published', value)} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
