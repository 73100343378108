import Home from 'Containers/Home'
import Edit from 'Containers/Edit'
import EditDossier from 'Containers/EditDossier'
import SearchDossier from 'Containers/SearchDossier'
import SearchDossierReferences from 'Containers/SearchDossierReferences'
import EditDossierDetails from 'Containers/EditDossierDetails'
import SearchOpecom from 'Containers/SearchOpecom'
import EditOpecomDetails from 'Containers/EditOpecomDetails'
import ChangeLog from 'Containers/ChangeLog/ChangeLog'
import ProdottiSimbolo from 'Containers/ProdottiSimbolo'
import SearchFamily from 'Containers/SearchFamily'
import Families from 'Containers/Families'
import AnagraficaProdotti from 'Containers/AnagraficaProdotti/AnagraficaProdotti'
import Dashboard from 'Containers/Dashboard/Dashboard'
import Simulazione from 'Containers/Simulazione/Simulazione'
import SimulazioneHistory from 'Containers/Simulazione/SimulazioneHistory'
import SimulazioneCreate from 'Containers/Simulazione/SimulazioneCreate'
import SimulazioneDetail from 'Containers/Simulazione/SimulazioneDetail'
import SimulazioneList from 'Containers/Simulazione/SimulazioneList'
import SimulazioneReport from 'Containers/Simulazione/SimulazioneReport'
import FamilyStore from 'Containers/Families/FamilyStore'
import DossierUnicoPage from 'Components/DossierUnico/DossierUnicoPage'

export const PATHS = {
  Simulazione: '/simulazione',
  SimulazioneHistory: '/simulazione/storico',
  SimulazioneCreate: '/simulazione/crea',
  SimulazioneList: '/simulazione/list',
  SimulazioneDetail: '/simulazione/detail/:id',
  SimulazioneReport: '/simulazione/detail/:id/:report',
  SearchOpecom: '/search-opecom',
  ProdottiSimbolo: '/prodotti-simbolo/:id?',
  EditOpecom: '/edit-opecom/:id',
  SearchDossier: '/search-dossier',
  SearchFamily: '/search-family',
  FamilyStore: '/family-store',
  EditDossier: '/edit-dossier/:id',
  NewDossier: '/new-dossier',
  EditFamily: '/edit-family/:id',
  NewFamily: '/new-family',
  SearchDossierReferences: '/dossier/:dossierId/add',
  ChangeLog: '/news',
  Families: '/view-family',
  Anagrafica: '/anagrafica',
  Dashboard: '/dashboard',
  Dossier: '/dossier',
  Family: '/family',
  DossierUnico: '/dossier-unico',
  DossierUnicoDetail: '/dossier-unico/:id',
  Home: '/'
}

const TEST_ROUTES = []

export const CONFIG = [
  ...TEST_ROUTES,
  // INSERT ROUTES HERE (Before root component)
  {
    key: PATHS.SearchOpecom,
    path: PATHS.SearchOpecom,
    component: SearchOpecom
  },
  {
    key: PATHS.EditOpecom,
    path: PATHS.EditOpecom,
    component: EditOpecomDetails
  },
  {
    key: PATHS.SearchDossierReferences,
    path: PATHS.SearchDossierReferences,
    component: SearchDossierReferences
  },
  {
    key: PATHS.SearchDossier,
    path: PATHS.SearchDossier,
    component: SearchDossier
  },
  {
    key: PATHS.SearchFamily,
    path: PATHS.SearchFamily,
    component: SearchFamily
  },
  {
    key: PATHS.EditDossier,
    path: PATHS.EditDossier,
    component: EditDossierDetails
  },
  {
    key: PATHS.FamilyStore,
    path: PATHS.FamilyStore,
    component: FamilyStore
  },
  {
    key: PATHS.NewDossier,
    path: PATHS.NewDossier,
    component: EditDossier
  },
  {
    key: PATHS.EditFamily,
    path: PATHS.EditFamily,
    component: Edit
  },
  {
    key: PATHS.NewFamily,
    path: PATHS.NewFamily,
    component: Edit
  },
  {
    key: PATHS.ChangeLog,
    path: PATHS.ChangeLog,
    component: ChangeLog
  },
  {
    key: PATHS.ProdottiSimbolo,
    path: PATHS.ProdottiSimbolo,
    component: ProdottiSimbolo
  },
  {
    key: PATHS.Families,
    path: PATHS.Families,
    component: Families
  },
  {
    key: PATHS.Anagrafica,
    path: PATHS.Anagrafica,
    component: AnagraficaProdotti
  },
  {
    key: PATHS.Dashboard,
    path: PATHS.Dashboard,
    component: Dashboard
  },
  {
    key: PATHS.SimulazioneReport,
    path: PATHS.SimulazioneReport,
    component: SimulazioneReport
  },
  {
    key: PATHS.SimulazioneDetail,
    path: PATHS.SimulazioneDetail,
    component: SimulazioneDetail
  },
  {
    key: PATHS.SimulazioneHistory,
    path: PATHS.SimulazioneHistory,
    component: SimulazioneHistory
  },
  {
    key: PATHS.SimulazioneCreate,
    path: PATHS.SimulazioneCreate,
    component: SimulazioneCreate
  },
  {
      key: PATHS.SimulazioneList,
      path: PATHS.SimulazioneList,
      component: SimulazioneList
  },
  {
    key: PATHS.Simulazione,
    path: PATHS.Simulazione,
    component: Simulazione
  },
  {
    key: PATHS.Dossier,
    path: PATHS.Dossier,
    component: Home
  },
  {
    key: PATHS.Family,
    path: PATHS.Family,
    component: Home
  },
  {
    key: PATHS.DossierUnico,
    path: PATHS.DossierUnico,
    component: DossierUnicoPage,
  },
  {
    key: PATHS.DossierUnicoDetail,
    path: PATHS.DossierUnicoDetail,
    component: DossierUnicoPage,
  },
  {
    key: PATHS.Home,
    path: PATHS.Home,
    component: Home
  }
]
