import React  from 'react';
import { Tooltip } from 'antd'

export default (props) => {
  const { formatValue } = props;
  let { value } = props;

  if (formatValue) {
    value = formatValue(value);
  }

  return <Tooltip
    title={value}
    overlayStyle={{ maxWidth: '500px' }}>
    <span
      style={{
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%'
      }}>
      {value}
    </span>
  </Tooltip>
};
