import React from 'react'
import * as _ from 'lodash'
import { Alert, Col, Row } from 'antd'
import {
  CardInformationContainer,
  CardInformationItem,
  PaginationCol,
  PaginationRow,
  StyledText,
} from 'Components/SearchDossierResults/styled'
import { StyledButton, StyledPagination, StyledCard } from 'Common/styled'
import moment from 'moment'
import PropTypes from 'prop-types'
import { opecomStates } from 'Common/constants'
import AppAccess from 'Common/appAccess'
import { getOpecomTitle } from 'Common/utils'
import {
  getOpecomInfo,
  OPECOM_NEGO_RICEZIONE_RELEX,
  OPECOM_NEGO_ULTIMO_INVIO_RELEX,
  OPECOM_SEDE_ULTIMA_RICEZIONE_RELEX,
  OPECOM_SEDE_ULTIMO_INVIO_RELEX
} from 'Common/constants/global'

/**
 * Component for opecom search results
 */
class SearchOpecomResults extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    onViewClick: PropTypes.func,
    onPageChange: PropTypes.func,
    totalItems: PropTypes.number,
    currentPage: PropTypes.number,
  }

  state = {
    items: []
  }

  componentDidMount() {
    this.setState({
      items: [...this.props.items.map(o => ({...o}))]
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        items: [...this.props.items.map(o => ({...o}))]
      });
    }
  }

  /**
   * View click handler
   * @param {object} item
   * @param {string|number} key
   */
  handleViewClick = (item, key) => {
    if (_.isFunction(this.props.onViewClick)) {
      this.props.onViewClick(item, key)
    }
  }

  /**
   * Generate title from an opecom
   * @param item
   * @returns {string}
   */
  getTitleFromItem = item => {
    return (
      <StyledText>
        {item.code}-{item.description} {item.idStore == null ? '' : `(Store ${item.idStore})`}
      </StyledText>
    )
  }

  /**
   * Handle Modifica button for an opecom, based on statoOpecom
   * @param item
   * @returns {string}
   */
  getModificaButton = item => {
    return (
      <StyledButton
        type="primary"
        onClick={e => this.handleViewClick(item, item.id)}>
        {AppAccess.isAuthorized('edit_opecom', {
          store: item.idStore,
          stato: item.statoOpecom,
        })
          ? 'Tratta'
          : 'Visualizza'}
      </StyledButton>
    )
  }

  /**
   * Page change handler
   * @param {number} page
   * @param {number} pageSize
   */
  handlePageChange = (page, pageSize) => {
    // Invoke parent onPageChange callback
    _.invoke(this.props, 'onPageChange', page, pageSize)
  }

  render() {
    const { items } = this.state;

    if (items.length === 0) {
      return (
        <div>
          <Row gutter={[0, 16]}>
            <Col
              xs={{ span: 22, offset: 1 }}
              xl={{ span: 18, offset: 3 }}
              xxl={{ span: 12, offset: 6 }}>
              <Alert
                message="Non ci sono risultati per la ricerca"
                type="info"
              />
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div>
        <Row gutter={[0, 16]}>
          {items.map(item => {
            const information = getOpecomInfo({ startDate: item.startDate, code: item.code });

            return (
              <Col
                key={item.id}
                xs={{ span: 22, offset: 1 }}
                xl={{ span: 18, offset: 3 }}
                xxl={{ span: 12, offset: 6 }}>
                <StyledCard
                  bordered={false}
                  title={<StyledText>{getOpecomTitle(item, this.props.stores)}</StyledText>}
                  extra={this.getModificaButton(item)}>
                  <CardInformationContainer>
                    <CardInformationItem>
                      <label>Data inizio</label>
                      <span>{moment(item.startDate).format('DD/MM/YYYY')}</span>
                    </CardInformationItem>
                    <CardInformationItem>
                      <label>Data fine</label>
                      <span>{moment(item.endDate).format('DD/MM/YYYY')}</span>
                    </CardInformationItem>
                    <CardInformationItem>
                      <label>Stato</label>
                      <span>
                      {
                        opecomStates.filter(
                          state => state.key === item.statoOpecom
                        )[0].label
                      }
                    </span>
                    </CardInformationItem>
                    <CardInformationItem>
                      {item.idStore === null && (
                        <>
                          <label>Data fine lavorazione sede</label>
                          <span>{information?.lastHqCompletionDate.format('DD/MM/YYYY')}</span>
                        </>
                      )}
                      {item.idStore !== null && (
                        <>
                          <label>Data fine lavorazione negozio</label>
                          <span>{information?.lastStoreCompletionDate.format('DD/MM/YYYY')}</span>
                        </>
                      )}
                    </CardInformationItem>
                    {item.idStore === null && (
                      <CardInformationItem>
                        <label>Data ultimo aggiornamento da Opecom</label>
                        <span>{information?.lastOpecomUpdateDate.format('DD/MM/YYYY')}</span>
                      </CardInformationItem>
                    )}
                    <CardInformationItem>
                      <label>Periodo completamento negozio</label>
                      <span>{information?.storeCompletionFrom.format('DD/MM/YYYY')} - {information?.storeCompletionTo.format('DD/MM/YYYY')}</span>
                    </CardInformationItem>
                  </CardInformationContainer>
                </StyledCard>
              </Col>
            );
          })}
        </Row>
        <PaginationRow>
          <PaginationCol
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 14, offset: 5 }}>
            <StyledPagination
              onChange={(page, pageSize) =>
                this.handlePageChange(page, pageSize)
              }
              total={_.get(this.props, 'totalItems', 0)}
              pageSize={10}
              showLessItems={true}
              hideOnSinglePage={true}
              current={this.props.currentPage}
            />
          </PaginationCol>
        </PaginationRow>
      </div>
    )
  }
}

export default SearchOpecomResults
