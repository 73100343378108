import React, { useEffect, useMemo, useState } from 'react';
import SimulazioneUtils from 'Common/simulazioneUtils';
import { css } from '@emotion/css';
import { agNumberFormatter, num } from 'Common/utils'
import useMemoizedFunction from '../../hooks/useMemoizedFunction'

export default function SimulazioneFooterStatisticsBar({ api, history }) {

  const [statistics, setStatistics] = useState(SimulazioneUtils.getDefaultStatistics());
  const memoizedCalculatedStatistics = useMemoizedFunction(SimulazioneUtils.getCalculatedStatistics);

  const recalculateData = () => {
    const data = [];

    api.forEachNodeAfterFilter(node => data.push(node.data));

    const updatedStatistics = memoizedCalculatedStatistics(data);

    if (!updatedStatistics) {
      return;
    }

    setStatistics(updatedStatistics);
  };

  //api.addEventListener('rowDataUpdated', recalculateData);
  api.addEventListener('firstDataRendered', recalculateData);
  api.addEventListener('filterChanged', recalculateData);
  //api.addEventListener('cellValueChanged', recalculateData);
  //api.addEventListener('rowValueChanged', recalculateData);
  //api.addEventListener('modelUpdated', recalculateData);


  return (
    <div className={css`display: flex; flex-direction: row; align-items: center; padding: 15px 0px; margin: 0 -10px;`}>
      {SimulazioneUtils.getStatisticLabels(history).map(label => (
        <div key={label.field} className={css`margin-left: 10px; margin-right: 10px; font-size: 14px;`}>
          <span className={css`opacity: 0.5;`}>{label.name}</span>
          <span className={css`margin-left: 10px; font-weight: bold;`}>{label.format ? label.format({ value: statistics[label.field] || 0 }) : agNumberFormatter({ value: statistics[label.field] || 0 })}</span>
        </div>
      ))}
    </div>
  )
}
