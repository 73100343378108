import styled from 'styled-components'
import { Button, Icon, Form, Row } from 'antd'
import { Link } from 'react-router-dom'

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`

export const StyledLink = styled(Link)`
  color: white;
  :hover {
    color: white;
  }
`

export const Title = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  padding-top: 0.4rem;
`

export const AddButton = styled(Button)`
  height: 6rem !important;
  width: 9rem !important;
  white-space: normal !important;
`

export const StyledIcon = styled(Icon)`
  font-size: 2rem;
`

export const StyledText = styled.span`
  font-size: 0.7rem;
`

export const StyledForm = styled(Form)`
  display: flex;
`

export const StyledFormItem = styled(Form.Item)`
  flex-grow: 1;
  display: flex !important;
  label {
    font-size: 1.1rem !important;
    color: rgba(0, 0, 0, 0.65) !important;
    font-weight: bold;
  }
  .ant-form-item-control-wrapper {
    // width: 20rem;
    flex-grow: 1;
  }
`

export const StyledDiv = styled.div`
  padding-left: 1rem;
`

export const StyledRow = styled(Row)`
  padding: 0 1rem 0 1rem;
  height: 4.5rem !important;
`
