export const PRECOTOP_REAPPRO_TO_BE_1_AND_PREV_VENDUTO_ANNUO_NULL = {fields: ['precotopReapproToBe', 'prevVendutoAnnuo'], description: 'Il precotop reappro futuro è 1 ma la prev venduto annuo non è valorizzata'}
export const ID_REF_SOSTITUITA_NOT_NULL_AND_TIPO_SOSTITUZIONE_NULL = {fields: ['idRefSostituita', 'tipoSostituzione'], description: 'È stata specificata una referenza sostituita ma non il tipo sostituzione'}
export const PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_A_NULL_OR_ZERO = {fields: ['precotopReapproToBe', 'gammaToBe', 'facingA'], description: 'La referenza è in A1 ma il facing cluster S è 0 o non valorizzato'}
export const PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_AB_NULL_OR_ZERO = {fields: ['precotopReapproToBe', 'gammaToBe', 'facingAb'], description: 'La referenza è in A1 ma il facing cluster M è 0 o non valorizzato'}
export const PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_A_AND_FACING_ABC_NULL_OR_ZERO = {fields: ['precotopReapproToBe', 'gammaToBe', 'facingAbc'], description: 'La referenza è in A1 ma il facing cluster L è 0 o non valorizzato'}
export const PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_B_AND_FACING_AB_NULL_OR_ZERO = {fields: ['precotopReapproToBe', 'gammaToBe', 'facingAb'], description: 'La referenza è in B1 ma il facing cluster M è 0 o non valorizzato'}
export const PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_B_AND_FACING_ABC_NULL_OR_ZERO = {fields: ['precotopReapproToBe', 'gammaToBe', 'facingAbc'], description: 'La referenza è in B1 ma il facing cluster L è 0 o non valorizzato'}
export const PRECOTOP_REAPPRO_TO_BE_1_GAMMA_TO_BE_C_AND_FACING_ABC_NULL_OR_ZERO = {fields: ['precotopReapproToBe', 'gammaToBe', 'facingAbc'], description: 'La referenza è in C1 ma il facing cluster L è 0 o non valorizzato'}
export const NUM_PDD_NULL = {fields: ['numPdd'], description: 'Il num PDD non è valorizzato'}
export const SPT_NULL = {fields: ['facingA'], description: 'Lo SPT non è valorizzato'}
export const PREV_VMM_NULL = {fields: ['prevVMM'], description: 'La previsione venduto non è valorizzata'}
export const FACING_A_NULL = {fields: ['facingA'], description: 'Il facing S non può essere null'};
export const FACING_AB_NULL = {fields: ['facingAb'], description: 'Il facing M non può essere null'};
export const FACING_ABC_NULL = {fields: ['facingAbc'], description: 'Il facing L non può essere null'};
export const STATO_INI_OR_PRE = {fields: ['stato'], description: 'Lo stato è INI o PRE'}

export const PREV_TOT_MUST_NULL = {fields: ['prevVtot'], description: 'christmas'};
export const SPT_MUST_NULL = {fields: ['stock'], description: 'Lo stato è INI o PRE'}
