import React from 'react'
import { StyledButton } from 'Common/styled'
import { StyledFormItem, StyledInputNumber } from './styled'
import { Form, Modal, Row, Col, Select, DatePicker } from 'antd'
import AppAccess from 'Common/appAccess'

const { Option } = Select
const { RangePicker } = DatePicker

export const EditRefsModal = Form.create({ name: 'edit_refs_modal' })(
  class extends React.Component {
    state = {
      saveDisabled: false
    }

    disableSave = saveDisabled => {
      this.setState({ saveDisabled });
    }

    render() {
      const { visible, onCancel, onSave, form, isBatchSavingRefs, user, cluster,
        dossierHeader, departments, families } = this.props
      const { getFieldDecorator } = form

      const { saveDisabled } = this.state

      const formBody = dossierHeader.type === 'XS' ?
        <>
          <Col span={12}>
            <StyledFormItem
              label="Reparto suggerito"
              colon={false}>
              {getFieldDecorator('departments', {
                rules: [{
                  validator: (rule, value, callback) => {
                    if (value) {
                      if (value.length > 5) {
                        this.disableSave(true);
                        callback('Massimo 5 reparti selezionabili')
                      } else if (value.length <= 5) {
                        this.disableSave(false);
                        callback()
                      }
                    }
                  }
                }]
              })(
                <Select
                  allowClear
                  mode="multiple"
                  optionFilterProp="children"
                  maxTagCount={1}
                  maxTagTextLength={
                    form.getFieldValue('departments') &&
                      form.getFieldValue('departments').length > 1
                      ? 8
                      : 15
                  }>
                  {departments.map(department => (
                    <Option key={department.id} value={department.id}>
                      {`${department.id}-${department.label}`}
                    </Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label="Famiglia merchandising"
              colon={false}>
              {getFieldDecorator('family', {
                rules: [{
                  validator: (rule, value, callback) => {
                    if (value) {
                      if (value.length > 5) {
                        this.disableSave(true)
                        callback('Massimo 5 famiglie selezionabili')
                      } else if (value.length <= 5) {
                        this.disableSave(false)
                        callback()
                      }
                    }
                  }
                }]
              })(
                <Select
                  mode="multiple"
                  showSearch
                  allowClear
                  maxTagCount={1}
                  optionFilterProp="children"
                  filterOption={true}>
                  {families.map(family => (
                    <Option key={family.idFamily} value={family.idFamily}>
                      {family.name}
                    </Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
          </Col>
          <Col span={8} offset={4}>
            <StyledFormItem label="Facing XS" colon={false}>
              {getFieldDecorator('facingXs')(
                <StyledInputNumber min={0} precision={0} />
              )}
            </StyledFormItem>
          </Col>
          <Col span={8}>
            <StyledFormItem label="Cluster XS" colon={false}>
              {getFieldDecorator('clusterXs')(
                <Select>
                  <Option key="A" value="A">A</Option>
                  <Option key="AB" value="AB">AB</Option>
                  <Option key="ABC" value="ABC">ABC</Option>
                </Select>
              )}
            </StyledFormItem>
          </Col>
          <Col span={20} offset={2}>
            <StyledFormItem label="Data inizio-fine XS" colon={false}>
              {getFieldDecorator('installation', {
                rules: [{
                  validator: (rule, value, callback) => {
                    if (value) {
                      if (!value[0].isBetween(dossierHeader.installationFrom, dossierHeader.installationTo, 'day', '[)')
                      || !value[1].isBetween(dossierHeader.installationFrom, dossierHeader.installationTo, 'day', '(]')) {
                        this.disableSave(true)
                        callback(`Le date d'inizio e fine devono essere comprese tra il periodo d'impianto (${dossierHeader.installationFrom} - ${dossierHeader.installationTo})`)
                      } else {
                        this.disableSave(false);
                      }
                    }
                  }
                }]
              })(
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={["Data d'inizio", 'Data di fine']}
                />
              )}
            </StyledFormItem>
          </Col>
        </>
        :
        <>
          {(cluster === null || cluster.cluster === 'A') && user.role !== AppAccess.ROLE_CP && <Col span={8}>
            <StyledFormItem label="Facing A" colon={false}>
              {getFieldDecorator('facingA')(
                <StyledInputNumber min={0} precision={0} />
              )}
            </StyledFormItem>
          </Col>}
          {(cluster === null || cluster.cluster === 'AB') && user.role !== AppAccess.ROLE_CP && <Col span={8}>
            <StyledFormItem label="Facing AB" colon={false}>
              {getFieldDecorator('facingAb')(
                <StyledInputNumber min={0} precision={0} />
              )}
            </StyledFormItem>
          </Col>}
          {(cluster === null || cluster.cluster === 'ABC') && user.role !== AppAccess.ROLE_CP && <Col span={8}>
            <StyledFormItem label="Facing ABC" colon={false}>
              {getFieldDecorator('facingAbc')(
                <StyledInputNumber min={0} precision={0} />
              )}
            </StyledFormItem>
          </Col>}
          {user.role === AppAccess.ROLE_MERCH && <Col span={8}>
            <StyledFormItem label="Stock presentazione" colon={false}>
              {getFieldDecorator('stock')(
                <StyledInputNumber
                  min={0}
                  precision={2}
                  decimalSeparator=","
                />
              )}
            </StyledFormItem>
          </Col>}
          {user.role === AppAccess.ROLE_MERCH && <Col span={8}>
            <StyledFormItem label="Progressivo PDD" colon={false}>
              {getFieldDecorator('numPdd')(
                <StyledInputNumber
                  min={0}
                  precision={0}
                />
              )}
            </StyledFormItem>
          </Col>}
          {user.role === AppAccess.ROLE_CP && ['CR', 'R1'].includes(dossierHeader.statoDossier) && ['NG', 'RG', 'ST'].includes(dossierHeader.type) && <Col span={8}>
            <StyledFormItem label="Precotop Reappro Futuro" colon={false}>
              {getFieldDecorator('precotopReapproToBe')(
                <Select allowClear>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                </Select>
              )}
            </StyledFormItem>
          </Col>
          }
        </>

      return (
        <Modal
          visible={visible}
          title="Modifica massiva referenze"
          onCancel={onCancel}
          maskClosable={false}
          afterClose={() => { this.disableSave(false); form.resetFields() }}
          footer={[
            <StyledButton key="back" onClick={onCancel}>
              Indietro
            </StyledButton>,
            <StyledButton
              key="submit"
              type="primary"
              disabled={saveDisabled}
              onClick={onSave}
              loading={isBatchSavingRefs}>
              Conferma e salva
            </StyledButton>
          ]}>
          <Form layout="vertical">
            <Row gutter={[32, 0]}>
              {formBody}
            </Row>
          </Form>
        </Modal>
      )
    }
  }
)
