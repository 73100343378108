import React from 'react'
import { StyledButton } from 'Common/styled'
import { StyledFormItem } from './styled'
import { Form, Modal, Row, Col, Select, InputNumber, Input } from 'antd'

const { Option } = Select

export const EditOpecomRefsModal = Form.create({
  name: 'edit_opecom_refs_modal',
})(
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onSave,
        form,
        isBatchSavingRefs,
        fields,
      } = this.props
      const { getFieldDecorator } = form

      const formBody = fields.map(field => {
        let inputField

        if (field.editableSelect) {
          inputField = (
            <Select allowClear>
              {field.editableSelectList.map(option => (
                <Option key={option.id} value={option.id}>
                  {option.label}
                </Option>
              ))}
            </Select>
          )
        } else {
          switch (field.contentType) {
            case 'decimal':
              inputField = (
                <InputNumber
                  min={field.minValue ? field.minValue(form.getFieldsValue()) : 0}
                  precision={field.precision ? field.precision : 2}
                  decimalSeparator=","
                  disabled={field.disabled ? field.disabled(form.getFieldsValue()) : false}
                />
              )
              break;
          
            default:
              inputField = (
                <Input/>
              )
              break;
          }
          
        }

        return (
          <Col span={12}>
            <StyledFormItem
              label={field.cleanTitle ? field.cleanTitle : field.title}
              colon={false}>
              {getFieldDecorator(field.dataIndex)(inputField)}
            </StyledFormItem>
          </Col>
        )
      })

      const canSave = () => {
        const values = form.getFieldsValue()

        for (const field in values) {
          if (values[field]) {
            return true
          }
        }

        return false
      }

      return (
        <Modal
          visible={visible}
          title="Modifica massiva referenze"
          onCancel={onCancel}
          maskClosable={false}
          afterClose={() => {
            form.resetFields()
          }}
          footer={[
            <StyledButton key="back" onClick={onCancel}>
              Indietro
            </StyledButton>,
            <StyledButton
              key="submit"
              type="primary"
              disabled={!canSave()}
              onClick={onSave}
              loading={isBatchSavingRefs}>
              Conferma e salva
            </StyledButton>,
          ]}>
          <Form layout="vertical">
            <Row gutter={[32, 0]}>{formBody}</Row>
          </Form>
        </Modal>
      )
    }
  }
)
