export const steps = [
  {
    selector: 'h1', // HTML selector
    content: 'Beautiful title'
  },
  {
    selector: 'button', 
    content: 'Beautiful button'
  },
  {
    selector: 'p',
    content: 'Beautiful paragraph'
  }
]
