import { combineReducers } from 'redux'
import App from './app-reducer'
import User from './user-reducer'
import Tour from './tour-reducer'
import Data from './data-reducer'
import Edit from './edit-reducer'
import Search from './search-reducer'
import Notifications from './notifications-reducer'

const combinedReducers = combineReducers({
  App,
  User,
  Tour,
  Data,
  Edit,
  Search,
  Notifications
})

export default combinedReducers
