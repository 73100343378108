let env = process.env.REACT_APP_ENV;
if (env === undefined) {
  env = 'naked';
}

const defaults = {
  SIMULATION_API_URL: 'https://lmit-ds-sng-lrhmwwu6pa-ew.a.run.app'
};

const envConfig = {
  naked: {
    ...defaults,
    FE_URL: 'http://localhost:3001',
    API_URL: 'http://localhost:8080',
    // LOGIN_URL: 'https://test-lmit-cooperate-itlm-ping-p7aok5axaq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=' + encodeURIComponent('http://localhost:3000')
  },
  dev: {
    ...defaults,
    FE_URL: 'http://localhost:3001',
    API_URL: 'https://lmit-cooperate-itlm-api-test-p7aok5axaq-ew.a.run.app',
    //LOGIN_URL: 'https://test-lmit-cooperate-itlm-ping-p7aok5axaq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=' + encodeURIComponent('http://localhost:3000'),
    LOGIN_URL: 'https://prod-lmit-cooperate-itlm-ping-p7aok5axaq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=' + encodeURIComponent('http://localhost:3000')
  },
  test: {
    ...defaults,
    FE_URL: 'https://cooperate.test.cloud.leroymerlin.it',
    API_URL: 'https://lmit-cooperate-api-test-pakh2dlofq-ew.a.run.app',
    LOGIN_URL: 'https://test-lmit-cooperate-itlm-ping-p7aok5axaq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=' + encodeURIComponent('https://cooperate.test.cloud.leroymerlin.it')
  },
  preprod: {
    ...defaults,
    FE_URL: 'https://cooperate.pre.cloud.leroymerlin.it',
    API_URL: 'https://cooperatebe.pre.cloud.leroymerlin.it',
    LOGIN_URL: 'https://cooperatebe.pre.cloud.leroymerlin.it/saml/dologin'
  },
  prod: {
    ...defaults,
    FE_URL: 'https://cooperate.cloud.leroymerlin.it',
    API_URL: 'https://lmit-cooperate-itlm-api-p7aok5axaq-ew.a.run.app',
    LOGIN_URL: 'https://prod-lmit-cooperate-itlm-ping-p7aok5axaq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=' + encodeURIComponent('https://cooperate.cloud.leroymerlin.it')
  }
};

export default envConfig[env]
