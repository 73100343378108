import * as actionTypes from '../action-types'

export const initialState = {
  nomenclatures: {
    dismissed: false
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.DISMISS_NOTIFICATION:
      return {
        ...state,
        [payload]: {
          dismissed: true
        }
      }
    default:
      return state
  }
}

export default reducer
