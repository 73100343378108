import React, { Component } from 'react'
import { Form, Button, Select } from 'antd'
import { StyledModal, StyledFormItem } from './styled'

const { Option } = Select

export const EditSubcategoryModal = Form.create({
  name: 'edit_subcategory_modal',
  mapPropsToFields(props) {
    return {
      idSubCategory: Form.createFormField({
        value: props.familySubcategories.map(subcategory => subcategory.id)
      })
    }
  }
})(
  class extends Component {
    handleSelectSubcategory = value => {
      if (value === 'all') {
        this.props.form.setFieldsValue({
          idSubCategory: this.props.subcategories.map(
            subcategory => subcategory.subCategoryId
          )
        })
      }
    }

    render() {
      const {
        visible,
        onCancel,
        onOk,
        form,
        category,
        subcategories
      } = this.props
      const { getFieldDecorator } = form
      const formItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
        labelAlign: 'left'
      }
      return (
        <StyledModal
          visible={visible}
          title="Modifica i sottotipi"
          onCancel={onCancel}
          onOk={onOk}
          maskClosable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => onOk(category.id)}>
              OK
            </Button>
          ]}>
          <Form {...formItemLayout}>
            <StyledFormItem label="Tipo" colon={false}>
              <span className="ant-form-text">{`${category.id}-${category.label}`}</span>
            </StyledFormItem>
            <StyledFormItem label="Sottotipi" colon={false}>
              {getFieldDecorator('idSubCategory', {
                rules: [
                  { required: true, message: 'Scegli almeno un sottotipo' }
                ]
              })(
                <Select
                  mode="multiple"
                  allowClear
                  onSelect={this.handleSelectSubcategory}>
                  {subcategories.length !== 0 && (
                    <Option key={'all'}>Tutti i sottotipi</Option>
                  )}
                  {subcategories.map(subcategory => (
                    <Option
                      key={subcategory.subCategoryId}
                      value={subcategory.subCategoryId}>
                      {`${subcategory.subCategoryId}-${subcategory.label}`}
                    </Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
          </Form>
        </StyledModal>
      )
    }
  }
)
