import React from 'react';
import { Tag } from 'antd'
import { css } from '@emotion/css'

const containerStyle = css`
  display: block;
  width: 100%;
  height: auto;
  text-align: center;
`;

export default function simulationProductStateRenderer(props) {

  if (props.data.removed === true) {
    return (
      <div className={containerStyle}>
        <Tag color="red">OUT</Tag>
      </div>
    );
  }

  if (props.data.added === true) {
    return (
      <div className={containerStyle}>
        <Tag color="green">IN</Tag>
      </div>
    );
  }

   return (
    <div className={containerStyle}>
      <Tag>ATTIVO</Tag>
    </div>
  );

};
