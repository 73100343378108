import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import MainLayout from 'Hoc/MainLayout'
import { StyledH1 } from 'Common/styled'
import AppAccess from 'Common/appAccess'
import ErrorPage from 'Components/ErrorPage'
import { fetchProductIds, fetchStores } from 'Actions/data-actions'
import Loader from 'Components/Loader'
import { Col, Button, DatePicker, Descriptions, Icon, Input, Row, Select, Modal, InputNumber, notification } from 'antd'
import { SearchOpecomFilters } from 'Components/SearchOpecomFilters'
import { StyledRow } from 'Containers/SearchOpecom/styled'
import {
  CollapsedFormMask,
  StyledButton,
  StyledCollapse, StyledForm,
  StyledFormItem,
  StyledFormRow
} from 'Components/SearchOpecomFilters/styled'
import { DATE_LOCALE } from 'Common/globals'
import { StyledFormFake } from 'Containers/ProdottiSimbolo/styled'
import api from 'Common/api'
import { getOpecomTitle, handleServerError, handleServerSuccess } from 'Common/utils'
import { StyledDescriptions, StyledTitle } from 'Containers/EditOpecomDetails/styled'
import * as moment from 'moment'
import { opecomStates } from 'Common/constants'
import ProdottiSimboloTable from 'Containers/ProdottiSimbolo/ProdottiSimboloTable'
import { ROLE_AZ_COMM, ROLE_CPXS, ROLE_CS } from 'Common/constants/global'

/**
 * Container for opecom search page
 */
class ProdottiSimbolo extends React.Component {
  /**
   * Component's state
   * @type {{isLoading: boolean, redirectTo: null}}
   */
  state = {
    isLoading: true,
    redirectTo: null,
    dossier: null,
    selectedStore: null,
    status: null,
    showHeaderModal: false,
    changeLimit: null,
    hasChanges: false
  }

  componentDidMount = () => {
    if (!AppAccess.isAuthorized('prodotti_simbolo')) {
      this.setState({ status: '403' });
      return;
    }

    const newState = {};

    if (this.props.match.params.id) {
      Promise.all([
        this.props.fetchStores()
      ]).then(() => {
        this.retrieveDossier(Number(this.props.match.params.id));
      }).catch();
    } else {
      Promise.all([
        this.props.fetchStores()
      ]).then(() => {
        newState.isLoading = false;
        this.setState(newState);
      }).catch();
    }
  }

  goRoot() {
    const { user } = this.props;

    if (this.state.hasChanges === true && [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role)) {
      const btn = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button size="small" onClick={() => {
            notification.close('saved-changes-notification');
          }}>
            No
          </Button>

          <Button type="primary" size="small" onClick={() => {
            notification.close('saved-changes-notification');
            this.props.history.push(`/prodotti-simbolo`);
            this.props.fetchStores();
            this.setState({
              dossier: null
            });
          }} style={{ marginLeft: 10 }}>
            Si
          </Button>
        </div>
      );


      notification.open({
        message: 'Modifiche non notificate',
        description: 'Ci sono delle modifiche salvate che non sono state notificate ai negozi. Sei sicuro di voler procedere?',
        btn,
        duration: 0,
        key: 'saved-changes-notification',
        onClose: () => {}
      });

      return;
    }

    this.props.history.push(`/prodotti-simbolo`);
    this.props.fetchStores();
    this.setState({
      dossier: null
    });
  }

  onDossierChange(value) {
    this.setState({ selectedStore: Number(value) });
  }

  onAddProducts() {
    const { dossier } = this.state;
    this.setState({ redirectTo: `/dossier/${dossier.id}/add` })
  }

  selectDossier() {
    const { selectedStore } = this.state;
    this.setState({ isLoading: true });
    window.history.pushState({}, document.title, `/prodotti-simbolo/${selectedStore}`);

    this.retrieveDossier(selectedStore);
  }

  retrieveDossier(selectedStore) {
    api.dossiers.getDossierPSByStoreId(selectedStore).then(response => {
      this.setState({ dossier: response.data, isLoading: false, changeLimit: response.data.changeLimit });
    }).catch(handleServerError);
  }

  handleTableChanges(changes) {
    this.setState({
      hasChanges: Object.keys(changes).length > 0
    });
  }

  getContent() {
    const { dossier, selectedStore } = this.state;
    const { stores, user, productIds } = this.props;

    if (dossier) {
      return (
        <>
          {this.getTestata()}
          {this.getChangeDataModal()}
          <ProdottiSimboloTable dossier={dossier} onAddProducts={this.onAddProducts.bind(this)} user={user} stores={stores} productIds={productIds} onTableChanges={this.handleTableChanges.bind(this)} />
        </>
      )
    }

    return (
      <>
        <StyledRow type="flex" justify="center">
          <Col xs={22} xl={18} xxl={12}>
            <StyledFormFake>
              <StyledFormRow gutter={16} type="flex">
                <Col xs={24} sm={24} md={24}>
                  <StyledFormItem
                    label="Seleziona Dossier"
                    colon={false}>
                      <Select style={{ width: '100%' }} onChange={this.onDossierChange.bind(this)}>
                        <Select.Option value="0">SEDE</Select.Option>
                        {Array.isArray(stores) && stores.map(store => <Select.Option value={store.id} key={store.id}>{store.id} - {store.name}</Select.Option>)}
                      </Select>
                  </StyledFormItem>
                </Col>
              </StyledFormRow>
              <Row type="flex" justify="end" gutter={16} style={{ marginTop: '12px' }}>
                <Col xs={12} sm={6} md={4}>
                  <StyledButton
                    type="primary" onClick={this.selectDossier.bind(this)} disabled={selectedStore === null}>
                    {(selectedStore === 0 && [ROLE_AZ_COMM, ROLE_CPXS].includes(user.role)) || (selectedStore > 0 && user.role === ROLE_CS && user.store === selectedStore) ? 'Tratta' : 'Visualizza'}
                  </StyledButton>
                </Col>
              </Row>
            </StyledFormFake>
          </Col>
        </StyledRow>
      </>
    )
  }

  getTestata() {
    const { dossier } = this.state;

    if (dossier.idStore > 0) {
      return '';
    }

    return (
      <StyledDescriptions
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Button
              icon="left"
              onClick={() => this.goRoot()}>
              Indietro
            </Button>
            <StyledTitle>
              {dossier.description}
            </StyledTitle>
            {dossier.idStore === 0 && AppAccess.isAuthorized('change_header_prodotti_simbolo') && <Button
              style={{ marginLeft: 'auto' }}
              onClick={this.showHeaderModal}>
              Modifica Testata
            </Button>}
          </div>
        }
        size="small"
        bordered
        column={{ xs: 1, sm: 2, md: 3, xl: 4 }}>
        <Descriptions.Item label="Limite aggiornamento negozi">
          {dossier.changeLimit} {dossier.changeLimit === 1 ? 'giorno' : 'giorni'}
        </Descriptions.Item>
      </StyledDescriptions>
    )
  }

  changeHeader = () => {
    const { dossier, changeLimit } = this.state;

    api.dossiers.update(dossier.id, {
      changeLimit: changeLimit
    }).then(response => {
      this.setState({ dossier: response.data, changeLimit: response.data.changeLimit });
      handleServerSuccess();
    }).catch();

    this.hideHeaderModal();
  }

  showHeaderModal = () => {
    this.setState({ showHeaderModal: true });
  }

  hideHeaderModal = () => {
    const { dossier } = this.state;

    this.setState({ showHeaderModal: false, changeLimit: dossier.changeLimit });
  }

  getChangeDataModal() {
    const { showHeaderModal, changeLimit } = this.state;
    return (
      <Modal
        title="Modifica testata dossier"
        visible={showHeaderModal}
        onOk={this.changeHeader}
        onCancel={this.hideHeaderModal}
      >
        <InputNumber min={1} value={changeLimit} style={{ width: '100%' }} onChange={value => this.setState({ changeLimit: value })} />
      </Modal>
    )
  }

  /**
   * Render method
   * @returns {*}
   */
  render() {
    const {
      isLoading,
      redirectTo,
      status
    } = this.state

    return isLoading ? (
      <Loader />
    ) : redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : status !== null ? (
      <ErrorPage status={status} />
    ) : (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        defaultCurrentPage={'prodotti-simbolo'}>
        <StyledH1>Prodotti Simbolo/Complementari</StyledH1>
        {this.getContent()}
      </MainLayout>
    )
  }
}

/**
 * Redux store state
 * @type {{}}
 */
const mapStateToProps = state => {
  return {
    stores: state.Data.stores,
    productIds: state.Data.productIds,
    user: state.User
  }
}
/**
 * Redux store actions
 * @type {{}}
 */
const mapDispatchToProps = dispatch => {
  return {
    fetchStores: () => dispatch(fetchStores()),
    fetchProductIds: () => dispatch(fetchProductIds())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProdottiSimbolo))
