import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import * as moment from 'moment';

import { setTour } from 'Actions/tour-actions'
import {
  fetchDepartments,
  fetchFamilies,
  fetchDossiers,
  fetchStores
} from 'Actions/data-actions'
import {
  saveDossierFilters
} from 'Actions/search-actions'

import MainLayout from 'Hoc/MainLayout'
import {
  StyledH1,
  StyledRow,
  StyledForm,
  StyledFormRow,
  StyledFormItem,
  StyledButton,
  StyledCol,
  StyledCollapse,
  CollapsedFormMask
} from './styled'
import Loader from 'Components/Loader'
import SearchDossierResults from 'Components/SearchDossierResults'

import { steps } from './constants'
import { TOUR_HOME, dossierTypes, dossierStates } from 'Common/constants'
import { withRouter } from 'react-router'
import { Form, Col, Input, Select, Icon, InputNumber } from 'antd'
import AppAccess from 'Common/appAccess'
import { ROLE_CP, ROLE_MERCH, ROLE_CPXS, ROLE_CS } from 'Common/constants/global'

const { Option } = Select

export const Filters = Form.create({
  name: 'filters_form',
  mapPropsToFields(props) {
    return {
      dossierId: Form.createFormField({
        value: props.filters.dossierId
      }),
      year: Form.createFormField({
        value: props.filters.year
      }),
      departments: Form.createFormField({
        value: props.filters.departments
      }),
      title: Form.createFormField({
        value: props.filters.title
      }),
      family: Form.createFormField({
        value: props.filters.family
      }),
      types: Form.createFormField({
        value: props.filters.types
      }),
      typology: Form.createFormField({
        value: props.filters.typology
      }),
      states: Form.createFormField({
        value: props.filters.states
      }),
      productCode: Form.createFormField({
        value: props.filters.productCode
      }),
      productDescription: Form.createFormField({
        value: props.filters.productDescription
      }),
      storeId: Form.createFormField({
        value: props.filters.storeId
      })
    }
  }
})(
  class extends Component {
    static propTypes = {
      departments: PropTypes.array,
      families: PropTypes.array,
      stores: PropTypes.array,
      onSubmit: PropTypes.func,
      isSearching: PropTypes.bool
    }

    state = { collapsed: false }

    handleReset = () => {
      this.props.form.resetFields()
    }

    handleSelectDepartment = value => {
      if (value === 'all') {
        this.props.form.setFieldsValue({
          departments: this.props.departments.map(department => department.id)
        })
      }
    }

    handleChangeDepartment = value => {
      const departments = value.includes('all')
        ? this.props.departments.map(department => department.id)
        : value
      if (departments.length !== 0) {
        const selectedFamilyId = this.props.form.getFieldValue('family')
        // If a family is already selected and is not compatible with selected departments, deselect it
        if (
          selectedFamilyId &&
          !departments.includes(
            this.props.families.filter(
              family => family.idFamily === selectedFamilyId
            )[0].idDepartment
          )
        ) {
          this.props.form.setFieldsValue({ family: null })
        }
      }
    }

    handleSelectType = value => {
      if (value === 'all') {
        this.props.form.setFieldsValue({
          types: dossierTypes.map(type => type.key)
        })
      }
    }

    handleSelectStore = value => {
      if (value > 0) {
        this.props.form.setFieldsValue({
          states: dossierStates.filter(state => state.canView.includes(this.props.user.role)).map(state => state.key)
        })
      }
    }

    handleCollapse = () => {
      this.setState(state => ({
        collapsed: !state.collapsed
      }))
    }

    filterFamily = family => {
      const departments = this.props.form.getFieldValue('departments')
      if (departments && departments.length !== 0) {
        return departments.includes(family.idDepartment)
      }
      return true
    }

    render() {
      const { form, departments, families, onSubmit, isSearching, user, stores } = this.props
      const { getFieldDecorator } = form
      const { collapsed } = this.state
      return (
          <StyledForm
            className={collapsed ? 'collapsed' : null}
            onSubmit={onSubmit}>
            {collapsed && <CollapsedFormMask />}
            <StyledFormRow gutter={16} type="flex">
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="ID Dossier"
                  colon={false}>
                  {getFieldDecorator('dossierId', {
                    rules: [],
                    normalize: value => value ? value.replace(/\D/g, '') : ''
                  })(<Input />)}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Anno"
                  colon={false}>
                  {getFieldDecorator('year', {
                    rules: [{ required: true, message: 'Inserisci un anno' }],
                    normalize: value => value ? value.replace(/\D/g, '') : ''
                  })(<Input maxLength={4} />)}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Reparto"
                  colon={false}>
                  {getFieldDecorator('departments')(
                    <Select
                      mode="multiple"
                      allowClear
                      optionFilterProp="children"
                      maxTagCount={1}
                      maxTagTextLength={
                        form.getFieldValue('departments') &&
                        form.getFieldValue('departments').length > 1
                          ? 8
                          : 15
                      }
                      onSelect={this.handleSelectDepartment}
                      onChange={this.handleChangeDepartment}>
                      <Option key="all">Tutti i reparti</Option>
                      {departments.map(department => (
                        <Option key={department.id} value={department.id}>
                          {`${department.id}-${department.label}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Titolo"
                  colon={false}>
                  {getFieldDecorator('title')(<Input />)}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Famiglia"
                  colon={false}>
                  {getFieldDecorator('family')(
                    <Select
                      showSearch
                      optionFilterProp="children"
                      allowClear={true}
                      filterOption={true}>
                      {families.filter(this.filterFamily).map(family => (
                        <Option key={family.idFamily} value={family.idFamily}>
                          {family.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Tipo"
                  colon={false}>
                  {getFieldDecorator('types')(
                    <Select
                      mode="multiple"
                      allowClear
                      maxTagCount={1}
                      maxTagTextLength={
                        form.getFieldValue('types') &&
                        form.getFieldValue('types').length > 1
                          ? 6
                          : 15
                      }
                      onSelect={this.handleSelectType}
                      disabled={user.role === AppAccess.ROLE_CPXS}>
                      <Option key="all">Tutti i tipi</Option>
                      {dossierTypes.filter(type => type.canView.includes(user.role)).map(type => (
                        <Option
                          key={type.key}>{`${type.key}-${type.label}`}</Option>
                      ))}
                    </Select>
                  )}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Tipologia"
                  colon={false}>
                  {getFieldDecorator('typology')(
                    <Select disabled={user.role === ROLE_CS}>
                      <Option key="all">Tutti</Option>
                      <Option key="S">Sede</Option>
                      <Option key="N">Negozi</Option>
                    </Select>
                  )}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Stato"
                  colon={false}>
                  {getFieldDecorator('states')(
                    <Select
                      mode="multiple"
                      allowClear
                      maxTagCount={1}
                      maxTagTextLength={
                        form.getFieldValue('states') &&
                        form.getFieldValue('states').length > 1
                          ? 6
                          : 15
                      }
                      onSelect={this.handleSelectState}>
                      <Option key="all">Tutti gli stati</Option>
                      {dossierStates.filter(state => state.canView.includes(user.role)).map(state => (
                        <Option
                          key={state.key}>{`${state.key}-${state.label}`}</Option>
                      ))}
                    </Select>
                  )}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Referenza"
                  colon={false}>
                  {getFieldDecorator('productCode', {
                    rules: [{ required: false, message: 'Inserisci ID referenza' }],
                    normalize: value => value ? value.replace(/\D/g, '') : ''
                  })(<Input />)}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Descrizione Referenza"
                  colon={false}>
                  {getFieldDecorator('productDescription', {
                    rules: [{ required: false, message: 'Inserisci descrizione referenza' }]
                  })(<Input />)}
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <StyledFormItem
                  className={collapsed ? 'unclickable' : null}
                  label="Negozio"
                  colon={false}>
                  {getFieldDecorator('storeId')(
                    <Select
                      disabled={user.role === ROLE_CS}
                      allowClear>
                      {(stores || []).filter(store => user.role !== ROLE_CS || (user.role === ROLE_CS && user.store === store.id)).map(store => (
                        <Option
                          key={store.id} value={store.id}>{`${store.id}-${store.name}`}</Option>
                      ))}
                    </Select>
                  )}
                </StyledFormItem>
              </Col>
              <StyledCol xs={12} sm={6} md={4}>
                <StyledButton onClick={this.handleReset}>Reset</StyledButton>
              </StyledCol>
              <StyledCol xs={12} sm={6} md={4}>
                <StyledButton
                  type="primary"
                  htmlType="submit"
                  loading={isSearching}>
                  Cerca
                </StyledButton>
                </StyledCol>
            </StyledFormRow>
            <StyledCollapse shape="circle" onClick={this.handleCollapse}>
              <Icon type={collapsed ? 'down' : 'up'}></Icon>
            </StyledCollapse>
          </StyledForm>
      )
    }
  }
)

class SearchDossier extends Component {
  static propTypes = {
    tour: PropTypes.object,
    setTour: PropTypes.func,
    fetchFamilies: PropTypes.func,
    fetchDepartments: PropTypes.func,
    fetchDossiers: PropTypes.func,
    families: PropTypes.array,
    departments: PropTypes.array,
    stores: PropTypes.array,
    dossiers: PropTypes.object
  }

  state = {
    isLoading: true,
    redirectTo: null,
    showResults: false,
    isSearching: false,
    filters: {
      productCode: '',
      productDescription: '',
      storeId: this.props.user && this.props.user.role === ROLE_CS ? this.props.user.store : null,
      year: moment().format('YYYY'),
      types: this.props.user.role === AppAccess.ROLE_CPXS ? ['XS'] : [],
      typology: [ROLE_CP, ROLE_MERCH, ROLE_CPXS].includes(this.props.user.role) ? 'S' : this.props.user.role === ROLE_CS ? 'N' : 'all',
      states: dossierStates.filter(state => state.canView.includes(this.props.user.role) && state.key !== 'AN').map(state => state.key)
    },
    currentPage: 1
  }

  componentDidMount() {
    const referrer = this.props.location.state ? this.props.location.state.referrer : undefined
    const prevSearch = this.props.prevSearch

    if (referrer === '/edit-dossier' && prevSearch.filters) {
      this.setState(prevSearch)
      const { dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId} = prevSearch.filters
      this.props
          .fetchDossiers(dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, prevSearch.currentPage - 1, 10)
          .then(this.setState({ showResults: true, isSearching: false }))
    }

    let promises = [
      this.props.fetchFamilies(),
      this.props.fetchDepartments()
    ]

    if (this.props.stores.length === 0) {
      promises.push(this.props.fetchStores())
    }

    Promise.all(promises)
      .then(() =>
        this.setState({
          isLoading: false
        })
      )
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form } = this.filters.props
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isSearching: true, filters: _.cloneDeep(values) })
        const { dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId } = values
        this.props
          .fetchDossiers(dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, 0, 10)
          .then(
            this.setState({
              showResults: true,
              isSearching: false,
              currentPage: 1,
            })
          )
      }
    })
  }

  handleViewClick = (dossier, dossierId) => {
    this.props.saveDossierFilters({filters: this.state.filters, currentPage: this.state.currentPage})
    this.setState({ redirectTo: `/edit-dossier/${dossierId}` })
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page })
    const { dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId } = this.state.filters
    this.props.fetchDossiers(
      dossierId,
      year,
      departments,
      title,
      family,
      types,
      typology,
      states,
      productCode,
      productDescription,
      storeId,
      page - 1,
      pageSize
    )
  }

  render() {
    const { /* tour, */ setTour, families, departments, dossiers, user, stores } = this.props
    const { isLoading, redirectTo, showResults, isSearching, filters, currentPage } = this.state
    // const isTourAvailable = TOUR_HOME in tour
    // const isTourOpen = isTourAvailable && !tour[TOUR_HOME]
    return isLoading ? (
      <Loader />
    ) : redirectTo !== null ? (
      <Redirect to={redirectTo} />
    ) : (
      <MainLayout
        tourSteps={steps}
        isTourAvailable={false}
        isTourOpen={false}
        onNavigationChange={this.onNavigationChange}
        defaultCurrentPage={'dossier'}
        closeTour={() => setTour(TOUR_HOME)}>
        <StyledH1>Ricerca dossier</StyledH1>
        <StyledRow type="flex" justify="center">
          <Col xs={22} xl={20} xxl={16}>
            <Filters
              wrappedComponentRef={ref => {
                this.filters = ref
              }}
              families={families}
              stores={stores}
              departments={departments}
              onSubmit={this.handleSubmit}
              isSearching={isSearching}
              filters={filters}
              user={user}
            />
          </Col>
        </StyledRow>
        {showResults && (
          <SearchDossierResults
            items={dossiers.elements}
            onViewClick={this.handleViewClick}
            onPageChange={this.handlePageChange}
            totalItems={dossiers.numTotElements}
            departments={departments}
            user={user}
            currentPage={currentPage}
            stores={stores}
          />
        )}
      </MainLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    tour: state.Tour,
    departments: state.Data.departments,
    families: state.Data.families,
    dossiers: state.Data.dossiers,
    user: state.User,
    prevSearch: state.Search.dossier,
    stores: state.Data.stores
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTour: value => dispatch(setTour(value)),
    fetchDepartments: () => dispatch(fetchDepartments()),
    fetchFamilies: () => dispatch(fetchFamilies()),
    fetchDossiers: (dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, page, pageSize) =>
      dispatch(
        fetchDossiers(dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, page, pageSize)
      ),
    saveDossierFilters: filters => dispatch(saveDossierFilters(filters)),
    fetchStores: () => dispatch(fetchStores())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchDossier))
