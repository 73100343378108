import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

const PercentageEditor = forwardRef((props, ref) => {

  const [value, setValue] = useState(Number(props.value) * 100);
  const refInput = useRef(null);

  useEffect(() => {
    refInput.current.focus();
  });

  useImperativeHandle(ref, () => ({
    getValue: () => value / 100,
    isCancelBeforeStart: () => false,
    isCancelAfterEnd: () => false,
  }));

  return (
    <input
      ref={refInput}
      value={value}
      onChange={event => setValue(Number(event.target.value))}
      style={{width: "100%"}}
    />
  );

});

export default PercentageEditor;
