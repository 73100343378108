import React from 'react'
import { Checkbox, List, Tag } from 'antd'
import { useState } from 'react'

const ProductsLookupResults = ({ results, selected, onSelection, multiple }) => {
  const [page, setPage] = useState(1)

  const handlePageChange = newPage => setPage(newPage)

  return (
    <List
      itemLayout="vertical"
      size="small"
      pagination={{
        onChange: handlePageChange,
        pageSize: 10,
        current: page,
      }}
      dataSource={results}
      renderItem={item => (
        <List.Item
          key={item.id}
          style={{
            cursor: 'pointer',
            backgroundColor: selected.has(item.id)
              ? 'rgba(102, 204, 51, 0.1)'
              : '',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginRight: '16px' }}
              checked={selected.has(item.id)}
              disabled={!multiple && selected.size > 0 && !selected.has(item.id)}
              onClick={() => onSelection(item.id)}
            />
            <span
              style={{
                color: selected.has(item.id) ? 'rgba(102, 204, 51, 1)' : '',
              }}>
              <strong>{item.id}</strong> - {item.description}
            </span>
            <span style={{ marginLeft: '16px', color: 'rgba(0, 0, 0, 0.5)' }}>
              Gamma: {item.range}
            </span>
            <span style={{ marginLeft: '16px', color: 'rgba(0, 0, 0, 0.5)' }}>
              Stato: {item.state}
            </span>
          </div>
          <div style={{ marginTop: '8px', paddingLeft: '28px' }}>
            <Tag style={{ opacity: 0.5 }}>
              {item.departmentId} - {item.departmentName}
            </Tag>
            <Tag style={{ opacity: 0.5 }}>
              {item.subDepartmentId} - {item.subDepartmentName}
            </Tag>
            <Tag style={{ opacity: 0.5 }}>
              {item.categoryId} - {item.categoryName}
            </Tag>
            <Tag style={{ opacity: 0.5 }}>
              {item.subCategoryId} - {item.subCategoryName}
            </Tag>
          </div>
        </List.Item>
      )}
    />
  )
}

export default ProductsLookupResults
