import {
  ROLE_CP,
  ROLE_MERCH,
  ROLE_CS,
  ROLE_CPXS,
  ROLE_GUEST, ROLE_AZ_COMM, ROLE_CS_WEB
} from 'Common/constants/global'

export const dossierTypes = [
  {
    key: 'NG',
    canCreate: [ROLE_CP],
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_GUEST],
    label: 'Nuova Gamma',
    installationLabel: 'Periodo impianto'
  },
  {
    key: 'RG',
    canCreate: [ROLE_CP],
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_GUEST],
    label: 'Revisione Gamma',
    installationLabel: 'Periodo impianto'
  },
  {
    key: 'XS',
    canCreate: [ROLE_CPXS],
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_CPXS, ROLE_GUEST],
    label: 'Gamma Cross Selling',
    installationLabel: 'Periodo impianto'
  },
  {
    key: 'ST',
    canCreate: [ROLE_CP],
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_GUEST],
    label: 'Gamma Stagionale',
    installationLabel: 'Stagione'
  },
  {
    key: 'PL',
    canCreate: [ROLE_CS],
    canView: [ROLE_CP, ROLE_CS, ROLE_GUEST],
    label: 'Promo Locali',
    installationLabel: 'Periodo promo'
  }
]

export const dossierStates = [
  {
    key: 'CR',
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_CPXS, ROLE_GUEST],
    label: 'Creato'
  },
  {
    key: 'CC',
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_CPXS, ROLE_GUEST],
    label: 'Confermato sede'
  },
  {
    key: 'CN',
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_CPXS, ROLE_GUEST],
    label: 'Confermato negozio'
  },
  {
    key: 'R1',
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CPXS, ROLE_GUEST],
    label: 'Relex appro'
  },
  {
    key: 'R2',
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CS, ROLE_CPXS, ROLE_GUEST],
    label: 'Relex appro negozio'
  },
  {
    key: 'AN',
    canView: [ROLE_CP, ROLE_MERCH, ROLE_CPXS, ROLE_GUEST, ROLE_CS],
    label: 'Annullato'
  }
]

export const opecomStates = [
  {
    key: 'CR',
    label: 'Creato',
    canView: [ROLE_CP, ROLE_GUEST, ROLE_AZ_COMM, ROLE_CS_WEB]
  },
  {
    key: 'CC',
    label: 'Confermato CP',
    canView: [ROLE_CP, ROLE_CS, ROLE_GUEST, ROLE_AZ_COMM, ROLE_CS_WEB]
  },{
    key: 'CN',
    label: 'Confermato negozio',
    canView: [ROLE_CP, ROLE_CS, ROLE_GUEST, ROLE_AZ_COMM, ROLE_CS_WEB]
  },
  {
    key: 'R1',
    label: 'Relex appro',
    canView: [ROLE_CP, ROLE_GUEST, ROLE_AZ_COMM, ROLE_CS_WEB]
  },
  {
    key: 'R2',
    label: 'Relex appro negozio',
    canView: [ROLE_CP, ROLE_CS, ROLE_GUEST, ROLE_AZ_COMM, ROLE_CS_WEB]
  }
]

export const LM_EMAIL_PREFIX_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+\.[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+$/
export const LM_EMAIL_SUFFIX = '@leroymerlin.it'
