import React, { useMemo } from 'react'
import { useEffect, useRef, useState } from 'react'
import { AutoComplete, Form, Input, Modal, Radio } from 'antd'
import useBus from 'use-bus'
import { EVENT_SIM_ADDREF_DIALOG_CLOSE, EVENT_SIM_ADDREF_DIALOG_OPEN } from 'Common/constants/event-constants'
import api from 'Common/api'
import AddProductAutoComplete from 'Components/AddProductAutoComplete/AddProductAutoComplete'
import { agNumberFormatter, agPercentageFormatter } from 'Common/utils'
import { COLUMNS } from 'Containers/Simulazione/SimulazioneDetail'
import SimulazioneSegnapostoForm from 'Containers/Simulazione/SimulazioneSegnapostoForm'

const segnapostoFields = ['productId', 'libArt', 'codGamart', 'numTypreappropreco', 'supplier', 'prxAchfou', 'prxVtecns', 'qteVte', 'rfa2CaRapp', 'cm2CaRapp', 'boni2CaRapp'];

export default function AddProductDialog({ onConfirm, withSegnaposto }) {
  const [visible, setVisible] = useState(false);
  const [selectedRef, setSelectedRef] = useState(null);
  const [typeOfProduct, setTypeOfProduct] = useState('existing');
  const segnapostoFormRef = useRef(null);
  const [segnapostoFormValue, setSegnapostoFormValue] = useState({});

  useBus(
    EVENT_SIM_ADDREF_DIALOG_OPEN,
    () => setVisible(true),
    [visible]
  );

  useBus(
    EVENT_SIM_ADDREF_DIALOG_CLOSE,
    () => setVisible(false),
    [visible]
  );

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const confirm = () => {
    if (!onConfirm) return;

    if (typeOfProduct === 'existing') {
      onConfirm(selectedRef, typeOfProduct);
    } else if (typeOfProduct === 'segnaposto') {
      onConfirm(segnapostoFormValue, typeOfProduct);
    }
  };

  const handleTypeOfProductChange = e => {
    setTypeOfProduct(e.target.value);
  };

  const handleSegnapostoFormValueChange = value => {
    setSegnapostoFormValue(value);
  };

  const isSegnapostoFormValid = useMemo(() => {
    if (!segnapostoFormValue.codEan || segnapostoFormValue.codEan.length === 0) {
      return false;
    }

    if (!segnapostoFormValue.libArt || segnapostoFormValue.libArt.length === 0) {
      return false;
    }

    if (!segnapostoFormValue.codGamartDossier || segnapostoFormValue.codGamartDossier.length === 0) {
      return false;
    }

    if (!segnapostoFormValue.supplier || segnapostoFormValue.supplier.length === 0) {
      return false;
    }

    if (segnapostoFormValue.txTva === null || segnapostoFormValue.txTva === undefined) {
      return false;
    }

    return true;
  }, [segnapostoFormValue.codEan, segnapostoFormValue.libArt, segnapostoFormValue.codGamartDossier, segnapostoFormValue.supplier, segnapostoFormValue.txTva]);

  return (
    <Modal visible={visible}
      onCancel={close}
      cancelText="Annulla"
      okText="Conferma"
      onOk={confirm}
      title="Ricerca referenza da aggiungere"
      okButtonProps={{
        disabled: (typeOfProduct === 'existing' && selectedRef === null) || (typeOfProduct === 'segnaposto' && !isSegnapostoFormValid)
      }}
    >
      {withSegnaposto && (
        <Radio.Group onChange={handleTypeOfProductChange} value={typeOfProduct} style={{ marginBottom: 16 }}>
          <Radio value="existing">Referenza esistente</Radio>
          <Radio value="segnaposto">Segnaposto</Radio>
        </Radio.Group>
      )}
      {typeOfProduct === 'existing' && (
        <AddProductAutoComplete onSelectValue={value => setSelectedRef(value)} />
      )}
      {typeOfProduct === 'segnaposto' && (
        <SimulazioneSegnapostoForm valueChanged={handleSegnapostoFormValueChange} />
      )}
    </Modal>
  )
}
