import React from 'react';
import { StyledH1, StyledRow } from 'Containers/SearchDossier/styled'
import MainLayout from 'Hoc/MainLayout'
import * as changelog from '../../changelog';
import { Col } from 'antd'
import { compareVersions } from 'Common/utils';

class ChangeLog extends React.Component {
  getItem(key) {
    return (
      <div className="change-entry" style={{width: '100%', maxWidth: '800px', marginTop: '50px' }}>
        <h1>Versione {key}</h1>
        <p dangerouslySetInnerHTML={{__html: changelog.default[key].body}} />
        <ul style={{ paddingLeft: '1em' }}>
          {changelog.default[key].updates.map(update => <li>{update}</li>)}
        </ul>
      </div>
    );
  }

  render() {
    const changelogKeys = Object.keys(changelog.default).sort(compareVersions);

    return (
      <MainLayout
        tourSteps={[]}
        isTourAvailable={false}
        isTourOpen={false}
        onNavigationChange={() => {}}
        defaultCurrentPage={'news'}
      >
        <StyledH1>News</StyledH1>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {changelogKeys.map(key => this.getItem(key))}
        </div>
      </MainLayout>
    )
  }
}

export default ChangeLog;
