export function getClientSideNodes(gridRef) {
  const nodes = [];

  if (!gridRef || !gridRef.current || !gridRef.current.api) {
    return nodes;
  }

  gridRef.current.api.forEachNode(node => {
    nodes.push(node);
  });

  return nodes;
}
