import React, { useEffect, useState } from 'react'
import { Drawer, List, Input, Button } from 'antd';
import { css } from '@emotion/css'
import api from 'Common/api'
import moment from 'moment'
import { useSelector } from 'react-redux'

const SimulazioneChat = ({ simulationId, visible, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const userId = useSelector((state) => state.User?.id);

  useEffect(() => {
    if (!simulationId) return;

    api.simulazione.getMessages(simulationId)
      .then((response) => {
        setMessages(response.data);
      })
      .catch(console.error);
  }, [simulationId]);

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;

    api.simulazione.saveMessage(simulationId, inputValue.trim())
      .then((response) => {
        setMessages([...messages, response.data]);
        setInputValue('');
      })
      .catch(console.error);
  };

  return (
    <Drawer title="Chat" width={400} visible={visible} onClose={onClose} className={css`
      & .ant-drawer-wrapper-body { display: flex; flex-direction: column; }
      & .ant-drawer-body { display: flex; flex-direction: column; flex-grow: 1; max-height: 100vh; overflow: hidden; }
    `}>
      <div style={{ overflowY: 'scroll', flex: 1, marginBottom: 25 }}>
        <List
          dataSource={messages}
          itemLayout="horizontal"
          renderItem={(item) => (
            <List.Item style={{ backgroundColor: userId === item.email ? '#66cc33' : '#f0f0f0', padding: 10, borderRadius: 4, marginBottom: 5 }}>
              <List.Item.Meta
                title={item.user}
                description={(
                  <div>
                    <p>{item.message}</p>
                    <p style={{ textAlign: 'left', margin: 0, padding: 0 }}><small>{moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')}</small></p>
                  </div>
                )}
              />
            </List.Item>
          )}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 'auto' }}>
        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Type a message" />
        <Button type="primary" onClick={handleSendMessage}>
          Send
        </Button>
      </div>
    </Drawer>
  );
};

export default SimulazioneChat;
