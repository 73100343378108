import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import React from 'react';

const formatYAxisLabel = (value, percentage) => {
  if (percentage) {
    return `${value * 100}%`;
  }

  if (value >= 1000000) {
    return `${value / 1000000}M`;
  } else if (value >= 1000) {
    return `${value / 1000}K`;
  }
  return value;
};

const SimulazioneChart = ({ data, percentage, type, title }) => {
  const getBarChart = () => {
    return (
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis tickFormatter={value => formatYAxisLabel(value, percentage)} />
        <Bar dataKey="value" barSize={50}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    );
  }

  const getAreaChart = () => {
    return (
      <AreaChart
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis tickFormatter={value => formatYAxisLabel(value, percentage)} />
        <Area type="monotone" dataKey="value" stroke="#56b327" fill="#66CC33" />
      </AreaChart>
    );
  };

  const getChart = () => {
    switch (type) {
      case 'area':
        return getAreaChart();

      default:
        return getBarChart();
    }
  };

  return (
    <div>
      {title && <h4 style={{ textAlign: 'center' }}>{title}</h4>}
      <ResponsiveContainer width="100%" height={300}>
        {getChart()}
      </ResponsiveContainer>
    </div>
  );
};

export default SimulazioneChart;
