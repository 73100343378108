import React, { useEffect, useMemo, useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout'
import styles from './Simulazione.module.scss'
import { StyledH1 } from 'Common/styled'
import { Button, Col, InputNumber, message, Row, Table, Tabs } from 'antd'
import api from 'Common/api'
import { agNumberFormatter, agPercentageFormatter, divideNumbers } from 'Common/utils'
import { useParams } from 'react-router'
import { forkJoin } from 'rxjs'
import simulazioneUtils from 'Common/simulazioneUtils'
import { css } from '@emotion/css'
import AntTableWrapper from 'Components/AntTableWrapper/AntTableWrapper'
import AntTableBody from 'Components/AntTableWrapper/AntTableBody'
import AntTableRow from 'Components/AntTableWrapper/AntTableRow'
import SimulazioneChart from 'Components/SimulazioneChart/SimulazioneChart'

const REPORT_COST_DATA_STRUCTURE = {
    'general': {
        'name': null,
        'key': 'general',
        'type': 'hidden,standard',
        'format': 'none,euro',
        'data': [
            {
                'name': 'Prelevamenti per strutture',
                'key': 'prelevamentiPerStrutture',
                'type': 'manual,standard',
                'format': 'number,euro'
            },
            {
                'name': 'Prelevamenti per expo',
                'key': 'prelevamentiPerExpo',
                'type': 'manual,standard',
                'format': 'number,euro'
            },
            {
                'name': 'Costi nuove strutture',
                'key': 'costiNuoveStrutture',
                'type': 'manual,standard',
                'format': 'number,euro'
            },
            {
                'name': 'Costi balisage didattica',
                'key': 'costiBalisageDidattica',
                'type': 'manual,standard',
                'format': 'number,euro'
            },
            {
                'name': 'Ore di lavoro complessive collaboratori interni',
                'key': 'oreDiLavoroComplessiveCollaboratoriInterni',
                'type': 'manual,calculated',
                'format': 'number,euro'
            },
            {
                'name': 'Ore di lavoro complessive manodopera esterna',
                'key': 'oreDiLavoroComplessiveManodoperaEsterna',
                'type': 'manual,calculated',
                'format': 'number,euro'
            },
            {
                'name': 'Punti luce da lavorare per l\'elettricista',
                'key': 'puntiLuceDaLavorarePerLElettricista',
                'type': 'manual,calculated',
                'format': 'number,euro'
            }
        ]
    },
    'periodoImpiantazione': {
        'name': 'Periodo per l\'impiantazione',
        'key': 'periodoImpiantazione',
        'type': 'hidden,standard',
        'format': 'none,calculated',
        'data': [
            {
                'name': 'Numeri di giorni necessari per l\'impiantazione',
                'key': 'numeriDiGiorniNecessariPerLImpiantazione',
                'type': 'manual,hidden',
                'format': 'number,none'
            },
            {
                'name': 'Perdita diretta del CA giornaliera Italia dovuta all\'impiantazione',
                'key': 'perditaDirettaDelCaGiornalieraItaliaDovutaAllImpiantazione',
                'type': 'calculated,calculated',
                'format': 'euro,euro'
            },
            {
                'name': 'Tasso di MG % attuale della famiglia',
                'key': 'tassoDiMgAttualeDellaFamiglia',
                'type': 'calculated,calculated',
                'format': 'percentage,euro',
                'note': 'Perdita diretta di MG € giornaliero dovuta all\'impiantazione',
            }
        ]
    },
    'smaltimentoGammaS': {
        'name': 'Smaltimento Gamma S',
        'key': 'smaltimentoGammaS',
        'type': 'hidden,calculated',
        'format': 'none,euro',
        'data': [
            {
                'name': 'Valore a PV dello stock in uscita',
                'key': 'valoreAPvDelloStockInUscita',
                'type': 'calculated,hidden',
                'format': 'euro,none'
            },
            {
                'name': 'Incidenza sconti della famiglia a rotazione normale (%)',
                'key': 'incidenzaScontiDellaFamigliaARotazioneNormale',
                'type': 'manual,calculated',
                'format': 'number,euro',
                'note': '€ non incassati per sconto senza smaltimento'
            },
            {
                'name': 'Incidenza sconti per smaltimento ref in uscita (%)',
                'key': 'incidenzaScontiPerSmaltimentoRefInUscita',
                'type': 'manual,calculated',
                'format': 'number,euro',
                'note': '€ non incassati per sconto con smaltimento'
            }
        ]
    },
    'creazioneMediaPerIlSito': {
        'name': 'Creazione media per il sito',
        'key': 'creazioneMediaPerIlSito',
        'type': 'hidden,hidden',
        'format': 'none,none',
        'data': [
            {
                'name': 'Eventuali costi da sostenere al posto del fornitore',
                'key': 'eventualiCostiDaSostenereAlPostoDelFornitore',
                'type': 'manual,hidden',
                'format': 'number,none'
            },
            {
                'name': 'Altri costi',
                'key': 'altriCosti',
                'type': 'manual,hidden',
                'format': 'number,none'
            }
        ]
    }
}

const ANALYSIS_COLUMNS = [
    {
        title: 'Valori',
        dataIndex: 'kpiName'
    },
    {
        title: 'Vecchia gamma',
        children: [
            {
                title: 'Resta in gamma',
                dataIndex: 'remainingOld',
                render: (v, r) => {
                    if (r.percentage) {
                        return agPercentageFormatter({ value: v })
                    }

                    return agNumberFormatter({ value: v })
                }
            },
            {
                title: 'In uscita',
                dataIndex: 'out',
                render: (v, r) => {
                    if (r.percentage) {
                        return agPercentageFormatter({ value: v })
                    }

                    return agNumberFormatter({ value: v })
                }
            },
            {
                title: 'Totale',
                dataIndex: 'old',
                render: (v, r) => {
                    if (r.percentage) {
                        return agPercentageFormatter({ value: v })
                    }

                    return agNumberFormatter({ value: v })
                }
            }
        ]
    },
    {
        title: 'Nuova gamma',
        children: [
            {
                title: 'Resta in gamma',
                dataIndex: 'remaining',
                render: (v, r) => {
                    if (r.percentage) {
                        return agPercentageFormatter({ value: v })
                    }

                    return agNumberFormatter({ value: v })
                }
            },
            {
                title: 'In entrata',
                dataIndex: 'in',
                render: (v, r) => {
                    if (r.percentage) {
                        return agPercentageFormatter({ value: v })
                    }

                    return agNumberFormatter({ value: v })
                }
            },
            {
                title: 'Totale',
                dataIndex: 'total',
                render: (v, r) => {
                    if (r.percentage) {
                        return agPercentageFormatter({ value: v })
                    }

                    return agNumberFormatter({ value: v })
                }
            }
        ]
    },
    {
        title: 'Delta',
        dataIndex: 'delta',
        render: v => agNumberFormatter({ value: v })
    },
    {
        title: 'Delta %',
        dataIndex: 'deltaPercentage',
        render: v => agPercentageFormatter({ value: v })
    }
]

const KPI = ['N. Ref', 'Ref A1', 'Qta vendute', 'CA', 'CA HT', 'PVM', 'Quota MDH %', 'MG €', 'RFA €', 'CM €', 'Boni €', '3xNET', 'MAP %', 'MG %', 'RFA %', 'CM %', 'Boni %', 'Redd %']


export default function SimulazioneReport({ route }) {

    const [gridReady, setGridReady] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [simulation, setSimulation] = useState({})
    const [nomenclature, setNomenclature] = useState({
        department: '',
        subDepartment: '',
        category: '',
        subCategory: ''
    })
    const [totalStores, setTotalStores] = useState(50)
    const [products, setProducts] = useState([])
    const [costsCalculation, setCostsCalculation] = useState({})
    const [totalCosts, setTotalCosts] = useState(0)
    const [roiData, setRoiData] = useState({})
    const [costsData, setCostsData] = useState([
        {
            title: null, data: [{
                costType: 'Prelevamenti per strutture',
                gammaAStore: null,
                gammaACountry: 0
            },
                {
                    costType: 'Prelevamenti per expo',
                    gammaAStore: null,
                    gammaACountry: 0
                },
                {
                    costType: 'Costi nuove strutture',
                    gammaAStore: null,
                    gammaACountry: 0
                },
                {
                    costType: 'Costi balisage didattica',
                    gammaAStore: null,
                    gammaACountry: 0
                },
                {
                    costType: 'Ore di lavoro complessive collaboratori interni',
                    gammaAStore: null,
                    gammaACountry: 0,
                    gammaACountryCalculate: params => (params.gammaAStore || 0) * 12 * totalStores
                },
                {
                    costType: 'Ore di lavoro complessive manodopera esterna',
                    gammaAStore: null,
                    gammaACountry: 0,
                    gammaACountryCalculate: params => (params.gammaAStore || 0) * 29 * totalStores
                },
                {
                    costType: 'Punti luce da lavorare per l\'elettricista',
                    gammaAStore: null,
                    gammaACountry: 0,
                    gammaACountryCalculate: params => Math.ceil((params.gammaAStore || 0) / 3) * 29 * totalStores
                }]
        },
        {
            title: 'Periodo per l\'impiantazione', data: [{
                costType: 'Numeri di giorni necessari per l\'impiantazione',
                gammaAStore: null,
                gammaACountry: 0
            },
                {
                    costType: 'Perdita diretta del CA giornaliera Italia dovuta all\'impiantazione',
                    gammaAStore: null,
                    gammaACountry: 0,
                    calculated: true,
                    calculate: (statistics) => agNumberFormatter({ value: ((statistics['CA']?.new || 0) - (statistics['CA']?.old || 0)) / 365 })
                },
                {
                    costType: 'Tasso di MG % attuale della famiglia',
                    gammaAStore: null,
                    gammaACountry: 0,
                    calculated: true,
                    calculate: (statistics) => agPercentageFormatter({ value: (statistics['MG %']?.new || 0) - (statistics['MG %']?.old || 0) })
                }]
        },
        {
            title: 'Smaltimento Gamma S', data: [{
                costType: 'Valore a PV dello stock in uscita',
                gammaAStore: null,
                gammaACountry: 0,
                calculated: true,
                calculate: (_, items) => {
                    let total = 0

                    for (const item of items) {
                        if (!item.removed) continue
                        total += item.lastStockQteTot * item.prxVtecnsDossier
                    }

                    return agNumberFormatter({ value: total })
                }
            },
                {
                    costType: 'Incidenza sconti della famiglia a rotazione normale',
                    gammaAStore: null,
                    gammaACountry: 0,
                    gammaACountryCalculate: params => {
                        const { gammaAStore, groupIndex } = params
                        return 0
                    }
                },
                {
                    costType: 'Incidenza sconti per smaltimento ref in uscita',
                    gammaAStore: null,
                    gammaACountry: 0
                }]
        },
        {
            title: 'Creazione media per il sito', data: [{
                costType: 'Eventuali costi da sostenere al posto del fornitore',
                gammaAStore: null,
                gammaACountry: 0
            }, {
                costType: 'Altri costi',
                gammaAStore: null,
                gammaACountry: 0
            }]
        }
    ])
    const [canSaveCosts, setCanSaveCosts] = useState(false)
    const [canSaveRoi, setCanSaveRoi] = useState(false)
    const [statisticsData, setStatisticsData] = useState({})
    const [costs, setCosts] = useState({});

    const revalidatedCosts = (inputCosts) => {
        const formulaTypesByKey = {};
        const updatedCosts = { ...(inputCosts || costs) };

        for (const groupKey in REPORT_COST_DATA_STRUCTURE) {
            const group = REPORT_COST_DATA_STRUCTURE[groupKey];
            const groupFormula = group.type.split(',');
            let totalGroup = 0;

            for (const item of group.data) {
                const itemFormula = item.type.split(',');
                let storeValue = updatedCosts[item.key]?.store;
                let countryValue = updatedCosts[item.key]?.country;

                if (itemFormula[0] === 'calculated') {
                    switch (item.key) {
                        case 'perditaDirettaDelCaGiornalieraItaliaDovutaAllImpiantazione':
                            storeValue = divideNumbers(statisticsData['CA']?.old || 0, 365);
                            break;

                        case 'tassoDiMgAttualeDellaFamiglia':
                            storeValue = statisticsData['MG %']?.old || 0;
                            break;

                        case 'valoreAPvDelloStockInUscita':
                            storeValue = products.filter(it => it.removed).reduce((acc, curr) => acc + (curr.lastStockQteTot * curr.prxVtecnsDossier), 0);
                            break;

                        default:
                    }
                }

                if (itemFormula[1] === 'calculated') {
                    switch (item.key) {
                        case 'oreDiLavoroComplessiveCollaboratoriInterni':
                            countryValue = storeValue * 12 * totalStores;
                            break;

                        case 'oreDiLavoroComplessiveManodoperaEsterna':
                            countryValue = storeValue * 29 * totalStores;
                            break;

                        case 'puntiLuceDaLavorarePerLElettricista':
                            countryValue = storeValue / 3 * 29 * totalStores;
                            break;

                        case 'perditaDirettaDelCaGiornalieraItaliaDovutaAllImpiantazione':
                            countryValue = storeValue * (updatedCosts['numeriDiGiorniNecessariPerLImpiantazione']?.store || 0);
                            break;

                        case 'tassoDiMgAttualeDellaFamiglia':
                            countryValue = divideNumbers(statisticsData['MG €']?.old ?? 0, 365);
                            break;

                        case 'incidenzaScontiDellaFamigliaARotazioneNormale':
                            countryValue = (updatedCosts['valoreAPvDelloStockInUscita']?.store || 0) * divideNumbers(updatedCosts['incidenzaScontiDellaFamigliaARotazioneNormale']?.store || 0, 100);
                            break;

                        case 'incidenzaScontiPerSmaltimentoRefInUscita':
                            countryValue = (updatedCosts['valoreAPvDelloStockInUscita']?.store || 0) * divideNumbers(updatedCosts['incidenzaScontiPerSmaltimentoRefInUscita']?.store || 0, 100)
                            break;

                        default:
                    }
                } else if (itemFormula[1] === 'standard') {
                    countryValue = storeValue * totalStores;
                }

                updatedCosts[item.key] = {
                    store: storeValue,
                    country: countryValue
                };

                totalGroup += countryValue || 0;
            }

            if (groupKey === 'smaltimentoGammaS') {
                totalGroup = (updatedCosts['incidenzaScontiPerSmaltimentoRefInUscita']?.country || 0) - (updatedCosts['incidenzaScontiDellaFamigliaARotazioneNormale']?.country || 0);
            } else if (groupKey === 'creazioneMediaPerIlSito') {
                totalGroup += updatedCosts['eventualiCostiDaSostenereAlPostoDelFornitore']?.store || 0;
                totalGroup += updatedCosts['altriCosti']?.store || 0;
            }

            if (groupKey === 'periodoImpiantazione' && updatedCosts['numeriDiGiorniNecessariPerLImpiantazione']?.store <= 0) {
                totalGroup = 0;
            } else if (groupKey === 'periodoImpiantazione') {
                totalGroup = (updatedCosts['perditaDirettaDelCaGiornalieraItaliaDovutaAllImpiantazione']?.country || 0) * (updatedCosts['tassoDiMgAttualeDellaFamiglia']?.store || 0);
            }

            updatedCosts[groupKey] = {
                store: null,
                country: totalGroup
            };
        }

        setCosts(updatedCosts);
    };

    const handleMetadata = async () => {
        let { data: metadata } = await api.simulazione.getMetadata(simulation.id);
        metadata = metadata.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {});

        const initialCosts = {}

        for (const groupKey in REPORT_COST_DATA_STRUCTURE) {
            const group = REPORT_COST_DATA_STRUCTURE[groupKey]

            initialCosts[group.key] = {
                store: null,
                country: null
            }

            for (const item of group.data) {
                initialCosts[item.key] = {
                    store: metadata[`costs.${item.key}.store`] ? Number(metadata[`costs.${item.key}.store`].value) : null,
                    country: null
                };
            }
        }

        setCosts(initialCosts);

        const updatedRoiData = { ...roiData };

        const allowedKeys = ['mlGammaA', 'mlGammaB', 'mlGammaC']

        Object.keys(metadata).filter(it => it.startsWith('roi')).forEach(key => {
            if (!allowedKeys.includes(key.substring(key.indexOf('.') + 1))) return;
            const metaitem = metadata[key];
            const variableName = key.substring(key.indexOf('.') + 1);
            updatedRoiData[variableName] = metaitem.type === 'number' ? Number(metaitem.value) : metaitem.value;
        });

        setRoiData(updatedRoiData);
        setLoaded(true);
    };

    useEffect(() => {
        if (!simulation.id) return;
        handleMetadata().catch(console.error);
    }, [simulation])

    useEffect(() => {
        handleTotalStoresChange()
    }, [totalStores])

    useEffect(() => {
        const groupKeys = Object.keys(REPORT_COST_DATA_STRUCTURE);
        setTotalCosts(Object.keys(costs).filter(it => groupKeys.includes(it)).reduce((acc, curr) => acc + (costs[curr]?.country || 0), 0));
    }, [costs]);

    useEffect(() => {
        revalidatedCosts();
    }, [statisticsData, products]);

    // useEffect(() => {
    //   if (localStorage.getItem('costsData')) {
    //     setCostsData(JSON.parse(localStorage.getItem('costsData')));
    //   }
    // }, []);

    useEffect(() => {
        let total = 0

        for (const group of costsData) {
            for (const costData of group.data) {
                total += costData.gammaACountry
            }
        }

        setTotalCosts(total)

    }, [costsData])

    const [kpiData, setKpiData] = useState(KPI.map(item => ({
        kpiName: item,
        oldStay: 0,
        oldOut: 0,
        newStay: 0,
        newOut: 0,
        delta: 0,
        deltaPercentage: 0
    })))

    const [returnData, setReturnData] = useState({
        caNy: 0,
        progCaPercNy: 0,
        mgPercNy: 0,
        redd3xNetPerc: 0,
        deltaMgNy: 0,
        delta3xNetNy: 0,
        roiMg: 0,
        roi3xNet: 0,
        roiMgMonths: 0,
        roi3xNetMonths: 0
    })

    const routeParams = useParams()

    const renderGammaACountryTotal = (v, r) => {
        if (costsCalculation.hasOwnProperty(r.costType)) {
            return agNumberFormatter(costsCalculation[r.costType])
        }

        return agNumberFormatter(0)
    }

    const findDownLevelIndex = (arr, filter) => {
        for (const [groupIndex, group] of arr.entries()) {
            for (const [rowIndex, row] of group.data.entries()) {
                if (filter(row)) {
                    return [groupIndex, rowIndex]
                }
            }
        }

        return [-1, -1]
    }

    const handleGammaAStoreChange = (v, r) => {
        const [groupIndex, rowIndex] = findDownLevelIndex(costsData, row => row.costType === r.name)

        if (rowIndex === -1 || groupIndex === -1) {
            return
        }

        setCostsData([
            ...costsData.slice(0, groupIndex),
            {
                ...costsData[groupIndex],
                data: [
                    ...costsData[groupIndex].data.slice(0, rowIndex),
                    {
                        ...costsData[groupIndex].data[rowIndex],
                        gammaAStore: v,
                        gammaACountry: typeof costsData?.[groupIndex]?.data?.[rowIndex]?.gammaACountryCalculate === 'function' ? costsData[groupIndex].data[rowIndex].gammaACountryCalculate({
                            gammaAStore: v,
                            groupIndex,
                            rowIndex
                        }) : v * totalStores
                    },
                    ...costsData[groupIndex].data.slice(rowIndex + 1)
                ]
            },
            ...costsData.slice(groupIndex + 1)
        ])

        setCanSaveCosts(true)
    }

    const handleTotalStoresChange = () => {
        setCostsData([
            ...costsData.map((group, groupIndex) => ({
                ...group,
                data: [
                    ...group.data.map((row, rowIndex) => ({
                        ...row,
                        gammaACountry: typeof row.gammaACountryCalculate === 'function' ? row.gammaACountryCalculate({
                            gammaAStore: row.gammaAStore,
                            groupIndex,
                            rowIndex
                        }) : row.gammaAStore * totalStores
                    }))
                ]
            }))
        ])
    };

    const handleStoreValueChange = (value, record) => {
        setCanSaveCosts(true);
        revalidatedCosts({
            ...costs,
            [record.key]: {
                ...costs[record.key],
                store: value,
            },
        });
    };

    const COSTS_COLUMNS = [
        {
            title: 'Tipo costo',
            dataIndex: 'name',
            render: (v, r) => r.type === 'cost' ? v : r.type === 'group' ? <strong>{v}</strong> :
                <span style={{ display: 'block', fontWeight: 'bold', textAlign: 'right' }}>{v}</span>
        },
        {
            title: 'Gamma A singolo negozio',
            dataIndex: 'gammaAStore',
            render: (v, r) => {
                if (r.type === 'group') return '';
                const storeFormulaType = r.formulaType.split(',')[0];
                if (storeFormulaType === 'hidden') return '';
                if (storeFormulaType !== 'manual') return r.storeFormatter({ value: v });

                return (
                    <InputNumber
                        disabled={simulation.state === 'PUBLISHED'}
                        className='no-spinners'
                        defaultValue={v}
                        value={v}
                        onChange={(value) => handleStoreValueChange(value, r)}
                    />
                );
            }
        },
        {
            title: 'Gamma A totale Italia',
            dataIndex: 'gammaACountry',
            render: (v, r) => {
                if (r.type === 'group') return '';
                const countryFormulaType = r.formulaType.split(',')[1];
                if (countryFormulaType === 'hidden') return '';
                if (countryFormulaType !== 'manual') return r.countryFormatter({ value: v });

                return '';
            }
        },
        {
            title: '',
            dataIndex: 'note'
        }
    ]

    useEffect(() => {

        if (!routeParams.id) {
            return
        }

        forkJoin([
            api.simulazione.get(routeParams.id),
            api.simulazione.getProducts(routeParams.id)
        ]).subscribe(([simulazioneResponse, productsResponse]) => {
            setSimulation(simulazioneResponse.data)
            setProducts(productsResponse.data.map(simulazioneUtils.buildProduct));
        })

    }, [routeParams.id]);

    useEffect(() => {

        if (!simulation.id) {
            return
        }

        api.simulazione.getAdditionalInformation(simulation.id).then(response => {
            const departments = []
            const subdepartments = []
            const categories = []
            const subcategories = []

            const processNode = (nodes, level) => {
                for (const node of nodes) {
                    if (level === 0) {
                        departments.push(`${node.id} - ${node.description}`)
                    } else if (level === 1) {
                        subdepartments.push(`${node.id} - ${node.description}`)
                    } else if (level === 2) {
                        categories.push(`${node.id} - ${node.description}`)
                    } else if (level === 3) {
                        subcategories.push(`${node.id} - ${node.description}`)
                    }

                    if (node.children && node.children.length > 0) {
                        processNode(node.children, level + 1)
                    }
                }
            }

            processNode(response.data.nomenclatureTreeNodes, 0)

            setNomenclature({
                department: departments.join(','),
                subDepartment: subdepartments.join(','),
                category: categories.join(','),
                subCategory: subcategories.join(',')
            })
        }).catch(console.error)

    }, [simulation])

    useEffect(() => {

        if (!loaded) return;

        const statistics = simulazioneUtils.getCalculatedStatistics(products)

        const summaryStatistics = simulazioneUtils.getSummaryStatistics(products, statistics)

        const summaryHeaders = [
            ['N. Ref', 'count'],
            ['Ref A1', 'a1Dossier'],
            ['Qta vendute', 'qteVteDossier'],
            ['CA', 'caDossier'],
            ['CA HT', 'caHtDossier'],
            ['PVM', 'pvmDossier'],
            ['Quota MDH %', 'quotaMdhDossier', true],
            ['MG €', 'mrgDossier'],
            ['RFA €', 'mntRfaRapp'],
            ['CM €', 'mntComplmrgRapp'],
            ['Boni €', 'mntBoniRapp'],
            ['3xNET', 'mrg3XnetDossier'],
            ['MAP %', 'percMap', true],
            ['MG %', 'mrg2caDossier', true],
            ['RFA %', 'rfa2CaRappDossier', true],
            ['CM %', 'cm2CaRappDossier', true],
            ['Boni %', 'boni2CaRappDossier', true],
            ['Redd %', 'percReddDossier', true]
        ]

        const statisticsMap = {}

        for (const summaryHeader of summaryHeaders) {
            statisticsMap[summaryHeader[0]] = {
                old: (statistics.split.existing[summaryHeader[1].replace('Dossier', '')] || 0) + (statistics.split.out[summaryHeader[1].replace('Dossier', '')] || 0),
                in: statistics.split.in[summaryHeader[1]] || 0,
                out: statistics.split.out[summaryHeader[1].replace('Dossier', '')] || 0,
                remainingOld: statistics.split.existing[summaryHeader[1].replace('Dossier', '')] || 0,
                remaining: statistics.split.existing[summaryHeader[1]] || 0,
                new: 0,
                percentage: summaryHeader.length === 3 && summaryHeader[2] === true
            }

            statisticsMap[summaryHeader[0]].new = statisticsMap[summaryHeader[0]].remaining + statisticsMap[summaryHeader[0]].in;
            statisticsMap[summaryHeader[0]].total = statisticsMap[summaryHeader[0]].remaining + statisticsMap[summaryHeader[0]].in;
        }

        for (const key in statisticsMap) {
            switch (key) {
                case 'PVM':
                    statisticsMap[key].old = divideNumbers(statisticsMap['CA'].old, statisticsMap['Qta vendute'].old);
                    statisticsMap[key].total = divideNumbers(statisticsMap['CA'].total, statisticsMap['Qta vendute'].total);
                    break;

                case 'MG %':
                    statisticsMap[key].total = simulazioneUtils.recalculate('mrgPerc', {
                        mrg: statisticsMap['MG €'].total,
                        caHt: statistics.split.existing.caHtDossier + statistics.split.in.caHtDossier
                    });

                    statisticsMap[key].old = simulazioneUtils.recalculate('mrgPerc', {
                        mrg: statisticsMap['MG €'].old,
                        caHt: statistics.split.existing.caHt + statistics.split.out.caHt
                    });
                    break;

                case 'RFA %':
                    statisticsMap[key].total = simulazioneUtils.recalculate('rfaPerc', {
                        rfaRapp: statisticsMap['RFA €'].total,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.in.caHtRapp
                    });

                    statisticsMap[key].old = simulazioneUtils.recalculate('rfaPerc', {
                        rfaRapp: statisticsMap['RFA €'].old,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.out.caHtRapp
                    });
                    break;

                case 'CM %':
                    statisticsMap[key].total = simulazioneUtils.recalculate('cmPerc', {
                        cmRapp: statisticsMap['CM €'].total,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.in.caHtRapp
                    });

                    statisticsMap[key].old = simulazioneUtils.recalculate('cmPerc', {
                        cmRapp: statisticsMap['CM €'].old,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.out.caHtRapp
                    });
                    break;

                case 'Boni %':
                    statisticsMap[key].total = simulazioneUtils.recalculate('boniPerc', {
                        boniRapp: statisticsMap['Boni €'].total,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.in.caHtRapp
                    });

                    statisticsMap[key].old = simulazioneUtils.recalculate('boniPerc', {
                        boniRapp: statisticsMap['Boni €'].old,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.out.caHtRapp
                    });
                    break;

                case 'MAP %':
                    statisticsMap[key].total = simulazioneUtils.recalculate('mapPerc', {
                        mrg: statistics.mrgDossier,
                        caHt: statistics.caHtDossier,
                        sconti: statistics.scontiDossier,
                    });

                    statisticsMap[key].old = simulazioneUtils.recalculate('mapPerc', {
                        mrg: statistics.split.existing.mrg + statistics.split.out.mrg,
                        caHt: statistics.split.existing.caHt + statistics.split.out.caHt,
                        sconti: statistics.sconti,
                    });
                    break;

                case 'Redd %':
                    statisticsMap[key].total = simulazioneUtils.recalculate('reddPerc', {
                        mrg: statisticsMap['MG €'].total,
                        cmRapp: statisticsMap['CM €'].total,
                        boniRapp: statisticsMap['Boni €'].total,
                        rfaRapp: statisticsMap['RFA €'].total,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.in.caHtRapp,
                        caHt: statistics.split.existing.caHtDossier + statistics.split.in.caHtDossier
                    });

                    statisticsMap[key].old = simulazioneUtils.recalculate('reddPerc', {
                        mrg: statisticsMap['MG €'].old,
                        cmRapp: statisticsMap['CM €'].old,
                        boniRapp: statisticsMap['Boni €'].old,
                        rfaRapp: statisticsMap['RFA €'].old,
                        caHtRapp: statistics.split.existing.caHtRapp + statistics.split.in.caHtRapp,
                        caHt: statistics.split.existing.caHt + statistics.split.in.caHt
                    });
                    break;

                case 'Quota MDH %':
                    statisticsMap[key].total = divideNumbers(
                      statistics.split.existing.caTopMdhDossier + statistics.split.in.caTopMdhDossier,
                      statisticsMap['CA'].total
                    );

                    statisticsMap[key].old = divideNumbers(
                      statistics.split.existing.caTopMdh + statistics.split.out.caTopMdh,
                      statisticsMap['CA'].old
                    );
                    break;

                default:
            }
        }

        setStatisticsData(statisticsMap)

        const updatedKpiData = KPI.map(item => {
            const data = {
                kpiName: item,
                old: statisticsMap[item].old,
                out: statisticsMap[item].out,
                new: statisticsMap[item].new,
                in: statisticsMap[item].in,
                percentage: statisticsMap[item].percentage,
                remaining: statisticsMap[item].remaining,
                remainingOld: statisticsMap[item].remainingOld,
                total: statisticsMap[item].total,
                delta: 0,
                deltaPercentage: 0
            };

            data.delta = !item.includes('%') ? data.total - data.old : '';
            data.deltaPercentage = !item.includes('%') ? divideNumbers(data.total - data.old, data.old) : data.total - data.old;

            return data;
        });

        const caItem = updatedKpiData.find(item => item.kpiName === 'CA')
        const quotaMdhItemIndex = updatedKpiData.findIndex(item => item.kpiName === 'Quota MDH')

        // if (caItem && quotaMdhItemIndex !== -1) {
        //     updatedKpiData[quotaMdhItemIndex].old = agPercentageFormatter({ value: divideNumbers(updatedKpiData[quotaMdhItemIndex].old, caItem.old) * 100 })
        //     updatedKpiData[quotaMdhItemIndex].out = agPercentageFormatter({ value: divideNumbers(updatedKpiData[quotaMdhItemIndex].out, caItem.old) * 100 })
        //     updatedKpiData[quotaMdhItemIndex].in = agPercentageFormatter({ value: divideNumbers(updatedKpiData[quotaMdhItemIndex].in, caItem.new) * 100 })
        //     updatedKpiData[quotaMdhItemIndex].remaining = agPercentageFormatter({ value: divideNumbers(updatedKpiData[quotaMdhItemIndex].remaining, caItem.new) * 100 })
        //     updatedKpiData[quotaMdhItemIndex].new = agPercentageFormatter({ value: divideNumbers(updatedKpiData[quotaMdhItemIndex].new, caItem.new) * 100 })
        //     updatedKpiData[quotaMdhItemIndex].total = agPercentageFormatter({ value: divideNumbers(updatedKpiData[quotaMdhItemIndex].total, caItem.new) * 100 })
        //     updatedKpiData[quotaMdhItemIndex].delta = ''
        //     updatedKpiData[quotaMdhItemIndex].deltaPercentage = ''
        // }

        setKpiData(updatedKpiData)

        const kpiMap = updatedKpiData.reduce((acc, curr) => ({
            ...acc,
            [curr.kpiName]: curr
        }), {})

        const updatedRoiData = {
            ...roiData,
            caNy: kpiMap['CA'].new,
            progCaPercNy: kpiMap['CA'].deltaPercentage,
            mgPercNy: kpiMap['MG %'].new,
            redd3xNetPerc: kpiMap['Redd %'].new,
            deltaMgNy: kpiMap['MG €'].delta,
            delta3xNetNy: kpiMap['3xNET'].delta,
            roiMg: !kpiMap['MG €'].delta ? 0 : divideNumbers(kpiMap['MG €'].delta - totalCosts, totalCosts),
            roi3xNet: !kpiMap['3xNET'].delta ? 0 : divideNumbers(kpiMap['3xNET'].delta - totalCosts, totalCosts)
        }

        updatedRoiData.roiMgMonths = Math.ceil(updatedRoiData.deltaMgNy > 0 ? divideNumbers(totalCosts * 12, updatedRoiData.deltaMgNy) : 0)
        updatedRoiData.roi3xNetMonths = Math.ceil(updatedRoiData.delta3xNetNy > 0 ? divideNumbers(totalCosts * 12, updatedRoiData.delta3xNetNy) : 0)

        setRoiData(updatedRoiData);

    }, [products, totalCosts, loaded]);

    const navigateBack = () => {
        if (canSaveCosts || canSaveRoi) {
            message.warn('Salvare prima le modifiche e poi cambiare pagina');
            return;
        }

        window.location.href = `/simulazione/detail/${simulation.id}`
    }

    const handleCostsSave = () => {
        const metadata = [];

        for (const groupKey in REPORT_COST_DATA_STRUCTURE) {
            const group = REPORT_COST_DATA_STRUCTURE[groupKey];

            for (const item of group.data) {
                const itemTypes = item.type.split(',');
                const itemFormats = item.format.split(',');

                if (itemTypes[0] !== 'manual') continue;

                if (costs[item.key]?.store === null || costs[item.key]?.store === undefined) continue;

                metadata.push({
                    key: `costs.${item.key}.store`,
                    type: itemFormats[0] || 'number',
                    value: costs[item.key]?.store
                });
            }
        }

        api.simulazione.saveMetadata(simulation.id, metadata).then(() => {
            message.success('Salvataggio avvenuto');
            setCanSaveCosts(false);
        }).catch(e => {
            console.error(e);
            message.error('Errore durante il salvataggio');
        });
    }

    const handleRoiDataUpdate = (value, field) => {
        setCanSaveRoi(true)

        setRoiData({
            ...roiData,
            [field]: value
        });
    };

    const handleRoiSave = () => {
        const metadata = [];

        for (const key in roiData) {
            metadata.push({
                key: `roi.${key}`,
                type: typeof roiData[key],
                value: roiData[key] + ''
            });
        }

        api.simulazione.saveMetadata(simulation.id, metadata).then(() => {
            message.success('Salvataggio avvenuto senza problmei');
            setCanSaveRoi(false);
        }).catch(console.error);
    }

    const costsDataSource = useMemo(() => {
        const dataSource = [];

        const euroFormatter = (params) => '€' + agNumberFormatter(params)
        const numberFormatter = agNumberFormatter
        const percentageFormatter = agPercentageFormatter
        const noneFormatter = () => ''

        for (const groupKey in REPORT_COST_DATA_STRUCTURE) {
            const group = REPORT_COST_DATA_STRUCTURE[groupKey]
            let formatter = group.format.split(',')
            let storeFormatter = ({ value }) => value
            let countryFormatter = ({ value }) => value

            switch (formatter[0]) {
                case 'number':
                    storeFormatter = numberFormatter
                    break;

                case 'euro':
                    storeFormatter = euroFormatter
                    break;

                case 'percentage':
                    storeFormatter = percentageFormatter;
                    break;

                case 'none':
                    storeFormatter = noneFormatter
                    break;

                default:
            }

            switch (formatter[1]) {
                case 'number':
                    countryFormatter = numberFormatter
                    break;

                case 'euro':
                    countryFormatter = euroFormatter
                    break;

                case 'percentage':
                    storeFormatter = percentageFormatter;
                    break;

                case 'none':
                    countryFormatter = noneFormatter
                    break;

                default:
            }

            if (group.name) {
                dataSource.push({
                    key: group.key,
                    name: group.name,
                    type: 'group',
                    storeFormatter: storeFormatter,
                    countryFormatter: countryFormatter,
                    formulaType: group.type,
                    gammaAStore: costs?.[group.key]?.store,
                    gammaACountry: costs?.[group.key]?.country,
                    note: group.note || ''
                });
            }

            for (const item of group.data) {
                formatter = item.format.split(',');
                storeFormatter = ({ value }) => value
                countryFormatter = ({ value }) => value

                switch (formatter[0]) {
                    case 'number':
                        storeFormatter = numberFormatter
                        break;

                    case 'euro':
                        storeFormatter = euroFormatter
                        break;

                    case 'none':
                        storeFormatter = noneFormatter
                        break;

                    case 'percentage':
                        storeFormatter = percentageFormatter;
                        break;

                    default:
                }

                switch (formatter[1]) {
                    case 'number':
                        countryFormatter = numberFormatter
                        break;

                    case 'euro':
                        countryFormatter = euroFormatter
                        break;

                    case 'none':
                        countryFormatter = noneFormatter
                        break;

                    case 'percentage':
                        storeFormatter = percentageFormatter;
                        break;

                    default:
                }

                dataSource.push({
                    key: item.key,
                    name: item.name,
                    type: 'cost',
                    formulaType: item.type,
                    storeFormatter: storeFormatter,
                    countryFormatter: countryFormatter,
                    gammaAStore: costs?.[item.key]?.store,
                    gammaACountry: costs?.[item.key]?.country,
                    note: item.note || ''
                });
            }

            if ('hidden,hidden' !== group.type) {
                dataSource.push({
                    key: group.key,
                    name: 'Totale',
                    type: 'total',
                    storeFormatter: storeFormatter,
                    countryFormatter: countryFormatter,
                    formulaType: group.type,
                    gammaAStore: costs?.[group.key]?.store,
                    gammaACountry: costs?.[group.key]?.country,
                    note: ''
                });
            }
        }

        return dataSource;
    }, [costs])

    const dataSourceTransformed = useMemo(() => {
        const dataSource = []

        for (const group of costsData) {
            let totalStore = 0
            let totalCountry = 0

            if (group.title) {
                dataSource.push({
                    type: 'group',
                    name: group.title,
                    gammaAStore: null,
                    gammaACountry: null
                })
            }

            for (const item of group.data) {
                dataSource.push({
                    type: 'cost',
                    name: item.costType,
                    gammaAStore: item.calculate ? item.calculate(statisticsData, products) : item.gammaAStore,
                    gammaACountry: item.gammaACountry,
                    calculated: item.calculated === true
                })

                if (item.calculated !== true) {
                    totalStore += item.gammaAStore || 0
                    totalCountry += item.gammaACountry || 0
                }
            }

            if (group.data.length > 1) {
                dataSource.push({
                    type: 'total',
                    name: 'Totale',
                    gammaAStore: totalStore,
                    gammaACountry: totalCountry
                })
            }
        }
        const valoreAPvItem = dataSource.find(item => item.name === 'Valore a PV dello stock in uscita')
        const incidenzaAIndex = dataSource.findIndex(item => item.name === 'Incidenza sconti della famiglia a rotazione normale')
        const incidenzaBIndex = dataSource.findIndex(item => item.name === 'Incidenza sconti per smaltimento ref in uscita')

        if (valoreAPvItem) {
            const removeFormat = v => v ? Number(v.toString().replace('.', '').replace(',', '.')) : 0
            const value = removeFormat(valoreAPvItem.gammaAStore)

            if (incidenzaAIndex !== -1) {
                dataSource[incidenzaAIndex].gammaACountry = agNumberFormatter({ value: value * (removeFormat(dataSource[incidenzaAIndex].gammaAStore) / 100) })
            }

            if (incidenzaBIndex !== -1) {
                dataSource[incidenzaBIndex].gammaACountry = agNumberFormatter({ value: value * (removeFormat(dataSource[incidenzaBIndex].gammaAStore) / 100) })
            }
        }

        return dataSource
    }, [costsData, statisticsData, products])

    const getContent = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Button
                        icon='left'
                        onClick={navigateBack}>
                        Indietro
                    </Button>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <StyledH1 className={styles.title}>{simulation.title}</StyledH1>
                        <h3 className={styles.subtitle}>Periodo dal {simulation.dateFrom} al {simulation.dateTo}</h3>
                    </div>
                </div>
                <Row style={{ marginBottom: 25 }}>
                    <Col>
                        <div className='header-grid'>
                            <div>
                                <span className='label'>Reparto</span>
                                <span
                                    className='value'>{nomenclature.department.length > 0 ? nomenclature.department : 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Sottoreparto</span>
                                <span
                                    className='value'>{nomenclature.subDepartment.length > 0 ? nomenclature.subDepartment : 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Tipo</span>
                                <span
                                    className='value'>{nomenclature.category.length > 0 ? nomenclature.category : 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Sottotipo</span>
                                <span
                                    className='value'>{nomenclature.subCategory.length > 0 ? nomenclature.subCategory : 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Negozi A</span>
                                <span className='value'>{simulation.storesA || 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Negozi AB</span>
                                <span className='value'>{simulation.storesAb || 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Negozi ABC</span>
                                <span className='value'>{simulation.storesAbc || 'Tutti'}</span>
                            </div>
                            <div>
                                <span className='label'>Stato</span>
                                <span
                                    className='value'>{simulazioneUtils.getTranslationForState(simulation.state)}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey='analysis' size='large'>
                            <Tabs.TabPane tab='Analisi' key='analysis'>
                                <Row gutter={[16, 16]} style={{ marginBottom: 50 }}>
                                    <Col xs={6}>
                                        <SimulazioneChart title='CA' data={[
                                            {
                                                label: 'Vecchia Gamma',
                                                value: statisticsData.CA ? statisticsData.CA.old : 0,
                                                color: '#91ad82'
                                            },
                                            {
                                                label: 'Nuova Gamma',
                                                value: statisticsData.CA ? statisticsData.CA.new : 0,
                                                color: '#66CC33'
                                            }
                                        ]} />
                                    </Col>
                                    <Col xs={6}>
                                        <SimulazioneChart title='Quote MDH' data={[
                                            {
                                                label: 'Vecchia Gamma',
                                                value: statisticsData['Quota MDH %'] ? statisticsData['Quota MDH %'].old : 0,
                                                color: '#91ad82'
                                            },
                                            {
                                                label: 'Nuova Gamma',
                                                value: statisticsData['Quota MDH %'] ? statisticsData['Quota MDH %'].new : 0,
                                                color: '#66CC33'
                                            }
                                        ]} />
                                    </Col>
                                    <Col xs={6}>
                                        <SimulazioneChart title='3xNET' data={[
                                            {
                                                label: 'Vecchia Gamma',
                                                value: statisticsData['3xNET'] ? statisticsData['3xNET'].old : 0,
                                                color: '#91ad82'
                                            },
                                            {
                                                label: 'Nuova Gamma',
                                                value: statisticsData['3xNET'] ? statisticsData['3xNET'].new : 0,
                                                color: '#66CC33'
                                            }
                                        ]} />
                                    </Col>
                                    <Col xs={6}>
                                        <SimulazioneChart title='Redd %' data={[
                                            {
                                                label: 'Vecchia Gamma',
                                                value: statisticsData['Redd %'] ? statisticsData['Redd %'].old : 0,
                                                color: '#91ad82'
                                            },
                                            {
                                                label: 'Nuova Gamma',
                                                value: statisticsData['Redd %'] ? statisticsData['Redd %'].new : 0,
                                                color: '#66CC33'
                                            }
                                        ]} />
                                    </Col>
                                </Row>
                                <Table dataSource={kpiData} columns={ANALYSIS_COLUMNS} bordered size='middle'
                                       pagination={false} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='Costi' key='costs'>
                                <div className={css`background-color: #e0e0e0;
                                  padding: 10px 15px;`}>
                                    <Row gutter={16} className={css`display: flex;
                                      flex-direction: row;
                                      align-items: center;`}>
                                        <Col span={6}>
                                            Totale store
                                        </Col>
                                        <Col span={6}>
                                            <InputNumber disabled={simulation.state === 'PUBLISHED'} value={totalStores} onChange={value => {
                                                setTotalStores(value)
                                            }} />
                                        </Col>
                                        <Col span={6}>
                                            <strong>Totale complessivo</strong>
                                        </Col>
                                        <Col span={6}>
                                            <strong>{agNumberFormatter({ value: totalCosts })} &euro;</strong>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={css`margin-top: 25px;`}>
                                    {simulation?.state !== 'PUBLISHED' && <Button type='primary' style={{ marginBottom: 25 }} disabled={!canSaveCosts}
                                            onClick={handleCostsSave}>Salva</Button>}
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={24} sm={{ span: 16 }}>
                                                <Table columns={COSTS_COLUMNS} dataSource={costsDataSource} bordered
                                                       pagination={false} />
                                            </Col>
                                            <Col span={24} sm={{ span: 8 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <span style={{ width: '80%' }}>Costo medio orario di un collaboratore interno</span>
                                                    <span>&euro; 12</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <span
                                                        style={{ width: '80%' }}>Costo medio orario manodopera esterna</span>
                                                    <span>&euro; 29</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <span
                                                        style={{ width: '80%' }}>Costo medio lavorazione 3 punti luce</span>
                                                    <span>&euro; 29</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab='Ritorno' key='return'>
                                {simulation?.state !== 'PUBLISHED' && <Button type='primary' style={{ marginBottom: 25 }} disabled={!canSaveRoi}
                                        onClick={handleRoiSave}>Salva</Button>}
                                <AntTableWrapper>
                                    <AntTableBody>
                                        <AntTableRow>
                                            <td>Nome gamma</td>
                                            <td>{simulation.title}</td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>ML Gamma A</td>
                                            <td><InputNumber disabled={simulation.state === 'PUBLISHED'} defaultValue={roiData.mlGammaA}
                                                             onChange={value => handleRoiDataUpdate(value, 'mlGammaA')} />
                                            </td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>ML Gamma B</td>
                                            <td><InputNumber disabled={simulation.state === 'PUBLISHED'} defaultValue={roiData.mlGammaB}
                                                             onChange={value => handleRoiDataUpdate(value, 'mlGammaB')} />
                                            </td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>ML Gamma C</td>
                                            <td><InputNumber disabled={simulation.state === 'PUBLISHED'} defaultValue={roiData.mlGammaC}
                                                             onChange={value => handleRoiDataUpdate(value, 'mlGammaC')} />
                                            </td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>CA 12 mg N+1</td>
                                            <td>{agNumberFormatter({ value: roiData.caNy })}</td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>Prog % CA 12 mg N+1</td>
                                            <td>{agPercentageFormatter({ value: roiData.progCaPercNy })}</td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>Tasso di MG % N+1</td>
                                            <td>{agPercentageFormatter({ value: roiData.mgPercNy })}</td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>Redd 3xNET %</td>
                                            <td>{agPercentageFormatter({ value: roiData.redd3xNetPerc })}</td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>Delta MG € 12 mg N+1 vs N</td>
                                            <td>{agNumberFormatter({ value: roiData.deltaMgNy })}</td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td>Delta 3xNET € 12 mg N+1 vs N</td>
                                            <td>{agNumberFormatter({ value: roiData.delta3xNetNy })}</td>
                                        </AntTableRow>
                                    </AntTableBody>
                                </AntTableWrapper>

                                <AntTableWrapper style={{ marginTop: 50 }}>
                                    <AntTableBody>
                                        <AntTableRow>
                                            <td style={{ width: '50%' }}><strong>MG Cassa</strong></td>
                                            <td style={{ width: '50%' }}><strong>3xNET</strong></td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td style={{ width: '50%' }}>{agPercentageFormatter({ value: roiData.roiMg / 100 })}</td>
                                            <td style={{ width: '50%' }}>{agPercentageFormatter({ value: roiData.roi3xNet / 100 })}</td>
                                        </AntTableRow>
                                    </AntTableBody>
                                </AntTableWrapper>
                                <AntTableWrapper style={{ marginTop: 15 }}>
                                    <AntTableBody>
                                        <AntTableRow>
                                            <td style={{ width: '50%' }}><strong>ROI MG Cassa</strong></td>
                                            <td style={{ width: '50%' }}><strong>ROI 3xNET</strong></td>
                                        </AntTableRow>
                                        <AntTableRow>
                                            <td style={{ width: '50%' }}>{roiData.roiMgMonths} mes{roiData.roiMgMonths === 1 ? 'e' : 'i'}</td>
                                            <td
                                                style={{ width: '50%' }}>{roiData.roi3xNetMonths} mes{roiData.roi3xNetMonths === 1 ? 'e' : 'i'}</td>
                                        </AntTableRow>
                                    </AntTableBody>
                                </AntTableWrapper>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <MainLayout
            tourSteps={steps}
            isTourAvailable={false}
            isTourOpen={false}
            defaultCurrentPage='simulazione'>
            <div className={styles.root}>
                {getContent()}
            </div>
        </MainLayout>
    )
}
