import React, { Component } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { StyledModal, StyledFormItem } from './styled'

const { Option } = Select

export const CreateFamilyModal = Form.create({ name: 'create_family_modal' })(
  class extends Component {
    render() {
      const { visible, onCancel, onOk, form, departments } = this.props
      const { getFieldDecorator } = form
      const formItemLayout = {
        labelCol: { span: 11 },
        wrapperCol: { span: 13 },
        labelAlign: 'left'
      }
      return (
        <StyledModal
          visible={visible}
          title="Crea una nuova famiglia"
          onCancel={onCancel}
          onOk={onOk}
          maskClosable={false}
          afterClose={() => form.resetFields()}
          footer={[
            <Button key="submit" type="primary" onClick={onOk}>
              OK
            </Button>
          ]}>
          <Form {...formItemLayout}>
            <StyledFormItem label="Nomenclatura famiglia" colon={false}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Inserisci il nome della famiglia'
                  }
                ]
              })(<Input />)}
            </StyledFormItem>
            <StyledFormItem label="Reparto" colon={false}>
              {getFieldDecorator('idDepartment', {
                rules: [{ required: true, message: 'Scegli un reparto' }]
              })(
                <Select>
                  {departments.map(department => (
                    <Option key={department.id} value={department.id}>
                      {`${department.id}-${department.label}`}
                    </Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
          </Form>
        </StyledModal>
      )
    }
  }
)
