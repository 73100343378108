import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Input } from 'antd'
import { CellBox } from 'Components/DossierUnico/DossierUnicoData'

const AgTextCellEditor = memo(
  forwardRef((props, ref) => {
    const { value: initialValue, colDef, stopEditing } = props
    const { cellEditorParams } = colDef
    const [value, setValue] = useState(initialValue)
    const refInput = useRef(null)

    useEffect(() => {
      refInput.current.focus()
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
        isCancelBeforeStart() {
          return cellEditorParams?.isCancelBeforeStart?.(props) ?? false
        },
        isCancelAfterEnd() {
          return cellEditorParams?.isCancelAfterEnd?.(props) ?? false
        },
      }
    })

    return (
      <CellBox>
        <Input
          onChange={e => setValue(e.target.value)}
          value={value}
          ref={refInput}
          style={{ width: '100%' }}
          onBlur={() => stopEditing?.()}
        />
      </CellBox>
    )
  }),
)

export default AgTextCellEditor;
