import { Alert, Button, message } from 'antd'
import { css } from '@emotion/css'
import React, { useCallback, useState } from 'react'
import api from 'Common/api'
import SearchProductsModal from 'Components/DossierUnico/SearchProductsModal'
import { ROLE_CP } from 'Common/constants/global'
import { useSelector } from 'react-redux'

const DossierUnicoDataToolbar = ({
  dataChanges,
  hideHeader,
  setHideHeader,
  dossierId,
  selectedRecords,
  onDeleteSelectedRecords,
  onAddRecords,
  onUpdateRecords,
  toggleProductErrorsModal,
  productIssues,
}) => {
  const [showAddProductsModal, setShowAddProductsModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const user = useSelector(state => state.User)

  const handleSave = useCallback(async () => {
    if (!dossierId) return

    setIsSaving(true)

    const updateMap = dataChanges.reduce((acc, update) => {
      if (!acc[update.idReferenza]) {
        acc[update.idReferenza] = {
          idReferenza: update.idReferenza,
        }
      }

      acc[update.idReferenza][update.field] = update.newValue

      return acc
    }, {})

    const updates = Object.values(updateMap)

    if (updates.length === 0) return

    try {
      const { data: updatedProducts } = await api.dossierUnico.updateProducts(
        dossierId,
        updates,
      )
      await onUpdateRecords(updatedProducts, true)
    } catch (e) {
      message.error('Errore durante il salvataggio dei dati')
    } finally {
      setIsSaving(false)
    }
  }, [dataChanges, dossierId, onUpdateRecords])

  const handleAddProductModalHide = useCallback(() => {
    setShowAddProductsModal(false)
  }, [])

  const handleAddProducts = useCallback(
    async productIds => {
      if (!productIds.length === 0) return
      try {
        const { data: newProducts } = await api.dossierUnico.addProducts(
          dossierId,
          Array.from(productIds),
        )
        onAddRecords(newProducts)
        setShowAddProductsModal(false)
      } catch (e) {
        message.error("Errore durante l'aggiunta dei prodotti")
      }
    },
    [dossierId, onAddRecords],
  )

  const productIssuesCount = Object.keys(productIssues).length

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '16px',
        }}>
        <div style={{ width: '50%' }}>
          <Button
            type="dashed"
            icon={hideHeader ? 'down' : 'up'}
            onClick={() => setHideHeader(!hideHeader)}
          />
          {user?.role === ROLE_CP && (
            <Button
              type="primary"
              icon="plus"
              onClick={() => setShowAddProductsModal(true)}
              className={css`
              margin-left: 8px;
            `}>
              Aggiungi prodotti
            </Button>
          )}
          <Button
            icon="save"
            disabled={dataChanges.length === 0}
            onClick={handleSave}
            loading={isSaving}
            className={css`
              margin-left: 8px;
            `}>
            Salva
          </Button>
          <Button
            icon="delete"
            disabled={selectedRecords.length === 0}
            onClick={onDeleteSelectedRecords}
            className={css`
              margin-left: 8px;
            `}>
            Elimina
          </Button>
        </div>
        <div
          style={{
            width: '50%',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Button>Storico</Button>
        </div>
      </div>
      {productIssuesCount > 0 && (
        <Alert
          message={
            <div
              className={css`
                display: flex;
                align-items: center;
              `}>
              <span>
                Attenzione! {productIssuesCount === 1 ? "C'è" : 'Ci sono'}{' '}
                {productIssuesCount}{' '}
                {productIssuesCount === 1 ? 'referenza' : 'referenze'} con dati
                non validi che{' '}
                {productIssuesCount === 1 ? 'blocca' : 'bloccano'} l'invio dei
                dati ai sistemi esterni.
              </span>
              <Button
                className={css`
                  margin-left: auto;
                `}
                size="small"
                onClick={toggleProductErrorsModal}>
                Scopri di più
              </Button>
              <Button
                className={css`
                  margin-left: 8px;
                `}
                size="small">
                Visualizza nella griglia
              </Button>
            </div>
          }
          type="warning"
          showIcon
          style={{ marginBottom: '16px' }}
        />
      )}
      <SearchProductsModal
        visible={showAddProductsModal}
        onHide={handleAddProductModalHide}
        onConfirm={handleAddProducts}
      />
    </>
  )
}

export default DossierUnicoDataToolbar
