import API from './axiosConfig'
import qs from 'qs'
import {REFERENCE_STATE} from "Common/globals";
import { getFromSession, objectToQueryStringInUrl, serializeParams, setToSession } from 'Common/utils'
import axios from 'axios'
import config from './config'


// EXAMPLE APIS
// Reference: https://codeburst.io/how-to-call-api-in-a-smart-way-2ca572c6fe86 (Solution #2)
export default {
  about: {
    getBuildInfo: () => API.get('/build-info')
  },
  user: {
    authenticate: (token, selectedStoreId) => {
      const headers = {
        Authorization: `Bearer ${token}`
      };

      if (selectedStoreId) {
        headers['LM-Store'] = selectedStoreId;
      }

      return API.get('/v1/user', { headers });
    },
    getSpecialAuthorization: () => API.get('/v1/user/special-authorization')
  },
  validityPeriods: {
    get: () => API.get('/v1/familyvalidity'),
    create: (code, description, startDate, endDate) =>
      API.post('/v1/familyvalidity', {
        code: code,
        description: description,
        startDate: startDate,
        endDate: endDate
      })
  },
  families: {
    getAll: () => API.get('/v1/families'),
    queryFamilyTree: query => API.get('/v1/families/tree' + objectToQueryStringInUrl(query)),
    importFamilyTree: (familyId, importList) => API.post('/v1/families/tree/import', {
      targetFamilyId: familyId,
      updateItems: importList
    }),
    getOne: id => API.get(`/v1/families/${id}`),
    getFamilyDetail: id => API.get(`/v1/families/${id}/detail`),
    post: family => API.post('/v1/family', family),
    delete: id => API.delete(`/v1/families/${id}`),
    getMlByUser: () => API.get('/v1/families/ml/by-user'),
    updateMl: payload => API.put('/v1/families/ml', payload),
 /*   search: (searchBody, page, pageSize, grouped) => API.post(
      '/v1/families/search',
      searchBody,
      {
        params: {
          page,
          pageSize,
          grouped
        }
      }

    ), */
    search: () => API.post(
      "/v1/families/search/"),

    export: (visibleColumns) => API.post(
      `v1/families/export`,visibleColumns
    )
      
  },


    


  departments: {
    get: () => API.get('/v1/departments')
  },
  subdepartments: {
    get: departmentId =>
      API.get('/v1/subdepartments', { params: { department: departmentId } })
  },
  categories: {
    get: (departmentId, subdepartmentId) =>
      API.get('/v1/categories', {
        params: { department: departmentId, subDepartment: subdepartmentId }
      })
  },
  subcategories: {
    get: (departmentId, subdepartmentId, categoryIds, unassigned) =>
      API.get('/v1/subcategories', {
        params: {
          department: departmentId,
          subDepartment: subdepartmentId,
          categories: categoryIds.join(),
          unassigned: unassigned
        }
      })
  },
  dossiers: {
    getAll: () => API.get('/v1/dossiers'),
    getDossierPSByStoreId: (storeId) => API.get(`/v1/prodotti-simbolo/${storeId}`),
    getOne: id => API.get(`/v1/dossiers/${id}`),
    update: (id, data) => API.put(`/v1/dossiers/${id}`, data),
    export: (id, visibleColumns) => API.post(`/v1/dossiers/${id}/export`, {
      visibleColumns
    }),
    alignWithBackoffice: (dossierId, productIds) => API.post(`/v1/dossiers/${dossierId}/align-with-backoffice`, productIds),
    sendDossierReport:  departmentIds => API.get('/v1/dossiers-command/report', {
      params: {
        departments: departmentIds.join(',')
      }
    }),
    exportDashboard: (id) => API.get(`/v1/dossiers/${id}/export-dashboard`),
    get: (dossierId, year, departments, title, family, types, typology, states, productCode, productDescription, storeId, page, pageSize) => {
      const params = {
        dossierId,
        anno: year,
        reparti: departments,
        titolo: title ? title : undefined,
        famiglia: family,
        tipi: types,
        tipologia: typology,
        stati: states,
        productCode: productCode,
        productDescription: productDescription,
        storeId: storeId,
        nPagina: page,
        dimPagina: pageSize
      };

      return API.get('/v1/dossiers', {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    post: dossier => API.post('/v1/dossiers', dossier),
    delete: id => API.delete(`/v1/dossiers/${id}`),
    getRefs: (id, params) => API.post(`/v1/dossiers/${id}/references`, params),
    sendNotification: (id, params) => API.post(`/v1/dossiers/${id}/send-notification`, params),
    getAllProducts: (id) => API.get(`/v1/dossiers/${id}/all`),
    saveProducts: (id, products) => API.put(`/v1/dossiers/${id}/products`, products),
    saveProdottiSimbolo: (id, products) => API.put(`/v1/dossiers/${id}/prodotti-simbolo`, products),
    addRefs: (id, payload) =>
      API.post(`/v1/dossiers/${id}/references/append`, payload),
    batchSaveRefs: (dossierId, mode, filters, refIds, values) => {
      const params = {
        filter: { ...filters, mode: mode, references: refIds },
        value: values
      }

      return API.put(`/v1/dossiers/${dossierId}/references/batch`, params)
    },
    saveRefs: (id, changes) => API.put(`/v1/dossiers/${id}/references`, changes),
    deleteRefs: (dossierId, mode, filters, refIds) => {
      const params = {
        mode: mode,
        references: refIds,
        ...filters
      }

      return API.delete(`/v1/dossiers/${dossierId}/references`, {
        data: params
      })
    },
    duplicate: (id, newDossierHeader) => API.post(`/v1/dossiers/${id}/duplicate`, newDossierHeader),
    exportPS: (id, visibleColumns) => API.post(`/v1/dossiers/${id}/exportPS`, {
      visibleColumns
    }),
  },
  references: {
    get: search => API.post('/v1/references', search),
    search: search => API.post('/v1/references/search', search),
    applyStockVariations: stockVariations => API.post('/v1/references/stock-variation', stockVariations),
    getById: id => API.get(`/v1/references/${id}`),
    count: (familyId, departmentId, dossierId) => API.post('/v1/references/count', {
      family: familyId,
      department: null,
      state: REFERENCE_STATE.INI.key,
      topxs: false,
      dossier: dossierId
    })
  },
  stores: {
    get: () => API.get('/v1/stores'),
    getCluster: (storeId, familyId) => API.get(`/v1/clusters/${storeId}/${familyId}`),
    changeCluster: (dossierId, cluster) => API.post(`/v1/clusters`, {
      idDossier: dossierId,
      cluster
    })
  },
  products: {
    getIds: () => API.get('/v1/references/all/id'),
    search: params => API.get(`/v1/product/search`, { params }),
    lookup: (payload) => API.post(`/v2/product/lookup`, payload)
  },
  analytics: {
    getDashboard: () => {
      return API.get('/v1/analytics/dashboard')
    }
  },
  opecom: {
    get: (code, description, startDate, endDate, type, opecomId, storeId, productCode, page, pageSize) => {
      const params = {
        codice: code ? code : null,
        descrizione: description ? description : null,
        data_inizio: startDate ? startDate.format('YYYY-MM-DD') : startDate,
        data_fine: endDate ? endDate.format('YYYY-MM-DD') : endDate,
        type: type === 'all' ? null : type,
        opecomId,
        storeId: parseInt(storeId) === 0 ? null : storeId,
        productCode: productCode || null,
        nPagina: page,
        dimPagina: pageSize
      };

      return API.get('/v1/opecom', { params: params });
    },
    exportDashboard: (id) => API.get(`/v1/opecom/${id}/export-dashboard`),
    export: (id, visibleColumns) => API.post(`/v1/opecom/${id}/export`, {
      visibleColumns
    }),
    getOne: id => API.get(`/v1/opecom/${id}`),
    getRefs: (id, params) => API.post(`/v1/opecom/${id}/references`, params),
    getAllRefs: (id) => API.get(`/v1/opecom/${id}/products/all`),
    getAllRefsIncomplete: (id) => API.get(`/v1/opecom/${id}/productsincomplete/all`),
    saveRefs: (id, changes) => API.put(`/v1/opecom/${id}/references`, changes),
    saveProducts: (id, products) => API.put(`/v1/opecom/${id}/products`, products),
    batchSaveRefs: (opecomId, mode, filters, refIds, values) => {
      const params = {
        filter: { ...filters, mode: mode, references: refIds },
        value: values
      }

      return API.put(`/v1/opecom/${opecomId}/references/batch`, params)
    },
  },
  nomenclature: {
    get: associated => API.get('/v1/nomenclature', { params: {associated: associated} })
  },

  attached: {
    saveAttachments: (idDossier,idReference,fileData) => API.put(`/v1/attachments/${idDossier}/${idReference}`, fileData),
  },
  data: {
    suppliers: () => API.get('/v1/data/suppliers'),
  },
  simulazione: {
    skuList: filters => API.get('/v1/simulazione/sku', { params: { ...filters }, paramsSerializer: params => serializeParams(params)}),
    crea: payload => API.post('/v1/simulazione/create', payload),
    creaHeader: payload => API.post('/v1/simulazione/create/header', payload),
    fetchProducts: (simulationId, payload) => API.post('/v1/simulazione/' + simulationId + '/fetch-products', payload),
    get: id => API.get('/v1/simulazione/' + id),
    getAdditionalInformation: id => API.get('/v1/simulazione/' + id + '/additional'),
    getProducts: simulationId => API.get(`/v1/simulazione/${simulationId}/products`),
    updateProducts: (simulationId, products) => API.put(`/v1/simulazione/${simulationId}/products`, products),
    getSimulationsByLoggedUser: params => API.get('/v1/simulazione/by-user', { params: { ...params }} ),
    replaceProduct: (simulationId, replacedId, replaceId) => API.get(`/v1/simulazione/${simulationId}/inout?replaced=${replacedId}&replace=${replaceId}`),
    removeProducts: (simulationId, productIds) => API.get(`/v1/simulazione/${simulationId}/remove?productIds=${productIds.join(',')}`),
    addProducts: (simulationId, productIds) => API.get(`/v1/simulazione/${simulationId}/add?productIds=${productIds.join(',')}`),
    updateSimulation: payload => API.put('/v1/simulazione', payload),
    addPlaceholder: (simulationId, payload) => API.post(`/v1/simulazione/${simulationId}/products/placeholder`, payload),
    getMetadata: (simulationId) => API.get(`/v1/simulazione/${simulationId}/metadata`),
    replaceProductWithPlaceholder: (simulationId, replacedProductId, placeholder) => API.post(`/v1/simulazione/${simulationId}/inout/placeholder`, {
      placeholder,
      replacedProductId
    }),
    saveMetadata: (simulationId, metadata) => API.put(`/v1/simulazione/${simulationId}/metadata`, metadata),
    simulate: (departmentId, params) => {
      return new Promise(async (resolve, reject) => {
        try {
          let simulazioneToken = getFromSession('Simulazione.Token', null);

          if (!simulazioneToken) {
            const tokenResponse = await API.get('/v1/simulazione/token');
            simulazioneToken = tokenResponse.data;
          }

          const response = await axios.post(
            `${config.SIMULATION_API_URL}/simulate/${departmentId}`,
            {},
            {
              params,
              paramsSerializer: params => serializeParams(params),
              headers: {
                'Authorization': `Bearer ${simulazioneToken}`
              }
            }
          );

          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    getToken: () => {
      return new Promise((resolve, reject) => {

      });
    },
    getMessages: (simulationId) => API.get(`/v1/simulazione/${simulationId}/messages`),
    saveMessage: (simulationId, message) => API.post(`/v1/simulazione/${simulationId}/messages`, { message }),
    publish: (simulationId, payload) => API.post(`/v1/simulazione/${simulationId}/publish`, payload),
    changeHeader: (simulationId, payload) => API.patch(`/v1/simulazione/${simulationId}`, payload),
  },
  dossierUnico: {
    getAll: () => API.get('/v1/dossierunico'),
    getAllProducts: (dossierId) => API.get(`/v1/dossierunico/${dossierId}`),
    updateProducts: (dossierId, updates) => API.patch(`/v1/dossierunico/${dossierId}/products`, updates),
    addProducts: (dossierId, productIds) => API.post(`/v1/dossierunico/${dossierId}/add-products`, productIds),
    deleteProducts: (dossierId, productIds) => API.post(`/v1/dossierunico/${dossierId}/delete-products`, productIds),
    replaceProduct: (dossierId, replacement) => API.post(`/v1/dossierunico/${dossierId}/replace-product`, replacement),
  },
}
