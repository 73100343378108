import styled from 'styled-components'
import { Card, Spin, Icon } from 'antd'

export const StyledCard = styled(Card)`
  height: 7rem;
  width: 11rem;
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem !important;
`

export const StyledSpin = styled(Spin)`
  vertical-align: initial !important;
`

export const LoadingIcon = styled(Icon)`
  font-size: 16px !important;
`

export const StyledDiv = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
  max-height: 5.4rem;
  display: flex;
  flex-direction: column;
`

export const StyledH1 = styled.h1`
  font-weight: normal;
  font-size: 0.7rem;
`

export const StyledP = styled.p`
  font-size: 0.5rem;
  margin-bottom: 0;
  overflow-y: auto;
`
