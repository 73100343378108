import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout'
import styles from './FamilyStore.module.scss'
import { StyledH1 } from 'Common/styled'
import { Button, Col, Row } from 'antd'
import api from 'Common/api'
import standardCellRenderer from 'Common/renderers/standardCellRenderer'
import { AgGridReact } from 'ag-grid-react'
import { agNumberFormatter } from 'Common/utils'
import { useSelector } from 'react-redux'
import { selectUser } from 'Store'
import { useHistory } from 'react-router'
import { ROLE_CS, ROLE_MERCH } from 'Common/constants/global'

const handleNumberValueSet = params => {
  const newValue = Number(params.newValue)

  if (isNaN(newValue) || newValue > 100) {
    return false
  }

  params.data[params.colDef.field] = newValue

  return true
}

const DETAIL_COLUMNS = [
  {
    headerName: 'Reparto',
    field: 'departmentId',
    flex: 1,
    valueGetter: params =>
      `${params.data.departmentId} - ${params.data.departmentName}`,
  },
  {
    headerName: 'Sottoreparto',
    field: 'subDepartmentId',
    flex: 1,
    valueGetter: params =>
      `${params.data.subDepartmentId} - ${params.data.subDepartmentName}`,
  },
  {
    headerName: 'Tipo',
    field: 'categoryId',
    flex: 1,
    valueGetter: params =>
      `${params.data.categoryId} - ${params.data.categoryName}`,
  },
  {
    headerName: 'Sottotipo',
    field: 'subCategoryId',
    flex: 1,
    valueGetter: params =>
      `${params.data.subCategoryId} - ${params.data.subCategoryName}`,
  },
]

export default function FamilyStore(params) {
  const user = useSelector(selectUser)
  const history = useHistory()
  const [families, setFamilies] = useState([])
  const [familiesMap, setFamiliesMap] = useState({})
  const [gridReady, setGridReady] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const gridRef = useRef()
  const [departments, setDepartments] = useState([])
  const [familyMl, setFamilyMl] = useState([])
  const [changedRowIndexes, setChangedRowIndexes] = useState([])

  const columns = useMemo(
    () => [
      {
        headerName: 'Famiglia Merceologica',
        field: 'familyName',
        flex: 1,
        cellRenderer: 'agGroupCellRenderer',
        suppressAutoSize: true,
        suppressSizeToFit: true,
        filter: true,
        sortable: true,
      },
      {
        headerName: 'Reparto',
        field: 'departmentId',
        valueGetter: params =>
          params.data.departmentId + ' - ' + params.data.departmentName,
        filter: true,
        sortable: true,
      },
      {
        headerName: 'Gamma A preco',
        field: 'clusterAPrecotop',
        width: 100,
        filter: true,
        valueFormatter: agNumberFormatter,
        sortable: true,
        editable: user?.role === ROLE_MERCH,
      },
      {
        headerName: 'Gamma B preco',
        field: 'clusterBPrecotop',
        width: 100,
        filter: true,
        valueFormatter: agNumberFormatter,
        sortable: true,
        editable: user?.role === ROLE_MERCH,
      },
      {
        headerName: 'Gamma C preco',
        field: 'clusterCPrecotop',
        width: 100,
        filter: true,
        valueFormatter: agNumberFormatter,
        sortable: true,
        editable: user?.role === ROLE_MERCH,
      },
      {
        headerName: 'Gamma A negozio',
        field: 'clusterA',
        valueFormatter: params =>
          params.value === null ? '' : agNumberFormatter(params),
        valueSetter: handleNumberValueSet,
        width: 100,
        filter: true,
        sortable: true,
        editable: user?.role === ROLE_CS,
      },
      {
        headerName: 'Gamma B negozio',
        field: 'clusterB',
        valueFormatter: params =>
          params.value === null ? '' : agNumberFormatter(params),
        valueSetter: handleNumberValueSet,
        width: 100,
        filter: true,
        sortable: true,
        editable: user?.role === ROLE_CS,
      },
      {
        headerName: 'Gamma C negozio',
        field: 'clusterC',
        valueFormatter: params =>
          params.value === null ? '' : agNumberFormatter(params),
        valueSetter: handleNumberValueSet,
        width: 100,
        filter: true,
        sortable: true,
        editable: user?.role === ROLE_CS,
      },
    ],
    [user?.role],
  )

  useEffect(() => {
    api.departments
      .get()
      .then(({ data }) => {
        setDepartments(data)
      })
      .catch(console.error)

    api.families
      .getAll()
      .then(({ data }) => {
        setFamilies(data)
      })
      .catch(console.error)

    api.families
      .getMlByUser()
      .then(({ data }) => {
        setFamilyMl(data)
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (changedRowIndexes.length > 0) {
        return 'Ci sono modifiche non salvate. Sei sicuro di voler uscire?'
      }
    })

    return () => {
      unblock()
    }
  }, [changedRowIndexes])

  useEffect(() => {
    setFamiliesMap(
      families.reduce(
        (acc, family) => ({
          ...acc,
          [family.idFamily]: family,
        }),
        {},
      ),
    )
  }, [families])

  useEffect(() => {
    if (!gridReady) return
    if (!gridRef.current) return
    if (Object.keys(familiesMap).length === 0) return
    if (familyMl.length === 0) return

    let rowData = familyMl.map(row => ({
      familyId: familiesMap[row.familyId]?.idFamily,
      familyName: familiesMap[row.familyId]?.name,
      departmentId: familiesMap[row.familyId]?.idDepartment,
      departmentName: departments.find(
        o => o.id === familiesMap[row.familyId]?.idDepartment,
      )?.label,
      storeId: user.store,
      clusterAPrecotop: row.gammaAPreco,
      clusterBPrecotop: row.gammaBPreco,
      clusterCPrecotop: row.gammaCPreco,
      clusterA: row.gammaAMl,
      clusterB: row.gammaBMl,
      clusterC: row.gammaCMl,
    }))

    gridRef.current.api.setRowData(rowData)
  }, [gridReady, gridRef.current, familyMl, familiesMap, departments])

  const handleSave = () => {
    const updatedData = []

    for (const index of changedRowIndexes) {
      const node = gridRef.current.api.getDisplayedRowAtIndex(index)
      updatedData.push({
        familyId: node.data.familyId,
        storeId: node.data.storeId,
        gammaAPreco: node.data.clusterAPrecotop,
        gammaBPreco: node.data.clusterBPrecotop,
        gammaCPreco: node.data.clusterCPrecotop,
        gammaAMl: node.data.clusterA,
        gammaBMl: node.data.clusterB,
        gammaCMl: node.data.clusterC,
      })
    }

    gridRef.current.api.showLoadingOverlay()

    api.families
      .updateMl({ updateList: updatedData })
      .then(({ data }) => {
        setChangedRowIndexes([])
        gridRef.current.api.hideOverlay()
      })
      .catch(console.error)
  }

  const onGridReady = useCallback(params => {
    setGridReady(true)
  })

  const handleCellValueChanged = params => {
    const watchedFields = ['clusterA', 'clusterB', 'clusterC']

    if (watchedFields.includes(params.colDef.field)) {
      const currentField = params.colDef.field

      const updatedData = {
        ...params.data,
        [currentField]: params.newValue,
      }

      for (const field of watchedFields) {
        if (field === currentField) continue
        updatedData[field] = 0
      }

      params.node.setData(updatedData)
    }

    if (!changedRowIndexes.includes(params.node.rowIndex)) {
      setChangedRowIndexes([...changedRowIndexes, params.node.rowIndex])
    }
  }

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="family-store">
      <div className={styles.root}>
        <StyledH1 className={`${styles.title} ${styles.titleWithMargin}`}>
          Famiglia Merceologica - Inserimento ML
        </StyledH1>
        <Row style={{ marginBottom: 25 }}>
          <Col
            style={{ display: 'flex', flexDirection: 'row' }}
            xs={22}
            md={22}
            xl={22}
            xxl={22}>
            <Button
              icon="save"
              onClick={handleSave}
              disabled={changedRowIndexes.length === 0}>
              Salva
            </Button>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="center"
          style={{ marginTop: 50 }}>
          <Col xs={22} md={22} xl={22} xxl={22} style={{ width: '100%' }}>
            <div
              style={{ height: 400, width: '100%' }}
              className="ag-theme-alpine modern-ag-grid">
              <AgGridReact
                ref={gridRef}
                rowMultiSelectWithClick={false}
                suppressRowClickSelection={true}
                suppressColumnVirtualisation={true}
                defaultColDef={{
                  floatingFilter: true,
                  minWidth: 150,
                  cellRenderer: 'standardCellRenderer',
                }}
                frameworkComponents={{
                  standardCellRenderer: standardCellRenderer,
                }}
                columnDefs={columns}
                pagination={false}
                onGridReady={onGridReady}
                masterDetail={true}
                onCellValueChanged={handleCellValueChanged}
                detailCellRendererParams={{
                  detailGridOptions: {
                    columnDefs: DETAIL_COLUMNS,
                  },
                  getDetailRowData: params => {
                    api.families
                      .getFamilyDetail(params.data.familyId)
                      .then(({ data }) => {
                        const children = []

                        for (const record of data) {
                          children.push({
                            departmentId: record.idReparto,
                            departmentName: record.nomeReparto,
                            subDepartmentId: record.idSottoreparto,
                            subDepartmentName: record.nomeSottoreparto,
                            categoryId: record.idTipo,
                            categoryName: record.nomeTipo,
                            subCategoryId: record.idSottotipo,
                            subCategoryName: record.nomeSottotipo,
                          })
                        }

                        params.successCallback(children)
                      })
                      .catch(console.error)
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </MainLayout>
  )
}
