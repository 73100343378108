import React from 'react'
import { StyledButton, StyledIcon, StyledText } from './styled'
import { Spin } from 'antd'

const AddButton = props => (
  <StyledButton type="dashed" onClick={props.onClick}>
    {props.loading ? (
      <Spin indicator={<StyledIcon type="loading-3-quarters" spin />} />
    ) : (
      <StyledIcon type="plus" />
    )}
    <StyledText>{props.text}</StyledText>
  </StyledButton>
)

export default AddButton
