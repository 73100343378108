import React from 'react';

export default function AntTableWrapper({ children, style }) {
  return (
    <div className="ant-table-wrapper" style={style}>
      <div className="ant-spin-nested-loading">
        <div className="ant-spin-container">
          <div className="ant-table ant-table-default ant-table-bordered ant-table-scroll-position-left">
            <div className="ant-table-content">
              <div className="ant-table-body">
                <table>
                  {children}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
