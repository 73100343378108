import React from 'react';
import { Button, Table, Tooltip } from 'antd'
import moment from 'moment'
import DossierUnicoUtils from 'Components/DossierUnico/DossierUnicoUtils'

const DossierUnicoHeader = ({ dossier }) => {
  return (
    <>
      <Table
        style={{ width: '100%' }}
        bordered={true}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'nome',
            width: '25%',
            render: (value, record) => `${record.idFamiglia} - ${value}`
          },
          {
            title: 'Stato',
            dataIndex: 'stato',
            width: '25%',
            render: value => DossierUnicoUtils.translateStatus(value)
          },
          {
            title: 'Ultimi aggiornamenti',
            dataIndex: 'stato',
            width: '25%',
            render: (_, record) => (
              <>
                <div>
                  <Tooltip title={`Creato da ${record.creatoDa}`}>
                    <span
                      style={{ cursor: 'default' }}>Creazione: {moment(record.dataCreazione).format('DD/MM/YYYY HH:mm:ss')}</span>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title={`Modificato da ${record.ultimaModificaDa}`} placement="bottom">
                    <span
                      style={{ cursor: 'default' }}>Ultima modifica: {moment(record.dataUltimaModifica).format('DD/MM/YYYY HH:mm:ss')}</span>
                  </Tooltip>
                </div>
              </>
            )
          },
          {
            title: 'Merch. rif',
            dataIndex: 'merchRiferimento',
            width: '25%'
          }
        ]}
        dataSource={[
          dossier
        ]}
        pagination={false}
        size='small'
      />
    </>
  );
};

export default DossierUnicoHeader;
