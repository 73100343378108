import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout';
import styles from './Simulazione.module.scss';
import { StyledH1 } from 'Common/styled'
import { Button, Card, Col, Row } from 'antd';
import SimulazioneFilters from 'Components/SimulazioneFilters/SimulazioneFilters'
import api from 'Common/api'
import { AgGridReact } from 'ag-grid-react'
import { agBooleanFormatter, agNumberFormatter, agPercentageFormatter, num, setToSession } from 'Common/utils'
import SimulazioneStatisticsBar from 'Components/SimulazioneStatisticsBar/SimulazioneStatisticsBar'
import SimulazioneFooterStatisticsBar from 'Components/SimulazioneFooterStatisticsBar/SimulazioneFooterStatisticsBar'
import moment from 'moment'

const REQUIRED_FILTERS = ['dateFrom', 'dateTo'];
const VIEW_BASE = 0;
const VIEW_RESULTS = 1;

const HISTORY_COLUMNS = [
  { headerName: 'Referenza', field: 'productId', width: 100, align: 'center', valueGetter: params => params.data.id.numArt, filter: true, sortable: true, resizable: true, pinned: 'left' },
  { headerName: 'Descrizione', field: 'libArt', width: 250, align: 'left', filter: true, sortable: true, resizable: true },
  { headerName: 'Gamma', field: 'codGamart', width: 100, align: 'center', filter: true, sortable: true, resizable: true },
  { headerName: 'Precotop', field: 'numTypreappropreco', width: 100, align: 'center', filter: true, sortable: true, resizable: true },
  { headerName: 'Top MDH', field: 'topMdh', width: 100, align: 'center', valueFormatter: agBooleanFormatter, filter: true, sortable: true, resizable: true },
  { headerName: 'Quartile', field: 'codNivgam', width: 100, align: 'center', filter: true, sortable: true, resizable: true },
  { headerName: 'Fornitore', field: 'supplier', width: 200, align: 'left', valueGetter: params => params.data.numFoucom + ' - ' + params.data.libFoucom, filter: true, sortable: true, resizable: true },
  { headerName: 'PA', field: 'prxAchfou', width: 50, align: 'center', valueFormatter: agNumberFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'PV', field: 'prxVtecns', width: 50, align: 'center', valueFormatter: agNumberFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'QTA', field: 'qteVte', width: 50, align: 'center', valueFormatter: agNumberFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'CA', field: 'ca', width: 50, align: 'center', valueFormatter: agNumberFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'Prog%', field: 'progCa', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'Red%', field: 'percRedd', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'MG€', field: 'mrg', width: 50, align: 'center', valueFormatter: agNumberFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'MG%', field: 'mrg2ca', width: 50, align: 'center', valueFormatter: agPercentageFormatter, filter: 'agNumberColumnFilter', sortable: true, resizable: true },
  { headerName: 'Reparto', field: 'department', width: 200, align: 'center', valueGetter: params => params.data.numRay + ' - ' + params.data.libRay, filter: true, sortable: true, resizable: true },
  { headerName: 'Sottoreparto', field: 'subDepartment', width: 200, align: 'center', valueGetter: params => params.data.numSray + ' - ' + params.data.libSray, filter: true, sortable: true, resizable: true },
  { headerName: 'Tipo', field: 'category', width: 200, align: 'center', valueGetter: params => params.data.numTyp + ' - ' + params.data.libTyp, filter: true, sortable: true, resizable: true },
  { headerName: 'Sottotipo', field: 'subCategory', width: 200, align: 'center', valueGetter: params => params.data.numStyp + ' - ' + params.data.libStyp, filter: true, sortable: true, resizable: true },
  { headerName: 'Top EXPO', field: 'topExpo', width: 100, align: 'center', valueFormatter: agBooleanFormatter, filter: true, sortable: true, resizable: true },
  { headerName: 'Top EM', field: 'topPrecoem', width: 100, align: 'center', valueFormatter: agBooleanFormatter, filter: true, sortable: true, resizable: true },
  { headerName: 'Data creazione', field: 'datCreprod', width: 100, align: 'center', filter: 'agDateColumnFilter', sortable: true, resizable: true },
  { headerName: 'Data AVS', field: 'datAvsart', width: 100, align: 'center', filter: 'agDateColumnFilter', sortable: true, resizable: true }
]

export default function SimulazioneHistory () {

  const [filtersValue, setFiltersValue] = useState({});
  const [view, setView] = useState(VIEW_BASE);
  const [data, setData] = useState([]);
  const gridRef = useRef();

  const onFiltersValueChange = value => setFiltersValue({ ...value });

  const isFormValid = () => {
    if (!filtersValue) return false;

    for (const field of REQUIRED_FILTERS) {
      if (!filtersValue.hasOwnProperty(field)) return false;

      const value = filtersValue[field];

      if (!value) return false;

      if (typeof value === 'string' && value.length === 0) return false;
    }

    return true;
  }

  const handleView = () => {
    if (!isFormValid()) return;

    setView(VIEW_RESULTS);
  }

  const handleCreaSimulazione = () => {
    setToSession('Simulazione.Filters', filtersValue);
    window.location.href = '/simulazione/crea';
  }

  const onGridReady = useCallback(params => {
    params.columnApi.autoSizeAllColumns(false);

    const nmc = [];

    if (filtersValue.subCategoryId.length > 0) {
      nmc.push(...filtersValue.subCategoryId);
    } else if (filtersValue.categoryId.length > 0) {
      for (const obj of filtersValue.categoryId) {
        nmc.push(filtersValue.departmentId + '.' + obj);
      }
    } else if (filtersValue.subDepartmentId.length > 0) {
      for (const obj of filtersValue.subDepartmentId) {
        nmc.push(filtersValue.departmentId + '.' + obj);
      }
    } else if (filtersValue.departmentId) {
      nmc.push(filtersValue.departmentId.toString());
    }

    api.simulazione.skuList({
      from: filtersValue.dateFrom.format('YYYY-MM-DD'),
      to: filtersValue.dateTo.format('YYYY-MM-DD'),
      nmc
    }).then(response => {
      const productsMapped = response.data.items;

      setData([...productsMapped])
      params.api.setRowData([...productsMapped]);
      params.columnApi.autoSizeAllColumns(false);
    }).catch(error => {
      console.error(error);
      params.fail();
    });
  })

  const getFiltersContent = () => {
    return (
      <>
        <Row type="flex" justify="center" align="center" style={{ marginBottom: 50 }}>
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                icon="left"
                onClick={() => window.location.href = '/simulazione'}>
                Indietro
              </Button>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <StyledH1 className={styles.title}>Simulazione Nuove Gamme</StyledH1>
                <h3 className={styles.subtitle} style={{ marginBottom: 0 }}>Definisci il perimetro d'azione per visualizzare lo storico</h3>
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="center">
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <Card className={styles.filtersCard}>
              <SimulazioneFilters onChange={onFiltersValueChange} />
              <div className={styles.buttonsBar}>
                <Button type="primary" disabled={!isFormValid()} onClick={handleView}>Visualizza</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: SimulazioneFooterStatisticsBar,
          statusPanelParams: {
            history: true
          },
          key: 'footerStatistics'
        },
      ],
    };
  }, []);

  const getResultsContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Button
            icon="left"
            onClick={() => window.location.href = '/simulazione'}>
            Indietro
          </Button>
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <StyledH1 className={styles.title}>Storico</StyledH1>
            <h3 className={styles.subtitle}>Periodo dal {filtersValue.dateFrom.format('YYYY-MM-DD')} al {filtersValue.dateTo.format('YYYY-MM-DD')}</h3>
          </div>
        </div>
        <Row style={{ marginBottom: 25 }}>
          <Col>
            <SimulazioneStatisticsBar data={data} history={true} />
          </Col>
        </Row>
        <Row style={{ marginBottom: 25 }}>
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Button style={{ marginLeft: 'auto' }} type="default" onClick={handleCreaSimulazione}>Crea simulazione</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 400, width: '100%' }} className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                statusBar={statusBar}
                columnDefs={HISTORY_COLUMNS}
                defaultColDef={{
                  floatingFilter: true,
                  minWidth: 150
                }}
                pagination={true}
                paginationPageSize={500}
                onGridReady={onGridReady}
              />
            </div>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="simulazione">
      <div className={styles.root}>
        {view === VIEW_BASE && getFiltersContent()}
        {view === VIEW_RESULTS && getResultsContent()}
      </div>
    </MainLayout>
  )
}
