import moment from 'moment/moment'

const ROLE_CP = 'CP';
const ROLE_MERCH = 'MERCH';
const ROLE_CS = 'CS';
const ROLE_CPXS = 'CPXS';
const ROLE_GUEST = 'GUEST'
const ROLE_AZ_COMM = 'AZ_COMM'
const ROLE_CS_WEB = 'CS_WEB'

const TITLE_STORE_LEADER = 'STORE LEADER'
const TITLE_STORE_PERFORMANCE_LEADER = 'STORE PERFORMANCE LEADER'

const OPECOM_SEDE_PRIMO_INVIO_RELEX = 150;
const OPECOM_SEDE_ULTIMO_INVIO_RELEX = 56;
const OPECOM_SEDE_ULTIMA_RICEZIONE_RELEX = 60;
const OPECOM_NEGO_ULTIMO_INVIO_RELEX = 42;
const OPECOM_NEGO_RICEZIONE_RELEX = 51;

export {
    ROLE_CP,
    ROLE_MERCH,
    ROLE_CS,
    ROLE_CPXS,
    ROLE_AZ_COMM,
    ROLE_GUEST,
    ROLE_CS_WEB,
    TITLE_STORE_LEADER,
    TITLE_STORE_PERFORMANCE_LEADER,
    OPECOM_SEDE_PRIMO_INVIO_RELEX,
    OPECOM_SEDE_ULTIMO_INVIO_RELEX,
    OPECOM_SEDE_ULTIMA_RICEZIONE_RELEX,
    OPECOM_NEGO_ULTIMO_INVIO_RELEX,
    OPECOM_NEGO_RICEZIONE_RELEX
};

export const getOpecomInfo = ({ startDate, code }) => {
    const information = {
        lastHqCompletionDate: moment(startDate).subtract(OPECOM_SEDE_ULTIMO_INVIO_RELEX, 'days'),
        lastStoreCompletionDate: moment(startDate).subtract(OPECOM_NEGO_ULTIMO_INVIO_RELEX, 'days'),
        lastOpecomUpdateDate: moment(startDate).subtract(OPECOM_SEDE_ULTIMA_RICEZIONE_RELEX, 'days'),
        storeCompletionFrom: moment(startDate).subtract(OPECOM_NEGO_RICEZIONE_RELEX, 'days'),
        storeCompletionTo: moment(startDate).subtract(OPECOM_NEGO_ULTIMO_INVIO_RELEX, 'days'),
    };

    if (code === 5421) {
        information.storeCompletionFrom = moment('2024-08-05');
        information.lastHqCompletionDate = moment('2024-08-04');
        information.lastOpecomUpdateDate = moment('2024-08-01');
    }

    return information;
};
