import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AutoComplete, Form, Input, InputNumber, Select } from 'antd'
import api from 'Common/api'

function SimulazioneSegnapostoForm({ defaultValues, valueChanged }, ref) {
    const [formValue, setFormValue] = useState({ ...defaultValues });
    const [dataSource, setDataSource] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);

    useEffect(() => {
      api.data.suppliers().then(res => {
        setSuppliers(res.data);
      }).catch(console.error);
    }, []);

    const handleChange = (v, k) => {
        const newValue = { ...formValue, [k]: v };
        setFormValue(newValue);

        if (valueChanged) valueChanged({
          ...newValue,
          txTva: (newValue.txTva || 0) / 100,
        });
    };

    const onSupplierSearch = searchText => {
      setFilteredSuppliers(suppliers.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase())).map(item => `${item.id} - ${item.name}`).slice(0, 10));
    };

    useImperativeHandle(ref, () => ({
      getValues: () => formValue,
      reset: () => setFormValue({ ...defaultValues })
    }));

    return (
        <Form style={{ marginTop: 25 }} layout="vertical">
            <Form.Item label="EAN">
                <Input style={{ width: '100% '}} onChange={e => handleChange(e.target.value, 'codEan')} />
            </Form.Item>
            <Form.Item label="Descrizione">
                <Input style={{ width: '100% '}} onChange={e => handleChange(e.target.value, 'libArt')} />
            </Form.Item>
            <Form.Item label="Gamma">
                <Select style={{ width: '100% '}} onChange={e => handleChange(e, 'codGamartDossier')}>
                    {['A', 'B', 'C', 'D', 'L', 'R', 'S'].map(item => <Select.Option value={item}>{item}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Precotop">
                <Select style={{ width: '100% '}} onChange={e => handleChange(e, 'numTypreapproprecoDossier')}>
                    {[1, 2].map(item => <Select.Option value={item}>{item}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Fornitore">
                <AutoComplete dataSource={filteredSuppliers} onSearch={onSupplierSearch} style={{ width: '100% '}} onChange={e => handleChange(e, 'supplier')} />
            </Form.Item>
            <Form.Item label="Prezzo Acquisto">
                <InputNumber style={{ width: '100% '}} onChange={e => handleChange(e, 'prxAchfouDossier')} />
            </Form.Item>
            <Form.Item label="Prezzo Vendita">
                <InputNumber style={{ width: '100% '}} onChange={e => handleChange(e, 'prxVtecnsDossier')} />
            </Form.Item>
            <Form.Item label="Quantità Vendita">
                <InputNumber style={{ width: '100% '}} onChange={e => handleChange(e, 'qteVteDossier')} />
            </Form.Item>
            <Form.Item label="IVA">
                <InputNumber style={{ width: '100% '}} onChange={e => handleChange(e, 'txTva')} />
            </Form.Item>
        </Form>
    );
}

export default forwardRef(SimulazioneSegnapostoForm);
