import React from 'react'
import { Modal, Button, Table, message } from 'antd'

/**
 * Component description
 */
class NomenclatureNotification extends React.Component {
  copyToClipboard = e => {
    navigator.clipboard
      .writeText(
        this.props.items
          .map(
            i =>
              `${i.idDepartment}\t${i.idSubDepartment}\t${i.idType}\t${i.idSubType}\t${i.name}`
          )
          .join('\n')
      )
      .then(() => message.success('Testo copiato'))
  }

  render() {
    return (
      <Modal
        title="Livelli di nomenclatura non associati"
        visible={this.props.visible}
        closable={false}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={this.props.onCancel}>
            Chiudi
          </Button>,
          this.props.currentPath !== '/' && (
            <Button key="submit" type="primary" onClick={this.props.onOk}>
              Vai a gestione famiglie
            </Button>
          ),
        ]}>
        <p>
          Ci sono {this.props.items.length} livelli non associati ad alcuna
          famiglia merch:
        </p>
        <Table
          style={{ height: '200px', overflow: 'scroll', overflowX: 'hidden' }}
          pagination={false}
          showHeader={false}
          size="small"
          dataSource={this.props.items}
          columns={[
            { dataIndex: 'idDepartment' },
            { dataIndex: 'idSubDepartment' },
            { dataIndex: 'idType' },
            { dataIndex: 'idSubType' },
            { dataIndex: 'name' },
          ]}
          rowKey={r =>
            `${r.idDepartment}${r.idSubDepartment}${r.idType}${r.idSubType}`
          }></Table>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}>
          <Button size="small" icon="copy" onClick={this.copyToClipboard}>
            Copia
          </Button>
        </div>
      </Modal>
    )
  }
}

export default NomenclatureNotification
