import React from 'react';
import { Alert, Col, Row } from "antd";
import {
    CardInformationContainer,
    CardInformationItem,
    PaginationCol, PaginationRow, StyledText
} from "Components/SearchDossierResults/styled";
import { StyledButton, StyledPagination, StyledCard } from "Common/styled";
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import AppAccess from "Common/appAccess";
import { colors, dossierStates, dossierTypes } from 'Common/constants'
import { formatDate, getDossierTitle } from 'Common/utils';
import * as moment from 'moment';
import { ROLE_CP } from 'Common/constants/global';

/**
 * Component to show results after searching for a dossier
 * @reactProps {!array} items - list of items to show
 * @reactProps {Function} onViewClick - callback method when clicking view button -> item, item.id
 * @reactProps {Function} onPageChange - callback method when page changes -> page, pageSize
 * @reactProps {!totalItems} totalItems - count of total items provided by backend
 */
class SearchDossierResults extends React.PureComponent {
    state = {
        updated: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.items !== this.props.items && !this.state.updated) {
            this.setState({ updated: true });
        }
    }

    /**
     * View click handler
     * @param {object} item
     * @param {string|number} key
     */
    handleViewClick = (item, key) => {
        if (_.isFunction(this.props.onViewClick)) {
            this.props.onViewClick(item, key);
        }
    };

    /**
     * Generate title from a dossier
     * @param item
     * @returns {string}
     */
    getTitleFromItem = (item) => {
        return <StyledText>{item.type}-{item.year}-{_.padStart(item.department, 2, '0')}-{item.description} {item.idStore == null ? '' : '(Store ' + item.idStore + ')'}</StyledText>;
    };

    /**
     * Handle Modifica button for a dossier, based on statoDossier
     * @param item
     * @returns {string}
     */
    getModificaButton = (item) => {
        return (
            <StyledButton
                type="primary"
                onClick={(e) => this.handleViewClick(item, item.id)}>
                {AppAccess.isAuthorized('edit_dossier', { stato: item.statoDossier, type: item.type, installationTo: item.installationTo, storeId: item.idStore }) ? 'Tratta' : 'Visualizza'}
            </StyledButton>
        );
    }

    /**
     * Page change handler
     * @param {number} page
     * @param {number} pageSize
     */
    handlePageChange = (page, pageSize) => {
        // Invoke parent onPageChange callback
        _.invoke(this.props, 'onPageChange', page, pageSize);
    };

    /**
     * Get department name by id
     * @param {number} id
     * @returns {string|null}
     */
    getDepartmentNameById = (id) => {
        if (id === 0) {
            return 'TUTTI';
        }

        const departments = _.get(this.props, 'departments', []);

        for (const department of departments) {
            if (department.id === id) {
                return _.padStart(department.id, 2, '0') + '-' + department.label;
            }
        }

        return null;
    };

    /**
     * Render method
     * @returns {*}
     */
    render() {
        /** @type {Array} items - List of data to show */
        const items = _.get(this.props, 'items', []);
        const { updated } = this.state;
        const { user } = this.props;

        if (items.length === 0 && updated) {
            return (
                <div>
                    <Row gutter={[0, 16]}>
                        <Col xs={{ span: 22, offset: 1 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 12, offset: 6 }}>
                            <Alert message="Non ci sono risultati per la ricerca" type="info" />
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>
                <Row gutter={[0, 16]}>
                    {items.map(item => (
                        <Col key={item.id} xs={{ span: 22, offset: 1 }} xl={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                            <StyledCard bordered={false} title={<StyledText>{getDossierTitle(item, this.props.stores)} [{item.id}]</StyledText>} extra={this.getModificaButton(item)}>
                                <CardInformationContainer>
                                    <CardInformationItem>
                                        <label>Anno</label>
                                        <span>{item.year}</span>
                                    </CardInformationItem>
                                    <CardInformationItem>
                                        <label>Reparto</label>
                                        <span>{this.getDepartmentNameById(item.department)}</span>
                                    </CardInformationItem>
                                    <CardInformationItem>
                                        <label>Famiglia</label>
                                        <span>{item.idFamily} - {item.family}</span>
                                    </CardInformationItem>
                                    <CardInformationItem>
                                        <label>Tipo</label>
                                        <span>{item.type}</span>
                                    </CardInformationItem>
                                    <CardInformationItem>
                                        <label>Stato</label>
                                        <span>{dossierStates.filter(state => state.key === item.statoDossier)[0].label}</span>
                                    </CardInformationItem>
                                    {item.type === 'PL' && <CardInformationItem>
                                        <label>Data arrivo merce</label>
                                        <span>{`${formatDate(item.merchArrivalDate, 'YYYY-MM-DD', 'DD/MM/YYYY')}`}</span>
                                    </CardInformationItem>}
                                    <CardInformationItem>
                                        <label>{dossierTypes.filter(type => type.key === item.type)[0].installationLabel}</label>
                                        <span>{`${formatDate(item.installationFrom, 'YYYY-MM-DD', 'DD/MM/YYYY')} ~ ${formatDate(item.installationTo, 'YYYY-MM-DD', 'DD/MM/YYYY')}`}</span>
                                    </CardInformationItem>
                                    {item.type !== 'PL' && <CardInformationItem label="Periodo completamento negozio">
                                        <label>Periodo completamento negozio</label>
                                        <span className={moment(item.storeCompletionFrom).clone().subtract(1, 'days').isSameOrAfter(moment(), 'day') && moment(item.storeCompletionFrom).clone().subtract(1, 'days').diff(moment(), 'days') <= 5 ? 'highlighted' : null}>{formatDate(item.storeCompletionFrom, 'YYYY-MM-DD', 'DD/MM/YYYY')}</span>
                                        <span style={{margin: 0, whiteSpace: 'pre'}}> ~ </span>
                                        <span className={moment(item.storeCompletionTo).isSameOrAfter(moment(), 'day') && moment(item.storeCompletionTo).diff(moment(), 'days') <= 5 ? 'highlighted' : null} style={{marginLeft: 0}}>{formatDate(item.storeCompletionTo, 'YYYY-MM-DD', 'DD/MM/YYYY')}</span>
                                    </CardInformationItem>}
                                    {item.idStore && ['NG', 'RG', 'ST'].includes(item.type) && <CardInformationItem>
                                        <label>Data impianto negozio</label>
                                        <span>{`${formatDate(item.installationDate, 'YYYY-MM-DD', 'DD/MM/YYYY')}`}</span>
                                    </CardInformationItem>}
                                    {!item.idStore && item.type === 'ST' && <CardInformationItem>
                                        <label>Data massima impianto</label>
                                        <span>{`${formatDate(item.maxInstallationDate, 'YYYY-MM-DD', 'DD/MM/YYYY')}`}</span>
                                    </CardInformationItem>}
                                    {/* <CardInformationItem>
                                        <label>Fine completamento sede</label>
                                        <span style={{color: moment(item.storeCompletionFrom).clone().subtract(1, 'days').isSameOrAfter(moment(), 'day') && moment(item.storeCompletionFrom).clone().subtract(1, 'days').diff(moment(), 'days') <= 5 ? colors.red : 'inherit'}}>{moment(item.storeCompletionFrom).clone().subtract(1, 'days').format('DD/MM/YYYY')}</span>
                                    </CardInformationItem>
                                    <CardInformationItem>
                                        <label>Fine completamento negozio</label>
                                        <span style={{color: moment(item.storeCompletionTo).isSameOrAfter(moment(), 'day') && moment(item.storeCompletionTo).diff(moment(), 'days') <= 5 ? colors.red : 'inherit'}}>{moment(item.storeCompletionTo).format('DD/MM/YYYY')}</span>
                                    </CardInformationItem> */}
                                </CardInformationContainer>
                            </StyledCard>
                        </Col>
                    ))}
                </Row>
                <PaginationRow>
                    <PaginationCol xs={{ span: 22, offset: 1 }} lg={{ span: 14, offset: 5 }}>
                        <StyledPagination onChange={(page, pageSize) => this.handlePageChange(page, pageSize)} total={_.get(this.props, 'totalItems', 0)} pageSize={10} showLessItems={true} hideOnSinglePage={true} current={this.props.currentPage} />
                    </PaginationCol>
                </PaginationRow>
            </div>
        )
    }
}

SearchDossierResults.propTypes = {
    items: PropTypes.array,
    totalItems: PropTypes.number,
    onViewClick: PropTypes.func,
    onPageChange: PropTypes.func,
    departments: PropTypes.array,
    user: PropTypes.object
};

export default SearchDossierResults;
