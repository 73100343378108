import React, { useEffect, useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout';
import styles from './Simulazione.module.scss';
import { StyledH1 } from 'Common/styled'
import { Button, Card, Col, List, Row } from 'antd'
import SimulazioneFilters from 'Components/SimulazioneFilters/SimulazioneFilters'
import api from 'Common/api'
import { agNumberFormatter, agPercentageFormatter, getFromSession } from 'Common/utils'
import SimulazioneSearchForm from 'Components/SimulazioneSearchForm/SimulazioneSearchForm'
import { css } from '@emotion/css'
import { Link } from 'react-router-dom'
import SimulationCard from 'Components/ModernCard/SimulationCard.js'
import SimulazioneUtils from 'Common/simulazioneUtils'

export default function SimulazioneList () {

  const [filtersValue, setFiltersValue] = useState({});
  const [simulations, setSimlations] = useState([]);
  const [simulationStatistics, setSimulationStatistics] = useState({});
  const [collapseFilters, setCollapseFilters] = useState(false);

  useEffect(() => {
    const promises = [];
    for (const simulation of simulations) {
      promises.push(api.simulazione.getProducts(simulation.id));
    }

    Promise.all(promises).then(results => {
      const updatedSimulationStatistics = {};

      for (let i = 0; i < simulations.length; i++) {
        updatedSimulationStatistics[simulations[i].id] = SimulazioneUtils.getCalculatedStatistics(results[i].data);
      }

      setSimulationStatistics(updatedSimulationStatistics);

    });
  }, [simulations]);

  const onFiltersValueChange = value => setFiltersValue({ ...value });

  const handleSearch = () => {
    const params = {
      from: filtersValue.dateFrom ? filtersValue.dateFrom.format('YYYY-MM-DD') : null,
      to: filtersValue.dateTo ? filtersValue.dateTo.format('YYYY-MM-DD') : null,
      title: filtersValue.title,
      department: filtersValue.departmentId,
      subdepartment: filtersValue.subDepartmentId,
      category: filtersValue.categoryId,
      subcategory: filtersValue.subCategoryId,
      family: filtersValue.familyId,
      id: filtersValue.simulationId,
      published: filtersValue.published,
    };

    api.simulazione.getSimulationsByLoggedUser(params).then(({ data }) => {
      setSimlations(data);
    }).catch(console.error);
  }

  const toggleCollapseFilters = () => {
    setCollapseFilters(!collapseFilters);
  };

  const getSearchForm = () => {
    return (
      <>
        <Row type="flex" justify="center" align="center" style={{ marginBottom: 50 }}>
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                icon="left"
                onClick={() => window.location.href = '/simulazione'}>
                Indietro
              </Button>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <StyledH1 className={styles.title}>Simulazione Nuove Gamme</StyledH1>
                <h3 className={styles.subtitle} style={{ marginBottom: 0 }}>Definisci il perimetro d'azione per visualizzare lo storico</h3>
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="center">
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <Card className={styles.filtersCard}>
              <div className={css`display: relative; overflow: hidden;`} style={{ height: collapseFilters ? 0 : undefined }}>
                <SimulazioneSearchForm onChange={onFiltersValueChange}/>
              </div>
              <div className={styles.buttonsBar} style={{ marginTop: collapseFilters ? 0 : undefined }}>
                <Button type="primary" onClick={handleSearch} icon="search" style={{ marginRight: 10 }}>Cerca</Button>
                <Button icon={collapseFilters ? 'down' : 'up'} onClick={toggleCollapseFilters} />
              </div>
            </Card>
          </Col>
        </Row>
      </>
    )
  };

  const handleViewClick = id => {
    window.location.href = '/simulazione/detail/' + id;
  }

  const getStatisticsForSimulation = simulationId => {
    const statistics = {
      progCa: 0,
      reddPerc: 0,
      mge: 0,
      mgPerc: 0
    };

    if (simulationStatistics.hasOwnProperty(simulationId)) {
      statistics.progCa = agPercentageFormatter({ value: simulationStatistics[simulationId].progCaDossier });
      statistics.reddPerc = agPercentageFormatter({ value: simulationStatistics[simulationId].percReddDossier });
      statistics.mge = agNumberFormatter({ value: simulationStatistics[simulationId].mrgDossier });
      statistics.mgPerc = agPercentageFormatter({ value: simulationStatistics[simulationId].mrg2caDossier });
    }

    return statistics;
  }

  const getResultsContent = () => {
    return (
      <>
        <Row type="flex" justify="center" align="center" style={{ marginBottom: 50, marginTop: 50 }}>
          <Col xs={22} md={22} xl={22} xxl={22} className={styles.cardCol}>
            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={simulations}
              pagination={{
                pageSize: 10,
              }}
              renderItem={item => (
                <List.Item>
                  <SimulationCard
                    simulationId={item.id}
                    title={item.title}
                    tagLabel={SimulazioneUtils.getTranslationForState(item.state)}
                    tagColor={SimulazioneUtils.getTranslationColorForState(item.state)}
                    statistics={getStatisticsForSimulation(item.id)}
                  />
                </List.Item>
              )}
            >
            </List>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="simulazione">
      <div className={styles.root}>
        {getSearchForm()}
        {getResultsContent()}
      </div>
    </MainLayout>
  )
}
