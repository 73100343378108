import React, { useState } from 'react'
import { steps } from 'Containers/Home/constants'
import MainLayout from 'Hoc/MainLayout';
import styles from './Simulazione.module.scss';
import { StyledH1 } from 'Common/styled';
import { Button, Card, Col, Row } from 'antd';
import { Redirect } from 'react-router-dom'

const PAGES = {
  'SIMULATIONS': 'list',
  'HISTORY': 'storico',
  'CREATE': 'crea'
};

export default function Simulazione (params) {

  const [redirectTo, setRedirectTo] = useState(null);

  const navigateTo = view => {
    setRedirectTo(`/simulazione/${view}`);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <MainLayout
      tourSteps={steps}
      isTourAvailable={false}
      isTourOpen={false}
      defaultCurrentPage="simulazione">
      <div className={styles.root}>
        <StyledH1 className={`${styles.title} ${styles.titleWithMargin}`}>Simulazione Nuove Gamme</StyledH1>
        <Row type="flex" justify="center" align="center">
          <Col xs={22} md={6} xl={6} xxl={4} className={styles.cardCol}>
            <Card className={styles.card}>
              <div className={styles.cardBody}>
                <h3>Le mie simulazioni</h3>
                <Button type="primary" onClick={() => navigateTo(PAGES.SIMULATIONS)}>ACCEDI</Button>
                <img src="/assets/img/sng-simulazioni.svg" />
              </div>
            </Card>
          </Col>
          <Col xs={22} md={6} xl={6} xxl={4} className={styles.cardCol}>
            <Card className={styles.card}>
              <div className={styles.cardBody}>
                <h3>Dati storico</h3>
                <Button type="primary" onClick={() => navigateTo(PAGES.HISTORY)}>ACCEDI</Button>
                <img src="/assets/img/sng-storico.svg" />
              </div>
            </Card>
          </Col>
          <Col xs={22} md={6} xl={6} xxl={4} className={styles.cardCol}>
            <Card className={styles.card}>
              <div className={styles.cardBody}>
                <h3>Nuova simulazione</h3>
                <Button type="primary" onClick={() => navigateTo(PAGES.CREATE)}>CREA</Button>
                <img src="/assets/img/sng-simulazione.svg" />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </MainLayout>
  )
};
