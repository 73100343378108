import * as actionTypes from '../action-types'
import api from 'Common/api'
import { handleServerSuccess, handleServerError } from 'Common/utils'

export const editFamily = family => {
  return { type: actionTypes.EDIT_FAMILY, payload: family }
}

export const saveFamily = family => {
  return async dispatch => {
    try {
      const res = await api.families.post(family)
      dispatch({
        type: actionTypes.SAVE_FAMILY,
        payload: res.data
      })
      handleServerSuccess()
    } catch (err) {
      handleServerError(err)
      throw new Error()
    }
  }
}

export const fetchFamily = id => {
  return async dispatch => {
    try {
      const res = await api.families.getOne(id)
      dispatch({
        type: actionTypes.FETCH_FAMILY,
        payload: res.data[0]
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}

export const editDossier = dossier => {
  return { type: actionTypes.EDIT_DOSSIER, payload: dossier }
}

export const saveDossier = dossier => {
  return async dispatch => {
    try {
      const res = await api.dossiers.post(dossier)
      // Remove year to avoid inconsistencies
      const { year, ...dossierWithoutYear } = res.data
      dispatch({
        type: actionTypes.SAVE_DOSSIER,
        payload: dossierWithoutYear
      })
      handleServerSuccess()
    } catch (err) {
      handleServerError(err)
      throw new Error()
    }
  }
}

export const fetchDossier = id => {
  return async dispatch => {
    try {
      const res = await api.dossiers.getOne(id)
      // Remove year to avoid inconsistencies
      const { year, ...dossier } = res.data.elements[0]
      dispatch({
        type: actionTypes.FETCH_DOSSIER,
        payload: dossier
      })
    } catch (err) {
      handleServerError(err)
    }
  }
}
