import React from 'react'
import { StyledButton } from 'Common/styled'
import { StyledFormItem } from './styled'
import { Form, Modal, DatePicker, Input, Switch } from 'antd'
import { parseDate, daysBetween, daysFromToday, formatDate } from 'Common/utils'
import { dossierTypes, LM_EMAIL_SUFFIX, LM_EMAIL_PREFIX_REGEX } from 'Common/constants'
import * as _ from 'lodash';
import { ROLE_CP, ROLE_CS, ROLE_CPXS } from 'Common/constants/global'
import moment from "moment";
import AppAccess from 'Common/appAccess'

export const EditDossierHeaderModal = Form.create({
  name: 'edit_dossier_header_modal',
  mapPropsToFields(props) {
    return {
      installation: Form.createFormField({
        value: [
          parseDate(props.dossierHeader.installationFrom),
          parseDate(props.dossierHeader.installationTo)
        ]
      }),
      storeCompletion: Form.createFormField({
        value: [
          parseDate(props.dossierHeader.storeCompletionFrom),
          parseDate(props.dossierHeader.storeCompletionTo)
        ]
      }),
      installationDate: Form.createFormField({
        value: parseDate(props.dossierHeader.installationDate)
      }),
      maxInstallationDate: Form.createFormField({
        value: parseDate(props.dossierHeader.maxInstallationDate)
      }),
      merchArrivalDate: Form.createFormField({
          value: parseDate(props.dossierHeader.merchArrivalDate)
      }),
      referenceMerch: Form.createFormField({
        value: props.dossierHeader.referenceMerch ? props.dossierHeader.referenceMerch.slice(0, props.dossierHeader.referenceMerch.indexOf('@')) : null
      }),
      installationTo: Form.createFormField({
        value: parseDate(props.dossierHeader.installationTo)
      }),
      clusterGarden: Form.createFormField({
        value: props.dossierHeader.clusterGarden === true
      })
    }
  }
})(
  class extends React.Component {
    validatePeriod = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const [from, to] = value
      return daysBetween(from, to) > 0
    }

    validateFutureDates = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const from = value[0]
      return daysFromToday(from) >= 0
    }

    validateInstallation = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const from = value[0]
      return daysFromToday(from) >= 37
    }

    validateStoreCompletion = (rule, value) => {
      const installationPeriod = this.props.form.getFieldValue('installation')
      if (
        !value ||
        value.length === 0 ||
        !installationPeriod ||
        installationPeriod.length === 0 ||
        !this.validatePeriod(null, value)
      ) {
        return true
      }
      const storeCompletionTo = value[1]
      const installationFrom = installationPeriod[0]
      return daysBetween(storeCompletionTo, installationFrom) >= 30
    }

    validateInstallationDate = (rule, value) => {
      const storeCompletionTo = this.props.form.getFieldValue('storeCompletion')[1]
      const installationPeriod = this.props.form.getFieldValue('installation')
      return value.isBetween(storeCompletionTo, installationPeriod[1], 'day', '[]')
    }

    validateMaxInstallationDate = (rule, value) => {
      const storeCompletionTo = this.props.form.getFieldValue('storeCompletion')[1]
      const installationTo = this.props.form.getFieldValue('installation')[1]
      return value.isBetween(storeCompletionTo, installationTo, 'day', '(]')
    }

    render() {
      const {
        visible,
        onCancel,
        onSave,
        form,
        isSavingHeader,
        dossierHeader,
        user
      } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          title="Modifica testata dossier"
          onCancel={onCancel}
          maskClosable={false}
          //   bodyStyle={{ display: 'flex', justifyContent: 'center' }}
          footer={[
            <StyledButton
              key="submit"
              type="primary"
              onClick={onSave}
              loading={isSavingHeader}>
              Salva
            </StyledButton>
          ]}>
          <Form layout="vertical">
            {dossierHeader.type === 'PL' && dossierHeader.statoDossier === 'CR' && (
                <StyledFormItem
                    label="Data arrivo merce"
                    colon={false}>
                    {getFieldDecorator('merchArrivalDate', {
                        rules: [
                            { required: true, message: 'Inserisci una data' }
                        ]
                    })(
                        <DatePicker
                            format="DD/MM/YYYY"
                            placeholder={['Seleziona una data']}
                            allowClear={false}
                            disabledDate={currentDate => {
                              if (currentDate.isBefore(moment().add(30, 'day'), 'day')) {
                                return true;
                              }
    
                              if (currentDate.isSameOrAfter(form.getFieldValue('installation')[0], 'day')) {
                                return true;
                              }
    
                              return false;
                            }}
                        />
                    )}
                </StyledFormItem>
            )}
            {dossierHeader.type !== 'PL' || (dossierHeader.type === 'PL' && dossierHeader.statoDossier === 'CR') && (
              <StyledFormItem
                label={
                  dossierTypes.filter(type => type.key === dossierHeader.type)[0]
                    .installationLabel
                }
                colon={false}>
                {(dossierHeader.type !== 'PL' && ['CR', 'R1'].includes(dossierHeader.statoDossier)) || dossierHeader.type === 'PL' ? (
                  getFieldDecorator('installation', {
                    rules: [
                      { required: true, message: 'Inserisci un periodo' },
                      {
                        validator: this.validatePeriod,
                        message: 'Il periodo deve essere di almeno 2 gg. '
                      }
                    ].filter((item, index) => !(dossierHeader.type === 'PL' && index > 1))
                  })(
                    <DatePicker.RangePicker
                      allowClear={false}
                      format="DD/MM/YYYY"
                      placeholder={["Data d'inizio", 'Data di fine']}
                      disabledDate={currentDate => {
                        if (dossierHeader.type !== 'PL') {
                          return currentDate.isBefore(this.props.form.getFieldValue('storeCompletion')[1].clone().add(37, 'days'), 'day')
                        }    

                        if (dossierHeader.type === 'PL' && currentDate.isSameOrBefore(form.getFieldValue('merchArrivalDate'), 'day')) {
                          return true;
                        }

                        return false;
      
                      }}
                    />
                  )
                ) : (
                  <span className="ant-form-text">{`${formatDate(
                    dossierHeader.installationFrom,
                    'YYYY-MM-DD',
                    'DD/MM/YYYY'
                  )} ~ ${formatDate(
                    dossierHeader.installationTo,
                    'YYYY-MM-DD',
                    'DD/MM/YYYY'
                  )}`}</span>
                )}
              </StyledFormItem>
            )}
            {dossierHeader.type !== 'PL' && (
              <StyledFormItem
                label="Periodo completamento negozio"
                colon={false}>
                {['CR', 'R1'].includes(dossierHeader.statoDossier) ? (
                  getFieldDecorator('storeCompletion', {
                    rules: [
                      { required: true, message: 'Inserisci un periodo' },
                      {
                        validator: this.validatePeriod,
                        message: 'Il periodo deve essere di almeno 2 gg'
                      }
                    ]
                  })(
                    <DatePicker.RangePicker
                      allowClear={false}
                      format="DD/MM/YYYY"
                      placeholder={["Data d'inizio", 'Data di fine']}
                      disabled={!this.props.form.getFieldValue('installation') || this.props.form.getFieldValue('installation').length === 0}
                      disabledDate={currentDate => {
                        if (currentDate.isBefore(moment().add(1, 'days'), 'day')) {
                          return true
                        }

                        if (currentDate.isAfter(this.props.form.getFieldValue('installation')[0].clone().subtract(37, 'days'), 'day')) {
                          return true
                        }
                        return false;
                      }}
                    />
                  )
                ) : (
                  <span className="ant-form-text">{`${formatDate(
                    dossierHeader.storeCompletionFrom,
                    'YYYY-MM-DD',
                    'DD/MM/YYYY'
                  )} ~ ${formatDate(
                    dossierHeader.storeCompletionTo,
                    'YYYY-MM-DD',
                    'DD/MM/YYYY'
                  )}`}</span>
                )}
              </StyledFormItem>
            )}
            {(['NG', 'RG', 'XS'].includes(dossierHeader.type) || (dossierHeader.type === 'ST' && user.role === ROLE_CS)) && (
              <StyledFormItem
                label={[ROLE_CP, ROLE_CPXS].includes(user.role) ? "Data suggerita inizio impianto" : "Data impianto negozio"}
                colon={false}>
                {getFieldDecorator('installationDate', {
                  rules: [
                    { required: true, message: 'Inserisci una data' },
                    {
                      validator: this.validateInstallationDate,
                      message: 'La data deve essere compresa nel periodo impianto'
                    }
                  ]
                })(
                  <DatePicker
                    allowClear={false}
                    format="DD/MM/YYYY"
                    placeholder={['Seleziona una data']}
                    disabledDate={currentDate => {
                      if (['NG', 'RG', 'XS'].includes(dossierHeader.type)) {
                        const installationPeriod = this.props.form.getFieldValue('installation')
                        return !currentDate.isBetween(installationPeriod[0], installationPeriod[1], 'day', '[]')
                      }

                      if (dossierHeader.type === 'ST') {
                        return !currentDate.isBetween(dossierHeader.storeCompletionTo, dossierHeader.maxInstallationDate, 'day', '(]')
                      }
                    }}
                  />
                )}
              </StyledFormItem>
            )}
            {dossierHeader.type === 'ST' && (
              <StyledFormItem
                label="Data massima impianto"
                colon={false}>
                {user.role === ROLE_CP ? (
                  getFieldDecorator('maxInstallationDate', {
                    rules: [
                      { required: true, message: 'Inserisci una data' },
                      {
                        validator: this.validateMaxInstallationDate,
                        message: 'La data deve essere compresa tra fine completamento negozio e fine stagione'
                      }
                    ]
                  })(
                    <DatePicker
                      allowClear={false}
                      format="DD/MM/YYYY"
                      placeholder={['Seleziona una data']}
                      disabled={user.role === 'MERCH'}
                      disabledDate={currentDate => {
                        const installationPeriod = this.props.form.getFieldValue('installation');
                        const storeCompletionPeriod = this.props.form.getFieldValue('storeCompletion');                
                        return !currentDate.isBetween(storeCompletionPeriod[1], installationPeriod[1], 'day', '(]')
                      }}
                    />
                  )
                ) : (
                  <span className="ant-form-text">{`${formatDate(
                    dossierHeader.maxInstallationDate,
                    'YYYY-MM-DD',
                    'DD/MM/YYYY'
                  )}`}</span>
                )}
              </StyledFormItem>
            )}
            {user.role === ROLE_CP && ['NG', 'RG', 'ST'].includes(dossierHeader.type) && (
              <StyledFormItem label="Merch di riferimento" colon={false}>
                {getFieldDecorator('referenceMerch', {
                  rules: [
                    { required: true, message: 'Inserisci un nome utente' },
                    {
                      pattern: LM_EMAIL_PREFIX_REGEX,
                      message: 'Inserisci un nome utente nel formato nome.cognome',
                    },
                  ],
                })(<Input addonAfter={LM_EMAIL_SUFFIX} allowClear />)}
              </StyledFormItem>
            )}
            {dossierHeader.type === 'PL' && dossierHeader.statoDossier === 'R2' && (
                <StyledFormItem
                    label="Data fine promo"
                    colon={false}>
                    {getFieldDecorator('installationTo', {
                        rules: [
                            { required: true, message: 'Inserisci una data' }
                        ]
                    })(
                        <DatePicker
                            format="DD/MM/YYYY"
                            placeholder={['Seleziona una data']}
                            allowClear={false}
                            disabledDate={currentDate => {
                              if (currentDate.isSameOrBefore(dossierHeader.installationFrom, 'day')) {
                                return true;
                              }

                              if (currentDate.isBefore(moment(), 'day')) {
                                return true;
                              }
    
                              return false;
                            }}
                        />
                    )}
                </StyledFormItem>
            )}
            {AppAccess.isAuthorized('edit_cluster_garden', {
              dossierType: dossierHeader.type,
              dossierState: dossierHeader.statoDossier,
              dossierStore: dossierHeader.idStore
            }) && (
              <StyledFormItem
                label="Includi cluster Garden"
                colon={false}>
                {getFieldDecorator('clusterGarden', {valuePropName: 'checked'})(
                  <Switch />
                )}
              </StyledFormItem>
            )}
          </Form>
        </Modal>
      )
    }
  }
)
