import React, { Component } from 'react'
import { StyledDiv, TitleBar, Title, StyledIcon, Content } from './styled'
import AddButton from 'Components/AddButton'
import { AddSubcategoryModal } from 'Components/AddSubcategoryModal'
import { EditSubcategoryModal } from 'Components/EditSubcategoryModal'
import SubcategoryCard from 'Components/SubcategoryCard'
import { Popconfirm } from 'antd'

class SubdepartmentRow extends Component {
  state = {
    isAddSubcategoryModalVisible: false,
    editedCategoryId: null,
    isEditSubcategoryModalVisible: false,
    isLoadingAddSubcategoryModal: false,
    isLoadingEditSubcategoryModal: false,
  }

  showAddSubcategoryModal = () => {
    this.setState({ isLoadingAddSubcategoryModal: true })
    this.props.onShowAddSubcategoryModal(this.props.subdepartment.id).then(() =>
      this.setState({
        isLoadingAddSubcategoryModal: false,
        isAddSubcategoryModalVisible: true
      })
    )
  }

  handleAddSubcategoryModalCancel = () => {
    this.setState({ isAddSubcategoryModalVisible: false })
  }

  handleSelectCategory = categoryId => {
    return this.props.onSelectCategory(this.props.subdepartment.id, categoryId)
  }

  handleAddSubcategoryModalOk = () => {
    const { form } = this.addSubcategoryModal.props
    form.validateFields((err, values) => {
      if (!err) {
        this.props.onAddSubcategoryModalOk(
          this.props.subdepartment.id,
          values.idSubCategory
        )
        form.resetFields()
        this.setState({ isAddSubcategoryModalVisible: false })
      }
    })
  }

  showEditSubcategoryModal = categoryId => {
    this.setState({
      editedCategoryId: categoryId,
      isLoadingEditSubcategoryModal: true
    });

    this.props
      .onShowEditSubcategoryModal(this.props.subdepartment.id, categoryId)
      .then(() =>
        this.setState({
          isLoadingEditSubcategoryModal: false,
          isEditSubcategoryModalVisible: true
        })
      );
  }

  handleEditSubcategoryModalCancel = () => {
    this.setState({
      editedCategoryId: null,
      isEditSubcategoryModalVisible: false
    })
  }

  handleEditSubcategoryModalOk = categoryId => {
    const { form } = this.editSubcategoryModal.props
    form.validateFields((err, values) => {
      if (!err) {
        this.props.onEditSubcategoryModalOk(
          this.props.subdepartment.id,
          categoryId,
          values.idSubCategory
        )
        form.resetFields()
        this.setState({
          editedCategoryId: null,
          isEditSubcategoryModalVisible: false
        })
      }
    })
  }

  handleDeleteCategory = categoryId => {
    this.props.onDeleteCategory(this.props.subdepartment.id, categoryId)
  }

  render() {
    const {
      subdepartment,
      categories,
      subcategories,
      familySubdepartment,
      onDeleteSubdepartment
    } = this.props
    const {
      isAddSubcategoryModalVisible,
      isEditSubcategoryModalVisible,
      editedCategoryId,
      isLoadingAddSubcategoryModal,
      isLoadingEditSubcategoryModal
    } = this.state
    return (
      <>
        <StyledDiv>
          <TitleBar>
            <Title>{`Sottoreparto: ${subdepartment && subdepartment.id}-${subdepartment && subdepartment.label}`}</Title>
            <Popconfirm
              title="Sei sicuro di voler eliminare il sottoreparto?"
              onConfirm={() => onDeleteSubdepartment(subdepartment.id)}
              okText="Sì"
              cancelText="No"
              placement="topRight"
              arrowPointAtCenter>
              <StyledIcon type="delete" />
            </Popconfirm>
          </TitleBar>
          <Content>
            <AddButton
              text="Aggiungi tipo"
              onClick={this.showAddSubcategoryModal}
              loading={isLoadingAddSubcategoryModal}
            />
            {familySubdepartment.categories.map(familyCategory => (
              <SubcategoryCard
                key={familyCategory.idCategory}
                category={familyCategory.idCategory}
                subcategories={familyCategory.subcategories}
                onEdit={this.showEditSubcategoryModal}
                onDelete={this.handleDeleteCategory}
                loading={
                  familyCategory.idCategory === editedCategoryId &&
                  isLoadingEditSubcategoryModal
                }
              />
            ))}
          </Content>
        </StyledDiv>
        <AddSubcategoryModal
          wrappedComponentRef={ref => {
            this.addSubcategoryModal = ref
          }}
          visible={isAddSubcategoryModalVisible}
          onCancel={this.handleAddSubcategoryModalCancel}
          onSelectCategory={this.handleSelectCategory}
          onOk={this.handleAddSubcategoryModalOk}
          // Show only categories that are not already in family
          categories={categories.filter(
            category =>
              !familySubdepartment.categories
                .map(category => category.idCategory)
                .includes(category.id)
          )}
          subcategories={subcategories}
        />
        {editedCategoryId &&
          categories.filter(category => category.id === editedCategoryId)
            .length !== 0 && (
            <EditSubcategoryModal
              wrappedComponentRef={ref => {
                this.editSubcategoryModal = ref
              }}
              visible={isEditSubcategoryModalVisible}
              onCancel={this.handleEditSubcategoryModalCancel}
              onOk={this.handleEditSubcategoryModalOk}
              category={
                categories.filter(
                  category => category.id === editedCategoryId
                )[0]
              }
              subcategories={subcategories}
              familySubcategories={
                familySubdepartment.categories.filter(
                  familyCategory =>
                    familyCategory.idCategory === editedCategoryId
                )[0].subcategories
              }
            />
          )}
      </>
    )
  }
}

export default SubdepartmentRow
