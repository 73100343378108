import React, { useCallback, useEffect, useState } from 'react'
import { AutoComplete, Modal } from 'antd'
import { DatePicker } from 'antd';
import styled from 'styled-components';
import moment from 'moment'
import 'moment/locale/it';
import api from 'Common/api'

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  '&:last-of-type': {
    margin-bottom: 0;
  }
`;

const FormInputLabel = styled.label`
    margin-bottom: 8px;
    text-transform: uppercase;
`;

const SimulazionePublishModal = ({ visible, onOk, onCancel, publishing }) => {
  const [installationPeriod, setInstallationPeriod] = useState([null, null]);
  const [suggestedStartDate, setSuggestedStartDate] = useState(null);
  const [storeCompletionPeriod, setStoreCompletionPeriod] = useState([null, null]);
  const [families, setFamilies] = useState([]);
  const [filteredFamilies, setFilteredFamilies] = useState([]);
  const [family, setFamily] = useState(null);

  useEffect(() => {
    api.families.getAll().then(({ data }) => {
      setFamilies(data);
    });
  }, []);

  useEffect(() => {
    setSuggestedStartDate(installationPeriod[0] ?? null);
    
    let storeCompletionFrom = null;
    let storeCompletionTo = null;
    
    if (installationPeriod[0]) {
      storeCompletionFrom = installationPeriod[0].clone().subtract(39, 'days');
      storeCompletionTo = installationPeriod[0].clone().subtract(37, 'days');
    }
    
    setStoreCompletionPeriod([storeCompletionFrom, storeCompletionTo]);
  }, [installationPeriod]);

  const handleFamilySearch = useCallback((searchText) => {
    if (searchText.length < 3) return;
    setFilteredFamilies(families.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase())).slice(0, 10));
  }, [families]);

  const isFormValid = installationPeriod[0] && installationPeriod[1] && suggestedStartDate && storeCompletionPeriod[0] && storeCompletionPeriod[1] && family;

  const handleOk = () => {
    if (!isFormValid) return;
    onOk({
      installationFrom: installationPeriod[0],
      installationTo: installationPeriod[1],
      suggestedStartDate: suggestedStartDate,
      storeCompletionFrom: storeCompletionPeriod[0],
      storeCompletionTo: storeCompletionPeriod[1],
      familyId: family
    });
  }

  return (
    <Modal
      title="Pubblica simulazione"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Conferma"
      cancelText="Annulla"
      okButtonProps={{
        disabled: !isFormValid || publishing,
      }}
    >
      <FormInputContainer>
        <FormInputLabel>Periodo impianto</FormInputLabel>
        <DatePicker.RangePicker
          format="DD/MM/YYYY"
          placeholder={["Data d'inizio", "Data fine"]}
          value={installationPeriod}
          onChange={(dates) => setInstallationPeriod(dates)}
          disabledDate={current => current.isBefore(moment().add(40, 'days'), 'day')}
        />
      </FormInputContainer>
      <FormInputContainer>
        <FormInputLabel>Data suggerita inizio impianto</FormInputLabel>
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Inserisci la data suggerita di inizio impianto"
          value={suggestedStartDate}
          onChange={(date) => setSuggestedStartDate(date)}
          disabledDate={current => !installationPeriod[0] || !installationPeriod[1] || !current.isBetween(installationPeriod[0], installationPeriod[1], 'day', '[]')}
        />
      </FormInputContainer>
      <FormInputContainer>
        <FormInputLabel>Periodo completamento negozio</FormInputLabel>
        <DatePicker.RangePicker
          format="DD/MM/YYYY"
          placeholder={["Data d'inizio", "Data fine"]}
          value={storeCompletionPeriod}
          onChange={(dates) => setStoreCompletionPeriod(dates)}
          disabledDate={current =>
            !installationPeriod[0] || !installationPeriod[1] ||
            current.isBefore(moment().add(1, 'day'), 'day') ||
            current.isAfter(installationPeriod[0].clone().subtract(37, 'days'))}
        />
      </FormInputContainer>
      <FormInputContainer>
        <FormInputLabel>Famiglia merchandising</FormInputLabel>
        <AutoComplete
          onSearch={handleFamilySearch}
          onSelect={(value) => setFamily(value)}
          placeholder="Seleziona la famiglia merchandising..."
        >
          {filteredFamilies.map(item => (
            <AutoComplete.Option key={item.id} value={item.idFamily.toString()}>{item.name}</AutoComplete.Option>
          ))}
        </AutoComplete>
      </FormInputContainer>
    </Modal>
  )
};

export default SimulazionePublishModal;
