import React, { Component } from 'react'
import { Icon } from 'antd'
import { colors } from 'Common/constants'

export default class AgTableErrorAndWarningPanel extends Component {
  state = { errors: 0, warnings: 0 }

  componentDidMount = () => {
    this.props.api.addEventListener('firstDataRendered', this.updateCounters)
    this.props.api.addEventListener('cellValueChanged', this.updateCounters)
  }

  updateCounters = () => {
    const products = this.props.api
      .getModel()
      .rowsToDisplay.map(row => row.data)

    this.setState({
      errors: products.filter(product => product.errors.length > 0).length,
      warnings: products
        .map(product => this.props.getIncompleteProductWarnings(product))
        .filter(warnings => warnings.length > 0).length,
    })
  }

  render = () => {
    const { errors, warnings } = this.state

    return (
      <>
        {errors > 0 && (
          <>
            <Icon
              type="exclamation-circle"
              theme="filled"
              style={{ color: 'rgba(219, 94, 94, 1)' }}
            />
            <span>{errors}</span>
          </>
        )}
        {warnings > 0 && (
          <>
            <Icon
              type="warning"
              theme="filled"
              style={{ color: colors.yellow }}
            />
            <span>{warnings}</span>
          </>
        )}
      </>
    )
  }
}
