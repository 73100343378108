import React from 'react'
import { StyledButton } from 'Common/styled'
import { StyledFormItem } from './styled'
import { Form, Modal, DatePicker, Input, Select, Tooltip, Popconfirm } from 'antd'
import {
  daysBetween,
  daysFromToday,
  getYear,
  subtractDays,
} from 'Common/utils'
import {
  dossierTypes,
  LM_EMAIL_SUFFIX,
  LM_EMAIL_PREFIX_REGEX,
} from 'Common/constants'
import * as _ from 'lodash'
import moment from 'moment'
import { DATE_LOCALE } from 'Common/globals'

const { Option } = Select
const { RangePicker } = DatePicker

export const DuplicateDossierModal = Form.create({
  name: 'duplicate_dossier_modal',
})(
  class extends React.Component {
    state = {
      hasTypedDescription: false,
      hasPickedStoreCompletionPeriod: false,
      isPopconfirmVisible: false
    }

    validatePeriod = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const [from, to] = value
      return daysBetween(from, to) > 0
    }

    validateFutureDates = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const from = value[0]
      return daysFromToday(from) >= 0
    }

    validateInstallation = (rule, value) => {
      if (!value || value.length === 0) {
        return true
      }
      const from = value[0]
      return daysFromToday(from) >= 37
    }

    validateStoreCompletion = (rule, value) => {
      const installationPeriod = this.props.form.getFieldValue('installation')
      if (
        !value ||
        value.length === 0 ||
        !installationPeriod ||
        installationPeriod.length === 0 ||
        !this.validatePeriod(null, value)
      ) {
        return true
      }
      const storeCompletionTo = value[1]
      const installationFrom = installationPeriod[0]
      return daysBetween(storeCompletionTo, installationFrom) >= 30
    }

    validateInstallationDate = (rule, value) => {
      const installationPeriod = this.props.form.getFieldValue('installation')
      return value.isBetween(
        installationPeriod[0],
        installationPeriod[1],
        'day',
        '[]'
      )
    }

    validateMaxInstallationDate = (rule, value) => {
      const storeCompletionTo = this.props.form.getFieldValue(
        'storeCompletion'
      )[1]
      const installationTo = this.props.form.getFieldValue('installation')[1]
      return value.isBetween(storeCompletionTo, installationTo, 'day', '(]')
    }

    filterFamily = family => {
      const department = this.props.form.getFieldValue('department')
      if (department) {
        return family.idDepartment === department
      }
      return true
    }

    getProposedInstallationDateField = () => {
      const installationPeriod = this.props.form.getFieldValue('installation')
      const installationPeriodExists =
        !_.isNil(installationPeriod) && _.size(installationPeriod) === 2

      const DatePickerComponent = (
        <DatePicker
          disabled={!installationPeriodExists}
          className={'generic-datepicker'}
          locale={DATE_LOCALE}
          format="DD/MM/YYYY"
          inputReadOnly={true}
          onChange={this.handleChangeProposedInstallationDate}
          disabledDate={currentDate => {
            const installationPeriod = this.props.form.getFieldValue(
              'installation'
            )

            if (
              _.isNil(installationPeriod) ||
              _.size(installationPeriod) !== 2
            ) {
              return true
            }

            if (
              !currentDate.isBetween(
                installationPeriod[0],
                installationPeriod[1],
                'day',
                '[]'
              )
            ) {
              return true
            }

            return false
          }}
        />
      )

      if (installationPeriodExists) {
        return DatePickerComponent
      }

      return (
        <Tooltip title="Devi selezionare un periodo d'impianto">
          {DatePickerComponent}
        </Tooltip>
      )
    }

    handleChangeProposedInstallationDate = value => {}

    handleSelectFamily = value => {
      if (
        !this.props.form.getFieldValue('description') ||
        !this.state.hasTypedDescription
      ) {
        this.props.form.setFieldsValue({
          description: this.props.families.filter(
            family => family.idFamily === value
          )[0].name,
        })
      }
    }

    handleKeyUpDescription = event => {
      event.persist()
      if (event.target.value) {
        this.setState({ hasTypedDescription: true })
      } else {
        // If user clears field, reset flag
        this.setState({ hasTypedDescription: false })
      }
    }

    handleChangeInstallationPeriod = value => {
      if (value.length !== 0) {
        const installationFrom = value[0]
        const installationTo = value[1]

        let storeCompletionFrom = subtractDays(installationFrom, 67)
        if (storeCompletionFrom.isBefore(moment().add(1, 'days'), 'day')) {
          storeCompletionFrom = moment().add(1, 'days')
        }
        const storeCompletionTo = subtractDays(installationFrom, 37)

        let maxInstallationDate
        if (this.props.form.getFieldValue('type') === 'ST') {
          const installationPeriodLength = daysBetween(
            installationFrom,
            installationTo
          )
          if (installationPeriodLength >= 7) {
            maxInstallationDate = installationFrom.clone().add(7, 'days')
          } else {
            maxInstallationDate = installationFrom
              .clone()
              .add(installationPeriodLength, 'days')
          }
        }

        this.props.form.setFieldsValue({
          storeCompletion: [storeCompletionFrom, storeCompletionTo],
          installationDate: installationFrom,
          ...(maxInstallationDate && {
            maxInstallationDate: maxInstallationDate,
          }),
        })
      } else {
        this.props.form.setFieldsValue({
          storeCompletion: null,
          installationDate: null,
          maxInstallationDate: null,
        })
      }
    }

    handleChangeStoreCompletionPeriod = () => {
      this.setState({ hasPickedStoreCompletionPeriod: true })
    }

    getMaxInstallationDateField = () => {
      const installationPeriod = this.props.form.getFieldValue('installation')
      const storeCompletionPeriod = this.props.form.getFieldValue(
        'storeCompletion'
      )
      const installationPeriodExists =
        !_.isNil(installationPeriod) && _.size(installationPeriod) === 2
      const storeCompletionPeriodExists =
        !_.isNil(storeCompletionPeriod) && _.size(storeCompletionPeriod) === 2

      const DatePickerComponent = (
        <DatePicker
          disabled={!installationPeriodExists}
          className={'generic-datepicker'}
          locale={DATE_LOCALE}
          format="DD/MM/YYYY"
          inputReadOnly={true}
          disabledDate={currentDate => {
            return !currentDate.isBetween(
              storeCompletionPeriod[1],
              installationPeriod[1],
              'day',
              '(]'
            )
          }}
        />
      )

      if (installationPeriodExists && storeCompletionPeriodExists) {
        return DatePickerComponent
      }

      let msg
      if (!installationPeriodExists && !storeCompletionPeriodExists) {
        msg = 'Devi selezionare stagione e periodo completamento negozio'
      } else if (!installationPeriodExists) {
        msg = 'Devi selezionare una stagione'
      } else {
        msg = 'Devi selezionare un periodo completamento negozio'
      }

      return <Tooltip title={msg}>{DatePickerComponent}</Tooltip>
    }

    render() {
      const {
        visible,
        onCancel,
        onSave,
        form,
        isDuplicatingDossier,
        dossierHeader,
        user,
      } = this.props
      const { isPopconfirmVisible } = this.state
      const { getFieldDecorator } = form

      const availableDossierTypes = dossierTypes.filter(type =>
        type.canCreate.includes(user.role)
      )

      const type = form.getFieldValue('type') ? form.getFieldValue('type') : ''
      const year = form.getFieldValue('installation')
        ? `-${getYear(form.getFieldValue('installation')[0])}`
        : ''
      const department =
        form.getFieldValue('department') > -1
          ? `-${form.getFieldValue('department').toString().padStart(2, '0')}`
          : ''
      const description = form.getFieldValue('description')
        ? `-${form.getFieldValue('description')}`
        : ''

      return (
        <Modal
          visible={visible}
          title="Duplica dossier"
          onCancel={onCancel}
          maskClosable={false}
          afterClose={() => form.resetFields()}
          footer={[
            <Popconfirm
              title="L'operazione potrebbe richiedere qualche minuto"
              visible={isPopconfirmVisible}
              onVisibleChange={() => form.validateFields((err, values) => {
                if (!err) {
                  this.setState({isPopconfirmVisible: true})
                }
              })}
              onCancel={() => this.setState({isPopconfirmVisible: false})}
              onConfirm={() => {
                this.setState({isPopconfirmVisible: false})
                onSave(form.getFieldsValue())
              }}
              okText="OK"
              cancelText="Annulla">
              <StyledButton
                key="submit"
                type="primary"
                // onClick={onSave}
                loading={isDuplicatingDossier}>
                Duplica
              </StyledButton>
            </Popconfirm>,
          ]}>
          <Form layout="vertical">
            <StyledFormItem label="Tipo dossier" colon={false}>
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Scegli un tipo' }],
                initialValue:
                  availableDossierTypes.length === 1
                    ? availableDossierTypes[0].key
                    : null,
              })(
                <Select disabled={availableDossierTypes.length === 1}>
                  {availableDossierTypes.map(type => (
                    <Option
                      key={type.key}>{`${type.key}-${type.label}`}</Option>
                  ))}
                </Select>
              )}
            </StyledFormItem>
            <div
              style={{
                display: form.getFieldValue('type') ? 'block' : 'none',
              }}>
              <StyledFormItem label="Titolo" colon={false}>
                <span className="ant-form-text">{`${type}${year}${department}${description}`}</span>
              </StyledFormItem>
              <StyledFormItem label="Descrizione" colon={false}>
                {getFieldDecorator('description', {
                  rules: [
                    { required: true, message: 'Inserisci una descrizione' },
                  ],
                })(<Input onKeyUp={this.handleKeyUpDescription} allowClear />)}
              </StyledFormItem>
              {form.getFieldValue('type') === 'PL' && (
                <StyledFormItem label="Data arrivo merce" colon={false}>
                  {getFieldDecorator('merchArrivalDate', {
                    rules: [{ required: true, message: 'Inserisci una data' }],
                  })(
                    <DatePicker
                      className={'generic-datepicker'}
                      locale={DATE_LOCALE}
                      format="DD/MM/YYYY"
                      inputReadOnly={true}
                      onChange={() => {}}
                      allowClear={false}
                      disabledDate={currentDate => {
                        if (
                          currentDate.isBefore(moment().add(30, 'day'), 'day')
                        ) {
                          return true
                        }

                        if (
                          form.getFieldValue('installation') &&
                          currentDate.isSameOrAfter(
                            form.getFieldValue('installation')[0],
                            'day'
                          )
                        ) {
                          return true
                        }

                        return false
                      }}
                    />
                  )}
                </StyledFormItem>
              )}
              <StyledFormItem
                label={
                  form.getFieldValue('type')
                    ? dossierTypes.filter(
                        type => type.key === form.getFieldValue('type')
                      )[0].installationLabel
                    : 'Periodo impianto'
                }
                colon={false}>
                {getFieldDecorator('installation', {
                  rules: [
                    { required: true, message: 'Inserisci un periodo' },
                    {
                      validator: this.validatePeriod,
                      message: 'Il periodo deve essere di almeno 2 gg',
                    },
                  ].filter(
                    (item, index) =>
                      !(index > 0 && form.getFieldValue('type') === 'PL')
                  ),
                })(
                  <RangePicker
                    format="DD/MM/YYYY"
                    onChange={
                      form.getFieldValue('type') === 'PL'
                        ? () => {}
                        : this.handleChangeInstallationPeriod
                    }
                    placeholder={["Data d'inizio", 'Data di fine']}
                    allowClear={false}
                    disabledDate={currentDate => {
                      if (
                        form.getFieldValue('type') !== 'PL' &&
                        currentDate.isBefore(moment().add(39, 'days'), 'day')
                      ) {
                        return true
                      }

                      if (
                        form.getFieldValue('type') === 'PL' &&
                        currentDate.isSameOrBefore(
                          form.getFieldValue('merchArrivalDate'),
                          'day'
                        )
                      ) {
                        return true
                      }

                      if (
                        currentDate.isBetween(
                          moment(dossierHeader.installationFrom),
                          moment(dossierHeader.installationTo),
                          'day',
                          '[]'
                        )
                      ) {
                        return true
                      }

                      return false
                    }}
                  />
                )}
              </StyledFormItem>
              {!['ST', 'PL'].includes(form.getFieldValue('type')) && (
                <StyledFormItem
                  label="Data suggerita inizio impianto"
                  colon={false}>
                  {getFieldDecorator('installationDate', {
                    rules: [{ required: true, message: 'Inserisci una data' }],
                  })(this.getProposedInstallationDateField())}
                </StyledFormItem>
              )}
              {form.getFieldValue('type') === 'ST' && (
                <StyledFormItem label="Data massima impianto" colon={false}>
                  {getFieldDecorator('maxInstallationDate', {
                    rules: [{ required: true, message: 'Inserisci una data' }],
                  })(this.getMaxInstallationDateField())}
                </StyledFormItem>
              )}
              {form.getFieldValue('type') !== 'PL' && (
                <StyledFormItem
                  label="Periodo completamento negozio"
                  colon={false}>
                  {getFieldDecorator('storeCompletion', {
                    rules: [
                      { required: true, message: 'Inserisci un periodo' },
                      {
                        validator: this.validatePeriod,
                        message: 'Il periodo deve essere di almeno 2 gg',
                      },
                    ],
                  })(
                    <RangePicker
                      format="DD/MM/YYYY"
                      onChange={this.handleChangeStoreCompletionPeriod}
                      placeholder={["Data d'inizio", 'Data di fine']}
                      disabled={
                        !this.props.form.getFieldValue('installation') ||
                        this.props.form.getFieldValue('installation').length ===
                          0
                      }
                      disabledDate={currentDate => {
                        if (
                          currentDate.isBefore(moment().add(1, 'days'), 'day')
                        ) {
                          return true
                        }

                        if (
                          currentDate.isAfter(
                            this.props.form
                              .getFieldValue('installation')[0]
                              .clone()
                              .subtract(37, 'days'),
                            'day'
                          )
                        ) {
                          return true
                        }

                        return false
                      }}
                    />
                  )}
                </StyledFormItem>
              )}
              {['NG', 'RG', 'ST'].includes(form.getFieldValue('type')) && (
                <StyledFormItem label="Merch di riferimento" colon={false}>
                  {getFieldDecorator('referenceMerch', {
                    rules: [
                      { required: true, message: 'Inserisci un nome utente' },
                      {
                        pattern: LM_EMAIL_PREFIX_REGEX,
                        message:
                          'Inserisci un nome utente nel formato nome.cognome',
                      },
                    ],
                  })(<Input addonAfter={LM_EMAIL_SUFFIX} allowClear />)}
                </StyledFormItem>
              )}
            </div>
          </Form>
        </Modal>
      )
    }
  }
)
