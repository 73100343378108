import styled from 'styled-components'
import { colors } from 'Common/constants'
import { Form, Row, Col, Button } from 'antd'

export const StyledH1 = styled.h1`
  font-size: 1.7rem;
  color: ${colors.main};
  text-align: center;
`

export const StyledRow = styled(Row)`
  margin-bottom: 16px;
  .collapsed {
    height: 50px;
    overflow: hidden;
  }
`

export const StyledForm = styled(Form)`
  padding: 8px 16px 16px 16px !important;
  margin-bottom: 16px !important;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  .unclickable {
    pointer-events: none;
  }
`

export const StyledFormFake = styled.div`
  padding: 8px 16px 16px 16px !important;
  margin-bottom: 16px !important;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  .unclickable {
    pointer-events: none;
  }
`

export const StyledFormRow = styled(Row)`
  align-items: stretch;
`

export const StyledCol = styled(Col)`
  display: flex !important;
  align-items: flex-end;
`

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
  .ant-form-item-label {
    line-height: normal;
    padding-bottom: 4px;
  }
  .ant-form-item-label label {
    text-transform: uppercase;
    font-weight: 500;
    color: ${colors.grey4};
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`

export const StyledCollapse = styled(Button)`
  position: absolute !important;
  bottom: 0px;
  left: 50%;
  margin-left: -16px;
  z-index: 2;
`

export const CollapsedFormMask = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-shadow: inset -10px -10px 10px 0px white;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
`
