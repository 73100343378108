const REFERENCE_STATE = {
    AC: {key: 'AC', value: 'Attivo commercio'},
    ASI: {key: 'ASI', value: 'Attivo servizi interni'},
    ESUP: {key: 'ESUP', value: 'In soppressione'},
    INI: {key: 'INI', value: 'Inizializzato'},
    PRE: {key: 'PRE', value: 'Pre-inizializzato'},
    SUP: {key: 'SUP', value: 'Soppresso'},
    SUPP: {key: 'SUPP', value: 'Soppressione programmata'}
};

const DATE_LOCALE = {
    "lang": {
        "locale": "it_IT",
        "placeholder": "Seleziona data",
        "rangePlaceholder": ["Data inizio", "Data fine"],
        "today": "Oggi",
        "now": "Adesso",
        "backToToday": "Torna ad oggi",
        "ok": "Ok",
        "clear": "Annulla",
        "month": "Mese",
        "year": "Anno",
        "timeSelect": "Seleziona l'ora",
        "dateSelect": "Seleziona la data",
        "monthSelect": "Seleziona il mese",
        "yearSelect": "Seleziona l'anno",
        "decadeSelect": "Seleziona il decennio",
        "yearFormat": "YYYY",
        "dateFormat": "M/D/YYYY",
        "dayFormat": "D",
        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
        "monthFormat": "MMMM",
        "monthBeforeYear": true,
        "previousMonth": "Il mese scorso (PageUp)",
        "nextMonth": "Il mese successivo (PageDown)",
        "previousYear": "L'anno scorso (Control + left)",
        "nextYear": "Il prossimo anno (Control + right)",
        "previousDecade": "Il decennio precedente",
        "nextDecade": "Il decennio successivo",
        "previousCentury": "Il secolo precedente",
        "nextCentury": "Il secolo successivo"
    },
    "timePickerLocale": {
        "placeholder": "Seleziona l'ora"
    },
    "dateFormat": "DD/MM/YYYY",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
};


export {REFERENCE_STATE, DATE_LOCALE}
