import { Wrapper } from 'Common/styled'
import { ActionBar } from 'Components/DossierReferencesTable/styled'
import { Button, Col, Row } from 'antd'
import AgTable from 'Components/AgTable/AgTable'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ROLE_AZ_COMM, ROLE_CPXS, ROLE_CS, ROLE_MERCH } from 'Common/constants/global'
import * as moment from 'moment'
import { feedbackNotification, handleServerError, handleServerSuccess } from 'Common/utils'
import api from 'Common/api'

const baseColumns = [
  {
    title: 'REF LM',
    dataIndex: 'idReferenza',
    key: 'idReferenza',
    searchable: true,
    searchMultiple: true,
    sortable: true,
    align: 'center',
    width: 150
  },
  {
    title: 'DESCRIZIONE',
    dataIndex: 'descrizione',
    key: 'descrizione',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 250
  },
  {
    title: 'SP ANAGRAFICO',
    dataIndex: 'stock',
    contentType: 'decimal',
    precision: 2,
    align: 'center',
    width: 100,
    minValue: 0,
    editable: true,
    role: ROLE_MERCH
  },
  {
    title: 'SP ANAGRAFICO',
    dataIndex: 'stockxs',
    contentType: 'decimal',
    precision: 2,
    align: 'center',
    width: 100,
    minValue: 0,
    editable: true,
    role: ROLE_CPXS
  },
  {
    title: 'GAMMA',
    dataIndex: 'gamma',
    key: 'gamma',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 100
  },
  {
    title: 'NUM PDD',
    dataIndex: 'numPdd',
    key: 'numPdd',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 100
  },
  {
    title: 'DOSSIER',
    dataIndex: 'dossierId',
    key: 'dossierId',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 100
  },
  {
    title: 'STATO',
    dataIndex: 'stato',
    key: 'stato',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 100
  },
  {
    title: 'DATA ATTIVO COMMERCIO',
    dataIndex: 'dataAttivoCommercio',
    key: 'dataAttivoCommercio',
    contentType: 'date',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 150
  },
  {
    title: 'PRECOTOP REAPPRO',
    dataIndex: 'precotopReappro',
    key: 'precotopReappro',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 150
  },
  {
    title: 'REPARTO',
    dataIndex: 'nomeReparto',
    key: 'nomeReparto',
    align: 'center',
    searchable: true,
    sortable: true,
    width: 200
  },
  {
    title: 'SOTTOREPARTO',
    dataIndex: 'nomeSottoreparto',
    key: 'nomeSottoreparto',
    align: 'center',
    searchable: true,
    sortable: true,
    width: 200
  },
  {
    title: 'TIPO',
    dataIndex: 'nomeTipo',
    key: 'nomeTipo',
    align: 'center',
    searchable: true,
    sortable: true,
    width: 200
  },
  {
    title: 'SOTTOTIPO',
    dataIndex: 'nomeSottotipo',
    key: 'nomeSottotipo',
    align: 'center',
    searchable: true,
    sortable: true,
    width: 200
  },
  {
    title: 'QUARTILI',
    dataIndex: 'quartili',
    key: 'quartili',
    searchable: true,
    sortable: true,
    align: 'center',
    width: 150
  },
  {
    title: 'TOP EM',
    dataIndex: 'topEm',
    key: 'topEm',
    contentType: 'bool',
    filtrable: true,
    filters: [
      {value: true, text: 'Sì'},
      {value: false, text: 'No'}
    ],
    filterMultiple: false,
    searchable: true,
    sortable: true,
    align: 'center',
    width: 100
  },
  {
    title: 'TOP EXPO',
    dataIndex: 'topExpo',
    key: 'topExpo',
    contentType: 'bool',
    filtrable: true,
    filters: [
      {value: true, text: 'Sì'},
      {value: false, text: 'No'}
    ],
    filterMultiple: false,
    searchable: true,
    sortable: true,
    align: 'center',
    width: 100
  }
];

const AnagraficaProdottiTable = forwardRef(({ user, products }, ref) => {
  const agTable = useRef();
  const [canSave, setCanSave] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [changedRecords, setChangedRecords] = useState([]);
  const [recordChanges, setRecordChanges] = useState({});
  const [originalRecords, setOriginalRecords] = useState({});
  const columns = [...baseColumns.filter(baseColumn => {
    if (baseColumn.role && baseColumn.role !== user.role) {
      console.log(baseColumn);
      return false;
    }

    return true;
  })];

  useImperativeHandle(ref, () => ({
    showLoadingOverlay: () => {
      if (agTable.current) {
        agTable.current.state.gridApi.showLoadingOverlay();
      }
    },
    hideOverlay: () => {
      if (agTable.current) {
        agTable.current.state.gridApi.hideOverlay();
      }
    }
  }));

  const validateProduct = change => {
    const errors = [];
    const { node } = change;
    node.setDataValue('errors', errors);
  };

  const getAllProducts = () => {
    setOriginalRecords(products.reduce((acc, curr) => ({
      ...acc,
      ['idReferenza:' + curr.id]: {...curr}
    }), {}));

    agTable.current.loadData(products);

    checkForAlertIncomplete();
  };

  const checkForAlertIncomplete = () => {
    const filterInstance = agTable.current.state.gridApi.getFilterInstance('idReferenza');
    const values = products.filter(product => (!product.stock || product.stock === 0) && product.precotopReappro === 1).map(product => product.idReferenza);

    if (values.length > 0) {
      filterInstance.setModel({
        filterType: "set",
        values
      });

      feedbackNotification('warning', 'Ci sono delle referenze incomplete. Abbiamo applicato il filtro per la sistemazione immediata');
    } else {
      filterInstance.setModel(null);
    }

    agTable.current.state.gridApi.onFilterChanged();
  };

  const onAgTableChange = (change) => {
    switch (change.action) {
      case 'cellChange':
        const updatedRecordChanges = {
          ...recordChanges,
          [change.node.id]: change.node
        };

        if (change.colDef && change.colDef.field !== 'errors') {
          validateProduct(change);
        }

        const canSave = Object.keys(updatedRecordChanges).length > 0 && Object.keys(updatedRecordChanges).filter(key => updatedRecordChanges[key].data.errors.length > 0).length === 0;

        setRecordChanges(updatedRecordChanges);
        setCanSave(canSave);

        break;

      case 'gridLoaded':
        getAllProducts();
        break;

      default:
    }
  };

  const getIncompleteProductWarnings = product => {
    const warnings = [];

    if (product.stato === 'AC' && (!product.stock || product.stock === 0) && product.precotopReappro === 1) {
      warnings.push({fields: ['stock'], description: 'Il campo stock deve essere maggiore di zero per referenze in stato AC con precotop reappro a 1'});
    }

    return warnings;
  };

  const handleSaveRecords = () => {
    agTable.current.state.gridApi.showLoadingOverlay();
    const stockVariations = Object.keys(recordChanges).map(key => ({
      reference: recordChanges[key].data.idReferenza,
      stock: recordChanges[key].data.stock,
      stockxs: recordChanges[key].data.stockxs
    }));

    api.references.applyStockVariations(stockVariations).then(response => {
      const failedProducts = response.data.failedProductIds;

      if (failedProducts.length === 0) {
        handleServerSuccess();
      } else {
        feedbackNotification('warning', 'Operazione parziale', `Le seguenti referenze non sono state aggiornate: ${failedProducts.join(', ')}`);
      }

      agTable.current.state.gridApi.hideOverlay();
    }).catch(error => {
      handleServerError(error);
      agTable.current.state.gridApi.hideOverlay();
    });
  };

  let extraStyles = {};

  if (fullscreen) {
    extraStyles = {
      width: '100%',
      height: '100%',
      position: 'fixed',
      backgroundColor: '#ffffff',
      top: 0,
      left: 0,
      padding: '25px 25px 0 25px',
      zIndex: 999
    };
  }

  return (
    <Wrapper style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', ...extraStyles }}>
      <ActionBar>
        <Row gutter={[16, 16]}>
          <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
            <Button icon="save" disabled={Object.keys(recordChanges).length === 0 || !canSave} onClick={handleSaveRecords} >Salva</Button>
          </Col>
          <Col xs={{span: 22, offset: 1}} sm={{span: 12, offset: 0}}>
            <Button icon={fullscreen ? 'fullscreen-exit' : 'fullscreen'} onClick={() => setFullscreen(!fullscreen)} />
          </Col>
        </Row>
      </ActionBar>
      <Row style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', }}>
        <Col style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <AgTable
            canMakeSelection={false}
            ref={agTable}
            columns={columns}
            emitChange={(change) => onAgTableChange(change)}
            getIncompleteProductWarnings={getIncompleteProductWarnings}
          />
        </Col>
      </Row>
    </Wrapper>
  )
});

export default AnagraficaProdottiTable;
