import * as actionTypes from '../action-types'

export const saveDossierFilters = filters => {
  return { type: actionTypes.SAVE_DOSSIER_FILTERS, payload: filters }
}

export const saveOpecomFilters = filters => {
  return { type: actionTypes.SAVE_OPECOM_FILTERS, payload: filters }
}

export const saveFamilyFilters = filters => {
  return { type: actionTypes.SAVE_FAMILY_FILTERS, payload: filters }
}
