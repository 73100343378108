import React, {useRef, useState, useImperativeHandle, forwardRef} from 'react';
import { Input, InputNumber, DatePicker, Checkbox, Tag, Tooltip, Icon } from 'antd'
import * as _ from 'lodash';
import * as moment from 'moment'

export default forwardRef((props, ref) => {
  const inputRef = useRef();
  const {colDef, node, stopEditing, data} = props;
  const [value, setValue] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  function inputHandler(value) {
    setValue(value);
  }

  function blurHandler() {
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => {
        setValue(props.value);
      }
    };
  });

  const handleTagClose = () => {

  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputConfirm();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  const handleInputConfirm = () => {
    if (value.indexOf(inputValue) === -1) {
      setValue([
        ...value,
        inputValue
      ]);
    }

    setInputVisible(false);
    setInputValue('');
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const extra = _.get(colDef, 'cellEditorParams.extra', {});
  const cellEditorParams = _.get(colDef, 'cellEditorParams', {});

  const colType = colDef.type || null;

  return (
    <>
      {value.map((tag, index) => {
        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            key={tag}
            closable={index !== 0}
            onClose={() => handleTagClose(tag)}
          >
              <span>
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
          </Tag>
        );

        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}

      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onKeyDown={handleInputKeyPress}
        />
      )}

      {!inputVisible && (
        <Tag onClick={() => setInputVisible(true)} style={{ background: '#fff', borderStyle: 'dashed' }}>
          <Icon type="plus" /> Nuovo
        </Tag>
      )}
    </>
  )
});
