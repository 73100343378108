import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Checkbox, Col, Input, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDepartments } from 'Actions/data-actions'
import api from 'Common/api'
import { rankedSearch } from 'Common/algorithms'

const ProductsFilterForm = ({ onSearch }) => {
  const dispatch = useDispatch()

  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues: {
      departmentId: undefined,
      subDepartmentId: undefined,
      categoryId: undefined,
      subCategoryId: undefined,
      familyId: undefined,
      productId: undefined,
      productDescription: undefined,
      productStates: undefined,
      productRanges: undefined,
      onlyAvailable: true,
    },
  })

  const departments = useSelector(state => state.Data.departments)
  const [subdepartments, setSubdepartments] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [families, setFamilies] = useState([])
  const [filteredFamilies, setFilteredFamilies] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    dispatch(fetchDepartments())
    api.families
      .getAll()
      .then(({ data }) => {
        setFamilies(data)
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    setFilteredFamilies([])
  }, [families])

  useEffect(() => {
    if (departments.length === 0) return
    setValue('departmentId', departments[0].id)
  }, [departments])

  useEffect(() => {
    const subscription = watch((value, { name, type, ...other }) => {
      if (type !== 'change') return

      switch (name) {
        case 'departmentId':
          setValue('subDepartmentId', undefined)
          setValue('categoryId', undefined)
          setValue('subCategoryId', undefined)
          setSubdepartments([])
          setCategories([])
          setSubcategories([])

          if (!value.departmentId) break

          api.subdepartments
            .get(value.departmentId)
            .then(({ data }) => {
              setSubdepartments(data)
            })
            .catch(console.error)
          break

        case 'subDepartmentId':
          setValue('categoryId', undefined)
          setValue('subCategoryId', undefined)
          setCategories([])
          setSubcategories([])

          if (!value.subDepartmentId) break

          api.categories
            .get(value.departmentId, value.subDepartmentId)
            .then(({ data }) => {
              setCategories(data)
            })
          break

        case 'categoryId':
          setValue('subCategoryId', undefined)
          setSubcategories([])

          if (!value.categoryId) break

          api.subcategories
            .get(
              value.departmentId,
              value.subDepartmentId,
              [value.categoryId],
              false,
            )
            .then(({ data }) => {
              setSubcategories(data)
            })
          break

        default:
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const handleFamilySearch = useCallback(
    searchText => {
      const searchFields = [
        { name: 'idFamily', match: 0, minLen: 0, ranked: true },
        { name: 'name', match: 2, minLen: 3, ranked: true },
      ]

      const results = rankedSearch(searchText, families, searchFields)

      setFilteredFamilies(results.slice(0, 10))
    },
    [families],
  )

  const handleSearch = useCallback(async () => {
    setIsSearching(true)
    try {
      await onSearch(getValues())
    } catch (e) {
      console.error(e)
    } finally {
      setIsSearching(false)
    }
  }, [getValues, onSearch])

  return (
    <form>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="departmentId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Seleziona reparto"
                size="large"
                {...field}>
                {departments
                  .sort((a, b) => a.id - b.id)
                  .map(department => (
                    <Select.Option value={department.id} key={department.id}>
                      {department.id} - {department.label}
                    </Select.Option>
                  ))}
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="subDepartmentId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Seleziona sottoreparto"
                size="large"
                {...field}>
                {subdepartments
                  .sort((a, b) => a.id - b.id)
                  .map(subdepartment => (
                    <Select.Option
                      value={subdepartment.id}
                      key={subdepartment.id}>
                      {subdepartment.id} - {subdepartment.label}
                    </Select.Option>
                  ))}
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="categoryId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Seleziona tipo"
                size="large"
                {...field}>
                {categories
                  .sort((a, b) => a.id - b.id)
                  .map(category => (
                    <Select.Option value={category.id} key={category.id}>
                      {category.id} - {category.label}
                    </Select.Option>
                  ))}
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="subCategoryId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Seleziona sottotipo"
                size="large"
                {...field}>
                {subcategories
                  .sort((a, b) => a.id - b.id)
                  .map(subcategory => (
                    <Select.Option value={subcategory.id} key={subcategory.id}>
                      {subcategory.id} - {subcategory.label}
                    </Select.Option>
                  ))}
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={12}>
          <Controller
            name="familyId"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Seleziona famiglia merceologica"
                size="large"
                onSearch={handleFamilySearch}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                {...field}>
                {filteredFamilies.map(family => (
                  <Select.Option value={family.idFamily} key={family.idFamily}>
                    {family.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="productRanges"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Gamma referenza"
                size="large"
                mode="multiple"
                {...field}>
                <Select.Option value="A">A</Select.Option>
                <Select.Option value="B">B</Select.Option>
                <Select.Option value="C">C</Select.Option>
                <Select.Option value="D">D</Select.Option>
                <Select.Option value="L">L</Select.Option>
                <Select.Option value="P">P</Select.Option>
                <Select.Option value="R">R</Select.Option>
                <Select.Option value="S">S</Select.Option>
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="productStates"
            control={control}
            render={({ field }) => (
              <Select
                showSearch
                placeholder="Stato referenza"
                size="large"
                mode="multiple"
                {...field}>
                <Select.Option value="INI">INI - Inizializzato</Select.Option>
                <Select.Option value="ASI">
                  ASI - Attivo servizi interni
                </Select.Option>
                <Select.Option value="AC">AC - Attivo commercio</Select.Option>
                <Select.Option value="ESUP">
                  ESUP - In soppressione
                </Select.Option>
                <Select.Option value="SUP">SUP - Soppresso</Select.Option>
                <Select.Option value="SUPP">
                  SUPP - Soppressione programmata
                </Select.Option>
                <Select.Option value="PRE">
                  PRE - Pre-Inizializzato
                </Select.Option>
              </Select>
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="productId"
            control={control}
            render={({ field }) => (
              <Input placeholder="ID referenza" size="large" {...field} />
            )}
          />
        </Col>
        <Col span={24} md={12} lg={12}>
          <Controller
            name="productDescription"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Descrizione referenza"
                size="large"
                {...field}
              />
            )}
          />
        </Col>
        <Col span={24} md={12} lg={6}>
          <Controller
            name="onlyAvailable"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={e =>
                  field.onChange({ target: { value: e.target.checked } })
                }>
                Solo disponibili
              </Checkbox>
            )}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={handleSearch} loading={isSearching}>
            {isSearching ? 'Stiamo cercando...' : 'Cerca'}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default ProductsFilterForm
