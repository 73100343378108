import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from "Common/constants";

export const StyledFooter = styled(Layout.Footer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0;
  height: 32px;
  color: ${colors.grey2} !important;
  background-color: ${colors.white} !important;
`
